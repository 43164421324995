import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/authContext';
import UploadProfileMedia from './UploadProfileMedia';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase'; // Adjust the import path as necessary

const EditProfileModal = ({ userData, onClose, handleProfileUpdate }) => {
  const { currentUser } = useAuth(); 
  const [username, setUsername] = useState(userData.username || '');
  const [headline, setHeadline] = useState(userData.headline || '');
  const [avatarURL, setAvatarURL] = useState(userData.avatarURL || '');
  const [bannerURL, setBannerURL] = useState(userData.bannerURL || '');
  const [initialReputation, setInitialReputation] = useState(0);

  useEffect(() => {
    const fetchReputation = async () => {
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          setInitialReputation(userDoc.data().reputation || 0);
        }
      } catch (error) {
        console.error('Error fetching reputation:', error);
      }
    };

    fetchReputation();
  }, [currentUser]);

const calculateReputationChange = () => {
  let repChange = 0;

  if (username !== userData.username) {
    repChange += username ? 5 : (userData.username ? -5 : 0);
  }
  if (headline !== userData.headline) {
    repChange += headline ? 5 : (userData.headline ? -5 : 0);
  }
  if (avatarURL !== userData.avatarURL) {
    repChange += avatarURL ? 5 : (userData.avatarURL ? -5 : 0);
  }
  if (bannerURL !== userData.bannerURL) {
    repChange += bannerURL ? 5 : (userData.bannerURL ? -5 : 0);
  }

  return repChange;
};

  const handleSave = async () => {
    const updatedProfile = {
      username,
      headline,
      avatarURL,
      bannerURL,
    };

    try {
      const reputationChange = calculateReputationChange();
      const newReputation = initialReputation + reputationChange;

      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, { ...updatedProfile, reputation: newReputation });

      handleProfileUpdate(updatedProfile);
      onClose();
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-900 p-6 rounded-lg shadow-lg max-w-md mx-auto text-white">
        <h2 className="text-2xl mb-4">Edit Profile</h2>
        <div className="mb-4">
          <label className="block mb-1">Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="input-field"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-1">Headline</label>
          <input
            type="text"
            value={headline}
            onChange={(e) => setHeadline(e.target.value)}
            className="input-field"
          />
        </div>
        {currentUser && (
          <UploadProfileMedia
            currentUser={currentUser}
            onAvatarURLChange={setAvatarURL}
            onBannerURLChange={setBannerURL}
          />
        )}
        <div className="mt-6 flex justify-between">
          <button
            onClick={onClose}
            className="hustlesmall-button bg-gray-600 hover:bg-gray-500"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="hustlesmall-button bg-blue-600 hover:bg-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProfileModal;

