import React, { useState } from 'react';
import { categories } from '../createlisting/categories'; // Import categories
import DynamicForm from '../DynamicForm'; // Import the DynamicForm component

const CategorySelection = () => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [showForm, setShowForm] = useState(false);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedSubcategory(''); // Reset subcategory when category changes
  };

  const handleSubcategoryChange = (e) => {
    setSelectedSubcategory(e.target.value);
  };

  const handleFormCancel = () => {
    setShowForm(false);
    setSelectedCategory('');
    setSelectedSubcategory('');
  };

  const handleCategorySelection = () => {
    if (selectedCategory && selectedSubcategory) {
      setShowForm(true);
    } else {
      alert('Please select both category and subcategory.');
    }
  };

  const filteredSubcategories = categories.find(cat => cat.name === selectedCategory)?.subcategories || [];

  return (
    <div className="w-full max-w-screen-lg mx-auto p-6 text-white rounded-lg shadow-lg">
      {!showForm ? (
        <div>
          <h2 className="text-xl font-semibold text-center mb-6 text-yellow-400">Select Category and Subcategory</h2>

          <div className="mb-6">
            <label className="text-sm text-yellow-400 font-bold">Category</label>
            <select
              value={selectedCategory}
              onChange={handleCategoryChange}
              className="w-full mt-2 px-3 py-2 text-gray-500 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
            >
              <option value="" disabled>Select a category</option>
              {categories.map((cat, index) => (
                <option key={index} value={cat.name}>{cat.name}</option>
              ))}
            </select>
          </div>

          <div className="mb-6">
            <label className="text-sm text-yellow-400 font-bold">Subcategory</label>
            <select
              value={selectedSubcategory}
              onChange={handleSubcategoryChange}
              disabled={!selectedCategory}
              className="w-full mt-2 px-3 py-2 text-gray-500 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
            >
              <option value="" disabled>Select a subcategory</option>
              {filteredSubcategories.map((subcat, index) => (
                <option key={index} value={subcat}>{subcat}</option>
              ))}
            </select>
          </div>

          <button
            type="button"
            onClick={handleCategorySelection}
            className="w-full px-4 py-2 text-white font-medium rounded-lg hustlesmall-button hover:bg-indigo-700 transition duration-300"
          >
            Proceed to Form
          </button>
        </div>
      ) : (
        <DynamicForm
          category={selectedCategory}
          subcategory={selectedSubcategory}
          onCancel={handleFormCancel}
        />
      )}
    </div>
  );
};

export default CategorySelection;

