import React from 'react';
import { Avatar, Typography, Card, CardContent, Box, Button } from '@mui/material';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const DeliveryElement = ({ avatarURL, username, attachments, createdAt, version }) => {
  return (
    <Card 
      sx={{ 
        marginBottom: 2, 
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 1)', 
        borderRadius: '8px', 
        background: '#1a202c', 
        width: '80%', 
        maxWidth: '600px', 
        margin: '0 auto', 
        padding: '1rem' 
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
          <Avatar src={avatarURL} alt={username} sx={{ marginRight: 2 }} />
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'white' }}>{username}</Typography>
            <Typography variant="body2" color="#fbbf24">
              {new Date(createdAt.seconds * 1000).toLocaleString()}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'white' }}>
            Version: {version}
          </Typography>
        </Box>
        <Box sx={{ marginTop: 1 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', marginBottom: 1, color: 'white' }}>
            Attachments:
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', justifyContent: 'center' }}>
            {attachments.map((attachment, idx) => {
              const urlWithoutParams = attachment.url.split('?')[0];
              const fileExtension = urlWithoutParams.split('.').pop().toLowerCase();
              const isImage = ['jpeg', 'jpg', 'png', 'gif', 'svg'].includes(fileExtension);
              const isPdf = fileExtension === 'pdf';
              const isText = ['txt', 'csv', 'doc', 'docx'].includes(fileExtension);

              return (
                <Card
                  key={idx}
                  sx={{
                    width: '150px',
                    padding: '10px',
                    border: '1px solid #e0e0e0',
                    borderRadius: '8px',
                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    background: 'linear-gradient(135deg, #2d3748, #4a5568)', // Gradient background for each attachment card
                  }}
                >
                  <a href={attachment.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    {isImage ? (
                      <img
                        src={attachment.url}
                        alt={attachment.name}
                        style={{
                          width: '100%',
                          height: '80px',
                          objectFit: 'cover',
                          borderRadius: '4px',
                          marginBottom: '8px',
                        }}
                      />
                    ) : isPdf ? (
                      <PictureAsPdfRoundedIcon sx={{ fontSize: 40, marginBottom: '8px', color: '#d32f2f' }} />
                    ) : (
                      <TextSnippetRoundedIcon sx={{ fontSize: 40, marginBottom: '8px', color: '#1976d2' }} />
                    )}
                  </a>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '0.875rem',
                      overflowWrap: 'break-word',
                      textAlign: 'center',
                      wordWrap: 'break-word',
                      whiteSpace: 'normal',
                      maxWidth: '100%',
                      color: 'white', // Updated text color
                    }}
                  >
                    {attachment.name}
                  </Typography>
                  <Button
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="outlined"
                    size="small"
                    startIcon={<FileDownloadRoundedIcon />}
                    sx={{ marginTop: '8px', fontSize: '0.75rem', color: 'white', borderColor: 'white' }} // Updated button color
                  >
                    Download
                  </Button>
                </Card>
              );
            })}
          </Box>
        </Box>

        {/* Render PDF preview */}
        {attachments.some(attachment => attachment.url.endsWith('.pdf')) && (
          <Box sx={{ marginTop: 2 }}>
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`}>
              {attachments.filter(attachment => attachment.url.endsWith('.pdf')).map((attachment, idx) => (
                <Viewer key={idx} fileUrl={attachment.url} />
              ))}
            </Worker>
          </Box>
        )}

        {/* Render text file snippet preview */}
        {attachments.some(attachment => ['txt', 'csv', 'doc', 'docx'].includes(attachment.url.split('.').pop().toLowerCase())) && (
          <Box sx={{
            marginTop: 2,
            background: 'linear-gradient(135deg, #2d3748, #4a5568)', // Gradient background for text preview box
            borderRadius: '8px',
            padding: '10px',
            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
            fontFamily: 'monospace',
            fontSize: '14px',
            color: '#f7fafc',
            overflow: 'auto',
            maxHeight: '150px'
          }}>
            {attachments.filter(attachment => ['txt', 'csv', 'doc', 'docx'].includes(attachment.url.split('.').pop().toLowerCase())).map((attachment, idx) => (
              <iframe
                key={idx}
                src={attachment.url}
                style={{ width: '100%', height: '100px', border: 'none', backgroundColor: '#1a202c' }}
                title={attachment.name}
              />
            ))}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default DeliveryElement;

