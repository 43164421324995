import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/authContext';
import { db, storage } from '../../firebase/firebase';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import PortfolioGallery from './PortfolioGallery';

const PortfolioSection = ({ portfolio, userId }) => {
  const { currentUser } = useAuth();
  const [isAdding, setIsAdding] = useState(false);
  const [description, setDescription] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [firestoreUser, setFirestoreUser] = useState(null);

  // Fetch user data from Firestore
  useEffect(() => {
    const fetchFirestoreUser = async () => {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setFirestoreUser(userSnap.data());
        } else {
          console.error('No such user in Firestore!');
        }
      }
    };
    fetchFirestoreUser();
  }, [currentUser]);

  // Log the currentUser and firestoreUser for debugging
  useEffect(() => {
    console.log('Auth Current User:', currentUser);
    console.log('Firestore User Data:', firestoreUser);
    console.log('Profile User ID:', userId);
  }, [currentUser, firestoreUser, userId]);

  const handleAddPortfolioClick = () => {
    setIsAdding(true);
  };

  const handleCancel = () => {
    setIsAdding(false);
    setDescription('');
    setCustomerName('');
    setFiles([]);
    setErrorMessage('');
    setSuccessMessage('');
  };

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!currentUser) throw new Error('User not authenticated');

      const portfolioItemId = new Date().toISOString();
      const uploadedUrls = [];

      for (const file of files) {
        const storageRef = ref(storage, `portfolio/${currentUser.uid}/${portfolioItemId}/${file.name}`);
        const uploadResult = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(uploadResult.ref);
        uploadedUrls.push(downloadURL);
      }

      const portfolioItem = {
        description,
        customerName,
        urls: uploadedUrls,
        createdAt: new Date(),
      };

      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, {
        portfolio: arrayUnion(portfolioItem),
      });

      setSuccessMessage('Portfolio item added successfully!');
      setTimeout(() => {
        handleCancel();
      }, 2000);
    } catch (error) {
      console.error('Error adding portfolio item:', error);
      setErrorMessage('Failed to add portfolio item');
    } finally {
      setLoading(false);
    }
  };

  // Debugging: Ensure the component's logic is clear
  console.log('Rendering PortfolioSection');
  console.log('Current User UID:', currentUser?.uid);
  console.log('Profile User ID:', userId);

  // Conditional rendering based on UID match
  if (currentUser?.uid !== userId) {
    console.log('UIDs do not match: Not rendering portfolio actions');
    return <PortfolioGallery portfolio={portfolio} />;
  }

  return (
    <div>
      <div className="mb-4">
        {!isAdding ? (
          <div className="flex justify-end">
            <button
              onClick={handleAddPortfolioClick}
              className="hustlesmall-button"
            >
              Add to Portfolio
            </button>
          </div>
        ) : (
          <div className="p-4 bg-gray-800 rounded-md">
            <h2 className="text-xl mb-4">Add New Portfolio Item</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="field-container">
                <label className="label">Description</label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="textarea-field"
                  required
                />
              </div>
              <div className="field-container">
                <label className="label">Customer Name</label>
                <input
                  type="text"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  className="input-field"
                />
              </div>
              <div className="field-container">
                <label className="label">Files (Images/Videos)</label>
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  className="input-field"
                />
              </div>
              <div className="flex space-x-4">
                <button type="submit" className="hustle-button" disabled={loading}>
                  {loading ? 'Uploading...' : 'Add Portfolio Item'}
                </button>
                <button
                  type="button"
                  onClick={handleCancel}
                  className="hustle-button bg-red-600"
                >
                  Cancel
                </button>
              </div>
            </form>
            {errorMessage && <div className="text-red-500 mt-2">{errorMessage}</div>}
            {successMessage && <div className="text-green-500 mt-2">{successMessage}</div>}
          </div>
        )}
      </div>
      <PortfolioGallery portfolio={portfolio} />
    </div>
  );
};

export default PortfolioSection;

