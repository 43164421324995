import React, { useState, useEffect, useRef } from 'react';
import { db, storage } from '../../../firebase/firebase';
import { doc, updateDoc, onSnapshot, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import UpdateChatMedals from '../../hustlermedals/Update/UpdateChatMedals';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';
import './Chat.css';

const Chat = ({ chatId, users, onClose }) => {
  const { currentUser } = useAuth();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [lightbox, setLightbox] = useState({ isOpen: false, src: '', type: '' });
  const [showMoreCount, setShowMoreCount] = useState(15);
  const [isSupportChat, setIsSupportChat] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [hasBlocked, setHasBlocked] = useState(false);
  const [triggerMedalUpdate, setTriggerMedalUpdate] = useState(false);

  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const navigate = useNavigate();

  const allowedFileTypes = [
    'image/jpeg', 'image/png', 'image/gif', 'image/webp',
    'video/mp4', 'video/webm', 'audio/mp3',
    'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  useEffect(() => {
    if (!chatId) {
      console.error('No chatId provided');
      setError('No chat selected');
      setLoading(false);
      return;
    }

    const checkBlockingStatus = async () => {
      try {
        const chatDoc = await getDoc(doc(db, 'messages', chatId));
        if (chatDoc.exists()) {
          const chatData = chatDoc.data();
          const otherParticipantId = chatData.participants.find(id => id !== currentUser.uid);
          
          const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
          const otherParticipantDoc = await getDoc(doc(db, 'users', otherParticipantId));
          
          if (currentUserDoc.exists() && otherParticipantDoc.exists()) {
            const currentUserData = currentUserDoc.data();
            const otherParticipantData = otherParticipantDoc.data();
            
            if (otherParticipantData.isBlocked?.includes(currentUser.uid)) {
              setIsBlocked(true);
            }
            
            if (currentUserData.isBlocked?.includes(otherParticipantId)) {
              setHasBlocked(true);
            }
          }
        }
      } catch (error) {
        console.error('Error checking block status:', error);
      }
    };

    const unsubscribe = onSnapshot(doc(db, 'messages', chatId), (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setMessages(data.messages || []);
        setIsSupportChat(data.isSupportChat || false);
      } else {
        setError('Chat not found');
      }
      setLoading(false);
    }, (error) => {
      console.error('Error fetching messages:', error);
      setError('Failed to fetch messages');
      setLoading(false);
    });

    checkBlockingStatus();

    return () => unsubscribe();
  }, [chatId, currentUser.uid]);

  useEffect(() => {
    if (messages.length > 0) {
      setFilteredMessages(messages.slice(-showMoreCount));
      if (showMoreCount === 15) {
        messagesContainerRef.current?.scrollTo({
          top: messagesContainerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }
  }, [messages, showMoreCount]);

  useEffect(() => {
    const markMessagesAsRead = async () => {
      try {
        const updatedMessages = messages.map(msg => {
          if (msg.senderId !== currentUser.uid && !msg.isRead) {
            return { ...msg, isRead: true };
          }
          return msg;
        });

        await updateDoc(doc(db, 'messages', chatId), { messages: updatedMessages });
      } catch (err) {
        console.error('Error marking messages as read:', err);
      }
    };

    if (currentUser && messages.length > 0) {
      markMessagesAsRead();
    }
  }, [currentUser, messages, chatId]);

  const handleSend = async (newMessage, file, setNewMessage, setFile, setFilePreview, setError) => {
    if (!newMessage.trim() && !file) {
      setError('Message or file cannot be empty');
      return;
    }

    try {
      let fileUrl = null;
      let fileType = null;

      if (file) {
        if (!file.type || !allowedFileTypes.includes(file.type)) {
          setError('Invalid file type');
          return;
        }

        const storageRef = ref(storage, `files/${chatId}/${file.name}`);
        const snapshot = await uploadBytes(storageRef, file);
        fileUrl = await getDownloadURL(snapshot.ref);
        fileType = file.type;
      }

      const newMessageData = {
        text: newMessage,
        senderId: currentUser.uid,
        timestamp: new Date(),
        isRead: false,
        ...(fileUrl && { fileUrl, fileType }),
      };

      const updatedMessages = [...(messages || []), newMessageData];

      await updateDoc(doc(db, 'messages', chatId), {
        messages: updatedMessages,
        lastChatTime: newMessageData.timestamp,
      });

      setNewMessage('');
      setFile(null);
      setFilePreview(null);
      setError('');

      messagesContainerRef.current?.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });

      setTriggerMedalUpdate((prev) => !prev);

    } catch (err) {
      console.error('Error sending message:', err);
      setError('Failed to send message');
    }
  };

  const renderFileContent = (url, type, text) => {
    if (text === 'User has deleted this message') {
      return null;
    }

    if (type.startsWith('image/')) {
      return (
        <div className="thumbnail" onClick={() => openLightbox(url, type)}>
          <img src={url} alt="Attachment" className="thumbnail-image" />
        </div>
      );
    } else if (type.startsWith('video/')) {
      return (
        <div className="thumbnail" onClick={() => openLightbox(url, type)}>
          <video controls className="thumbnail-video">
            <source src={url} type={type} />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    } else if (type.startsWith('audio/')) {
      return (
        <div className="thumbnail">
          <audio controls className="thumbnail-audio">
            <source src={url} type={type} />
            Your browser does not support the audio tag.
          </audio>
        </div>
      );
    } else if (type === 'application/pdf') {
      return (
        <div className="thumbnail">
          <img src="/path/to/pdf-icon.png" alt="PDF" className="thumbnail-icon" />
          <a href={url} target="_blank" rel="noopener noreferrer" className="text-yellow-400 underline">
            View PDF
          </a>
        </div>
      );
    } else if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return (
        <div className="thumbnail">
          <img src="/path/to/docx-icon.png" alt="DOCX" className="thumbnail-icon" />
          <a href={url} target="_blank" rel="noopener noreferrer" className="text-yellow-400 underline">
            View DOCX
          </a>
        </div>
      );
    } else {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer" className="text-yellow-400 underline">
          View Attachment
        </a>
      );
    }
  };

  const openLightbox = (src, type) => {
    setLightbox({ isOpen: true, src, type });
  };

  const closeLightbox = () => {
    setLightbox({ isOpen: false, src: '', type: '' });
  };

  const handleSearch = async (e) => {
    const queryText = e.target.value.toLowerCase();
    setSearchQuery(queryText);

    if (queryText.trim() === '') {
      setFilteredMessages(messages.slice(-showMoreCount));
    } else {
      setFilteredMessages(messages.filter((msg) => msg.text.toLowerCase().includes(queryText)));
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'Unknown time';
    const date = timestamp.toDate();
    const today = new Date();
    if (date.toDateString() === today.toDateString()) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    return date.toLocaleDateString();
  };

  const handleUserClick = (userId) => {
    if (!isSupportChat) {
      navigate(`/profile/${userId}`);
    }
  };

  const handleShowMore = () => {
    setShowMoreCount((prevCount) => prevCount + 15);
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      const messageIndex = messages.findIndex((msg) => msg.timestamp === messageId);

      const updatedMessages = messages.map((msg, index) => {
        if (index === messageIndex) {
          return { ...msg, text: 'User has deleted this message' };
        }
        return msg;
      });

      await updateDoc(doc(db, 'messages', chatId), { messages: updatedMessages });
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  if (loading) {
    return <div className="text-center text-yellow-400">Loading...</div>;
  }

  return (
    <div className="chat-container bg-gradient-to-b from-gray-800 via-gray-900 to-black text-white rounded-lg shadow-xl">
      <ChatMessages
        messages={messages}
        currentUser={currentUser}
        users={users}
        isSupportChat={isSupportChat}
        handleUserClick={handleUserClick}
        handleDeleteMessage={handleDeleteMessage}
        handleShowMore={handleShowMore}
        filteredMessages={filteredMessages}
        showMoreCount={showMoreCount}
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        formatDate={formatDate}
        renderFileContent={renderFileContent}
        messagesContainerRef={messagesContainerRef}
        messagesEndRef={messagesEndRef}
      />
      {!isBlocked && !hasBlocked && (
        <ChatInput onSend={handleSend} allowedFileTypes={allowedFileTypes} />
      )}
      {isBlocked && (
        <div className="text-red-500 font-bold p-4">You cannot send messages to this user.</div>
      )}
      {hasBlocked && (
        <div className="text-red-500 font-bold p-4">You have blocked this user.</div>
      )}
      {currentUser && <UpdateChatMedals uid={currentUser.uid} triggerUpdate={triggerMedalUpdate} />}
      {lightbox.isOpen && (
        <div className="lightbox bg-black bg-opacity-80 fixed inset-0 z-50 flex items-center justify-center p-4" onClick={closeLightbox}>
          <div className="lightbox-content bg-gray-800 p-4 rounded-lg shadow-lg" onClick={(e) => e.stopPropagation()}>
            {lightbox.type && lightbox.type.startsWith('image/') ? (
              <img src={lightbox.src} alt="Enlarged" className="lightbox-image rounded-lg" />
            ) : lightbox.type && lightbox.type.startsWith('video/') ? (
              <video controls className="lightbox-video rounded-lg">
                <source src={lightbox.src} type={lightbox.type} />
              </video>
            ) : null}
            <button className="lightbox-close text-white text-xl absolute top-2 right-2" onClick={closeLightbox}>×</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;

