import React from 'react';

const PricingPackage = ({ packageType, formState, handlePackageChange, showPackage, setShowPackage }) => (
  <div className="mb-6">
    {showPackage && (
      <div className="space-y-4">
        <input
          type="text"
          name="title"
          value={formState[packageType].title}
          onChange={(e) => handlePackageChange(e, packageType)}
          placeholder={
            packageType === 'standardPackage'
              ? 'Standard Package Title'
              : packageType === 'deluxePackage'
              ? 'Deluxe Package Title'
              : 'Premium Package Title'
          }
          required
          className="input-field w-full px-4 py-3 text-gray-200 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <textarea
          name="description"
          value={formState[packageType].description}
          onChange={(e) => handlePackageChange(e, packageType)}
          placeholder={`Describe what’s included in the ${
            packageType === 'standardPackage'
              ? 'standard'
              : packageType === 'deluxePackage'
              ? 'deluxe'
              : 'premium'
          } package.`}
          maxLength="500"
          required
          className="input-field w-full px-4 py-3 text-gray-200 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          rows="4"
        />
        <input
          type="number"
          name="price"
          value={formState[packageType].price}
          onChange={(e) => handlePackageChange(e, packageType)}
          placeholder="Price in SOL"
          required
          className="input-field w-full px-4 py-3 text-gray-200 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          min="0"
          step="0.01"
        />
        <input
          type="number"
          name="deliveryTime"
          value={formState[packageType].deliveryTime}
          onChange={(e) => handlePackageChange(e, packageType)}
          placeholder="Delivery Time (in days)"
          required
          className="input-field w-full px-4 py-3 text-gray-200 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          min="1"
        />
      </div>
    )}
    <button
      type="button"
      onClick={() => setShowPackage(!showPackage)}
      className={`mt-4 px-6 py-3 text-white font-semibold rounded-lg focus:outline-none hustlesmall-button`}
    >
      {showPackage ? `Remove Package` : `Add Package`}
    </button>
  </div>
);

export default PricingPackage;

