// src/components/notifications/NotificationDropdown.jsx
import React from 'react';

const NotificationDropdown = ({ notifications, onNotificationClick }) => {
  return (
    <div className="p-4">
      <h3 className="text-lg font-semibold mb-2">Notifications</h3>
      {notifications.length > 0 ? (
        <ul>
          {notifications
           .sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate()) // Sorting step
           .map(notification => (
            <li 
              key={notification.id} 
              className={`mb-2 cursor-pointer ${notification.read ? 'bg-gray-700' : 'bg-gray-800'}`}
              onClick={() => onNotificationClick(notification)}
            >
              <div className="p-2 background: linear-gradient(135deg, #2d3748, #4a5568) rounded">
                <p>{notification.message}</p>
                <p className="text-sm white">{new Date(notification.timestamp.toDate()).toLocaleString()}</p>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No notifications found.</p>
      )}
    </div>
  );
};

export default NotificationDropdown;

