import React, { useState } from 'react';
import './faq.css'; // Create a CSS file for specific styles if needed

const FAQ = () => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);

  const toggleQuestion = (index) => {
    setActiveQuestionIndex(activeQuestionIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "How can I enhance my reputation on Hustlez?",
      answer: "You can enhance your reputation by completing your profile, successfully selling and delivering listings, making successful purchases, and receiving positive reviews from other members."
    },
    {
      question: "When will I receive my payment after completing a listing?",
      answer: "Payments are processed automatically within 3 days after the successful completion of a listing, allowing a short period for any potential disputes."
    },
    {
      question: "How is my money secured on Hustlez?",
      answer: "All funds are securely held in either a smart contract that automatically releases the funds upon completion or a multi-signature wallet that requires multiple approvals from Hustlez team members for any transactions."
    },
    {
      question: "What is the duration for a listing to be featured as trending?",
      answer: "A listing will be featured as trending for a duration of 24 hours."
    },
    {
      question: "Why do you request an email address?",
      answer: "Providing an email address is optional and is solely used for sending notifications. Your email address will never be shared with other members."
    }
  ];

  return (
    <div className="faq">
      <h1>Frequently Asked Questions</h1>
      {faqs.map((faq, index) => (
        <div key={index}>
          <div
            className={`question ${activeQuestionIndex === index ? 'active' : ''}`}
            onClick={() => toggleQuestion(index)}
          >
            {faq.question}
          </div>
          <div className={`answer ${activeQuestionIndex === index ? 'block' : ''}`}>
            {faq.answer}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;

