import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, limit, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { useAuth } from '../../contexts/authContext';

const ResponsiveBanner = ({ onSearch }) => {
  const logoSrc = process.env.PUBLIC_URL + '/Hustlezlogo.png';
  const [isVisible, setIsVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [trendingListings, setTrendingListings] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setIsVisible(true);
    fetchTrendingListings();
  }, []);

  useEffect(() => {
    if (trendingListings.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % trendingListings.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [trendingListings]);

  const fetchTrendingListings = async () => {
    try {
      const q = query(collection(db, 'listings'), where('trending', '==', true), limit(10));
      const querySnapshot = await getDocs(q);
      const listings = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const listingsWithUserData = await Promise.all(listings.map(async (listing) => {
        const userDoc = await getDoc(doc(db, 'users', listing.uid));
        const userData = userDoc.exists() ? userDoc.data() : {};
        return { 
          ...listing, 
          userName: userData.username || listing.uid,
          displayPrice: listing.standardPackage && listing.standardPackage.price
            ? listing.standardPackage.price
            : listing.price,
        };
      }));

      setTrendingListings(listingsWithUserData);
    } catch (error) {
      console.error('Error fetching trending listings:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (onSearch) {
      onSearch(e.target.value);
    }
  };

  const handleProtectedLinkClick = (e, path) => {
    if (!currentUser) {
      e.preventDefault();
      navigate('/login');
    } else {
      navigate(path);
    }
  };

  const currentListing = trendingListings[currentIndex];

  return (
    <div className="relative flex justify-center py-10 bg-gradient-to-b from-gray-900 to-black rounded-lg shadow-lg">
      <div className="absolute inset-0 overflow-hidden z-0">
        {currentListing && (
          <>
            <img
              src={currentListing.images[0]}
              alt={currentListing.name}
              className="w-full h-full object-cover opacity-60"
            />
            <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-b from-transparent to-gray-900"></div> 
          </>
        )}
      </div>
      <div className="relative w-full md:w-3/5 py-6 z-10 text-center">
        <div className="flex justify-center items-center relative h-32 mb-4">
          <img
            src={logoSrc}
            alt="Hustlez Logo"
            className={`max-h-24 transition-transform duration-[2000ms] ${
              isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
            }`}
          />
        </div>
        {currentListing && (
          <div className="flex flex-col items-center mt-4 text-white space-y-2">
            <Link 
              to={`/listing/${currentListing.id}`} 
              className="text-3xl font-bold hover:underline"
              onClick={(e) => handleProtectedLinkClick(e, `/listing/${currentListing.id}`)}
            >
              {currentListing.name}
            </Link>
            <Link 
              to={`/profile/${currentListing.uid}`} 
              className="text-yellow-400 hover:underline"
              onClick={(e) => handleProtectedLinkClick(e, `/profile/${currentListing.uid}`)}
            >
              {currentListing.userName}
            </Link>
            <p className="text-lg">{currentListing.subcategory}</p>
            <p className="text-xl font-bold">{currentListing.displayPrice} SOL</p>
          </div>
        )}
        <div className="flex justify-center mt-6">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search for Services"
            className="input-field-2 w-1/3 md:w-1/3 px-4 py-2 text-gray-700 text-center bg-white border border-gray-400 rounded-lg shadow-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-150"
            style={{ fontSize: '16px' }} // Ensures the font size is 16px or larger  
	/>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveBanner;

