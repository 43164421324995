import React, { useEffect, useState } from 'react';
import { FaTelegramPlane, FaTwitter, FaEdit } from 'react-icons/fa';
import EditBioModal from './EditBioModal';
import SendVerificationEmail from './update/SendVerificationEmail';
import EmailVerificationModal from './update/EmailVerificationModal';

const BioSection = ({
  userData: initialUserData, // Rename the prop to distinguish from the state
  authUser,
  handleProfileUpdate,
  formatTelegramHandle
}) => {
  const [userData, setUserData] = useState(initialUserData); // Local state for user data
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [email, setEmail] = useState(userData.email);
  const [emailVerified, setEmailVerified] = useState(userData.emailVerified);
  const [successMessage, setSuccessMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailVerified(false);
  };

useEffect(() => {
  console.log("Modal open state:", isEditModalOpen); // Add this line to check the modal state
  if (isEditModalOpen) {
    document.body.classList.add('lock-scroll');
  } else {
    document.body.classList.remove('lock-scroll');
  }

  return () => {
    document.body.classList.remove('lock-scroll'); // Clean up in case the component unmounts
  };
}, [isEditModalOpen]);


  const handleEmailVerificationSuccess = () => {
    setShowVerificationModal(false);
    setEmailVerified(true);
    setSuccessMessage('Email verified successfully!');
  };

  const handleBioUpdate = (updatedData) => {
    // Update local state with new data
    setUserData((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
    // Also call the parent handler if necessary
    handleProfileUpdate(updatedData);
  };

  return (
    <div className="mt-4">
      {/* Bio Section */}
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold">Bio</h2>
        {authUser.uid === userData.uid && (
          <FaEdit
            className="text-white cursor-pointer hover:text-gray-300"
            onClick={() => setIsEditModalOpen(true)}
          />
        )}
      </div>
      <p className="text-gray-400 whitespace-pre-line">
        {userData.bio ? userData.bio : 'No bio available. Click edit to add one.'}
      </p>

      {/* Location Section */}
      {userData.location?.country && (
        <>
          <h2 className="text-xl font-bold mt-4">Location</h2>
          <p className="text-gray-400">{userData.location.country}</p>
        </>
      )}

      {/* Languages Section */}
      {userData.languages && userData.languages.length > 0 && (
        <>
          <h2 className="text-xl font-bold mt-4">Languages</h2>
          <p className="text-gray-400">{userData.languages.join(', ')}</p>
        </>
      )}

      {/* Skills Section */}
      {userData.skills && userData.skills.length > 0 && (
        <>
          <h2 className="text-xl font-bold mt-4">Skills</h2>
          <ul className="list-disc list-inside">
            {userData.skills.map((skill, index) => (
              <li key={index} className="text-gray-400">
                {skill}
              </li>
            ))}
          </ul>
        </>
      )}

      {/* Social Handles Section */}
      {(userData.telegramHandle || userData.twitterHandle) && (
        <>
          <h2 className="text-xl font-bold mt-4">Social Handles</h2>
          <div className="mt-4 flex items-left justify-left">
            {userData.telegramHandle && (
              <a 
                href={`https://t.me/${formatTelegramHandle(userData.telegramHandle)}`} 
                className="flex items-center text-blue-400 mr-4 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTelegramPlane className="mr-2" /> {userData.telegramHandle}
              </a>
            )}
            {userData.twitterHandle && (
              <a 
                href={`https://x.com/${userData.twitterHandle}`} 
                className="flex items-center text-blue-400 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter className="mr-2" /> {userData.twitterHandle}
              </a>
            )}
          </div>
        </>
      )}

      {/* Email Section (always shown for the user) */}
      {authUser.uid === userData.uid && (
        <div className="field-container relative mt-4">
          <label className="label">Email Address</label>
          <input
            type="email"
            placeholder="Only used for notifications, not shared with members"
            value={email}
            onChange={handleEmailChange}
            className="input-field pr-20"
          />
          {emailVerified ? (
            <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500">Verified</span>
          ) : (
            <SendVerificationEmail email={email} uid={authUser.uid} onVerify={() => setShowVerificationModal(true)} />
          )}
        </div>
      )}

      {/* Email Verification Modal */}
      {showVerificationModal && (
        <EmailVerificationModal
          email={email}
          onClose={() => setShowVerificationModal(false)}
          onVerificationSuccess={handleEmailVerificationSuccess}
        />
      )}

      {/* Edit Bio Modal */}
      {isEditModalOpen && (
        <EditBioModal
          userData={userData}
          onClose={() => setIsEditModalOpen(false)}
          handleProfileUpdate={handleBioUpdate} // Use local update function
        />
      )}
    </div>
  );
};

export default BioSection;

