import React, { useState, useEffect } from 'react';
import { LineChart } from '@mui/x-charts';
import { Paper, Box, Typography } from '@mui/material';
import { getEarningsData } from './utils/analyticsFunctions';

const EarningsChart = ({ freelancerID, timeFrame, subTimeFrame, customMonth, customRange, selectedQuarter }) => {
  const [chartData, setChartData] = useState([]);
  const [xAxisLabels, setXAxisLabels] = useState([]);
  const [yAxisMax, setYAxisMax] = useState(0);
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  useEffect(() => {
    const fetchData = async () => {
      console.log("Fetching data for EarningsChart with current settings:");
      console.log("Time Frame:", timeFrame);
      console.log("Sub Time Frame:", subTimeFrame);
      console.log("Custom Month:", customMonth ? customMonth.format('MMMM YYYY') : 'N/A');
      console.log("Custom Range:", customRange);
      console.log("Selected Quarter:", selectedQuarter);

      let currentRange = customRange;
      let labels = [];
      let monthsInQuarter = [];

      if (timeFrame === 'monthly' && subTimeFrame === 'currentMonth') {
        const now = new Date();
        const daysInMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
        currentRange = {
          start: new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split('T')[0],
          end: new Date(now.getFullYear(), now.getMonth(), daysInMonth).toISOString().split('T')[0],
        };
        labels = Array.from({ length: daysInMonth }, (_, i) => ({ value: i + 1, label: (i + 1).toString() }));
      } else if (timeFrame === 'monthly' && subTimeFrame === 'customMonth' && customMonth) {
        const daysInMonth = customMonth.daysInMonth();
        labels = Array.from({ length: daysInMonth }, (_, i) => ({ value: i + 1, label: (i + 1).toString() }));
      } else if (timeFrame === 'quarterly' && selectedQuarter) {
        const now = new Date();
        const quarterStartMonth = (selectedQuarter - 1) * 3;
        const quarterEndMonth = quarterStartMonth + 2;

        currentRange = {
          start: new Date(now.getFullYear(), quarterStartMonth, 1).toISOString().split('T')[0],
          end: new Date(now.getFullYear(), quarterEndMonth + 1, 0).toISOString().split('T')[0],
        };

        monthsInQuarter = Array.from({ length: 3 }, (_, i) => quarterStartMonth + i + 1);
        labels = monthsInQuarter.map(month => ({ value: month, label: monthNames[month - 1] }));
      } else if (timeFrame === 'yearly') {
        const now = new Date();
        currentRange = {
          start: new Date(now.getFullYear(), 0, 1).toISOString().split('T')[0],
          end: new Date(now.getFullYear(), 11, 31).toISOString().split('T')[0],  // Full year range
        };

        labels = monthNames.map((month, index) => ({ value: index + 1, label: month }));
      }

      console.log("Current Range:", currentRange);
      console.log("Generated Labels:", labels);

      if (currentRange && typeof currentRange.start === 'string') {
        currentRange.start = new Date(currentRange.start);
      }
      if (currentRange && typeof currentRange.end === 'string') {
        currentRange.end = new Date(currentRange.end);
      }

      const data = await getEarningsData(freelancerID, timeFrame, subTimeFrame, currentRange, selectedQuarter);

      console.log("Raw Data:", data);
      console.log("Dates Array:", data.dates);
      console.log("Earnings Array:", data.earnings);

      if (data && data.dates && data.dates.length) {
        const now = new Date();
        const filteredData = data.dates.map((date, index) => {
          let xValue;

          if (timeFrame === 'quarterly' || timeFrame === 'yearly') {
            const month = parseInt(date.split('-')[1], 10);
            xValue = month;
          } else {
            xValue = parseInt(date, 10);
          }

          const yValue = parseFloat(data.earnings[index] || 0);

          // Filter out future dates
          const dataDate = new Date(date);
          if (dataDate > now) {
            return null;
          }

          console.log(`Data Point - Date: ${date}, X Value: ${xValue}, Y Value: ${yValue}`);

          if (isNaN(xValue) || isNaN(yValue)) {
            console.error(`Invalid data point found - X Value: ${xValue}, Y Value: ${yValue}`);
            return null;
          }

          return {
            x: xValue,
            y: yValue,
          };
        }).filter(point => point !== null);

        console.log("Filtered and Formatted Data:", filteredData);

        if (timeFrame === 'quarterly' || timeFrame === 'yearly') {
          const monthsInPeriod = timeFrame === 'quarterly' ? monthsInQuarter : Array.from({ length: 12 }, (_, i) => i + 1);
          const aggregatedData = monthsInPeriod.map(month => {
            const totalEarnings = filteredData.reduce((acc, point) => {
              return point.x === month ? acc + point.y : acc;
            }, 0);

            return {
              x: month,
              y: totalEarnings,
            };
          });

          const maxEarning = Math.max(...aggregatedData.map(item => item.y));
          setYAxisMax(maxEarning + (maxEarning * 0.1));

          setChartData(aggregatedData);
        } else {
          const maxEarning = Math.max(...filteredData.map(item => item.y));
          setYAxisMax(maxEarning + (maxEarning * 0.1));

          setChartData(filteredData);
        }

        setXAxisLabels(labels.length ? labels : filteredData.map(item => ({ value: item.x, label: item.x.toString() })));
      } else {
        console.log("No data available for the current query.");
        setChartData([]);
        setXAxisLabels(labels);
      }
    };

    fetchData();
  }, [freelancerID, timeFrame, subTimeFrame, customMonth, customRange, selectedQuarter]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        height: 400,
        backgroundColor: 'transparent', // Make the background transparent
      }}
    >
      {chartData.length > 0 ? (
        <LineChart
          width={600}
          height={400}
          xAxis={[{
            scaleType: 'band',
            dataKey: 'x',
            ticks: xAxisLabels.map(label => label.value),
            valueFormatter: (value, context) => {
              if (context.location === 'tick') {
                return xAxisLabels.find((label) => label.value === value)?.label;
              }
              return `${xAxisLabels.find((label) => label.value === value)?.label} (${value})`;
            },
            min: Math.min(...xAxisLabels.map(label => label.value)),
            max: Math.max(...xAxisLabels.map(label => label.value)),
          }]}
          yAxis={[{ label: 'Earnings (SOL)', min: 0, max: yAxisMax }]}
          series={[{ dataKey: 'y', label: 'Earnings (SOL)' }]}
          dataset={chartData}
          grid={{ vertical: true, horizontal: true }}
        />
      ) : (
        <Typography variant="body1">No data available for the selected period.</Typography>
      )}
    </Box>
  );
};

export default EarningsChart;

