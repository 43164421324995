// src/components/adminDashboard/dashboardRoutes.js
import { MdHome, MdAssessment, MdPayment, MdGavel, MdTrendingUp } from "react-icons/md";
import PaymentDistributionManagement from './pages/PaymentDistributionManagement';


const routes = [
  {
    type: "collapse",
    name: "Dashboard Home",
    key: "dashboard-home",
    route: "/admin-dashboard",
    IconComponent: MdHome,

  },
  {
    type: "collapse",
    name: "User Metrics",
    key: "user-metrics",
    route: "/admin-dashboard/user-metrics",
    IconComponent: MdAssessment,
  },
  {
    type: "collapse",
    name: "Payment Distribution Management",
    key: "payment-distribution",
    route: "/admin-dashboard/payment-distribution",
    IconComponent: MdPayment,
  },
  {
    type: "collapse",
    name: "Dispute Management",
    key: "dispute-management",
    route: "/admin-dashboard/dispute-management",
    IconComponent: MdGavel,
  },
  {
    type: "collapse",
    name: "Manage Trending",
    key: "manage-trending",
    route: "/manage-trending",
    IconComponent: MdTrendingUp,
  },
];

export default routes;

