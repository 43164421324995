import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase/firebase'; // Adjust the import path accordingly
import { collection, getDoc, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { useAuth } from '../../../contexts/authContext'; // Adjust the import path accordingly

const ManageTrendingListings = () => {
  const { currentUser } = useAuth();
  const [message, setMessage] = useState('');

  useEffect(() => {
    const checkUserPermissions = async () => {
      if (!currentUser) {
        setMessage('Access denied.');
        return;
      }

      try {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.isAdmin !== true) {
            setMessage('Access denied.');
            return;
          }
        } else {
          setMessage('Access denied.');
          return;
        }

        const now = new Date();
        const oneDayAgo = new Date(now);
        oneDayAgo.setDate(now.getDate() - 1);

        const listingsRef = collection(db, 'listings');
        const trendingQuery = query(
          listingsRef,
          where('trending', '==', true),
          where('trendingDate', '<=', oneDayAgo)
        );

        const querySnapshot = await getDocs(trendingQuery);

        if (querySnapshot.empty) {
          setMessage('No trending listings need to be updated.');
          return;
        }

        querySnapshot.forEach(async (listingDoc) => {
          const listingRef = doc(db, 'listings', listingDoc.id);
          await updateDoc(listingRef, { trending: false });
        });

        setMessage('Trending listings updated successfully.');
      } catch (error) {
        console.error('Error updating trending listings:', error);
        setMessage('An error occurred while updating trending listings.');
      }
    };

    checkUserPermissions();
  }, [currentUser]);

  return (
    <div>
      <h1>Manage Trending Listings</h1>
      <p>{message}</p>
    </div>
  );
};

export default ManageTrendingListings;

