import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, Typography, Box } from '@mui/material';
import dayjs from 'dayjs';

const CompletedPayments = () => {
  const [completedPayments, setCompletedPayments] = useState([]);

  useEffect(() => {
    const fetchCompletedPayments = async () => {
      try {
        const now = dayjs();
        const cutoffDate = now.subtract(72, 'hour').toDate();

        // Query to fetch projects with completed status within the last 72 hours
        const completedQuery = query(
          collection(db, 'projects'),
          where('status', '==', 'completed'),
          where('completedAt', '>', cutoffDate), // Only include projects completed within the last 72 hours
          orderBy('completedAt', 'desc')
        );

        const completedSnapshot = await getDocs(completedQuery);
        const paymentsList = completedSnapshot.docs.map(doc => {
          const data = doc.data();
          console.log("Project data:", data); // Log the project data to inspect

          return {
            orderID: data.projectID || doc.id, // Use projectID if available, else fall back to doc.id
            amountPaid: data.amountEarned || 'N/A',
            dateCompleted: data.completedAt ? data.completedAt.toDate().toLocaleDateString() : 'N/A',
          };
        });

        setCompletedPayments(paymentsList);
      } catch (error) {
        console.error("Error fetching completed payments:", error);
      }
    };

    fetchCompletedPayments();
  }, []);

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <Box mb={2}>
        <Typography variant="h6">
          Completed Projects
        </Typography>
      </Box>
      <TableContainer sx={{ maxHeight: 540, overflowY: 'auto' }}>
        <Table aria-label="completed payments table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell align="center">Amount Paid (SOL)</TableCell>
              <TableCell align="center">Completed On</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {completedPayments.map((payment, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <Link href={`/order-tracking/${payment.orderID}`} underline="hover">
                    {payment.orderID}
                  </Link>
                </TableCell>
                <TableCell align="center">{payment.amountPaid}</TableCell>
                <TableCell align="center">{payment.dateCompleted}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CompletedPayments;

