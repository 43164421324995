import React, { useState, useEffect } from 'react';
import { categories } from '../../listings/createlisting/categories';
import { db } from '../../../firebase/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import ListingCard from '../../listings/alllistings/ListingCard';
import { FaSort } from 'react-icons/fa';
import './CategoryGallery.css';
import FadeInSection from './FadeInSection';

const CategoryGallery = () => {
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [listings, setListings] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [sortOption, setSortOption] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [budget, setBudget] = useState('');
  const [defaultSort, setDefaultSort] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const listingsPerPage = 9;

  const sortOptions = [
    { value: 'highest-rated', label: 'Highest Rated' },
    { value: 'name-asc', label: 'Name Ascending' },
    { value: 'name-desc', label: 'Name Descending' },
  ];

  const fetchListings = async (subcategory) => {
    try {
      const q = query(
        collection(db, 'listings'),
        where('subcategory', '==', subcategory)
      );
      const querySnapshot = await getDocs(q);
      const listingsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      const filteredListings = listingsData.filter((listing) => {
        const price = parseFloat(listing.price) || 0;
        return price <= parseFloat(budget) || budget === '';
      });

      const sortedListings = filteredListings.sort((a, b) => {
        if (defaultSort) {
          if (a.trending && !b.trending) return -1;
          if (!a.trending && b.trending) return 1;
          return b.createdAt - a.createdAt;
        } else {
          if (sortOption === 'name-asc') {
            return a.name.localeCompare(b.name);
          } else if (sortOption === 'name-desc') {
            return b.name.localeCompare(a.name);
          } else if (sortOption === 'highest-rated') {
            return (b.averageRating || 0) - (a.averageRating || 0);
          }
          return 0;
        }
      });

      setListings(sortedListings);
    } catch (error) {
      console.error('Error fetching listings:', error);
      setErrorMessage('Failed to fetch listings');
    }
  };

  useEffect(() => {
    if (selectedSubcategory) {
      fetchListings(selectedSubcategory);
      setCurrentPage(1);
    }
  }, [sortOption, selectedSubcategory, budget]);

  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListing = indexOfLastListing - listingsPerPage;
  const currentListings = listings.slice(indexOfFirstListing, indexOfLastListing);

  const handleCategoryClick = (categoryName) => {
    if (expandedCategory === categoryName) {
      setExpandedCategory(null);
    } else {
      setExpandedCategory(categoryName);
      setSelectedSubcategory(null);
      setListings([]);
    }
  };

  const handleSubcategoryClick = (subcategory) => {
    setSelectedSubcategory(subcategory);
    setExpandedCategory(null);
  };

  const handleBackButtonClick = () => {
    setExpandedCategory(null);
    setSelectedSubcategory(null);
    setListings([]);
  };

  const handleSortChange = (option) => {
    setSortOption(option);
    setDefaultSort(false);
    setIsModalVisible(false);
  };

  const handleBudgetChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value) && value >= 0) {
      setBudget(value);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(listings.length / listingsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="relative text-white min-h-screen">
      <FadeInSection>
        <h1 className="text-5xl font-extrabold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-white via-gray-300 to-white animate-fade-in p-4">
          Explore Our Categories
        </h1>
      </FadeInSection>
      {selectedSubcategory ? (
        <>
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <h1 className="text-4xl font-extrabold mb-6 text-gray-300 mr-4">
                Listings in {selectedSubcategory}
              </h1>
              <button
                onClick={handleBackButtonClick}
                className="hustlesmall-button"
              >
                Back
              </button>
            </div>
            <div className="relative">
              <div
                className="text-xl cursor-pointer hover:text-gray-300 flex items-center"
                onClick={() => setIsModalVisible(true)}
              >
                <FaSort className="mr-2" />
                <span>Sort</span>
              </div>
              {isModalVisible && (
                <div className="fixed inset-0 bg-gray-900 flex bg-opacity-75 z-50 items-center justify-center">
                  <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg">
                    <h2 className="text-2xl font-bold mb-4">Sort & Filter</h2>
                    <div>
                      <h3 className="text-xl mb-2">Sort by:</h3>
                      {sortOptions.map((option) => (
                        <button
                          key={option.value}
                          className={`block w-full text-left px-4 py-2 rounded ${sortOption === option.value ? 'bg-gray-700' : ''}`}
                          onClick={() => handleSortChange(option.value)}
                        >
                          {option.label}
                        </button>
                      ))}
                    </div>
                    <div className="mt-4">
                      <h3 className="text-xl mb-2">Budget (SOL):</h3>
                      <input
                        type="text"
                        value={budget}
                        onChange={handleBudgetChange}
                        className="w-full p-2 bg-gray-700 rounded"
                        placeholder="Enter budget in SOL"
                      />
                    </div>
                    <button
                      onClick={() => setIsModalVisible(false)}
                      className="mt-4 px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition duration-300"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {errorMessage && <div className="text-red-500 font-bold mb-4">{errorMessage}</div>}
          {currentListings.length === 0 ? (
            <p className="text-gray-400">No listings available</p>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {currentListings.map((listing) => (
                <ListingCard key={listing.id} listing={listing} />
              ))}
            </div>
          )}
          <div className="flex justify-between mt-4">
            <button
              onClick={handlePreviousPage}
              className={`p-2 ${currentPage === 1 ? 'text-gray-500 cursor-not-allowed' : 'text-white hover:text-gray-300'} transition duration-300`}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              onClick={handleNextPage}
              className={`p-2 ${currentPage === Math.ceil(listings.length / listingsPerPage) ? 'text-gray-500 cursor-not-allowed' : 'text-white hover:text-gray-300'} transition duration-300`}
              disabled={currentPage === Math.ceil(listings.length / listingsPerPage)}
            >
              Next
            </button>
          </div>
        </>
      ) : (
        <>
        {expandedCategory && (
  <div
    className="subcategory-modal"
    onClick={() => setExpandedCategory(null)}
  >
    <div
      className="modal-content"
      onClick={(e) => e.stopPropagation()} /* Prevent closing modal when clicking inside */
    >
      <button
        onClick={handleBackButtonClick}
        className="close-button"
      >
        X
      </button>
      <h1 className="modal-title">Select a Subcategory in {expandedCategory}</h1>
      <div className="medals-grid">
        {categories.find((cat) => cat.name === expandedCategory).subcategories.map((subcategory) => (
          <div
            key={subcategory}
            onClick={() => handleSubcategoryClick(subcategory)}
            className="subcategory-option"
          >
            {subcategory}
          </div>
        ))}
      </div>
    </div>
  </div>
)}  
        <FadeInSection>
  <div className="category-container">
    {categories.map((category) => (
      <div
        key={category.name}
        className={`category-card ${expandedCategory === category.name ? 'scale-105' : ''}`}
        onClick={() => handleCategoryClick(category.name)}
      >
        <div className="text-center mb-2">
          {React.cloneElement(category.icon, { size: '2em', className: 'inline-block mb-2' })}
        </div>
        <h2 className="text-xl font-semibold mb-4 whitespace-normal">{category.name}</h2>
      </div>
    ))}
  </div>
</FadeInSection>
	</>
      )}
    </div>
  );
};

export default CategoryGallery;

