import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-root">
      <div className="privacy-container">
        <div className="privacy-header">
          <h1>Privacy Policy for Hustlez</h1>
        </div>
        <div className="privacy-section">
          <h2>Introduction</h2>
          <p>
            Welcome to Hustlez! This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our platform. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the platform.
          </p>
        </div>
        <div className="privacy-section">
          <h2>Information We Collect</h2>
          <ul>
            <li>
              <strong>Email Addresses</strong>: We require email addresses for account creation and communication purposes. Your email address will never be shown to other users.
            </li>
            <li>
              <strong>Usage Data</strong>: We may collect information about your interactions with the platform, such as the pages you visit, the time spent on those pages, and other diagnostic data.
            </li>
            <li>
              <strong>Cookies</strong>: We use cookies and similar tracking technologies to track the activity on our platform and store certain information. Cookies are files with a small amount of data that may include an anonymous unique identifier.
            </li>
          </ul>
        </div>
        <div className="privacy-section">
          <h2>How We Use Your Information</h2>
          <ul>
            <li>To provide, operate, and maintain our platform.</li>
            <li>To improve, personalize, and expand our platform.</li>
            <li>To understand and analyze how you use our platform.</li>
            <li>To develop new products, services, and features.</li>
            <li>To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the platform, and for marketing and promotional purposes.</li>
            <li>To process your transactions and manage your orders.</li>
            <li>To find and prevent fraud.</li>
          </ul>
        </div>
        <div className="privacy-section">
          <h2>How We Share Your Information</h2>
          <p>
            We do not share your personal information with third parties except as necessary to provide our services, comply with the law, protect our rights, or as otherwise described in this Privacy Policy.
          </p>
        </div>
        <div className="privacy-section">
          <h2>Security of Your Information</h2>
          <p>
            We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
          </p>
        </div>
        <div className="privacy-section">
          <h2>Policy for Children</h2>
          <p>
            We do not knowingly solicit information from or market to children under the age of 13. If we learn that we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible. If you believe we might have any information from or about a child under 13, please contact us.
          </p>
        </div>
        <div className="privacy-section">
          <h2>Changes to This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
          </p>
        </div>
        <div className="privacy-section">
          <h2>Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy, please contact us in our discord.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

