import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography } from '@mui/material';

const ContractDetails = ({ order, listingDetails, paymentDetails }) => {
  if (!order || !listingDetails || !paymentDetails) {
    return <Typography>No contract details available.</Typography>;
  }

  const { selectedPackage, projectID, deliveryTime, dueDate } = order;
  const packageDetails = selectedPackage === 'standard' ? listingDetails.standardPackage : listingDetails.premiumPackage;

  const price = paymentDetails.totalPaid || 'N/A';
  const createdAt = new Date(paymentDetails.timestamp.seconds * 1000);

  return (
    <TableContainer
      component={Paper}
      style={{
        backgroundColor: '#1f2937', // Dark background color to match the profile banner
        color: '#ffffff', // White text color
        padding: '16px',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        style={{
          backgroundColor: '#2d3748', // Darker background for the title to differentiate
          padding: '12px',
          borderRadius: '8px',
          textAlign: 'center',
          fontSize: '1.5rem',
          fontWeight: 'bold',
        }}
      >
        Contract Details
      </Typography>
      <Table aria-label="contract details" style={{ color: '#ffffff' }}>
        <TableBody>
          <TableRow>
            <TableCell style={{ color: '#9ca3af', fontWeight: 'bold' }}>Package Level:</TableCell>
            <TableCell style={{ color: '#ffffff' }}>{selectedPackage}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ color: '#9ca3af', fontWeight: 'bold' }}>Price:</TableCell>
            <TableCell style={{ color: '#ffffff' }}>{price} SOL</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ color: '#9ca3af', fontWeight: 'bold' }}>Order Placed:</TableCell>
            <TableCell style={{ color: '#ffffff' }}>{createdAt.toLocaleDateString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ color: '#9ca3af', fontWeight: 'bold' }}>Delivery Time:</TableCell>
            <TableCell style={{ color: '#ffffff' }}>{deliveryTime !== 'N/A' ? `${deliveryTime} days` : 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ color: '#9ca3af', fontWeight: 'bold' }}>Due Date:</TableCell>
            <TableCell style={{ color: '#ffffff' }}>{dueDate !== 'N/A' ? new Date(dueDate.seconds * 1000).toLocaleDateString() : 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ color: '#9ca3af', fontWeight: 'bold' }}>Revisions:</TableCell>
            <TableCell style={{ color: '#ffffff' }}>{packageDetails.revisions || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ color: '#9ca3af', fontWeight: 'bold' }}>Order ID:</TableCell>
            <TableCell style={{ color: '#ffffff' }}>{projectID}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ContractDetails;

