import React, { useState } from 'react';
import { useAuth } from '../../../contexts/authContext';
import { db, storage } from '../../../firebase/firebase';
import { doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';

const AddPortfolio = () => {
  const { currentUser } = useAuth();
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!currentUser) throw new Error('User not authenticated');

      // Generate a unique ID for the portfolio item
      const portfolioItemId = new Date().toISOString();
      
      // Upload files to storage
      const uploadedUrls = [];
      for (const file of files) {
        const storageRef = ref(storage, `portfolio/${currentUser.uid}/${portfolioItemId}/${file.name}`);
        const uploadResult = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(uploadResult.ref);
        uploadedUrls.push(downloadURL);
      }

      // Create a reference to the user's portfolio subcollection
      const userPortfolioRef = doc(db, 'users', currentUser.uid, 'portfolio', portfolioItemId);

      // Create a reference to the top-level portfolio collection
      const topLevelPortfolioRef = doc(db, 'portfolio', portfolioItemId);

      // Add the portfolio item to both the user's portfolio subcollection and top-level portfolio collection
      const portfolioData = {
        userId: currentUser.uid,
        description,
        customerName,
        urls: uploadedUrls,
        timestamp: new Date()
      };

      await setDoc(userPortfolioRef, portfolioData);
      await setDoc(topLevelPortfolioRef, portfolioData);

      console.log("Portfolio item added successfully");
      navigate('/profile'); // Navigate back to the profile page
    } catch (error) {
      console.error('Error adding portfolio item:', error);
      setErrorMessage('Failed to add portfolio item. Please check your permissions.');
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <div className="p-4 max-w-2xl mx-auto">
      <h2 className="text-2xl font-semibold mb-4">Add Portfolio Item</h2>
      {errorMessage && <div className="text-red-600 font-bold mb-4">{errorMessage}</div>}
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-bold mb-2">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>

        <div>
          <label className="block text-sm font-bold mb-2">Customer Name</label>
          <input
            type="text"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>

        <div>
          <label className="block text-sm font-bold mb-2">Files (Images/Videos)</label>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>

        <div className="flex space-x-4">
          <button
            type="submit"
            className="hustle-button px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
            disabled={loading}
          >
            {loading ? 'Uploading...' : 'Add Portfolio Item'}
          </button>
          <button
            type="button"
            className="hustle-button px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
            onClick={handleGoBack}
          >
            Go Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddPortfolio;

