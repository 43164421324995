import React, { useEffect, useState } from 'react';
import { MaterialUIControllerProvider } from './context/MaterialUIContext';
import dayjs from 'dayjs';
import { Grid, Container, Box } from '@mui/material';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import CompletedPayments from './dashboardComponents/CompletedPayments';
import PaymentsReadyForDistribution from './dashboardComponents/PaymentsReadyForDistribution';
import Disputes from './dashboardComponents/Disputes';
import DisputeTakeover from './dashboardComponents/DisputeTakeover';
import InfoCard from './dashboardComponents/InfoCard';
import AdminSearchBar from './dashboardComponents/AdminSearchBar';
import Sidenav from './dashboardComponents/sidebar/Sidenav';
import routes from './dashboardComponents/dashboardRoutes';

const Dashboard = () => {
  const [newUsers, setNewUsers] = useState(0);
  const [newUsersPercentChange, setNewUsersPercentChange] = useState(0);
  const [premiumUsers, setPremiumUsers] = useState(0);
  const [premiumUsersPercentChange, setPremiumUsersPercentChange] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [activeUsersPercentChange, setActiveUsersPercentChange] = useState(0);

  useEffect(() => {
    const fetchNewUsers = async () => {
      const now = dayjs();

      const startOfMonth = now.startOf('month').toDate();
      console.log("Start of current month:", startOfMonth);

      const startOfPreviousMonth = now.subtract(1, 'month').startOf('month').toDate();
      const endOfPreviousMonth = now.startOf('month').subtract(1, 'day').endOf('day').toDate();

      console.log("Start of previous month:", startOfPreviousMonth);
      console.log("End of previous month:", endOfPreviousMonth);

      try {
        const currentMonthQuery = query(
          collection(db, 'users'),
          where('createdAt', '>=', Timestamp.fromDate(startOfMonth))
        );

        const previousMonthQuery = query(
          collection(db, 'users'),
          where('createdAt', '>=', Timestamp.fromDate(startOfPreviousMonth)),
          where('createdAt', '<=', Timestamp.fromDate(endOfPreviousMonth))
        );

        const currentMonthSnapshot = await getDocs(currentMonthQuery);
        const previousMonthSnapshot = await getDocs(previousMonthQuery);

        console.log("Current month user count:", currentMonthSnapshot.size);
        console.log("Previous month user count:", previousMonthSnapshot.size);

        setNewUsers(currentMonthSnapshot.size);

        const change = previousMonthSnapshot.size === 0
          ? 100
          : ((currentMonthSnapshot.size - previousMonthSnapshot.size) / previousMonthSnapshot.size) * 100;

        setNewUsersPercentChange(change.toFixed(2));
      } catch (error) {
        console.error("Error fetching new users:", error);
      }
    };

    const fetchPremiumUsers = async () => {
      const now = dayjs();
      const startOfMonth = now.startOf('month').toDate();
      const startOfPreviousMonth = now.subtract(1, 'month').startOf('month').toDate();
      const endOfPreviousMonth = now.startOf('month').subtract(1, 'day').endOf('day').toDate();

      console.log("Fetching Premium Users:");
      console.log("Start of current month:", startOfMonth);
      console.log("Start of previous month:", startOfPreviousMonth);
      console.log("End of previous month:", endOfPreviousMonth);

      try {
        const currentMonthQuery = query(
          collection(db, 'payments'),
          where('paymentType', '==', 'Premium Subscription'),
          where('timestamp', '>=', Timestamp.fromDate(startOfMonth))
        );

        const previousMonthQuery = query(
          collection(db, 'payments'),
          where('paymentType', '==', 'Premium Subscription'),
          where('timestamp', '>=', Timestamp.fromDate(startOfPreviousMonth)),
          where('timestamp', '<=', Timestamp.fromDate(endOfPreviousMonth))
        );

        const currentMonthSnapshot = await getDocs(currentMonthQuery);
        const previousMonthSnapshot = await getDocs(previousMonthQuery);

        console.log("Current month premium user count:", currentMonthSnapshot.size);
        console.log("Previous month premium user count:", previousMonthSnapshot.size);

        setPremiumUsers(currentMonthSnapshot.size);

        const change = previousMonthSnapshot.size === 0
          ? 100
          : ((currentMonthSnapshot.size - previousMonthSnapshot.size) / previousMonthSnapshot.size) * 100;

        setPremiumUsersPercentChange(change.toFixed(2));
      } catch (error) {
        console.error("Error fetching premium users:", error);
      }
    };

    const fetchActiveUsers = async () => {
      const now = dayjs();
      const startOfMonth = now.startOf('month').toDate();
      const startOfPreviousMonth = now.subtract(1, 'month').startOf('month').toDate();
      const endOfPreviousMonth = now.startOf('month').subtract(1, 'day').endOf('day').toDate();

      console.log("Fetching Active Users:");
      console.log("Start of current month:", startOfMonth);
      console.log("Start of previous month:", startOfPreviousMonth);
      console.log("End of previous month:", endOfPreviousMonth);

      try {
        const currentMonthQuery = query(
          collection(db, 'users'),
          where('lastLogin', '>=', Timestamp.fromDate(startOfMonth))
        );

        const previousMonthQuery = query(
          collection(db, 'users'),
          where('lastLogin', '>=', Timestamp.fromDate(startOfPreviousMonth)),
          where('lastLogin', '<=', Timestamp.fromDate(endOfPreviousMonth))
        );

        const currentMonthSnapshot = await getDocs(currentMonthQuery);
        const previousMonthSnapshot = await getDocs(previousMonthQuery);

        console.log("Current month active user count:", currentMonthSnapshot.size);
        console.log("Previous month active user count:", previousMonthSnapshot.size);

        setActiveUsers(currentMonthSnapshot.size);

        const change = previousMonthSnapshot.size === 0
          ? 100
          : ((currentMonthSnapshot.size - previousMonthSnapshot.size) / previousMonthSnapshot.size) * 100;

        setActiveUsersPercentChange(change.toFixed(2));
      } catch (error) {
        console.error("Error fetching active users:", error);
      }
    };

    fetchNewUsers();
    fetchPremiumUsers();
    fetchActiveUsers();
  }, []);

  return (
    <MaterialUIControllerProvider>
      <Box sx={{ display: 'flex' }}>
        <Sidenav brandName="YourBrand" routes={routes} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AdminSearchBar />
              </Grid>
              {/* InfoCards */}
              <Grid item xs={12} sm={6} md={4}>
                <InfoCard
                  title="New Users (Month to Date)"
                  value={newUsers}
                  percentChange={newUsersPercentChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InfoCard
                  title="Active Users (Month to Date)"
                  value={activeUsers}
                  percentChange={activeUsersPercentChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InfoCard
                  title="Premium Users (Month to Date)"
                  value={premiumUsers}
                  percentChange={premiumUsersPercentChange}
                />
              </Grid>

              {/* Completed Payments and Payments Ready for Distribution Side by Side */}
              <Grid item xs={12} md={6}>
                <CompletedPayments />
              </Grid>
              <Grid item xs={12} md={6}>
                <PaymentsReadyForDistribution detailedView={false} />
              </Grid>

              {/* Open Disputes and Dispute Takeover Side by Side */}
              <Grid item xs={12} md={6}>
                <Disputes />
              </Grid>
              <Grid item xs={12} md={6}>
                <DisputeTakeover />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </MaterialUIControllerProvider>
  );
};

export default Dashboard;


