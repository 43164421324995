import React, { useState } from 'react';
import { FaPaperclip } from 'react-icons/fa';

const ChatInput = ({ onSend, allowedFileTypes }) => {
  const [newMessage, setNewMessage] = useState('');
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [error, setError] = useState('');

  const handleSend = (e) => {
    e.preventDefault();
    onSend(newMessage, file, setNewMessage, setFile, setFilePreview, setError);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  return (
    <form onSubmit={handleSend} className="message-form bg-gray-900 p-4 rounded-b-lg shadow-lg">
      <div className="input-container flex items-center">
        <textarea
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          className="message-input flex-grow bg-gray-800 text-white placeholder-gray-400 px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400"
          placeholder="Type a message..."
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSend(e);
            }
          }}
        />
        <label htmlFor="file-upload" className="file-upload-label text-white cursor-pointer ml-4">
          <FaPaperclip size={20} />
          <input
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            className="file-input hidden"
            accept={allowedFileTypes.join(', ')}
          />
        </label>
        <button
          type="submit"
          className="hustlesmall-button bg-yellow-400 text-gray-900 px-4 py-2 rounded-lg ml-4 transition-all duration-300 hover:bg-yellow-500"
        >
          Send
        </button>
      </div>
      {filePreview && (
        <div className="file-preview mt-4 p-2 bg-gray-800 rounded-lg">
          {file && file.type.startsWith('image/') ? (
            <img src={filePreview} alt="File preview" className="preview-image-small rounded-lg" />
          ) : file && file.type.startsWith('video/') ? (
            <video className="preview-video-small rounded-lg" controls>
              <source src={filePreview} type={file.type} />
              Your browser does not support the video tag.
            </video>
          ) : (
            <p className="text-gray-300">{file?.name}</p>
          )}
        </div>
      )}
      {error && (
        <span className='text-red-600 font-bold'>{error}</span>
      )}
    </form>
  );
};

export default ChatInput;

