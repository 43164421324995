import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import Medal from '../Medal';
import './MedalsPage.css';
import { useAuth } from '../../../contexts/authContext';

const MedalsPage = () => {
  const { currentUser } = useAuth();
  const [daysLoggedInMedals, setDaysLoggedInMedals] = useState([]);
  const [consecutiveDaysMedals, setConsecutiveDaysMedals] = useState([]);
  const [listingMedals, setListingMedals] = useState([]);
  const [chatMedals, setChatMedals] = useState([]); // State for chat medals
  const [achievedCount, setAchievedCount] = useState(0);
  const [userMedalsData, setUserMedalsData] = useState({});
  const [chatCount, setChatCount] = useState(0); // State to hold chatCount

  useEffect(() => {
    const fetchMedals = async () => {
      if (!currentUser) {
        return;
      }

      const db = getFirestore();
      const userMedalsRef = doc(db, 'medals', currentUser.uid);
      const userMedalsSnap = await getDoc(userMedalsRef);
      const userMedalsData = userMedalsSnap.exists() ? userMedalsSnap.data() : {};

      setUserMedalsData(userMedalsData);

      const chatCount = userMedalsData.chatCount || 0;
      setChatCount(chatCount);

      const medalsList = [
        { id: '1-day', name: 'One Day Wonder', goal: 1, type: 'daysLoggedIn' },
        { id: '5-days', name: 'Five Day Fiesta', goal: 5, type: 'daysLoggedIn' },
        { id: '10-days', name: 'Ten Day Triumph', goal: 10, type: 'daysLoggedIn' },
        { id: '25-days', name: 'Twenty-Five Day Marathon', goal: 25, type: 'daysLoggedIn' },
        { id: '50-days', name: 'Fifty Day Legend', goal: 50, type: 'daysLoggedIn' },
        { id: '2-consecutive', name: 'Two Day Streak', goal: 2, type: 'consecutiveDays' },
        { id: '5-consecutive', name: 'Five Day Streak', goal: 5, type: 'consecutiveDays' },
        { id: '10-consecutive', name: 'Ten Day Streak', goal: 10, type: 'consecutiveDays' },
        { id: '20-consecutive', name: 'Twenty Day Streak', goal: 20, type: 'consecutiveDays' },
        { id: '30-consecutive', name: 'Thirty Day Streak', goal: 30, type: 'consecutiveDays' },
        // Listing Medals
        { id: '1-listing', name: 'First Listing', goal: 1, type: 'listings' },
        { id: '3-listings', name: 'Triple Threat', goal: 3, type: 'listings' },
        { id: '5-listings', name: 'Five-Star Seller', goal: 5, type: 'listings' },
        { id: '10-listings', name: 'Tenacious Ten', goal: 10, type: 'listings' },
        { id: '25-listings', name: 'Listing Master', goal: 25, type: 'listings' },
        { id: '50-listings', name: 'Legendary Lister', goal: 50, type: 'listings' },
        // Chat Medals
        { id: '1-chat', name: 'First Conversation', goal: 1, type: 'chatCount' },
        { id: '100-chats', name: 'Chatty Cathy', goal: 100, type: 'chatCount' },
        { id: '250-chats', name: 'Talkative Titan', goal: 250, type: 'chatCount' },
        { id: '500-chats', name: 'Conversational Conqueror', goal: 500, type: 'chatCount' },
        { id: '1000-chats', name: 'Chat Champion', goal: 1000, type: 'chatCount' },
      ];

      const filterMedalsToShow = (medals) => {
        let lastAchievedIndex = -1;
        for (let i = 0; i < medals.length; i++) {
          if ((userMedalsData[medals[i].type] || 0) >= medals[i].goal) {
            lastAchievedIndex = i;
          } else {
            break;
          }
        }
        if (lastAchievedIndex === -1) {
          return medals.slice(0, 1);
        }
        return medals.slice(lastAchievedIndex, lastAchievedIndex + 2);
      };

      setDaysLoggedInMedals(filterMedalsToShow(medalsList.filter(medal => medal.type === 'daysLoggedIn')));
      setConsecutiveDaysMedals(filterMedalsToShow(medalsList.filter(medal => medal.type === 'consecutiveDays')));
      setListingMedals(filterMedalsToShow(medalsList.filter(medal => medal.type === 'listings')));
      setChatMedals(filterMedalsToShow(medalsList.filter(medal => medal.type === 'chatCount')));

      setAchievedCount(daysLoggedInMedals.length + consecutiveDaysMedals.length + listingMedals.length + chatMedals.length);
    };

    fetchMedals();
  }, [currentUser, userMedalsData]);

  if (!currentUser) {
    return <div>Please log in to view your medals.</div>;
  }

  return (
    <div className="medals-page">
      <div className="medals-grid">
        <div className="medal-column">
          <h2 className="medal-header">Days Logged In</h2>
          {daysLoggedInMedals.map(medal => {
            const achieved = (userMedalsData[medal.type] || 0) >= medal.goal;
            const achievedOn = achieved ? new Date(userMedalsData[`${medal.type}AchievedOn`]?.toDate()).toLocaleDateString() : null;
            return (
              <Medal
                key={medal.id}
                icon="FaMedal"
                name={medal.name}
                description={`Login ${medal.goal} days`}
                achieved={achieved}
                progress={userMedalsData[medal.type] || 0}
                goal={medal.goal}
                achievedOn={achievedOn}
              />
            );
          })}
        </div>
        <div className="medal-column">
          <h2 className="medal-header">Consecutive Days</h2>
          {consecutiveDaysMedals.map(medal => {
            const achieved = (userMedalsData[medal.type] || 0) >= medal.goal;
            const achievedOn = achieved ? new Date(userMedalsData[`${medal.type}AchievedOn`]?.toDate()).toLocaleDateString() : null;
            return (
              <Medal
                key={medal.id}
                icon="FaMedal"
                name={medal.name}
                description={`Login ${medal.goal} consecutive days`}
                achieved={achieved}
                progress={userMedalsData[medal.type] || 0}
                goal={medal.goal}
                achievedOn={achievedOn}
              />
            );
          })}
        </div>
        <div className="medal-column">
          <h2 className="medal-header">Listings</h2>
          {listingMedals.map(medal => {
            const achieved = (userMedalsData[medal.type] || 0) >= medal.goal;
            const achievedOn = achieved ? new Date(userMedalsData[`${medal.type}AchievedOn`]?.toDate()).toLocaleDateString() : null;
            return (
              <Medal
                key={medal.id}
                icon="FaMedal"
                name={medal.name}
                description={`Create ${medal.goal} listings`}
                achieved={achieved}
                progress={userMedalsData[medal.type] || 0}
                goal={medal.goal}
                achievedOn={achievedOn}
              />
            );
          })}
        </div>
        <div className="medal-column">
          <h2 className="medal-header">Chat Medals</h2>
          {chatMedals.map(medal => {
            const achieved = chatCount >= medal.goal;
            const achievedOn = achieved ? new Date(userMedalsData[`${medal.type}AchievedOn`]?.toDate()).toLocaleDateString() : null;
            return (
              <Medal
                key={medal.id}
                icon="FaComments"
                name={medal.name}
                description={`Send ${medal.goal} chats`}
                achieved={achieved}
                progress={chatCount}
                goal={medal.goal}
                achievedOn={achievedOn}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MedalsPage;

