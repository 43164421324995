import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/authContext';
import { db } from '../../../firebase/firebase';
import { doc, setDoc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import CreatableSelect from 'react-select/creatable';
import { categories } from '../../listings/createlisting/categories';
import { useNavigate } from 'react-router-dom';
import RemoveProfile from './RemoveProfile';
import SendVerificationEmail from './SendVerificationEmail';
import EmailVerificationModal from './EmailVerificationModal';
import UploadProfileMedia from './UploadProfileMedia'; // Import the new component
import './Hustle.css';

const profbannerSrc = process.env.PUBLIC_URL + '/updateprofile.webp';

const UpdateProfile = () => {
  const { currentUser } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [username, setUsername] = useState('');
  const [bio, setBio] = useState('');
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [email, setEmail] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [avatarURL, setAvatarURL] = useState('');
  const [bannerURL, setBannerURL] = useState('');
  const [telegramHandle, setTelegramHandle] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [loading, setLoading] = useState(true);
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [location, setLocation] = useState({ country: '', state: '', city: '' });
  const [languages, setLanguages] = useState([]);
  const [headline, setHeadline] = useState('');
  const [professionalExperience, setProfessionalExperience] = useState([]);
  const [currentReputation, setCurrentReputation] = useState(0);
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const navigate = useNavigate();

  const skillOptions = [
    'Cryptocurrency Trading', 'Blockchain Development', 'Smart Contracts', 'DeFi',
    'NFTs', 'Crypto Mining', 'Tokenomics', 'Wallet Security', 'Crypto Regulations',
    'Crypto Market Analysis'
  ].map(skill => ({ label: skill, value: skill }));

  const languageOptions = [
    'English', 'Spanish', 'Chinese', 'German', 'French', 'Japanese', 'Russian'
  ].map(lang => ({ label: lang, value: lang }));

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        if (currentUser && currentUser.uid) {
          const docRef = doc(db, 'users', currentUser.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setUsername(data.username || '');
            setBio(data.bio || '');
            setSelectedSkills(data.skills ? data.skills.map(skill => ({ label: skill, value: skill })) : []);
            setEmail(data.email || '');
            setAvatarURL(data.avatarURL || '');
            setBannerURL(data.bannerURL || '');
            setTelegramHandle(data.telegramHandle || '');
            setTwitterHandle(data.twitterHandle || '');
            setLocation(data.location || { country: '', state: '', city: '' });
            setLanguages(data.languages ? data.languages.map(lang => ({ label: lang, value: lang })) : []);
            setHeadline(data.headline || '');
            setProfessionalExperience(data.professionalExperience || []);
            setSelectedCategories(data.categories ? data.categories.map(cat => ({ label: cat, value: cat })) : []);
            setCurrentReputation(data.reputation || 0);

            const q = query(
              collection(db, 'verifications'),
              where('uid', '==', currentUser.uid),
              where('email', '==', data.email),
              where('verified', '==', true)
            );
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
              setEmailVerified(true);

              if (!data.emailVerified) {
                const newReputation = data.reputation + 20;
                await updateDoc(docRef, {
                  emailVerified: true,
                  reputation: newReputation
                });
                setCurrentReputation(newReputation);
              }
            }
          } else {
            setErrorMessage('User profile does not exist');
          }
        } else {
          setErrorMessage('User is not authenticated');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [currentUser]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoryList = await categories;
        setCategoriesList(categoryList);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setErrorMessage('Failed to fetch categories');
      }
    };

    fetchCategories();
  }, []);

  const calculateFieldScore = (data) => {
    let score = 0;
    if (data.email && data.emailVerified) score += 20;
    if (data.username) score += 10;
    if (data.bio) score += 5;
    if (data.skills && data.skills.length) score += 5;
    if (data.languages && data.languages.length) score += 5;
    if (data.telegramHandle) score += 5;
    if (data.twitterHandle) score += 5;
    return score;
  };

  const updateProfile = async (avatarURL = null, bannerURL = null) => {
    try {
      if (currentUser && currentUser.uid) {
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);
        const initialData = docSnap.data();

        const initialFieldScore = calculateFieldScore(initialData);
        const newFieldScore = calculateFieldScore({
          email,
          emailVerified,
          username,
          bio,
          skills: selectedSkills,
          languages,
          telegramHandle,
          twitterHandle
        });

        const newReputation = currentReputation + (newFieldScore - initialFieldScore);

        const updateData = {
          username,
          bio,
          skills: selectedSkills.map(skill => skill.value),
          email,
          emailVerified,
          telegramHandle,
          twitterHandle,
          location,
          languages: languages.map(lang => lang.value),
          headline,
          professionalExperience,
          categories: selectedCategories.map(cat => cat.value),
          reputation: newReputation,
          ...(avatarURL && { avatarURL }),
          ...(bannerURL && { bannerURL })
        };

        await setDoc(doc(db, 'users', currentUser.uid), updateData, { merge: true });
        setCurrentReputation(newReputation); // Update the local reputation state
        setSuccessMessage('Profile updated successfully');
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      setErrorMessage('Failed to update profile');
    }
  };

  const handleAvatarURLChange = (url) => {
    setAvatarURL(url);
  };

  const handleBannerURLChange = (url) => {
    setBannerURL(url);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateProfile(avatarURL, bannerURL);
    } catch (error) {
      setErrorMessage('Failed to update profile');
    }
  };

  const handleSkillChange = (selectedOptions) => {
    setSelectedSkills(selectedOptions || []);
  };

  const handleCategoriesChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions || []);
  };

  const handleAddProfessionalExperience = () => {
    setProfessionalExperience([...professionalExperience, { companyName: '', jobTitle: '', duration: '', description: '' }]);
  };

  const handleRemoveProfessionalExperience = (index) => {
    const updatedExperience = [...professionalExperience];
    updatedExperience.splice(index, 1);
    setProfessionalExperience(updatedExperience);
  };

  const handleProfessionalExperienceChange = (index, field, value) => {
    const newExperience = [...professionalExperience];
    newExperience[index][field] = value;
    setProfessionalExperience(newExperience);
  };

  const handleVerificationSuccess = () => {
    setShowVerificationModal(false);
    setEmailVerified(true);
    setSuccessMessage('Email verified successfully!');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4 mx-auto bg-gray-900 text-white">
      <div className="relative w-full h-64 mb-8 overflow-hidden">
        <img src={profbannerSrc} alt="Update Profile Banner" className="w-full h-full object-cover object-top" />
        <div className="absolute bottom-0 w-full p-4 bg-gradient-to-t from-gray-900">
        </div>
      </div>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-4 md:space-y-0 md:grid md:grid-cols-2 md:gap-4">
          <div className="space-y-4">
            <div className="field-container">
              <label className="label">Username <span className="text-yellow-500">+10 rep</span></label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="input-field"
              />
            </div>

            <UploadProfileMedia
              currentUser={currentUser}
              onAvatarURLChange={handleAvatarURLChange}
              onBannerURLChange={handleBannerURLChange}
            />

            <div className="field-container relative">
              <label className="label">Email Address <span className="text-yellow-500">+20 rep</span></label>
              <input
                type="email"
                placeholder="Only used for notifications, not shared with members"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input-field pr-20"
                disabled={emailVerified} // Disable input if email is verified
              />
              {emailVerified ? (
                <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500">Verified</span>
              ) : (
                <SendVerificationEmail email={email} uid={currentUser.uid} onVerify={() => setShowVerificationModal(true)} />
              )}
            </div>

            <div className="field-container">
              <label className="label">Location</label>
              <div className="space-y-2">
                <input
                  type="text"
                  placeholder="Country"
                  value={location.country}
                  onChange={(e) => setLocation({ ...location, country: e.target.value })}
                  className="input-field"
                />
                <input
                  type="text"
                  placeholder="State/Province"
                  value={location.state}
                  onChange={(e) => setLocation({ ...location, state: e.target.value })}
                  className="input-field"
                />
                <input
                  type="text"
                  placeholder="City"
                  value={location.city}
                  onChange={(e) => setLocation({ ...location, city: e.target.value })}
                  className="input-field"
                />
              </div>
            </div>

            <div className="field-container">
              <label className="label">Professional Headline</label>
              <input
                type="text"
                value={headline}
                onChange={(e) => setHeadline(e.target.value)}
                className="input-field"
              />
            </div>

            <div className="field-container">
              <label className="label">Languages <span className="text-yellow-500">+5 rep</span></label>
              <CreatableSelect
                isMulti
                options={languageOptions}
                value={languages}
                onChange={(selectedOptions) => setLanguages(selectedOptions || [])}
                placeholder="Select or add languages"
                classNamePrefix="input-field"
                styles={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: 'transparent',
                    borderColor: 'rgba(255, 255, 255, 0.08)',
                    color: 'white',
                    borderRadius: '1rem',
                    boxShadow: 'inset 0 -2px 4px 0 rgba(255, 255, 255, 0.64), inset 0 2px 4px 0 rgba(255, 255, 255, 0.24)',
                    padding: '0.5rem',
                    width: '100%', // Adjust the width as needed
                    margin: 'auto',
                    zIndex: 5,
                  }),
                  input: (base) => ({
                    ...base,
                    color: 'white',
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: '#1f2937',
                    zIndex: 10,
                  }),
                  option: (base, { isFocused }) => ({
                    ...base,
                    backgroundColor: isFocused ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
                    color: 'white',
                  }),
                  multiValue: (base) => ({
                    ...base,
                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                    color: 'white',
                  }),
                  multiValueLabel: (base) => ({
                    ...base,
                    color: 'white',
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: '#9CA3AF',
                  }),
                }}
              />
            </div>
          </div>

          <div className="space-y-4">
            <div className="field-container">
              <label className="label">Description/Bio <span className="text-yellow-500">+5 rep</span></label>
              <textarea
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                className="textarea-field"
              />
            </div>

            <div className="field-container">
              <label className="label">Skills <span className="text-yellow-500">+5 rep</span></label>
              <CreatableSelect
                isMulti
                options={skillOptions}
                value={selectedSkills}
                onChange={handleSkillChange}
                placeholder="Select or add skills"
                classNamePrefix="select"
                styles={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: 'transparent',
                    borderColor: 'rgba(255, 255, 255, 0.08)',
                    color: 'white',
                    borderRadius: '1rem',
                    boxShadow: 'inset 0 -2px 4px 0 rgba(255, 255, 255, 0.64), inset 0 2px 4px 0 rgba(255, 255, 255, 0.24)',
                    padding: '0.5rem',
                    width: '100%', // Adjust the width as needed
                    margin: 'auto',
                    zIndex: 5,
                  }),
                  input: (base) => ({
                    ...base,
                    color: 'white',
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: '#1f2937',
                    zIndex: 10,
                  }),
                  option: (base, { isFocused }) => ({
                    ...base,
                    backgroundColor: isFocused ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
                    color: 'white',
                  }),
                  multiValue: (base) => ({
                    ...base,
                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                    color: 'white',
                  }),
                  multiValueLabel: (base) => ({
                    ...base,
                    color: 'white',
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: '#9CA3AF',
                  }),
                }}
              />
            </div>

            <div className="field-container">
              <label className="label">Categories</label>
              <CreatableSelect
                isMulti
                options={categoriesList.map(cat => ({ label: cat.name, value: cat.name }))}
                value={selectedCategories}
                onChange={handleCategoriesChange}
                placeholder="Select or add categories"
                classNamePrefix="select"
                styles={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: 'transparent',
                    borderColor: 'rgba(255, 255, 255, 0.08)',
                    color: 'white',
                    borderRadius: '1rem',
                    boxShadow: 'inset 0 -2px 4px 0 rgba(255, 255, 255, 0.64), inset 0 2px 4px 0 rgba(255, 255, 255, 0.24)',
                    padding: '0.5rem',
                    width: '100%', // Adjust the width as needed
                    margin: 'auto',
                    zIndex: 5,
                  }),
                  input: (base) => ({
                    ...base,
                    color: 'white',
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: '#1f2937',
                    zIndex: 10,
                  }),
                  option: (base, { isFocused }) => ({
                    ...base,
                    backgroundColor: isFocused ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
                    color: 'white',
                  }),
                  multiValue: (base) => ({
                    ...base,
                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                    color: 'white',
                  }),
                  multiValueLabel: (base) => ({
                    ...base,
                    color: 'white',
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: '#9CA3AF',
                  }),
                }}
                noOptionsMessage={() => 'No categories available'}
              />
            </div>

            <div className="field-container">
              <label className="label">Telegram Handle <span className="text-yellow-500">+5 rep</span></label>
              <input
                type="text"
                value={telegramHandle}
                onChange={(e) => setTelegramHandle(e.target.value)}
                className="input-field"
              />
            </div>

            <div className="field-container">
              <label className="label">Twitter Handle <span className="text-yellow-500">+5 rep</span></label>
              <input
                type="text"
                value={twitterHandle}
                onChange={(e) => setTwitterHandle(e.target.value)}
                className="input-field"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-col space-y-2">
            <div className="space-y-2">
              {professionalExperience.map((experience, index) => (
                <div key={index} className="space-y-2 relative">
                  <button
                    type="button"
                    onClick={() => handleRemoveProfessionalExperience(index)}
                    className="absolute top-0 right-0 text-gray-400 hover:text-gray-600 z-10"
                  >
                    X
                  </button>
                  <input
                    type="text"
                    placeholder="Company Name"
                    value={experience.companyName}
                    onChange={(e) => handleProfessionalExperienceChange(index, 'companyName', e.target.value)}
                    className="input-field"
                  />
                  <input
                    type="text"
                    placeholder="Job Title"
                    value={experience.jobTitle}
                    onChange={(e) => handleProfessionalExperienceChange(index, 'jobTitle', e.target.value)}
                    className="input-field"
                  />
                  <input
                    type="text"
                    placeholder="Duration"
                    value={experience.duration}
                    onChange={(e) => handleProfessionalExperienceChange(index, 'duration', e.target.value)}
                    className="input-field"
                  />
                  <textarea
                    placeholder="Description of duties and achievements"
                    value={experience.description}
                    onChange={(e) => handleProfessionalExperienceChange(index, 'description', e.target.value)}
                    className="textarea-field"
                  />
                </div>
              ))}
            </div>
            <button
              type="button"
              onClick={handleAddProfessionalExperience}
              className="hustle-button"
            >
              Add Experience
            </button>

            <button
              onClick={() => navigate('/add-portfolio')}
              className="hustle-button"
            >
              Add Portfolio
            </button>
            <button
              type="submit"
              className="hustle-button"
            >
              Update Profile
            </button>
          </div>
        </div>
      </form>
      {errorMessage && <div className="text-red-500 font-bold mb-4">{errorMessage}</div>}
      {successMessage && <div className="text-green-500 font-bold mb-4">{successMessage}</div>}

      <div className="flex-buttons mt-4">
        <RemoveProfile />
      </div>

      {showVerificationModal && (
        <EmailVerificationModal
          email={email}
          onClose={() => setShowVerificationModal(false)}
          onVerificationSuccess={handleVerificationSuccess}
        />
      )}
    </div>
  );
};

export default UpdateProfile;

