import React, { useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, sendPasswordResetEmail } from 'firebase/auth';
import { useAuth } from '../../../contexts/authContext';
import { db } from '../../../firebase/firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import ResetPasswordModal from './ResetPasswordModal'; // Import the modal component

const Admin = () => {
    const { userLoggedIn } = useAuth();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSigningIn, setIsSigningIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

    const auth = getAuth();

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!isSigningIn) {
            setIsSigningIn(true);
            setErrorMessage('');
            try {
                await signInWithEmailAndPassword(auth, email, password);
            } catch (error) {
                handleAuthError(error);
            } finally {
                setIsSigningIn(false);
            }
        }
    };

    const onGoogleSignIn = async (e) => {
        e.preventDefault();
        if (!isSigningIn) {
            setIsSigningIn(true);
            setErrorMessage('');
            try {
                const provider = new GoogleAuthProvider();
                provider.setCustomParameters({ prompt: 'select_account' });

                const result = await signInWithPopup(auth, provider);
                const user = result.user;
                if (user) {
                    const username = user.displayName || 'Anonymous';
                    const userRef = doc(db, 'users', user.uid);
                    const userDoc = await getDoc(userRef);

                    if (!userDoc.exists()) {
                        await setDoc(userRef, {
                            email: user.email,
                            uid: user.uid,
                            username: username,
                            createdAt: new Date().toISOString()
                        });
                    } else {
                        const existingData = userDoc.data();
                        if (!existingData.username) {
                            await setDoc(userRef, { username: username }, { merge: true });
                        }
                    }
                }
            } catch (error) {
                handleAuthError(error);
            } finally {
                setIsSigningIn(false);
            }
        }
    };

    const handleAuthError = (error) => {
        switch (error.code) {
            case 'auth/invalid-email':
                setErrorMessage('The email address is badly formatted.');
                break;
            case 'auth/user-disabled':
                setErrorMessage('This user has been disabled.');
                break;
            case 'auth/user-not-found':
                setErrorMessage('There is no user record corresponding to this email.');
                break;
            case 'auth/wrong-password':
                setErrorMessage('The password is invalid.');
                break;
            case 'auth/invalid-credential':
                setErrorMessage('The provided credentials are invalid.');
                break;
            default:
                setErrorMessage('An unexpected error occurred: ' + error.message);
        }
    };

    return (
        <div className="bg-gray-900 min-h-screen flex items-center justify-center p-6">
            {userLoggedIn && <Navigate to='/home' replace />}

            <div className="w-full max-w-md p-8 bg-gray-800 shadow-lg rounded-lg">
                <h2 className="text-2xl font-bold text-white text-center mb-6">Welcome Back</h2>

                <form onSubmit={onSubmit} className="space-y-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-300">Email</label>
                        <input
                            type="email"
                            autoComplete="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-600 rounded-md bg-gray-900 text-gray-300 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-300">Password</label>
                        <input
                            type="password"
                            autoComplete="current-password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-600 rounded-md bg-gray-900 text-gray-300 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                        />
                    </div>

                    {errorMessage && (
                        <div className="text-red-400 text-sm font-medium">{errorMessage}</div>
                    )}

                    <button
                        type="submit"
                        disabled={isSigningIn}
                        className={`w-full py-2 px-4 text-white font-medium rounded-md ${isSigningIn ? 'bg-gray-600 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-blue-500'}`}
                    >
                        {isSigningIn ? 'Signing In...' : 'Sign In'}
                    </button>
                </form>

                <p className="mt-4 text-center text-sm text-gray-300">
                    Don't have an account? <Link to="/register" className="text-blue-400 hover:underline">Sign up</Link>
                </p>

                <div className="mt-6 flex items-center justify-center">
                    <div className="border-t border-gray-600 flex-grow"></div>
                    <span className="mx-4 text-sm font-medium text-gray-400">OR</span>
                    <div className="border-t border-gray-600 flex-grow"></div>
                </div>

                <button
                    onClick={onGoogleSignIn}
                    disabled={isSigningIn}
                    className={`w-full mt-4 flex items-center justify-center gap-x-3 py-2 px-4 border border-gray-600 rounded-md text-sm font-medium ${isSigningIn ? 'bg-gray-600 cursor-not-allowed' : 'bg-gray-700 hover:bg-gray-600 focus:ring-2 focus:ring-blue-500'}`}
                >
                    <svg className="w-5 h-5" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M47.532 24.5528C47.532 22.9214 47.3997 21.2811 47.1175 19.6761H24.48V28.9181H37.4434C36.9055 31.8988 35.177 34.5356 32.6461 36.2111V42.2078H40.3801C44.9217 38.0278 47.532 31.8547 47.532 24.5528Z" fill="#4285F4" />
                        <path d="M24.48 48.0016C30.9529 48.0016 36.4116 45.8764 40.3888 42.2078L32.6549 36.2111C30.5031 37.675 27.7252 38.5039 24.4888 38.5039C18.2275 38.5039 12.9187 34.2798 11.0139 28.6006H3.03296V34.7825C7.10718 42.8868 15.4056 48.0016 24.48 48.0016Z" fill="#34A853" />
                        <path d="M11.0051 28.6006C9.99973 25.6199 9.99973 22.3922 11.0051 19.4115V13.2296H3.03298C-0.371021 20.0112 -0.371021 28.0009 3.03298 34.7825L11.0051 28.6006Z" fill="#FBBC04" />
                        <path d="M24.48 9.49932C27.9016 9.44641 31.2086 10.7339 33.6866 13.0973L40.5387 6.24523C36.2 2.17101 30.4414 -0.068932 24.48 0.00161733C15.4055 0.00161733 7.10718 5.11644 3.03296 13.2296L11.005 19.4115C12.901 13.7235 18.2187 9.49932 24.48 9.49932Z" fill="#EA4335" />
                    </svg>
                    Sign in with Google
                </button>

                <p className="mt-4 text-center text-sm text-gray-300 cursor-pointer" onClick={() => setShowResetPasswordModal(true)}>
                    Forgot your password?
                </p>
            </div>

            {/* Modal Component */}
            <ResetPasswordModal 
                isOpen={showResetPasswordModal} 
                onClose={() => setShowResetPasswordModal(false)} 
                email={email}
            />
        </div>
    );
};

export default Admin;
