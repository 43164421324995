import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const ClientUploadConfirmation = () => {
  const location = useLocation();
  const { projectID } = location.state || {};

  return (
    <div className="max-w-md mx-auto p-4 shadow-md border rounded-lg bg-gray-900 text-gray-200">
      <h2 className="text-xl font-semibold text-center mb-4">Upload Successful</h2>
      <p className="text-center mb-4">Your project details have been successfully submitted.</p>
      <p className="text-center mb-4">
        Project ID: <Link to={`/order-tracking/${projectID}`} className="text-blue-500 hover:underline">{projectID}</Link>
      </p>
      <div className="text-center">
        <button
          onClick={() => window.location.href = '/'}
          className="px-4 py-2 bg-green-600 text-white font-medium rounded-lg hover:bg-green-700 transition duration-300"
        >
          Go to Home
        </button>
      </div>
    </div>
  );
};

export default ClientUploadConfirmation;

