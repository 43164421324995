import React from 'react';
import { Link } from 'react-router-dom';

const DisputeProcessModal = ({ isOpen, onRequestClose, disputeID }) => {
  console.log('DisputeProcessModal rendered with isOpen:', isOpen);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gradient-to-b from-gray-700 via-gray-800 to-gray-900 p-6 rounded-lg max-w-lg w-full mx-4">
        <h2 className="text-2xl font-semibold text-white mb-4">Dispute Submitted</h2>
        <p className="mb-4 text-gray-300">
          Your dispute has been successfully submitted. 
        </p>
        <ul className="mb-4 text-gray-300 list-disc list-inside">
          <li>The freelancer will be notified and will have an opportunity to respond.</li>
          <li>You can track the progress of the dispute and respond with additional information if necessary.</li>
          <li>If the issue is not resolved, our team will step in to mediate.</li>
        </ul>
        <p className="mb-4 text-gray-300">
          You can track the status of your dispute using the link below:
        </p>
        <Link 
          to={`/dispute/${disputeID}`} 
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300">
          View Dispute Details
        </Link>
        <div className="flex justify-end mt-4">
          <button
            onClick={onRequestClose}
            className="px-4 py-2 hustlesmall-button text-white rounded-lg mr-2 hover:bg-gray-600 transition duration-300"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default DisputeProcessModal;

