import React, { useEffect } from 'react';
import { doc, getDoc, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';

const UpdateMedalsOnLogin = ({ uid }) => {
  useEffect(() => {
    const updateLoginMedals = async () => {
      console.log('Starting updateLoginMedals for UID:', uid); // Log UID

      const userMedalsRef = doc(db, 'medals', uid);
      const userMedalsDoc = await getDoc(userMedalsRef);
      console.log('Fetched userMedalsDoc:', userMedalsDoc.exists()); // Log document existence

      const now = new Date();
      const nowDay = now.getUTCDate();
      const nowMonth = now.getUTCMonth();
      const nowYear = now.getUTCFullYear();

      if (userMedalsDoc.exists()) {
        const data = userMedalsDoc.data();
        const lastLogin = data.lastLogin ? data.lastLogin.toDate() : null;

        let daysLoggedIn = data.daysLoggedIn || 0;
        let consecutiveDays = data.consecutiveDays || 0;
        let daysLoggedInAchievedOn = data.daysLoggedInAchievedOn || null;
        let consecutiveDaysAchievedOn = data.consecutiveDaysAchievedOn || null;

        if (lastLogin) {
          const lastLoginDay = lastLogin.getUTCDate();
          const lastLoginMonth = lastLogin.getUTCMonth();
          const lastLoginYear = lastLogin.getUTCFullYear();

          if (nowYear > lastLoginYear || (nowYear === lastLoginYear && (nowMonth > lastLoginMonth || (nowMonth === lastLoginMonth && nowDay > lastLoginDay)))) {
            // New day login, increment the counters
            daysLoggedIn += 1;

            if (nowDay === lastLoginDay + 1 || (lastLoginMonth < nowMonth && lastLoginDay === new Date(lastLoginYear, lastLoginMonth + 1, 0).getUTCDate() && nowDay === 1)) {
              consecutiveDays += 1;
            } else {
              consecutiveDays = 1; // Reset streak if not the next day
            }

            const achievements = {
              daysLoggedIn,
              consecutiveDays,
              lastLogin: serverTimestamp(),
            };

            // Set the achievement dates if goals are met
            if (daysLoggedInAchievedOn === null && daysLoggedIn >= 1) achievements.daysLoggedInAchievedOn = serverTimestamp();
            if (consecutiveDaysAchievedOn === null && consecutiveDays >= 2) achievements.consecutiveDaysAchievedOn = serverTimestamp();

            await updateDoc(userMedalsRef, achievements);
          } else {
            console.log('User has already logged in today.');
          }
        } else {
          // If no last login recorded, this is the first login
          daysLoggedIn = 1;
          consecutiveDays = 1;

          await setDoc(userMedalsRef, {
            uid,
            lastLogin: serverTimestamp(),
            daysLoggedIn,
            consecutiveDays,
            daysLoggedInAchievedOn: serverTimestamp(),
            consecutiveDaysAchievedOn: serverTimestamp(),
          });
        }

      } else {
        console.log('Creating new document for UID:', uid);

        await setDoc(userMedalsRef, {
          uid,
          lastLogin: serverTimestamp(),
          daysLoggedIn: 1,
          consecutiveDays: 1,
          daysLoggedInAchievedOn: serverTimestamp(),
          consecutiveDaysAchievedOn: serverTimestamp(),
        });
      }
    };

    updateLoginMedals();
  }, [uid]);

  return null; // This component doesn't render anything
};

export default UpdateMedalsOnLogin;

