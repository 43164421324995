import React, { useEffect, useState } from 'react';
import { FaStar, FaEdit, FaBan, FaEnvelopeOpenText, FaEnvelope, FaUserCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore'; // Import Firestore functions
import BioSection from './BioSection';
import ExperienceSection from './ExperienceSection';
import PortfolioSection from './PortfolioSection';
import ListingsSection from './ListingsSection';
import EditProfileModal from './update/EditProfileModal';
import './Profile.css';
import { db } from '../../firebase/firebase';

const UserProfileContent = ({
  userData,
  setUserData, // Add setUserData as a prop to manage the userData state
  averageRating,
  listings,
  receivedReviews,
  isBlocked,
  handleBlockUser,
  handleUnblockUser,
  confirmBlockUser,
  cancelBlock,
  handleCreateSupportChat,
  blockError,
  authUser,
  navigate,
  formatTelegramHandle,
  showBlockConfirmation,
  handleProfileUpdate,
}) => {
  const [isEditProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('bio');

  const openEditProfileModal = () => setEditProfileModalOpen(true);
  const closeEditProfileModal = () => setEditProfileModalOpen(false);

  const handleExperienceUpdate = (updatedExperiences) => {
    handleProfileUpdate({ professionalExperience: updatedExperiences });
  };

  const internalHandleProfileUpdate = async (updatedData) => {
    try {
      const sanitizedData = {};
      for (let key in updatedData) {
        if (updatedData.hasOwnProperty(key)) {
          if (typeof updatedData[key] === 'object' && !Array.isArray(updatedData[key])) {
            sanitizedData[key] = { ...updatedData[key] };
          } else {
            sanitizedData[key] = updatedData[key];
          }
        }
      }
      // Update the user's profile in the database
      const userRef = doc(db, 'users', authUser.uid);
      await updateDoc(userRef, sanitizedData);

      // Update the local state with the new data
      setUserData((prevUserData) => ({
        ...prevUserData,
        ...sanitizedData,
      }));
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

useEffect(() => {
  if (isEditProfileModalOpen) {
    document.body.classList.add('lock-scroll');
  } else {
    document.body.classList.remove('lock-scroll');
  }

  return () => {
    document.body.classList.remove('lock-scroll'); // Clean up in case the component unmounts
  };
}, [isEditProfileModalOpen]);

  if (!userData) return <div>No user data available.</div>;

  return (
    <div className="p-4 bg-gradient-to-b from-gray-800 via-gray-900 to-black text-white min-h-screen">
      <div className="max-w-4xl mx-auto bg-gray-800 shadow-lg rounded-lg overflow-hidden">
        <div className="profile-banner relative">
          <img
            src={userData.bannerURL || '/profileplaceholder.webp'}
            alt="Profile Banner"
            className="w-full h-80 sm:h-96 md:h-[70vh] object-cover"
          />
          {authUser.uid === userData.uid && ( // Only show edit button if authUser is the profile owner
            <div className="edit-profile-button absolute top-4 right-4" onClick={openEditProfileModal}>
              Edit Profile
              <FaEdit className="ml-2" />
            </div>
          )}
        </div>
        <div className="profile-info-container relative">
          <div className="profile-info-dark-bg flex justify-between items-center px-6 py-4 bg-gradient-to-b from-gray-800 via-gray-900 to-black text-white">
            <div className="flex flex-col space-y-2">
              {userData.isPremium && (
                <div className="text-[0.4rem] sm:text-[1rem] text-yellow-400 flex items-center">
                  <FaStar className="mr-2" /> Premium Account
                </div>
              )}
              {userData.email ? (
                <div className="text-[0.4rem] sm:text-[1rem] text-green-400 flex items-center">
                  <FaEnvelopeOpenText className="mr-2" /> Gets mail notifications
                </div>
              ) : (
                <div className="text-red-400 flex items-center">
                  <FaEnvelope className="mr-2" /> No address entered
                </div>
              )}
            </div>
            <div className="flex items-center space-x-4">
              <div className="flex items-center text-yellow-400">
                <FaStar className="mr-1" /> {averageRating || 'No Ratings'}
              </div>
              <div className="flex items-center text-yellow-400">
                <FaStar className="mr-1" /> {userData.reputation}
              </div>
            </div>
          </div>
          <div className="profile-avatar-container">
            <img
              src={userData.avatarURL || '/profileplaceholder.webp'}
              alt={userData.username}
              className="profile-avatar"
            />
          </div>
          <div className="profile-name-container text-center">
            <h1 className="profile-name">{userData.username}</h1>
            {userData.headline && (
              <h2 className="profile-headline">{userData.headline}</h2>
            )}
          </div>
        </div>

        {authUser.uid !== userData.uid && (
          <div className="mt-2 flex justify-center space-x-4">
            {(authUser.isAdmin || authUser.uid === '3sXCEFOKxPQyHXPNkxrRWgVKaUq2') && (
              <button
                onClick={handleCreateSupportChat}
                className="px-4 py-2 bg-yellow-600 text-white font-medium rounded-lg hover:bg-yellow-700 transition duration-300"
              >
                Message User
              </button>
            )}
            <div className="p-tooltip">
              <FaBan
                className="text-red-600 cursor-pointer hover:text-red-400"
                onClick={isBlocked ? handleUnblockUser : confirmBlockUser}
              />
              <span className="p-tooltiptext">{isBlocked ? 'Unblock User' : 'Block User'}</span>
            </div>
            {blockError && <p className="text-red-500">{blockError}</p>}
          </div>
        )}

        {showBlockConfirmation && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
            <div className="bg-gray-900 p-6 rounded-lg shadow-lg max-w-sm mx-auto">
              <h3 className="text-lg font-bold text-white mb-4">Are you sure you want to block this user?</h3>
              <div className="flex justify-between">
                <button
                  onClick={handleBlockUser}
                  className="px-4 py-2 bg-red-600 text-white font-medium rounded-lg hover:bg-red-700 transition duration-300"
                >
                  Block
                </button>
                <button
                  onClick={cancelBlock}
                  className="px-4 py-2 bg-gray-600 text-white font-medium rounded-lg hover:bg-gray-700 transition duration-300"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="mt-4 px-6 py-4 bg-gradient-to-b from-gray-800 via-gray-900 to-black">
          <div className="flex justify-around py-2 border-b border-gray-700 overflow-x-auto">
            <button
              onClick={() => setSelectedTab('bio')}
              className={`py-2 px-4 ${selectedTab === 'bio' ? 'font-bold text-blue-400 border-b-2 border-blue-400' : 'text-gray-400'}`}
            >
              Bio
            </button>
            <button
              onClick={() => setSelectedTab('experience')}
              className={`py-2 px-4 ${selectedTab === 'experience' ? 'font-bold text-blue-400 border-b-2 border-blue-400' : 'text-gray-400'}`}
            >
              Experience
            </button>
            <button
              onClick={() => setSelectedTab('portfolio')}
              className={`py-2 px-4 ${selectedTab === 'portfolio' ? 'font-bold text-blue-400 border-b-2 border-blue-400' : 'text-gray-400'}`}
            >
              Portfolio
            </button>
            <button
              onClick={() => setSelectedTab('listings')}
              className={`py-2 px-4 ${selectedTab === 'listings' ? 'font-bold text-blue-400 border-b-2 border-blue-400' : 'text-gray-400'}`}
            >
              Listings
            </button>
            <button
              onClick={() => setSelectedTab('reviews')}
              className={`py-2 px-4 ${selectedTab === 'reviews' ? 'font-bold text-blue-400 border-b-2 border-blue-400' : 'text-gray-400'}`}
            >
              Reviews
            </button>
          </div>

          {selectedTab === 'bio' && (
            <BioSection
              userData={userData}
              authUser={authUser}
              handleProfileUpdate={handleProfileUpdate}
              formatTelegramHandle={formatTelegramHandle}
            />
          )}

          {selectedTab === 'experience' && (
            <ExperienceSection
              userData={userData}
              onExperienceUpdate={handleExperienceUpdate}
            />
          )}

          {selectedTab === 'portfolio' && (
            <PortfolioSection portfolio={userData.portfolio} userId={userData.uid} />
          )}

          {selectedTab === 'listings' && (
            <ListingsSection listings={listings} />
          )}

          {selectedTab === 'reviews' && (
            <div className="p-4">
              {receivedReviews.length > 0 ? (
                receivedReviews.map((review, index) => (
                  <Link key={index} to={`/review/${review.id}`} className="block mb-4 p-4 bg-gray-800 border border-gray-600 rounded-lg hover:bg-gray-700 transition duration-300">
                    <div className="flex items-center">
                      {review.reviewer?.avatarURL ? (
                        <img
                          src={review.reviewer.avatarURL}
                          alt={review.reviewer.username}
                          className="w-8 h-8 rounded-full object-cover mr-2"
                        />
                      ) : (
                        <FaUserCircle className="w-8 h-8 text-gray-400 mr-2" />
                      )}
                      <FaStar className="text-yellow-400 mr-2" />
                      <span className="font-semibold">{review.rating}</span>
                    </div>
                    <p className="mt-2 text-gray-400">{review.comment}</p>
                    <p className="mt-1 text-gray-500">
                      By{' '}
                      <span className="text-blue-400 hover:underline">
                        {review.reviewer?.username || 'Unknown'}
                      </span>
                    </p>
                  </Link>
                ))
              ) : (
                <p>No reviews yet.</p>
              )}
            </div>
          )}
        </div>
      </div>

      {isEditProfileModalOpen && (
        <EditProfileModal
          userData={userData}
          onClose={closeEditProfileModal}
          handleProfileUpdate={internalHandleProfileUpdate}
        />
      )}
    </div>
  );
};

export default UserProfileContent;

