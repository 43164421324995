import { auth } from "./firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updatePassword,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  GoogleAuthProvider,
} from "firebase/auth";

// Create a new user with email and password
export const doCreateUserWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential;
  } catch (error) {
    throw new Error(`Error creating user: ${error.message}`);
  }
};

// Sign in with email and password
export const doSignInWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential;
  } catch (error) {
    throw new Error(`Error signing in: ${error.message}`);
  }
};

// Sign in with Google using popup or redirect based on environment
export const doSignInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();

    if (window.innerWidth < 768) { // Assuming mobile devices use redirect
      await signInWithRedirect(auth, provider);
      // Handle the redirect result after the page reloads
      const result = await getRedirectResult(auth);
      if (result) {
        return result;
      } else {
        throw new Error('No result after redirect sign-in');
      }
    } else {
      const result = await signInWithPopup(auth, provider);
      return result;
    }
  } catch (error) {
    throw new Error(`Error signing in with Google: ${error.message}`);
  }
};

// Sign out the current user
export const doSignOut = async () => {
  try {
    await auth.signOut();
  } catch (error) {
    throw new Error(`Error signing out: ${error.message}`);
  }
};

// Send password reset email
export const doSendPasswordResetEmail = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    throw new Error(`Error sending password reset email: ${error.message}`);
  }
};

// Change the password for the current user
export const doPasswordChange = async (newPassword) => {
  try {
    if (auth.currentUser) {
      await updatePassword(auth.currentUser, newPassword);
    } else {
      throw new Error('No user is currently signed in.');
    }
  } catch (error) {
    throw new Error(`Error updating password: ${error.message}`);
  }
};

// Send email verification to the current user
export const doSendEmailVerification = async () => {
  try {
    if (auth.currentUser) {
      await sendEmailVerification(auth.currentUser, {
        url: `${window.location.origin}/home`,
      });
    } else {
      throw new Error('No user is currently signed in.');
    }
  } catch (error) {
    throw new Error(`Error sending email verification: ${error.message}`);
  }
};

