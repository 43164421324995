import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom'; 
import { db } from '../../firebase/firebase';
import { useAuth } from '../../contexts/authContext';
import './DisputeCenter.css';

const DisputeCenter = () => {
  const { currentUser } = useAuth();
  const [clientDisputes, setClientDisputes] = useState([]);
  const [freelancerDisputes, setFreelancerDisputes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState('newest');
  const [activeTab, setActiveTab] = useState('open'); // New state for active tab

  useEffect(() => {
    const fetchDisputes = async () => {
      if (!currentUser) return;

      try {
        const clientDisputesQuery = query(
          collection(db, 'disputes'),
          where('clientID', '==', currentUser.uid)
        );
        const clientDisputesSnapshot = await getDocs(clientDisputesQuery);
        const clientDisputesData = await Promise.all(clientDisputesSnapshot.docs.map(async (doc) => {
          const dispute = doc.data();
          const projectQuery = query(collection(db, 'projects'), where('projectID', '==', dispute.projectID));
          const projectSnapshot = await getDocs(projectQuery);
          const listingName = projectSnapshot.empty ? 'Unknown Listing' : projectSnapshot.docs[0].data().listingName;
          return { id: doc.id, listingName, ...dispute };
        }));
        setClientDisputes(clientDisputesData);

        const freelancerDisputesQuery = query(
          collection(db, 'disputes'),
          where('freelancerID', '==', currentUser.uid)
        );
        const freelancerDisputesSnapshot = await getDocs(freelancerDisputesQuery);
        const freelancerDisputesData = await Promise.all(freelancerDisputesSnapshot.docs.map(async (doc) => {
          const dispute = doc.data();
          const projectQuery = query(collection(db, 'projects'), where('projectID', '==', dispute.projectID));
          const projectSnapshot = await getDocs(projectQuery);
          const listingName = projectSnapshot.empty ? 'Unknown Listing' : projectSnapshot.docs[0].data().listingName;
          return { id: doc.id, listingName, ...dispute };
        }));
        setFreelancerDisputes(freelancerDisputesData);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching disputes:', error);
        setLoading(false);
      }
    };

    fetchDisputes();
  }, [currentUser]);

  // Combine client and freelancer disputes, then filter based on active tab
  const filteredDisputes = [...clientDisputes, ...freelancerDisputes]
    .filter(dispute => activeTab === 'open' ? dispute.disputeStatus !== 'closed' : dispute.disputeStatus === 'closed')
    .sort((a, b) => {
      if (sortOrder === 'newest') {
        return b.disputeOpenedAt.seconds - a.disputeOpenedAt.seconds;
      } else {
        return a.disputeOpenedAt.seconds - b.disputeOpenedAt.seconds;
      }
    });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dispute-center">
      {/* Tab Navigation */}
      <div className="tabs">
        <button 
          className={`tab-button ${activeTab === 'open' ? 'active' : ''}`}
          onClick={() => setActiveTab('open')}
        >
          Open
        </button>
        <button 
          className={`tab-button ${activeTab === 'closed' ? 'active' : ''}`}
          onClick={() => setActiveTab('closed')}
        >
          Completed
        </button>
      </div>

      <div className="dispute-sort">
        <label htmlFor="sortOrder">Sort by: </label>
        <select id="sortOrder" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
          <option value="newest">Newest</option>
          <option value="oldest">Oldest</option>
        </select>
      </div>

      {filteredDisputes.length > 0 ? (
        filteredDisputes.map(dispute => (
          <div key={dispute.id} className="dispute-card">
            <h3>Dispute Number: {dispute.disputeNumber}</h3>
            <p>Listing Name: {dispute.listingName}</p>
            <p>Project ID: {dispute.projectID}</p>
            <p>Status: {dispute.disputeStatus}</p>
            <p>Date Opened: {new Date(dispute.disputeOpenedAt.seconds * 1000).toLocaleString()}</p>
            <Link to={`/dispute/${dispute.disputeNumber}`} className="hustlesmall-button">
              View Dispute
            </Link>
          </div>
        ))
      ) : (
        <p>No disputes found.</p>
      )}
    </div>
  );
};

export default DisputeCenter;

