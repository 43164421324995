import React, { useState } from 'react';
import AllOrders from './AllOrders';
import ClientOrderManagement from './clientOrderManagement/ClientOrderManagement';
import FreelancerOrderManagement from './freelancerOrderManagement/FreelancerOrderManagement';
import DisputeCenter from '../disputes/DisputeCenter'; // Import the unified Dispute Center component
import './Orders.css';

const Orders = () => {
  const [currentTab, setCurrentTab] = useState('clientOrders');

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <div>
      <div className="tabs">
        <button
          className={currentTab === 'clientOrders' ? 'active' : ''}
          onClick={() => handleTabChange('clientOrders')}
        >
          My Orders
        </button>
        <button
          className={currentTab === 'freelancerOrders' ? 'active' : ''}
          onClick={() => handleTabChange('freelancerOrders')}
        >
          Hustlerz Order Management
        </button>
        <button
          className={currentTab === 'disputeCenter' ? 'active' : ''}
          onClick={() => handleTabChange('disputeCenter')}
        >
          Dispute Center
        </button>
      </div>
      <div className="tab-content">
        {currentTab === 'clientOrders' && <ClientOrderManagement />}
        {currentTab === 'freelancerOrders' && <FreelancerOrderManagement />}
        {currentTab === 'disputeCenter' && <DisputeCenter />}
      </div>
    </div>
  );
};

export default Orders;

