import React, { useState } from 'react';
import { db } from '../../../firebase/firebase';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

const SendVerificationEmail = ({ email, uid, onVerify }) => {
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState('');

  const sendVerificationEmail = async () => {
    if (!email) {
      setMessage('Email address is required');
      return;
    }

    setSending(true);
    setMessage('');

    try {
      const verificationId = uuidv4();
      const verificationLink = `https://hustlez.io/verify-email/${verificationId}`;

      // Add verification entry to Firestore
      await addDoc(collection(db, 'verifications'), {
        email,
        uid,
        hash: verificationId,
        verified: false,
        createdAt: Timestamp.now()
      });

      // Send verification email
      await fetch('https://token.pr1mula.nl:3003/send-notification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_SENDGRID_API_KEY,
        },
        body: JSON.stringify({
          to: email,
          subject: 'Email Verification - Hustlez',
          text: `Please verify your email by clicking the following link: ${verificationLink}`,
          html: `
            <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
              <h1 style="color: #333; text-align: center;">Welcome to Hustlez!</h1>
              <p>Dear User,</p>
              <p>Thank you for signing up with Hustlez. To complete your registration, please verify your email address by clicking the link below:</p>
              <p style="text-align: center;">
                <a href="${verificationLink}" style="display: inline-block; padding: 10px 20px; background-color: #f0c14b; color: #333; text-decoration: none; font-weight: bold; border-radius: 5px;">Verify Email Address</a>
              </p>
              <p>If the button above doesn't work, you can also verify your email by clicking on the following link:</p>
              <p style="word-break: break-all;">
                <a href="${verificationLink}" style="color: #1a73e8;">${verificationLink}</a>
              </p>
              <p>If you did not sign up for a Hustlez account, please ignore this email.</p>
              <p style="text-align: center; color: #999; font-size: 12px;">&copy; 2024 Hustlez, All Rights Reserved.</p>
            </div>
          `,
          from: '"Hustlez Notifications" <noti@hustlez.io>',
        }),
      });

      setMessage('Verification email sent');
      // Trigger the onVerify callback to show the modal
      onVerify();
    } catch (error) {
      setMessage('Failed to send verification email');
      console.error('Error sending verification email:', error);
    } finally {
      setSending(false);
    }
  };

  return (
    <div>
      <button
        type="button"
        onClick={sendVerificationEmail}
        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600 transition"
        disabled={sending}
      >
        {sending ? 'Sending...' : 'Verify'}
      </button>
      {message && <p className="text-white mt-2">{message}</p>}
    </div>
  );
};

export default SendVerificationEmail;

