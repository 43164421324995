import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from '../../firebase/firebase';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/authContext';
import ClientResponses from './clientResponses';
import ContractDetails from './contractDetails';
import FreelancerUpload from './orderprogress/freelancerUpload';
import ApproveOrRequestChanges from './orderprogress/approveorrequestChanges';
import MessageForm from '../listings/listingdetail/MessageForm';
import ReviewForm from '../reviews/ReviewForm';
import DisputeForm from '../disputes/DisputeForm';
import DeliveryElement from './orderprogress/deliveryElement';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  CircularProgress,
  Button,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Box,
  Avatar,
} from '@mui/material';
import { FaEnvelope } from 'react-icons/fa';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReviewDisplay from './orderprogress/reviewDisplay';

const OrderTracking = () => {
  const { orderID } = useParams();
  const [order, setOrder] = useState(null);
  const [listingDetails, setListingDetails] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [milestones, setMilestones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [freelancerQuestions, setFreelancerQuestions] = useState([]);
  const { currentUser } = useAuth();
  const [freelancerUsername, setFreelancerUsername] = useState('');
  const [freelancerAvatar, setFreelancerAvatar] = useState('');
  const [clientUsername, setClientUsername] = useState('');
  const [showMessageForm, setShowMessageForm] = useState(false);
  const [isReviewSubmitted, setIsReviewSubmitted] = useState(false);
  const [hasReviewed, setHasReviewed] = useState(false);
  const [isProjectCompleted, setIsProjectCompleted] = useState(false);
  const [isDisputeEligible, setIsDisputeEligible] = useState(false);
  const [showDisputeForm, setShowDisputeForm] = useState(false);
  const [disputeSubmitted, setDisputeSubmitted] = useState(false);

  const fetchOrderDetails = useCallback(async () => {
    try {
      if (!orderID) {
        console.error('No orderID found in URL');
        setLoading(false);
        return;
      }

      const projectsQuery = query(collection(db, 'projects'), where('projectID', '==', orderID));
      const projectsSnapshot = await getDocs(projectsQuery);

      if (!projectsSnapshot.empty) {
        const projectDoc = projectsSnapshot.docs[0];
        const orderData = projectDoc.data();
        setOrder(orderData);

        const paymentsQuery = query(collection(db, 'payments'), where('projectID', '==', orderID));
        const paymentsSnapshot = await getDocs(paymentsQuery);
        if (!paymentsSnapshot.empty) {
          const paymentDoc = paymentsSnapshot.docs[0];
          const paymentData = paymentDoc.data();
          setPaymentDetails(paymentData);
        }

        if (orderData.status === 'completed') {
          setIsProjectCompleted(true);

          const completionDate = orderData.completionDate ? orderData.completionDate.toDate() : new Date();
          const currentTime = new Date();
          const disputeWindowOpen = currentTime - completionDate < 72 * 60 * 60 * 1000;

          setIsDisputeEligible(disputeWindowOpen);
        } else {
          const dueDate = orderData.dueDate.toDate();
          const currentTime = new Date();

          if (currentTime > dueDate) {
            setIsDisputeEligible(true);
          }
        }

        const milestonesQuery = query(collection(db, 'milestones'), where('project_id', '==', orderID));
        const milestonesSnapshot = await getDocs(milestonesQuery);
        if (!milestonesSnapshot.empty) {
          const milestoneDoc = milestonesSnapshot.docs[0];
          const milestoneData = milestoneDoc.data();

          const allMilestones = [milestoneData, ...(milestoneData.updates || [])];
          const sortedMilestones = allMilestones.sort((a, b) => a.step - b.step);
          setMilestones(sortedMilestones);
        }

        const listingRef = doc(db, 'listings', orderData.listingID);
        const listingDoc = await getDoc(listingRef);
        if (listingDoc.exists()) {
          const listingData = listingDoc.data();
          setListingDetails(listingData);
          const requirements = listingData.requirements || [];
          setFreelancerQuestions(requirements);
        }

        const freelancerRef = doc(db, 'users', orderData.freelancerID);
        const freelancerDoc = await getDoc(freelancerRef);
        if (freelancerDoc.exists()) {
          const freelancerData = freelancerDoc.data();
          setFreelancerUsername(freelancerData.username || 'Unknown User');
          setFreelancerAvatar(freelancerData.avatarURL || '');
        } else {
          setFreelancerUsername('Unknown User');
        }

        const clientRef = doc(db, 'users', orderData.clientID);
        const clientDoc = await getDoc(clientRef);
        if (clientDoc.exists()) {
          setClientUsername(clientDoc.data().username || 'Unknown User');
        } else {
          setClientUsername('Unknown User');
        }

        const reviewsQuery = query(
          collection(db, 'reviews'),
          where('listingId', '==', orderData.listingID),
          where('projectID', '==', orderData.projectID)
        );
        const reviewsSnapshot = await getDocs(reviewsQuery);
        if (!reviewsSnapshot.empty) {
          setHasReviewed(true);
        }
      } else {
        console.log('No such document in projects collection!');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching order details:', error);
      setLoading(false);
    }
  }, [orderID, currentUser.uid]);

  useEffect(() => {
    fetchOrderDetails();
  }, [orderID, fetchOrderDetails]);

  const startChat = () => {
    setShowMessageForm(true);
  };

  const closeMessageForm = (e) => {
    if (e.target === e.currentTarget) {
      setShowMessageForm(false);
    }
  };

  const handleDisputeSubmitted = () => {
    setDisputeSubmitted(true);
    fetchOrderDetails();
  };

  const formatDateTime = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  if (!order) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <Typography variant="h6" color="error">No orders found.</Typography>
      </Grid>
    );
  }

  const clientAnswers = Array.isArray(order.clientAnswers) ? order.clientAnswers : [];
  const isWorkUploaded = milestones.some((milestone) => milestone.title && milestone.title.startsWith('Work Uploaded'));

  return (
    <Grid container spacing={3} justifyContent="center" style={{ padding: '2rem' }}>
      <Grid item xs={12} md={10}>
        <Card sx={{ borderRadius: '8px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.5)', padding: '20px', marginBottom: '20px', background: 'linear-gradient(135deg, #1a1a1a 0%, #2c2c2c 50%,  #333333 100%)', border: '1px solid #444' }}>
          <CardContent>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Avatar
                  variant="rounded"
                  src={listingDetails?.images?.[0] || ''}
                  alt={listingDetails?.name || 'Listing'}
                  sx={{ width: 104, height: 104, borderRadius: '8px' }}
                />
              </Grid>
              <Grid item xs>
                <Typography variant="h5" gutterBottom>
                  <Link to={`/listing/${order.listingID}`} style={{ textDecoration: 'none', color: '#E0E0E0' }}>
                    {listingDetails ? listingDetails.name : 'Loading...'}
                  </Link>
                </Typography>
              </Grid>
              <Grid item>
                <IconButton color="primary" onClick={startChat}>
                  <FaEnvelope />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ marginTop: '1rem' }}>
              {/* Left Column: Order Tracking */}
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ color: '#E0E0E0', marginBottom: '1rem' }}>Track Order</Typography>
                <Stepper activeStep={milestones.length - 1} orientation="vertical" sx={{ backgroundColor: 'linear-gradient(135deg, #1a1a1a 0%, #333333 100%' }}>
                  {milestones.map((milestone, index) => {
                    if (milestone.step === 1) {
                      return (
                        <Step key={index} expanded={true}>
                          <StepLabel StepIconProps={{ completed: true }} sx={{ color: '#4A90E2' }}>
                            <Typography sx={{ color: '#E0E0E0', fontSize: '1rem' }}>{milestone.title || 'No title'}</Typography>
                          </StepLabel>
                          <Typography
                            variant="caption"
                            sx={{
                              display: 'block',
                              color: '#B0BEC5',
                              padding: '2px',
                              marginTop: '5px',
                            }}
                          >
                            {formatDateTime(milestone.createdAt)}
                          </Typography>
                          <StepContent
                            sx={{
                              display: 'block',
                              minHeight: 'auto',
                              maxHeight: 'none',
                              overflow: 'visible',
                              padding: '10px',
                            }}
                          >
                            <Typography variant="body1" sx={{ color: '#E0E0E0' }}>
                              {milestone.description || 'No description available'}
                            </Typography>
                            {index < milestones.length - 1 && (
                              <Divider
                                sx={{
                                  margin: '10px 0',
                                  width: '50%',
                                  marginLeft: '25%',
                                  backgroundColor: '#4A90E2',
                                  height: '1px',
                                }}
                              />
                            )}
                          </StepContent>
                        </Step>
                      );
                    }

                    const milestoneUpdates = milestone.updates ? milestone.updates : [milestone];

                    return milestoneUpdates.map((update, subIndex) => (
                      <Step key={`${index}-${subIndex}`} expanded={true}>
                        <StepLabel StepIconProps={{ completed: true }}>
                          <Typography sx={{ color: '#E0E0E0', fontSize: '1rem' }}>{update.title || 'No title'}</Typography>
                        </StepLabel>
                        <Typography
                          variant="caption"
                          sx={{
                            display: 'block',
                            color: '#B0BEC5',
                            padding: '2px',
                            marginTop: '5px',
                          }}
                        >
                          {formatDateTime(update.createdAt)}
                        </Typography>
                        <StepContent
                          sx={{
                            display: 'block',
                            minHeight: 'auto',
                            maxHeight: 'none',
                            overflow: 'visible',
                            padding: '10x',
                          }}
                        >
                          {(update.title.startsWith('Work Uploaded') || update.type) && (
                            <DeliveryElement
                              avatarURL={freelancerAvatar}
                              username={freelancerUsername}
                              attachments={[update]}
                              createdAt={update.createdAt}
                              version={update.version}
                            />
                          )}
                          {index < milestones.length - 1 && (
                            <Divider
                              sx={{
                                margin: '10px 0',
                                width: '50%',
                                marginLeft: '25%',
                                backgroundColor: '#4A90E2',
                                height: '1px',
                              }}
                            />
                          )}
                        </StepContent>
                      </Step>
                    ));
                  })}
                </Stepper>
              </Grid>

              {/* Right Column: Order Details */}
              <Grid item xs={12} md={7}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ color: '#E0E0E0', marginBottom: '1rem' }}>Order Details</Typography>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: '#4A90E2' }} />}
                        sx={{
                          padding: '10px 16px',
                          backgroundColor: '#1a202c',
                          color: '#E0E0E0',
                          borderBottom: '1px solid #4A90E2',
                          fontWeight: 'bold',
                          '&:hover': {
                            backgroundColor: '#3b414e',
                          },
                        }}
                      >
                        <Typography sx={{ color: '#E0E0E0' }}>Contract Details</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ backgroundColor: '#1a202c', color: '#E0E0E0' }}>
                        <ContractDetails order={order} listingDetails={listingDetails} paymentDetails={paymentDetails} />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: '#4A90E2' }} />}
                        sx={{
                          padding: '10px 16px',
                          backgroundColor: '#1a202c',
                          color: '#E0E0E0',
                          borderBottom: '1px solid #4A90E2',
                          '&:hover': {
                            backgroundColor: '#3b414e',
                          },
                        }}
                      >
                        <Typography sx={{ color: '#E0E0E0' }}>Requirements</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ backgroundColor: '#1a202c', color: '#E0E0E0' }}>
                        <ClientResponses
                          clientAnswers={clientAnswers}
                          freelancerQuestions={freelancerQuestions}
                          projectID={order.projectID}
                          additionalDetails={order.additionalDetails}
                          clientFiles={order.clientFiles}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  {currentUser.uid === order.freelancerID && (
                    <Grid item xs={12}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon sx={{ color: '#4A90E2' }} />}
                          sx={{
                            padding: '10px 16px',
                            backgroundColor: '#1a202c',
                            color: '#E0E0E0',
                            borderBottom: '1px solid #4A90E2',
                            '&:hover': {
                              backgroundColor: '#3b414e',
                            },
                          }}
                        >
                          <Typography sx={{ color: '#E0E0E0' }}>Upload Work</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ backgroundColor: '#1a202c', color: '#E0E0E0' }}>
                          <FreelancerUpload projectID={order.projectID} order={order} onUpload={fetchOrderDetails} />
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  )}

                  {/* Add Review Display here */}
                  {isProjectCompleted && hasReviewed && (
                    <Grid item xs={12}>
                      <ReviewDisplay 
                        projectID={order.projectID} 
                        clientID={order.clientID}
                        freelancerID={order.freelancerID}
                        listingName={listingDetails?.name}
                        currentUserID={currentUser.uid}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {isWorkUploaded && order.status === 'awaiting_client_review' && (
              <ApproveOrRequestChanges
                projectID={order.projectID}
                onApprove={fetchOrderDetails}
                onRequestChanges={fetchOrderDetails}
                clientUsername={clientUsername}
              />
            )}
            {isProjectCompleted && !hasReviewed && currentUser.uid === order.clientID && (
              <ReviewForm
                listingId={order.listingID}
                projectID={order.projectID}
                onReviewSubmitted={() => {
                  setIsReviewSubmitted(true);
                  setHasReviewed(true);
                }}
              />
            )}
            {isReviewSubmitted && (
              <Typography variant="body1" color="success" align="center" gutterBottom>
                Thank you for submitting your review!
              </Typography>
            )}

            <Button
              startIcon={<RefreshIcon />}
              variant="contained"
              color="primary"
              onClick={fetchOrderDetails}
              fullWidth
              sx={{ mt: 2, backgroundColor: '#4A90E2', '&:hover': { backgroundColor: '#357ABD' } }}
            >
              Refresh
            </Button>
          </CardContent>
        </Card>
      </Grid>
  
      {isProjectCompleted && isDisputeEligible && currentUser.uid === order.clientID && (
        <Grid item xs={12}>
          <Card sx={{ borderRadius: '8px', boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)', padding: '20px', marginTop: '20px', backgroundColor: '#2A3C50' }}>
            <CardContent>
              {!disputeSubmitted ? (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setShowDisputeForm(!showDisputeForm)}
                    fullWidth
                    sx={{ backgroundColor: '#E53935', '&:hover': { backgroundColor: '#D32F2F' } }}
                  >
                    {showDisputeForm ? 'Close Dispute Form' : 'Open Dispute'}
                  </Button>
  
                  {showDisputeForm && (
                    <div className="mt-4">
                      <Typography variant="h6" sx={{ color: '#E0E0E0' }}>
                        Dispute Form
                      </Typography>
                      <DisputeForm
                        projectID={order.projectID}
                        freelancerID={order.freelancerID}
                        onDisputeSubmitted={handleDisputeSubmitted}
                      />
                    </div>
                  )}
                </>
              ) : (
                <Typography variant="h6" color="success">
                  Thank you for submitting your dispute.
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      )}
  
      {showMessageForm && (
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeMessageForm}
        >
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <MessageForm
              listingOwnerId={currentUser.uid === order.clientID ? order.freelancerID : order.clientID}
              listingId={order.listingID}
              onClose={closeMessageForm}
            />
          </div>
        </div>
      )}
    </Grid>
  );

};

export default OrderTracking;

