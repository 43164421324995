import React, { useState, useRef } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../firebase/firebase';
import './UploadProfileMedia.css'; // Import your CSS for custom styling

const UploadProfileMedia = ({ currentUser, onAvatarURLChange, onBannerURLChange }) => {
  const [avatarFile, setAvatarFile] = useState(null);
  const [bannerFile, setBannerFile] = useState(null);
  const [avatarURL, setAvatarURL] = useState('');
  const [bannerURL, setBannerURL] = useState('');

  const avatarInputRef = useRef();
  const bannerInputRef = useRef();

  const handleFileUpload = async (file, type) => {
    if (!file) return;
    const fileName = `${currentUser.uid}-${Date.now()}-${file.name}`;
    const fileRef = ref(storage, `media/${type}/${fileName}`);
    const uploadTask = uploadBytesResumable(fileRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.error('Upload error:', error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            console.log(`File available at: ${downloadURL}`);
            resolve(downloadURL);
          } catch (error) {
            console.error('Failed to get download URL:', error);
            reject(error);
          }
        }
      );
    });
  };

  const handleAvatarUpload = async (file) => {
    console.log('Starting avatar upload');
    try {
      const url = await handleFileUpload(file, 'avatars');
      console.log('Avatar uploaded successfully:', url);
      setAvatarURL(url);
      onAvatarURLChange(url);
    } catch (error) {
      console.error('Failed to upload avatar');
    }
  };

  const handleBannerUpload = async (file) => {
    console.log('Starting banner upload');
    try {
      const url = await handleFileUpload(file, 'banners');
      console.log('Banner uploaded successfully:', url);
      setBannerURL(url);
      onBannerURLChange(url);
    } catch (error) {
      console.error('Failed to upload banner');
    }
  };

  const handleAvatarDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setAvatarFile(file);
    handleAvatarUpload(file);
  };

  const handleBannerDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setBannerFile(file);
    handleBannerUpload(file);
  };

  const handleAvatarClick = () => {
    avatarInputRef.current.click();
  };

  const handleBannerClick = () => {
    bannerInputRef.current.click();
  };

  const handleAvatarDelete = () => {
    setAvatarFile(null);
    setAvatarURL('');
    avatarInputRef.current.value = '';
    onAvatarURLChange('');
  };

  const handleBannerDelete = () => {
    setBannerFile(null);
    setBannerURL('');
    bannerInputRef.current.value = '';
    onBannerURLChange('');
  };

  return (
    <div className="upload-profile-media">
      <div className="upload-section">
        <label className="upload-label">Profile Picture</label>
        <div
          className="upload-dropzone"
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleAvatarDrop}
          onClick={handleAvatarClick}
        >
          {avatarURL ? (
            <div className="thumbnail-container">
              <img src={avatarURL} alt="Avatar Preview" className="thumbnail" />
              <button onClick={handleAvatarDelete} className="delete-button">Delete</button>
            </div>
          ) : (
            <div>
              <input
                type="file"
                ref={avatarInputRef}
                onChange={(e) => {
                  setAvatarFile(e.target.files[0]);
                  handleAvatarUpload(e.target.files[0]);
                }}
                className="upload-input"
              />
              <p>Drag & drop an image here, or click to select one</p>
            </div>
          )}
        </div>
      </div>

      <div className="upload-section">
        <label className="upload-label">Banner</label>
        <div
          className="upload-dropzone"
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleBannerDrop}
          onClick={handleBannerClick}
        >
          {bannerURL ? (
            <div className="thumbnail-container">
              <img src={bannerURL} alt="Banner Preview" className="thumbnail" />
              <button onClick={handleBannerDelete} className="delete-button">Delete</button>
            </div>
          ) : (
            <div>
              <input
                type="file"
                ref={bannerInputRef}
                onChange={(e) => {
                  setBannerFile(e.target.files[0]);
                  handleBannerUpload(e.target.files[0]);
                }}
                className="upload-input"
              />
              <p>Drag & drop an image here, or click to select one</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadProfileMedia;

