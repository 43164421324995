import { FaLaptopCode, FaCoins, FaPaintBrush, FaUsers, FaBullhorn, FaMobileAlt, FaLightbulb, FaBalanceScale, FaWallet, FaHeadset, FaChalkboardTeacher, FaCalendarAlt } from 'react-icons/fa';

export const categories = [
  {
    name: "Websites",
    icon: <FaLaptopCode />,
    subcategories: [
      "Website Development",
      "Website Maintenance",
      "WordPress Development",
      "Shopify Development",
      "Landing Page Design",
      "Web Application Development"
    ]
  },
  {
    name: "Blockchain",
    icon: <FaCoins />,
    subcategories: [
      "Blockchain Development",
      "Smart Contract Development",
      "Token Design & Tokenomics",
      "Blockchain Integration",
      "Decentralized Apps (dApps)",
      "Blockchain Security & Auditing"
    ]
  },
  {
    name: "Design & Art",
    icon: <FaPaintBrush />,
    subcategories: [
      "Logo Design",
      "NFT Art Creation",
      "Marketing Graphics",
      "Website UI/UX Design",
      "Custom Illustrations",
      "Infographics"
    ]
  },
  {
    name: "Community Management",
    icon: <FaUsers />,
    subcategories: [
      "Community Moderation",
      "Community Building",
      "Engagement Strategies",
      "Event Planning & Management",
      "Content Moderation",
      "Ambassador Programs"
    ]
  },
  {
    name: "Marketing & Promotion",
    icon: <FaBullhorn />,
    subcategories: [
      "Social Media Marketing",
      "Influencer Partnerships",
      "Content Creation",
      "SEO & SEM",
      "Email Marketing",
      "Paid Advertising"
    ]
  },
  {
    name: "Software Development",
    icon: <FaLaptopCode />,
    subcategories: [
      "Custom Software Development",
      "API Development & Integration",
      "Automation & Scripting",
      "AI & Machine Learning",
      "Database Design & Management",
      "Software Maintenance"
    ]
  },
  {
    name: "Mobile Apps",
    icon: <FaMobileAlt />,
    subcategories: [
      "Mobile App Development",
      "Cross-platform Apps",
      "Android App Development",
      "iOS App Development",
      "Mobile App Maintenance",
      "UI/UX Design for Mobile"
    ]
  },
  {
    name: "Consulting & Strategy",
    icon: <FaLightbulb />,
    subcategories: [
      "Blockchain Strategy & Advisory",
      "Market Research & Analysis",
      "Project Management",
      "Technical Consulting",
      "Tokenomics Consultation",
      "Regulatory Compliance"
    ]
  },
  {
    name: "Legal & Compliance",
    icon: <FaBalanceScale />,
    subcategories: [
      "Regulatory Compliance",
      "Legal Consultation",
      "Contract Drafting",
      "Intellectual Property Protection",
      "Privacy Policies & Terms",
      "Dispute Resolution"
    ]
  },
  {
    name: "Financial Services",
    icon: <FaWallet />,
    subcategories: [
      "Tokenomics Design",
      "Investment & Fundraising",
      "Accounting & Auditing",
      "Payment Integration",
      "Financial Reporting",
      "Cryptocurrency Wallet Setup"
    ]
  },
  {
    name: "Support & Maintenance",
    icon: <FaHeadset />,
    subcategories: [
      "Technical Support",
      "Bug Fixes & Patches",
      "System Upgrades & Enhancements",
      "Ongoing Maintenance",
      "Customer Support",
      "Performance Optimization"
    ]
  },
  {
    name: "Education & Training",
    icon: <FaChalkboardTeacher />,
    subcategories: [
      "Blockchain Training",
      "Development Workshops",
      "Marketing Workshops",
      "Legal & Compliance Training",
      "Community Management Training",
      "Technical Tutorials"
    ]
  }
];

