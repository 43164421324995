import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../../../../firebase/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { useAuth } from '../../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import PaymentComponent from '../PaymentComponent';

const ClientPayment = ({ listing, selectedPackage }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handlePaymentSuccess = async (publicKey, signature) => {
    try {
      const paymentID = `ser-${uuidv4()}`;
      const projectID = `ord-${uuidv4()}`;

      const lamports = selectedPackage ? selectedPackage.price * 1000000000 : listing.price * 1000000000;
      const packageName = selectedPackage?.title || 'Default Package';
      const deliveryTime = selectedPackage?.deliveryTime || listing.daysToDelivery;

      if (!selectedPackage) {
        throw new Error('Selected package is missing or invalid');
      }

      // Writing to Firestore in parallel to optimize performance
      const projectPromise = addDoc(collection(db, 'projects'), {
        projectID,
        clientID: currentUser.uid,
        freelancerID: listing.uid,
        listingID: listing.id,
        listingName: listing.name || '',
        listingImageUrl: listing.images?.[0] || '',
        status: 'active',
        selectedPackage: packageName,
        createdAt: new Date(),
        deliveryTime,
        dueDate: new Date(Date.now() + deliveryTime * 24 * 60 * 60 * 1000),
        amountEarned: lamports / 1000000,
      });

      const milestonePromise = addDoc(collection(db, 'milestones'), {
        project_id: projectID,
        title: 'Order Received',
        description: 'Thank you for your order and happy hustlez',
        status: 'pending',
        due_date: new Date(Date.now() + deliveryTime * 24 * 60 * 60 * 1000),
        createdAt: new Date(),
	step: 1,
	updates: [],
      });

      await Promise.all([projectPromise, milestonePromise]);

      // Navigate to confirmation page after successful payment and project setup
      navigate(`/purchase-confirmation?projectID=${projectID}&listingID=${listing.id}`, {
        state: {
          projectID,
          listingID: listing.id,
          freelancerQuestions: listing.requirements || [],
        },
      });
    } catch (error) {
      console.error('Error processing payment success:', error);
      alert('Failed to process payment success. Please contact support.');
    }
  };

  const handlePaymentFailure = (error) => {
    console.error('Payment failed:', error);
    alert('Payment failed. Please try again.');
  };

  return (
    <div className="max-w-md mx-auto text-white">
      <PaymentComponent
        amountSOL={selectedPackage ? selectedPackage.price : listing.price}
        paymentDescription="Listing"
        clientPaymentData={{ listingID: listing.id, selectedPackage, projectID: uuidv4() }}
        onPaymentSuccess={handlePaymentSuccess}
        onPaymentFailure={handlePaymentFailure}
      />
    </div>
  );
};

export default ClientPayment;

