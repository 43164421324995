import React, { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/firebase';

const ClickThroughRateChart = ({ freelancerID, timeFrame, subTimeFrame, customRange, selectedQuarter }) => {
  const [dataset, setDataset] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Determine the start and end timestamps based on the timeFrame and subTimeFrame
        let startTimestamp, endTimestamp;

        const now = new Date();
        switch (timeFrame) {
          case 'monthly':
            if (subTimeFrame === 'previousMonth') {
              const previousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
              startTimestamp = Timestamp.fromDate(new Date(previousMonth.getFullYear(), previousMonth.getMonth(), 1, 0, 0, 0, 0));
              endTimestamp = Timestamp.fromDate(new Date(previousMonth.getFullYear(), previousMonth.getMonth() + 1, 0, 23, 59, 59, 999));
            } else {
              startTimestamp = Timestamp.fromDate(new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0));
              endTimestamp = Timestamp.fromDate(new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999));
            }
            break;
          case 'yearly':
            startTimestamp = Timestamp.fromDate(new Date(now.getFullYear(), 0, 1, 0, 0, 0, 0));
            endTimestamp = Timestamp.fromDate(new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999));
            break;
          default:
            // Handle custom range or other default cases
            startTimestamp = Timestamp.fromDate(new Date(customRange.start));
            endTimestamp = Timestamp.fromDate(new Date(customRange.end));
            break;
        }

        // Log the timestamps
        console.log('Start Timestamp:', startTimestamp);
        console.log('End Timestamp:', endTimestamp);

        // Get all listings for the freelancer
        const listingsQuery = query(collection(db, 'listings'), where('uid', '==', freelancerID));
        const listingsSnapshot = await getDocs(listingsQuery);
        const listingIDs = listingsSnapshot.docs.map(doc => doc.id);

        // Log the listing IDs
        console.log('Listing IDs:', listingIDs);

        // Split listingIDs if necessary (due to Firestore limitations)
        const batchSize = 10;
        const batches = [];
        for (let i = 0; i < listingIDs.length; i += batchSize) {
          batches.push(listingIDs.slice(i, i + batchSize));
        }

        console.log('Batches of listing IDs:', batches);

        const clicksData = {};
        const purchasesData = {};

        for (const batch of batches) {
          // Fetch clicks data
          const clicksQuery = query(
            collection(db, 'clicks'),
            where('listingID', 'in', batch),
            where('timestamp', '>=', startTimestamp),
            where('timestamp', '<=', endTimestamp)
          );

          // Log the clicksQuery
          console.log('Clicks Query:', clicksQuery);

          const clicksSnapshot = await getDocs(clicksQuery);

          // Fetch purchases data
          const projectsQuery = query(
            collection(db, 'projects'),
            where('listingID', 'in', batch),
            where('completedAt', '>=', startTimestamp),
            where('completedAt', '<=', endTimestamp)
          );

          // Log the projectsQuery
          console.log('Projects Query:', projectsQuery);

          const projectsSnapshot = await getDocs(projectsQuery);

          // Initialize counts if necessary
          batch.forEach(listingID => {
            clicksData[listingID] = clicksData[listingID] || 0;
            purchasesData[listingID] = purchasesData[listingID] || 0;
          });

          // Process clicks data
          clicksSnapshot.forEach(doc => {
            const data = doc.data();
            clicksData[data.listingID] += 1;
          });

          // Process purchases data
          projectsSnapshot.forEach(doc => {
            const data = doc.data();
            purchasesData[data.listingID] += 1;
          });
        }

        // Generate labels and dataset
        const chartLabels = listingIDs.map(listingID => {
          const listingDoc = listingsSnapshot.docs.find(doc => doc.id === listingID);
          return listingDoc?.data()?.name || listingID;
        });
        const clicksArray = listingIDs.map(id => clicksData[id]);
        const purchasesArray = listingIDs.map(id => purchasesData[id]);

        // Log the data being passed to the chart
        console.log('Chart Labels:', chartLabels);
        console.log('Clicks Data:', clicksArray);
        console.log('Purchases Data:', purchasesArray);

        setLabels(chartLabels);
        setDataset([
          { label: 'Views', data: clicksArray },
          { label: 'Purchases', data: purchasesArray }
        ]);

        console.log('State after update:');
        console.log('Labels:', labels);
        console.log('Dataset:', dataset);
      } catch (error) {
        console.error('Error fetching CTR data:', error);
      }
    };

    if (freelancerID) {
      fetchData();
    }
  }, [freelancerID, timeFrame, subTimeFrame, customRange, selectedQuarter]);

  return (
    <div style={{ width: '100%', height: 400 }}>
      <BarChart
        key={`${timeFrame}-${subTimeFrame}-${Date.now()}`} // Add Date.now()
        xAxis={[{ scaleType: 'band', data: labels }]}
        series={dataset}
        width={600}
        height={400}
        barLabel="value"
      />
    </div>
  );
};

export default ClickThroughRateChart;

