import React from 'react';

const ReviewListing = ({ formState, onEdit, onListService, loading }) => {
  const { name, description, price, standardPackage, deluxePackage, premiumPackage, requirements, faqs, images, videos, daysToDelivery } = formState;

  return (
    <div className="max-w-full p-8 mx-auto shadow-md border rounded-lg">
      <h2 className="text-2xl font-semibold text-center mb-6">Review Your Listing</h2>
      <div className="space-y-4">
        {name && (
          <div>
            <h3 className="text-lg font-semibold">Name</h3>
            <p>{name}</p>
          </div>
        )}
        {description && (
          <div>
            <h3 className="text-lg font-semibold">Description</h3>
            <p>{description}</p>
          </div>
        )}
        {price && (
          <div>
            <h3 className="text-lg font-semibold">Price</h3>
            <p>{price}</p>
          </div>
        )}
        {daysToDelivery && (
          <div>
            <h3 className="text-lg font-semibold">Days to Delivery</h3>
            <p>{daysToDelivery}</p>
          </div>
        )}
        {standardPackage && (standardPackage.title || standardPackage.description || standardPackage.price || standardPackage.deliveryTime) && (
          <div>
            <h3 className="text-lg font-semibold">Standard Package</h3>
            {standardPackage.title && <p>Title: {standardPackage.title}</p>}
            {standardPackage.description && <p>Description: {standardPackage.description}</p>}
            {standardPackage.price && <p>Price: {standardPackage.price} SOL</p>}
            {standardPackage.deliveryTime && <p>Delivery Time: {standardPackage.deliveryTime} days</p>}
          </div>
        )}
        {deluxePackage && (deluxePackage.title || deluxePackage.description || deluxePackage.price || deluxePackage.deliveryTime) && (
          <div>
            <h3 className="text-lg font-semibold">Deluxe Package</h3>
            {deluxePackage.title && <p>Title: {deluxePackage.title}</p>}
            {deluxePackage.description && <p>Description: {deluxePackage.description}</p>}
            {deluxePackage.price && <p>Price: {deluxePackage.price} SOL</p>}
            {deluxePackage.deliveryTime && <p>Delivery Time: {deluxePackage.deliveryTime} days</p>}
          </div>
        )}
        {premiumPackage && (premiumPackage.title || premiumPackage.description || premiumPackage.price || premiumPackage.deliveryTime) && (
          <div>
            <h3 className="text-lg font-semibold">Premium Package</h3>
            {premiumPackage.title && <p>Title: {premiumPackage.title}</p>}
            {premiumPackage.description && <p>Description: {premiumPackage.description}</p>}
            {premiumPackage.price && <p>Price: {premiumPackage.price} SOL</p>}
            {premiumPackage.deliveryTime && <p>Delivery Time: {premiumPackage.deliveryTime} days</p>}
          </div>
        )}
        {requirements && (
          <div>
            <h3 className="text-lg font-semibold">Requirements</h3>
            <p>{requirements}</p>
          </div>
        )}
        {faqs && faqs.length > 0 && (
          <div>
            <h3 className="text-lg font-semibold">FAQs</h3>
            {faqs.map((faq, index) => (
              <div key={index}>
                <p><strong>Q:</strong> {faq.question}</p>
                <p><strong>A:</strong> {faq.answer}</p>
              </div>
            ))}
          </div>
        )}
        {images && images.length > 0 && (
          <div>
            <h3 className="text-lg font-semibold">Images</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {images.map((image, index) => (
                <img key={index} src={image} alt={`Uploaded ${index}`} className="w-full h-auto rounded-lg" />
              ))}
            </div>
          </div>
        )}
        {videos && videos.length > 0 && (
          <div>
            <h3 className="text-lg font-semibold">Videos</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {videos.map((video, index) => (
                <video key={index} src={video} controls className="w-full h-auto rounded-lg" />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-center mt-6">
        <button
          onClick={() => onListService()}
          className="hustle-button px-6 py-3 bg-green-500 text-white font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 hover:bg-green-600"
          disabled={loading}
        >
          {loading ? 'Processing...' : `List Service`}
        </button>
        <button
          onClick={() => onEdit()}
          className="pay-forlisting-button ml-4 px-6 py-3 bg-gray-500 text-white font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 hover:bg-gray-600"
          disabled={loading}
        >
          Edit
        </button>
      </div>
      {loading && (
        <div className="flex justify-center mt-4">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default ReviewListing;

