import { useEffect, useRef } from 'react';
import { doc, setDoc, getDoc, updateDoc, increment, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';

const UpdateChatMedals = ({ uid, triggerUpdate }) => {
  const updateTimeoutRef = useRef(null);
  const batchUpdateRef = useRef(0);

  useEffect(() => {
    const updateChatMedals = async () => {
      const userMedalsRef = doc(db, 'medals', uid);
      const userMedalsDoc = await getDoc(userMedalsRef);

      if (userMedalsDoc.exists()) {
        const data = userMedalsDoc.data();
        const chatCount = data.chatCount || 0;

        // Prepare new achievements
        const newAchievements = {
          chatCount: increment(batchUpdateRef.current), // Batch updates together
        };

        // Define milestones
        const milestones = [
          { id: '1-chat', goal: 1, type: 'chatCount' },
          { id: '100-chats', goal: 100, type: 'chatCount' },
          { id: '250-chats', goal: 250, type: 'chatCount' },
          { id: '500-chats', goal: 500, type: 'chatCount' },
          { id: '1000-chats', goal: 1000, type: 'chatCount' },
        ];

        // Check and update milestone achievements
        milestones.forEach((milestone) => {
          if (chatCount + batchUpdateRef.current >= milestone.goal && !data[`${milestone.type}AchievedOn`]) {
            newAchievements[`${milestone.type}AchievedOn`] = serverTimestamp();
          }
        });

        await updateDoc(userMedalsRef, newAchievements);
      } else {
        await setDoc(userMedalsRef, {
          uid: uid,
          chatCount: batchUpdateRef.current,
          chatCountAchievedOn: serverTimestamp(),
        });
      }

      // Reset the batch update count
      batchUpdateRef.current = 0;
    };

    if (triggerUpdate) {
      // Cancel any existing timeout
      if (updateTimeoutRef.current) {
        clearTimeout(updateTimeoutRef.current);
      }

      // Increment the batch update count
      batchUpdateRef.current += 1;

      // Set a timeout to debounce the updates
      updateTimeoutRef.current = setTimeout(() => {
        updateChatMedals();
      }, 500); // Adjust the debounce delay as needed (500ms here)
    }

    return () => {
      if (updateTimeoutRef.current) {
        clearTimeout(updateTimeoutRef.current);
      }
    };
  }, [triggerUpdate, uid]);

  return null;
};

export default UpdateChatMedals;

