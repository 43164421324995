import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Link } from '@mui/material';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import dayjs from 'dayjs';

const Disputes = () => {
  const [openDisputes, setOpenDisputes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const now = dayjs();
        const cutoffDate = now.subtract(48, 'hour').toDate();

        // Query to fetch disputes that are not closed
        const openDisputesQuery = query(
          collection(db, 'disputes'),
          where('disputeStatus', '!=', 'closed'),
          orderBy('disputeOpenedAt', 'desc')
        );

        const disputesSnapshot = await getDocs(openDisputesQuery);

        const disputesList = disputesSnapshot.docs
          .filter(doc => {
            const data = doc.data();
            const lastResponse = data.responses?.[data.responses.length - 1];
            const lastResponseTime = lastResponse ? lastResponse.respondedAt.toDate() : null;

            // Include disputes that are within the 48-hour grace period or have responses within the last 48 hours
            return !lastResponseTime || lastResponseTime > cutoffDate || (data.disputeOpenedAt.toDate() > cutoffDate);
          })
          .map(doc => {
            const data = doc.data();
            const lastResponse = data.responses?.[data.responses.length - 1];
            const lastResponseAt = lastResponse ? lastResponse.respondedAt.toDate().toLocaleDateString() : 'N/A';

            return {
              disputeID: data.disputeNumber || doc.id, // Use disputeNumber if available
              disputeStatus: data.disputeStatus || 'Unknown',
              disputeOpenedAt: data.disputeOpenedAt ? data.disputeOpenedAt.toDate().toLocaleDateString() : 'N/A',
              lastResponseAt,
            };
          });

        setOpenDisputes(disputesList);
      } catch (error) {
        console.error("Error fetching open disputes:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <Paper elevation={3} sx={{ padding: 2, width: '100%', maxWidth: '1000px', margin: '0 auto' }}>
      <Box mb={2}>
        <Typography variant="h6">Open Client Disputes</Typography>
      </Box>
      <TableContainer sx={{ maxHeight: 300, overflowY: 'auto' }}>
        <Table aria-label="open disputes table">
          <TableHead>
            <TableRow>
              <TableCell>Dispute ID</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Dispute Opened</TableCell>
              <TableCell align="center">Last Response</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {openDisputes.map((dispute, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <Link href={`/dispute/${dispute.disputeID}`} underline="hover">
                    {dispute.disputeID}
                  </Link>
                </TableCell>
                <TableCell align="center">{dispute.disputeStatus}</TableCell>
                <TableCell align="center">{dispute.disputeOpenedAt}</TableCell>
                <TableCell align="center">{dispute.lastResponseAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default Disputes;

