import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../../firebase/firebase';
import { collection, getDocs, query, where, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../../../contexts/authContext';
import Chat from '../chat';
import { FaRegCommentDots, FaLifeRing } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

const MyMessages = () => {
  const { currentUser, isAdmin } = useAuth();
  const [chats, setChats] = useState([]);
  const [users, setUsers] = useState({});
  const [listings, setListings] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();

  const fetchUsersAndListings = async (chatsData) => {
    const userIds = new Set(chatsData.flatMap(chat => chat.participants));
    const userPromises = Array.from(userIds).map(async (userId) => {
      if (!userId) return null;
      const userDoc = await getDocs(query(collection(db, 'users'), where('__name__', '==', userId)));
      if (!userDoc.empty) {
        const userData = userDoc.docs[0].data();
        return { id: userId, username: userData.username };
      }
      return { id: userId, username: 'No Profile' };
    });

    const usersArray = await Promise.all(userPromises);
    const usersMap = usersArray.reduce((acc, user) => {
      if (user) acc[user.id] = user.username;
      return acc;
    }, {});

    const listingIds = new Set(chatsData.map(chat => chat.listingId).filter(id => !!id));
    const listingPromises = Array.from(listingIds).map(async (listingId) => {
      if (!listingId) return null;
      const listingDoc = await getDocs(query(collection(db, 'listings'), where('__name__', '==', listingId)));
      if (!listingDoc.empty) {
        const listingData = listingDoc.docs[0].data();
        return { id: listingId, ...listingData };
      }
      return { id: listingId, name: 'No Listing Name', images: [] };
    });

    const listingsArray = await Promise.all(listingPromises);
    const listingsMap = listingsArray.reduce((acc, listing) => {
      if (listing) acc[listing.id] = listing;
      return acc;
    }, {});

    setUsers(usersMap);
    setListings(listingsMap);
  };

  const fetchChats = useCallback(() => {
    if (!currentUser) return;

    const chatsQuery = query(
      collection(db, 'messages'),
      where('participants', 'array-contains', currentUser.uid)
    );

    const unsubscribe = onSnapshot(chatsQuery, async (chatsSnapshot) => {
      const chatsData = chatsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      chatsData.sort((a, b) => (b.lastChatTime?.seconds || 0) - (a.lastChatTime?.seconds || 0));

      setChats(chatsData);
      await fetchUsersAndListings(chatsData);
      setLoading(false);
    }, (err) => {
      console.error('Error fetching chats:', err);
      setError('Failed to fetch chats');
      setLoading(false);
    });

    return () => unsubscribe();
  }, [currentUser]);

  useEffect(() => {
    fetchChats();
  }, [fetchChats]);

  const handleSelectChat = (chatId) => {
    setSelectedChatId(chatId);
    if (isMobile) {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate('/my-messages');
  };

  const hasUnreadMessages = (chat) => {
    return chat.messages?.some(message => message.senderId !== currentUser.uid && !message.isRead);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'No Last Message Time';
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleString();
  };

  const getLastMessage = (messages) => {
    if (!messages || messages.length === 0) return 'No Messages';
    return messages[messages.length - 1].text || 'No Text';
  };

  if (loading) {
    return (
      <div className="loading-container flex justify-center items-center h-screen bg-gradient-to-b from-gray-800 via-gray-900 to-black">
        <div className="spinner"></div>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gradient-to-b from-gray-800 via-gray-900 to-black text-white">
      <div className="md:w-1/4 p-4 messages-sidebar bg-gradient-to-b from-gray-900 via-gray-800 to-black rounded-lg shadow-lg overflow-y-auto h-full">
        <h2 className="text-xl font-bold mb-4 text-yellow-400">My Messages</h2>
        <ul>
          {chats.map((chat) => (
            <li
              key={chat.id}
              className={`flex border border-gray-700 p-4 mb-2 cursor-pointer hover:bg-gray-700 transition duration-300 rounded-lg ${selectedChatId === chat.id ? 'bg-gray-700' : 'bg-gray-800'}`}
              onClick={() => handleSelectChat(chat.id)}
            >
              <div className="w-16 h-16 flex-shrink-0 mr-4 relative">
                {chat.isSupportChat ? (
                  <FaLifeRing className="w-full h-full text-yellow-400" />
                ) : listings[chat.listingId]?.images?.[0] ? (
                  <img src={listings[chat.listingId].images[0]} alt="Thumbnail" className="w-full h-full object-cover rounded-lg" />
                ) : (
                  <FaRegCommentDots className="w-full h-full text-gray-500" />
                )}
                {hasUnreadMessages(chat) && (
                  <span className="absolute top-0 right-0 w-3 h-3 bg-red-500 rounded-full"></span>
                )}
              </div>
              <div className="flex-grow text-xs lg:text-sm">
                {chat.isSupportChat ? (
                  <>
                    <div>
                      <strong className="text-yellow-400">Support Chat</strong>
                    </div>
                    <div>
                      <strong>User:</strong>
                      {isAdmin ? (
                        <span className="text-yellow-400">Support</span>
                      ) : (
                        <a href={`/profile/${chat.participants.find(id => id !== currentUser.uid)}`} className="text-indigo-400 hover:text-yellow-400 underline transition duration-300 ease-in-out">
                          {users[chat.participants.find(id => id !== currentUser.uid)] || 'No Profile'}
                        </a>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <strong>Listing:</strong> <a href={`/listing/${chat.listingId}`} className="text-indigo-400 hover:text-yellow-400 underline transition duration-300 ease-in-out">{listings[chat.listingId]?.name || 'No Listing Name'}</a>
                    </div>
                    <div>
                      <strong>User:</strong> <a href={`/profile/${chat.participants.find(id => id !== currentUser.uid)}`} className="text-indigo-400 hover:text-yellow-400 underline transition duration-300 ease-in-out">
                        {users[chat.participants.find(id => id !== currentUser.uid)] || 'No Profile'}
                      </a>
                    </div>
                  </>
                )}
                <div>
                  <strong>Last Message:</strong> {getLastMessage(chat.messages)}
                </div>
                <div>
                  <strong>Last Activity:</strong> {formatTimestamp(chat.lastChatTime)}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="md:w-3/4 pl-4 bg-gray-900">
        {selectedChatId && !isMobile && (
          <Chat
            key={selectedChatId}
            chatId={selectedChatId}
            users={users}
            onClose={() => setSelectedChatId(null)}
          />
        )}
      </div>
      {isMobile && isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center">
          <div className="bg-gradient-to-b from-gray-900 via-gray-800 to-black w-full h-full p-4 overflow-auto rounded-lg shadow-2xl">
            <button onClick={handleCloseModal} className="mb-4 px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 transition duration-300 ease-in-out">
              Back to Messages
            </button>
            <Chat
              chatId={selectedChatId}
              users={users}
              onClose={handleCloseModal}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MyMessages;

