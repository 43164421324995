import React, { useState } from 'react';
import { FaClipboardList, FaShoppingCart, FaLock, FaInfo, FaHammer, FaComments, FaFileAlt, FaDollarSign } from 'react-icons/fa';
import FadeInSection from './CategoryGallery/FadeInSection';

const HowItWorks = () => {
    const [visibleTextIndex, setVisibleTextIndex] = useState(null);

    const steps = [
        {
            icon: <FaClipboardList className="mx-auto text-4xl text-yellow-400 mb-4" />,
            title: "List a Service",
            description: "Freelancers list their services with detailed descriptions and pricing.",
        },
        {
            icon: <FaShoppingCart className="mx-auto text-4xl text-yellow-400 mb-4" />,
            title: "Buy the Service",
            description: "Clients purchase the service by paying the listed price.",
        },
        {
            icon: <FaLock className="mx-auto text-4xl text-yellow-400 mb-4" />,
            title: "Funds Lock-Up",
            description: "Funds are held securely until the work is completed or a dispute is resolved.",
        },
        {
            icon: <FaInfo className="mx-auto text-4xl text-yellow-400 mb-4" />,
            title: "Provide Information",
            description: "Clients provide freelancers with necessary information to start the work.",
        },
        {
            icon: <FaHammer className="mx-auto text-4xl text-yellow-400 mb-4" />,
            title: "Freelancer Starts Working",
            description: "Freelancers start working on the project and keep clients updated on the progress.",
        },
        {
            icon: <FaComments className="mx-auto text-4xl text-yellow-400 mb-4" />,
            title: "Revisions and Feedback",
            description: "Freelancers and clients work together on revisions to get the product just right.",
        },
        {
            icon: <FaFileAlt className="mx-auto text-4xl text-yellow-400 mb-4" />,
            title: "Freelancer Sends Final Product",
            description: "Freelancers send the final product to clients for review.",
        },
        {
            icon: <FaDollarSign className="mx-auto text-4xl text-yellow-400 mb-4" />,
            title: "Work is Complete & Payment",
            description: "Both parties sign off on the completed work, and freelancers receive their payment. Clients get the final product.",
        },
    ];

    return (
        <div className="py-12">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <FadeInSection>
                    <h1 className="text-5xl font-extrabold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-white via-gray-300 to-white animate-fade-in p-4">
                        How it Works
                    </h1>
                </FadeInSection>
                <FadeInSection>
                    <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-8">
                        {steps.map((step, index) => (
                            <div 
                                key={index} 
                                className="bg-grad-cat rounded-lg shadow-md p-6 text-center cursor-pointer"
                                onClick={() => setVisibleTextIndex(visibleTextIndex === index ? null : index)}
                                onMouseEnter={() => setVisibleTextIndex(index)}
                                onMouseLeave={() => setVisibleTextIndex(null)}
                            >
                                {step.icon}
                                <h3 className="text-xl font-bold text-yellow-400 mb-2">{step.title}</h3>
                                <p className={`text-white sm:block ${visibleTextIndex === index ? 'block' : 'hidden'}`}>
                                    {step.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </FadeInSection>
            </div>
        </div>
    );
};

export default HowItWorks;

