import React from 'react';
import { FaCheck } from 'react-icons/fa';
import DeleteMessage from './DeleteMessage';

const ChatMessages = ({
  messages,
  currentUser,
  users,
  isSupportChat,
  handleUserClick,
  handleDeleteMessage,
  handleShowMore,
  filteredMessages,
  showMoreCount,
  searchQuery,
  handleSearch,
  formatDate,
  renderFileContent,
  messagesContainerRef,
  messagesEndRef
}) => {
  return (
    <div className="messages-container rounded-lg shadow-md" ref={messagesContainerRef}>
      <div className="search-bar sticky top-0 bg-gray-900 p-2 z-10">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          className="w-full px-2 py-1 border rounded-lg bg-gray-900 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
          placeholder="Search messages..."
        />
      </div>
      <ul className="messages-list overflow-y-auto p-2">
        {messages.length > showMoreCount && (
          <button onClick={handleShowMore} className="hustlesmall-button bg-yellow-400 text-gray-900 px-2 py-1 rounded-lg transition-all duration-300 hover:bg-yellow-500">
            Show More
          </button>
        )}
        {filteredMessages.map((msg) => {
          const user = users[msg.senderId] || 'Unknown';

          return (
            <li key={msg.timestamp} className="p-2 mb-1">
              <div className="flex items-start">
                <div className="flex-grow">
                  <strong
                    className={`cursor-pointer ${isSupportChat ? 'text-yellow-400' : 'text-indigo-400'} hover:underline`}
                    onClick={() => handleUserClick(msg.senderId)}
                  >
                    {user.username || user}
                  </strong>: {msg.text}
                  {msg.fileUrl && (
                    <div className="mt-1">
                      {renderFileContent(msg.fileUrl, msg.fileType, msg.text)}
                    </div>
                  )}
                  <div className="timestamp text-xs text-gray-400 mt-1">
                    {msg.timestamp ? formatDate(msg.timestamp) : 'Unknown time'}
                  </div>
                </div>
                <div className="flex items-center ml-1 space-x-1">
                  {msg.senderId === currentUser.uid && msg.isRead && (
                    <FaCheck className="checkmark-icon text-green-400 text-sm" />
                  )}
                  {msg.senderId === currentUser.uid && msg.text !== 'User has deleted this message' && (
                    <DeleteMessage messageId={msg.timestamp} onDelete={handleDeleteMessage} messageText={msg.text} />
                  )}
                </div>
              </div>
            </li>
          );
        })}
        <div ref={messagesEndRef} />
      </ul>
    </div>
  );
};

export default ChatMessages;

