import React from 'react';

const BasicInformation = ({ formState, handleInputChange }) => (
  <div className="space-y-4">
    <div>
      <label className="text-sm text-gray-300 font-semibold">Title of the Offer</label>
      <input
        type="text"
        name="name"
        value={formState.name}
        onChange={handleInputChange}
        placeholder="Professional Web Design for Your Business"
        required
        className="input-field mt-1 px-4 py-3 text-gray-200 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
  </div>
);

export default BasicInformation;

