import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const PurchaseConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectID = searchParams.get('projectID');
  const listingID = searchParams.get('listingID');
  const freelancerQuestions = location.state?.freelancerQuestions || [];
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
      // Redirect to ClientUploadDetails after a brief delay
      navigate(`/project-details/${projectID}`, { state: { projectID, listingID, freelancerQuestions } });
    }, 3000); // 3-second delay before redirecting

    return () => clearTimeout(timer);
  }, [navigate, projectID, listingID, freelancerQuestions]);

  return showMessage ? (
    <div>
      <h1>Purchase Confirmation</h1>
      <p>Thank you for your purchase! Redirecting to project details...</p>
    </div>
  ) : null;
};

export default PurchaseConfirmation;

