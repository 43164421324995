// ListingsSection.js
import React from 'react';
import ListingCard from '../listings/alllistings/ListingCard';

const ListingsSection = ({ listings }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
      {listings.map(listing => (
        <ListingCard key={listing.id} listing={listing} />
      ))}
    </div>
  );
};

export default ListingsSection;

