import React from 'react';
import { db, storage } from '../../../firebase/firebase';
import { doc, deleteDoc } from 'firebase/firestore';
import { listAll, ref, deleteObject } from 'firebase/storage';
import './RemoveListing.css';

const RemoveListing = ({ listingId, onClose, onSuccess }) => {
  const handleRemove = async () => {
    try {
      await deleteDoc(doc(db, 'listings', listingId));

      const directories = ['images', 'media'];

      const deletePromises = directories.map(async (dir) => {
        const dirRef = ref(storage, `${dir}/${listingId}`);
        const listFiles = await listAll(dirRef);

        const filePromises = listFiles.items.map(fileRef => deleteObject(fileRef));
        await Promise.all(filePromises);
      });

      await Promise.all(deletePromises);

      onSuccess();
    } catch (error) {
      console.error('Error removing listing:', error);
    } finally {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="confirmation-dialog">
          <p className="confirmation-message">
            Are you sure you want to remove this listing? This action cannot be undone and any open orders will be refunded to clients.
          </p>
          <div className="confirmation-buttons">
            <button className="payment-button" onClick={handleRemove}>Yes, Remove</button>
            <button className="pay-forlisting-button" onClick={onClose}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveListing;

