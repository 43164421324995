import React, { useState } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, doc, setDoc } from 'firebase/firestore';
import { useAuth } from '../../../contexts/authContext';
import { db, storage } from '../../../firebase/firebase';
import DragAndDropUpload from './DragAndDropUpload';
import CreatableSelect from 'react-select/creatable';
import BasicInformation from '../FormModules/BasicInformation';
import Description from '../FormModules/Description';
import PricingPackage from '../FormModules/PricingPackage';
import Requirements from '../FormModules/Requirements';
import FAQ from '../FormModules/FAQ';
import ReviewListing from './ReviewListing';
import { softwareOptions } from './SoftwareOption';
import { useNavigate } from 'react-router-dom';
import PaymentForTrending from '../payment/PaymentForTrending';
import MedalUpdateOnListing from '../../hustlermedals/Update/MedalUpdateOnListing'; // Adjust the path accordingly

const listbannerSrc = process.env.PUBLIC_URL + '/createlisting.webp';

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#2d3748',
    borderColor: '#4a5568',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#63b3ed',
    },
    color: '#e2e8f0',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#2d3748',
    color: '#e2e8f0',
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: '#2d3748',
    color: '#e2e8f0',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#63b3ed' : '#2d3748',
    color: state.isSelected ? '#ffffff' : '#e2e8f0',
    '&:hover': {
      backgroundColor: '#63b3ed',
      color: '#ffffff',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#e2e8f0',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#e2e8f0',
  }),
};

const DynamicForm = ({ category, subcategory, onCancel }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    name: '',
    description: '',
    keyFeatures: '',
    standardPackage: { title: '', description: '', price: '', deliveryTime: '' },
    deluxePackage: { title: '', description: '', price: '', deliveryTime: '' },
    premiumPackage: { title: '', description: '', price: '', deliveryTime: '' },
    requirements: [''],
    images: [],
    faqs: [],
    videos: [],
  });
  const [promoImageFile, setPromoImageFile] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [softwareUsed, setSoftwareUsed] = useState([]);
  const [showStandardPackage, setShowStandardPackage] = useState(true);
  const [showDeluxePackage, setShowDeluxePackage] = useState(false);
  const [showPremiumPackage, setShowPremiumPackage] = useState(false);
  const [isReviewing, setIsReviewing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [listingId, setListingId] = useState(null);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handlePackageChange = (e, packageType) => {
    const { name, value } = e.target;
    if (name === 'price' || name === 'deliveryTime') {
      if (value.match(/^\d*\.?\d{0,3}$/)) { // Allow only numbers and decimals for price and deliveryTime
        setFormState(prevState => ({
          ...prevState,
          [packageType]: {
            ...prevState[packageType],
            [name]: value
          }
        }));
      }
    } else {
      setFormState(prevState => ({
        ...prevState,
        [packageType]: {
          ...prevState[packageType],
          [name]: value
        }
      }));
    }
  };

  const handleFaqChange = (e, index, field) => {
    const { value } = e.target;
    const newFaqs = [...formState.faqs];
    newFaqs[index][field] = value;
    setFormState(prevState => ({
      ...prevState,
      faqs: newFaqs
    }));
  };

  const addFaq = () => {
    setFormState(prevState => ({
      ...prevState,
      faqs: [...prevState.faqs, { question: '', answer: '' }]
    }));
  };

  const handleFilesSelect = (files) => {
    setImageFiles(files);
    setFormState(prevState => ({
      ...prevState,
      images: files.filter(file => file.type.startsWith('image/')).map(file => URL.createObjectURL(file)),
      videos: files.filter(file => file.type.startsWith('video/')).map(file => URL.createObjectURL(file)),
    }));
  };

  const handleSoftwareChange = (selectedOptions) => {
    setSoftwareUsed(selectedOptions || []);
  };

  const handlePromoImageChange = (e) => {
    const file = e.target.files[0];
    setPromoImageFile(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!promoImageFile) {
      setErrorMessage('Please select a promotional image.');
      return;
    }
    setIsReviewing(true);
  };

  const handleEdit = () => {
    setIsReviewing(false);
  };

  const handleAddRequirement = () => {
    setFormState(prevState => ({
      ...prevState,
      requirements: [...prevState.requirements, '']
    }));
  };

  const handleRequirementChange = (e, index) => {
    const { value } = e.target;
    const newRequirements = [...formState.requirements];
    newRequirements[index] = value;
    setFormState(prevState => ({
      ...prevState,
      requirements: newRequirements
    }));
  };

  const handleRemoveRequirement = (index) => {
    const newRequirements = [...formState.requirements];
    newRequirements.splice(index, 1);
    setFormState(prevState => ({
      ...prevState,
      requirements: newRequirements
    }));
  };

  const handleListService = async () => {
    if (!currentUser) {
      setErrorMessage('You must be logged in to create a listing');
      return;
    }

    try {
      const listingRef = await addDoc(collection(db, 'listings'), {
        name: formState.name,
        description: formState.description,
        category,
        subcategory,
        softwareUsed: softwareUsed.map(option => option.value),
        standardPackage: formState.standardPackage,
        deluxePackage: formState.deluxePackage,
        premiumPackage: formState.premiumPackage,
        keyFeatures: formState.keyFeatures,
        requirements: formState.requirements,
        faqs: formState.faqs,
        images: [],
        media: [],
        uid: currentUser.uid,
        createdAt: new Date()
      });

      const listingIdValue = listingRef.id;
      setListingId(listingIdValue);

      const imageUrls = [];
      const mediaUrls = [];
      const uploadTasks = [];

      if (promoImageFile) {
        const fileRef = ref(storage, `images/${listingIdValue}/${promoImageFile.name}-${Date.now()}`);
        const uploadTask = uploadBytesResumable(fileRef, promoImageFile);

        uploadTasks.push(
          uploadTask.then(async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            imageUrls.push(downloadURL);
            if (!uploadedImageUrl) setUploadedImageUrl(downloadURL);
          })
        );

        uploadTask.on('state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error('Error uploading file:', error);
            setErrorMessage('Failed to upload file');
          }
        );
      }

      imageFiles.forEach((file) => {
        const fileRef = ref(storage, `media/${listingIdValue}/${file.name}-${Date.now()}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTasks.push(
          uploadTask.then(async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            const mediaType = file.type.startsWith('video/') ? 'video' : 'image';
            mediaUrls.push({ url: downloadURL, type: mediaType });
          })
        );

        uploadTask.on('state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error('Error uploading file:', error);
            setErrorMessage('Failed to upload additional media');
          }
        );
      });

      await Promise.all(uploadTasks);

      await setDoc(listingRef, { images: imageUrls, media: mediaUrls }, { merge: true });

      const userRef = doc(db, 'users', currentUser.uid);
      await setDoc(userRef, { paidFor: false }, { merge: true });

      setIsSuccess(true);
      setIsReviewing(false);
    } catch (error) {
      console.error('Error creating listing:', error);
      setErrorMessage('Failed to create listing');
    }
  };

  const handlePaymentSuccess = () => {
    setIsSuccess(false);
  };

  return (
    <div className="w-full p-8 mx-auto bg-gray-900 text-gray-200">
      <div className="relative w-full h-64 mb-8 overflow-hidden">
        <img src={listbannerSrc} alt="Create Listing Banner" className="w-full h-full object-cover object-bottom" />
        <div className="absolute bottom-0 w-full bg-gradient-to-t from-gray-800 to-transparent"></div>
      </div>
      {!isSuccess && !isReviewing && (
        <form onSubmit={handleSubmit} className="space-y-6">
          <BasicInformation formState={formState} handleInputChange={handleInputChange} />
          <Description formState={formState} handleInputChange={handleInputChange} />

          {/* Standard Package - always visible */}
          <PricingPackage
            packageType="standardPackage"
            formState={formState}
            handlePackageChange={handlePackageChange}
            showPackage={showStandardPackage} // Standard package is always shown
            setShowPackage={() => {}} // Disable toggle for standard package
            pricePlaceholder="Price in SOL (up to 3 decimals)"
          />

          {/* Optional Deluxe Package */}
          <PricingPackage
            packageType="deluxePackage"
            formState={formState}
            handlePackageChange={handlePackageChange}
            showPackage={showDeluxePackage}
            setShowPackage={setShowDeluxePackage}
            pricePlaceholder="Price in SOL (up to 3 decimals)"
          />

          {/* Optional Premium Package */}
          <PricingPackage
            packageType="premiumPackage"
            formState={formState}
            handlePackageChange={handlePackageChange}
            showPackage={showPremiumPackage}
            setShowPackage={setShowPremiumPackage}
            pricePlaceholder="Price in SOL (up to 3 decimals)"
          />

          <div>
            <label className="text-sm font-semibold text-gray-300">Promotional Image</label>
            <input
              type="file"
              onChange={handlePromoImageChange}
              className="input-field w-full mt-1 px-4 py-2 text-gray-200 bg-gray-700 border border-gray-600 rounded-lg"
              accept="image/*"
              required
            />
          </div>
          <Requirements
            formState={formState}
            handleInputChange={handleInputChange}
            handleAddRequirement={handleAddRequirement}
            handleRequirementChange={handleRequirementChange}
            handleRemoveRequirement={handleRemoveRequirement}
          />
          <DragAndDropUpload onFilesSelect={handleFilesSelect} />
          {errorMessage && <div className="text-red-500 text-center mt-4">{errorMessage}</div>}
          <div className="flex justify-between mt-6">
            <div className="flex flex-col space-y-4 items-end">
              <FAQ formState={formState} handleFaqChange={handleFaqChange} addFaq={addFaq} />
            </div>
            <div className="flex flex-col space-y-4">
              {uploadProgress > 0 && (
                <div className="w-full bg-gray-800 rounded-full h-2.5 mb-4">
                  <div
                    className="bg-blue-500 h-2.5 rounded-full"
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
              )}
              {errorMessage && <div className="text-red-500 text-center mt-4">{errorMessage}</div>}
              <button
                type="submit"
                className="hustlesmall-button px-6 py-3 bg-blue-500 text-white font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 hover:bg-blue-600"
              >
                Review Listing
              </button>
              <button
                type="button"
                onClick={onCancel}
                className="pay-forlisting-button px-6 py-3 bg-gray-600 text-white font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 hover:bg-gray-700"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      )}
      {isReviewing && <ReviewListing formState={formState} onEdit={handleEdit} onListService={handleListService} />}
      {isSuccess && (
        <div className="p-8 bg-gray-800 text-gray-200 rounded-lg">
          <h2 className="text-xl font-semibold mb-4">Your listing is now live!</h2>
          <img src={uploadedImageUrl} alt={formState.name} className="mb-4 max-w-full h-auto" />
          <h3 className="text-lg font-medium mb-4">{formState.name}</h3>
          <div className="flex justify-center space-x-4">
            <button
              onClick={() => navigate(`/listing/${listingId}`)}
              className="hustle-button px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg"
            >
              View your listing
            </button>
            <PaymentForTrending 
              listingId={listingId} 
              currentUser={currentUser} 
              onPaymentSuccess={handlePaymentSuccess} 
            />
          </div>
        </div>
      )}
    <MedalUpdateOnListing uid={currentUser.uid} isSuccess={isSuccess} />
    </div>
  );
};

export default DynamicForm;

