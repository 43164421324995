import React, { useState, useEffect } from 'react';
import { getTotalProjects } from './utils/analyticsFunctions';
import dayjs from 'dayjs';

const ProjectStats = ({ freelancerID, timeFrame, subTimeFrame, customRange, status, selectedQuarter }) => {
  const [totalProjects, setTotalProjects] = useState(0);
  const [previousProjects, setPreviousProjects] = useState(0);
  const [percentageChange, setPercentageChange] = useState(0);
  const [dateDisplay, setDateDisplay] = useState('');
  const [changeIndicator, setChangeIndicator] = useState(null);

  useEffect(() => {
    const fetchProjectStats = async () => {
      console.log(`Fetching project stats for status: ${status} with customRange:`, customRange);
      const projects = await getTotalProjects(freelancerID, status, timeFrame, subTimeFrame, customRange, selectedQuarter);
      console.log("Fetched Projects:", projects); 
      setTotalProjects(projects);

      // Set up previous date range
      let previousRange = null;
      const now = new Date(); // Define the now variable here

      if (timeFrame === 'monthly') {
        let previousMonthStart, previousMonthEnd;

        if (subTimeFrame === 'currentMonth') {
          previousMonthStart = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          previousMonthEnd = new Date(now.getFullYear(), now.getMonth(), 0);
        } else if (subTimeFrame === 'previousMonth') {
          previousMonthStart = new Date(now.getFullYear(), now.getMonth() - 2, 1);
          previousMonthEnd = new Date(now.getFullYear(), now.getMonth() - 1, 0);
        } else if (subTimeFrame === 'customMonth') {
          const customMonth = dayjs(customRange.start);
          previousMonthStart = customMonth.subtract(1, 'month').startOf('month').toDate();
          previousMonthEnd = customMonth.subtract(1, 'month').endOf('month').toDate();
        }

        previousRange = {
          start: previousMonthStart,
          end: previousMonthEnd,
        };
      } else if (timeFrame === 'quarterly' && selectedQuarter) {
        const quarterStartMonth = (selectedQuarter - 1) * 3;
        const previousQuarterStartMonth = quarterStartMonth - 3;

        previousRange = {
          start: new Date(now.getFullYear(), previousQuarterStartMonth, 1),
          end: new Date(now.getFullYear(), previousQuarterStartMonth + 3, 0, 23, 59, 59, 999),
        };
      } else if (timeFrame === 'yearly') {
        previousRange = {
          start: dayjs().subtract(1, 'year').startOf('year').toDate(),
          end: dayjs().subtract(1, 'year').endOf('year').toDate(),
        };
      }

      // Fetch previous projects
      if (previousRange) {
        const prevProjects = await getTotalProjects(freelancerID, status, timeFrame, 'customMonth', previousRange, selectedQuarter - 1);
        setPreviousProjects(prevProjects);

        if (prevProjects === 0) {
          if (projects > 0) {
            setChangeIndicator('▲');
            setPercentageChange(100); 
          } else {
            setChangeIndicator(null);
            setPercentageChange(0); 
          }
        } else {
          const percentage = ((projects - prevProjects) / prevProjects) * 100;
          setPercentageChange(percentage.toFixed(2));

          if (percentage > 0) {
            setChangeIndicator('▲');
          } else if (percentage < 0) {
            setChangeIndicator('▼');
          } else {
            setChangeIndicator(null);
          }
        }
      }
    };

    const formatDateDisplay = () => {
      if (timeFrame === 'monthly') {
        if (subTimeFrame === 'currentMonth') {
          setDateDisplay(dayjs().format('MMMM YYYY')); 
        } else if (subTimeFrame === 'customMonth') {
          setDateDisplay(customRange.start ? dayjs(customRange.start).format('MMMM YYYY') : 'Custom Month');
        } else if (subTimeFrame === 'previousMonth') {
          setDateDisplay(dayjs().subtract(1, 'month').format('MMMM YYYY'));
        }
      } else if (timeFrame === 'quarterly') {
        if (selectedQuarter && customRange.start && customRange.end) {
          const startMonth = dayjs(customRange.start).format('MMM');
          const endMonth = dayjs(customRange.end).format('MMM YYYY');
          setDateDisplay(`Q${selectedQuarter} (${startMonth} - ${endMonth})`);
        }
      } else if (timeFrame === 'yearly') {
        setDateDisplay(dayjs().format('YYYY')); 
      } else if (timeFrame === 'custom') {
        if (customRange.start && customRange.end) {
          const startDate = dayjs(customRange.start).format('MMM D, YYYY');
          const endDate = dayjs(customRange.end).format('MMM D, YYYY');
          setDateDisplay(`${startDate} - ${endDate}`);
        }
      }
    };

    fetchProjectStats();
    formatDateDisplay(); 
  }, [freelancerID, timeFrame, subTimeFrame, customRange, status, selectedQuarter]);

  return (
    <div className="project-stats">
      <p>{totalProjects} {status === "open" ? "open" : "completed"} projects ({dateDisplay})</p>
      <p style={{ color: percentageChange > 0 ? 'green' : percentageChange < 0 ? 'red' : 'gray' }}>
        {changeIndicator} {percentageChange}%
      </p>
    </div>
  );
};

export default ProjectStats;

