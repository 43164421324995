import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { FaArrowRight } from 'react-icons/fa';
import { db } from '../../../firebase/firebase';
import { doc, setDoc, getDoc, Timestamp } from 'firebase/firestore';
import axios from 'axios';
import '@solana/wallet-adapter-react-ui/styles.css';
import './Login.css';

const API_BASE_URL = 'https://token.pr1mula.nl:3002';

const Login = () => {
  const loginvectorSrc = process.env.PUBLIC_URL + '/logovector.webp';
  const { connected, publicKey, connect } = useWallet();
  const [isProcessing, setIsProcessing] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userUid, setUserUid] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    if (connected && publicKey) {
      console.log('Wallet connected and publicKey is available.');
      handlePhantomSignIn(publicKey.toString());
    }
  }, [connected, publicKey]);

  const handlePhantomSignIn = async (walletAddress) => {
    if (isProcessing) return;
    setIsProcessing(true);

    const params = new URLSearchParams(window.location.search);
    const ref = params.get('ref');
    let referrerUid = null;

    if (ref) {
      const transformedRef = ref.slice(2, -2); // Remove the first 2 and last 2 characters
      referrerUid = transformedRef.split('').reverse().join(''); // Reverse to get the original UID
    }

    try {
      const userRef = doc(db, 'users', walletAddress);
      const userDoc = await getDoc(userRef);
      let newUser = false;

      if (!userDoc.exists()) {
        const userData = {
          uid: walletAddress,
          walletAddress: walletAddress,
          createdAt: Timestamp.now(),
          lastLogin: Timestamp.now(),
        };

        if (referrerUid) {
          userData.referrer = referrerUid; // Store the referrer's UID
        }

        await setDoc(userRef, userData);
        newUser = true;
      } else {
        // Update the lastLogin timestamp for existing users
         await setDoc(userRef, { lastLogin: Timestamp.now() }, { merge: true });
      }

      const apiKey = process.env.REACT_APP_CUSTOMTOKEN_API;
      const tokenResponse = await axios.post(
        `${API_BASE_URL}/createCustomToken`,
        { uid: walletAddress },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
          },
        }
      );

      const customToken = tokenResponse.data.token;
      await signInWithCustomToken(auth, customToken);

      setUserUid(walletAddress); // Store the user UID

      if (newUser) {
        setModalIsOpen(true);
      } else {
        navigate(`/profile/${walletAddress}`);
      }
    } catch (error) {
      console.error('Critical error during sign in:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleLoginClick = async () => {
    if (!connected) {
      try {
        console.log('Attempting to connect the Phantom wallet...');
        await connect();
        console.log('Connected to Phantom wallet.');
      } catch (error) {
        console.error('Failed to connect Phantom wallet:', error);
        return;
      }
    }
    if (publicKey) {
      console.log('Public key available, signing in with Phantom wallet...');
      handlePhantomSignIn(publicKey.toString());
    } else {
      console.error('No public key found after attempting to connect.');
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    navigate(`/profile/${userUid}`);
  };

  return (
    <div className="min-h-screen flex items-center justify-center p-6">
      <div className="w-full max-w-md p-8 rounded-lg text-center">
        {/* Logo Image */}
        <img 
          src={loginvectorSrc} 
          alt="Hustlez Logo" 
          className="mx-auto mb-6 w-32 h-32" 
        />
        
        {/* Header */}
        <h2 className="text-3xl font-extrabold text-white mb-8">Welcome to Hustlez</h2>

        <WalletModalProvider>
          <WalletMultiButton 
            className="w-full py-3 px-6 mb-1 text-white font-semibold rounded-lg bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 hover:from-yellow-500 hover:via-yellow-600 hover:to-yellow-700 focus:ring-4 focus:ring-yellow-500 transition-transform transform hover:scale-105"
          />
        </WalletModalProvider>

        <button
          className="w-full loginsmall-button mt-6 py-3 px-6 text-white font-semibold rounded-lg bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 hover:from-yellow-500 hover:via-yellow-600 hover:to-yellow-700 focus:ring-4 focus:ring-yellow-500 transition-transform transform hover:scale-105"
          onClick={handleLoginClick}
        >
          Login
        </button>
      </div>

      {modalIsOpen && (
        <div className="modal-overlay">
          <div className="modal-content bg-gray-800 text-white p-6 rounded-lg shadow-2xl">
            <button className="modal-close text-yellow-400 text-2xl" onClick={closeModal}>&times;</button>
            <h2 className="text-3xl font-extrabold text-yellow-400 mb-4">Welcome to Our Platform!</h2>
            <p className="text-lg mb-4">We're excited to have you join our community. Here, freelancers can list their services, and clients can easily find what they need. Let's get you started!</p>
            <p className="text-lg mb-4">
              Start by <Link to={`/profile/${userUid}`} className="text-yellow-400 underline hover:text-yellow-500">updating your profile</Link> to showcase your skills and attract clients.
            </p>
            <p className="text-lg mb-4">
              Need an extra boost? Consider getting a <Link to="/premium" className="text-yellow-400 underline hover:text-yellow-500">premium membership</Link> to enjoy exclusive benefits and higher visibility.
            </p>
            <p className="text-lg mb-4">
              Our reputation system rewards you for completing projects, receiving positive reviews, and providing detailed profile information. The more active and detailed you are, the higher your reputation will be!
            </p>
            <p className="text-lg mb-4">
              To make the most out of our platform, explore trending listings and see what services are in high demand. You can also purchase a trending spot for your listing to attract more clients.
            </p>
            <div className="mt-6">
              <p>Ready to start? Let's update your profile now!</p>
              <button
                className="mt-4 py-2 px-4 bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white font-semibold rounded-lg hover:from-green-500 hover:via-green-600 hover:to-green-700 transition-transform transform hover:scale-105 flex items-center justify-center"
                onClick={closeModal}
              >
                Update Profile <FaArrowRight className="ml-2" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;

