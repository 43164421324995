import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../../firebase/firebase';
import { addDoc, collection, query, where, getDocs, updateDoc, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../../contexts/authContext';
import { useNotification } from '../../../contexts/notificationContext';
import ReviewSuccessModal from './ReviewSuccessModal';
import { FaStar } from 'react-icons/fa';

const ReviewForm = ({ listingId: propListingId, projectID, onReviewSubmitted }) => {
  const { currentUser } = useAuth();
  const { addNotification } = useNotification();
  const params = useParams();
  const listingId = propListingId || params.listingId;
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(null); // For hover effect
  const [comment, setComment] = useState('');
  const [privateSuggestion, setPrivateSuggestion] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [hasReviewed, setHasReviewed] = useState(false);
  const [ownerId, setOwnerId] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [reviewId, setReviewId] = useState(null);

  useEffect(() => {
    const fetchListingOwner = async () => {
      if (!listingId) return;

      try {
        const listingDoc = await getDoc(doc(db, 'listings', listingId));
        if (listingDoc.exists()) {
          setOwnerId(listingDoc.data().uid);
        } else {
          setErrorMessage('Listing not found.');
        }
      } catch (error) {
        console.error('Error fetching listing owner:', error);
        setErrorMessage('Failed to fetch listing owner.');
      }
    };

    const checkIfReviewed = async () => {
      if (!currentUser || !listingId || !projectID) return;
      try {
        const reviewsQuery = query(
          collection(db, 'reviews'),
          where('listingId', '==', listingId),
	  where('projectID', '==', projectID),
          where('uid', '==', currentUser.uid)
        );
        const reviewSnapshot = await getDocs(reviewsQuery);
        if (!reviewSnapshot.empty) {
          setHasReviewed(true);
        }
      } catch (error) {
        console.error('Error checking reviews:', error);
      }
    };

    fetchListingOwner();
    checkIfReviewed();
  }, [currentUser, listingId, projectID]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      setErrorMessage('You must be logged in to submit a review.');
      return;
    }

    if (rating === 0 || comment.trim() === '') {
      setErrorMessage('Please provide a rating and a comment.');
      return;
    }

    if (!listingId || !projectID) { // Ensure projectID is checked
      setErrorMessage('Listing ID or Project ID is missing.');
      return;
    }

    if (hasReviewed) {
      setErrorMessage('You have already reviewed this listing.');
      return;
    }

    try {
      const reviewRef = await addDoc(collection(db, 'reviews'), {
        listingId,
	projectID,
        ownerId,
        uid: currentUser.uid,
        rating,
        comment,
        privateSuggestion,
        timestamp: new Date(),
      });

      const reviewId = reviewRef.id;
      setReviewId(reviewId);

      const reviewsQuery = query(collection(db, 'reviews'), where('listingId', '==', listingId), where('projectID', '==', projectID));
      const reviewsSnapshot = await getDocs(reviewsQuery);
      const reviews = reviewsSnapshot.docs.map((doc) => doc.data());
      const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
      const averageRating = reviews.length > 0 ? totalRating / reviews.length : 0;

      const listingRef = doc(db, 'listings', listingId);
      await updateDoc(listingRef, { averageRating });

      const ownerRef = doc(db, 'users', ownerId);
      const ownerDoc = await getDoc(ownerRef);
      if (ownerDoc.exists()) {
        let ownerReputation = ownerDoc.data().reputation || 0;
        switch (rating) {
          case 1:
            ownerReputation -= 3;
            break;
          case 2:
            ownerReputation -= 1;
            break;
          case 3:
            // No change for a rating of 3
            break;
          case 4:
            ownerReputation += 3;
            break;
          case 5:
            ownerReputation += 5;
            break;
          default:
            break;
        }
        await updateDoc(ownerRef, { reputation: ownerReputation });
      }

      setRating(0);
      setComment('');
      setPrivateSuggestion('');
      setSuccessMessage('Review submitted successfully!');
      setErrorMessage('');
      setShowSuccessModal(true);

      await addNotification({
        uid: ownerId,
        message: `You have received a ${rating} star review on your listing.`,
        url: `/review/${reviewId}`,
        read: false,
        timestamp: new Date(),
      });

      if (onReviewSubmitted) {
        onReviewSubmitted();
      }
    } catch (error) {
      console.error('Error submitting review:', error);
      setErrorMessage('Failed to submit review. Please try again later.');
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 shadow-md border rounded-lg bg-gray-800">
      <h2 className="text-xl font-semibold text-center mb-4 text-white">Submit a Review</h2>
      {errorMessage && <div className="text-red-600 mb-4">{errorMessage}</div>}
      {successMessage && <div className="text-green-600 mb-4">{successMessage}</div>}
      {hasReviewed ? (
        <div className="text-gray-400 text-center">
          You have already submitted a review for this listing.
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-300 mb-2">Rating</label>
            <div className="flex">
              {[...Array(5)].map((star, index) => {
                const ratingValue = index + 1;

                return (
                  <label key={index}>
                    <input
                      type="radio"
                      name="rating"
                      value={ratingValue}
                      onClick={() => setRating(ratingValue)}
                      className="hidden"
                    />
                    <FaStar
                      className="star"
                      color={ratingValue <= (hover || rating) ? '#ffc107' : '#e4e5e9'}
                      size={30}
                      onMouseEnter={() => setHover(ratingValue)}
                      onMouseLeave={() => setHover(null)}
                    />
                  </label>
                );
              })}
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-300 mb-2">Comment</label>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg bg-gray-700 text-white"
              placeholder="Share your experience..."
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-300 mb-2">Private Suggestion</label>
            <textarea
              value={privateSuggestion}
              onChange={(e) => setPrivateSuggestion(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg bg-gray-700 text-white"
              placeholder="Any private suggestions for the owner..."
            />
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300"
          >
            Submit Review
          </button>
        </form>
      )}
      {showSuccessModal && <ReviewSuccessModal reviewId={reviewId} onClose={() => setShowSuccessModal(false)} />}
    </div>
  );
};

export default ReviewForm;

