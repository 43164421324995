import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../../firebase/firebase';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../../contexts/authContext';
import { FaRegCommentDots, FaLifeRing } from 'react-icons/fa';

const MyMessagesDropDown = () => {
  const { currentUser } = useAuth();
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState({});
  const [listings, setListings] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) return;

    const fetchMessagesAndUsers = async () => {
      try {
        const messagesQuery = query(
          collection(db, 'messages'),
          where('participants', 'array-contains', currentUser.uid)
        );

        const messagesSnapshot = await getDocs(messagesQuery);
        const messagesData = messagesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const userIds = new Set();
        const listingIds = new Set();

        messagesData.forEach((message) => {
          message.participants.forEach(id => userIds.add(id));
          if (message.listingId) {
            listingIds.add(message.listingId);
          }
        });

        // Fetch user data
        const usersData = {};
        await Promise.all(Array.from(userIds).map(async (userId) => {
          if (userId) {
            const userDoc = await getDoc(doc(db, 'users', userId));
            usersData[userId] = userDoc.exists() ? userDoc.data().username || 'No Profile' : 'No Profile';
          }
        }));

        // Fetch listing data
        const listingsData = {};
        await Promise.all(Array.from(listingIds).map(async (listingId) => {
          if (listingId) {
            const listingDoc = await getDoc(doc(db, 'listings', listingId));
            if (listingDoc.exists()) {
              const listing = listingDoc.data();
              listingsData[listingId] = {
                name: listing.name || 'No Listing Name',
                image: listing.images && listing.images[0] ? listing.images[0] : ''
              };
            }
          }
        }));

        setMessages(messagesData);
        setUsers(usersData);
        setListings(listingsData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching messages or user data:', err);
        setError('Failed to fetch messages');
        setLoading(false);
      }
    };

    fetchMessagesAndUsers();
  }, [currentUser]);

  const getUnreadMessages = () => {
    return messages.filter(message => 
      message.messages.some(
        msg => !msg.isRead && msg.senderId !== currentUser.uid
      )
    );
  };

  const sortedMessages = getUnreadMessages().sort((a, b) => {
    if (a.lastChatTime && b.lastChatTime) {
      return b.lastChatTime.seconds - a.lastChatTime.seconds;
    }
    return 0;
  });

  if (loading) {
    return (
      <div className="loading-container flex justify-center items-center h-64">
        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-gray-200"></div>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (sortedMessages.length === 0) {
    return (
      <div className="p-4 bg-gray-800 text-white shadow-lg rounded-lg" style={{ width: '400px' }}> {/* Adjusted width */}
        <h2 className="text-lg font-semibold mb-4">My Messages</h2>
        <div className="text-gray-400">You're up to date!</div>
        <button
          onClick={() => navigate('/my-messages')}
          className="mt-4 text-white px-4 py-2 rounded-lg transition duration-300"
        >
          View All Messages
        </button>
      </div>
    );
  }

  return (
    <div className="p-4 bg-gray-800 text-white shadow-lg rounded-lg" style={{ width: '400px' }}> {/* Adjusted width */}
      <h2 className="text-lg font-semibold mb-4">My Messages</h2>
      <ul>
        {sortedMessages.slice(0, 5).map((message) => {
          const otherUserId = message.participants.find(id => id !== currentUser.uid);
          const latestMessage = message.messages.slice(-1)[0]?.text || 'No message';
          const lastChatTime = message.lastChatTime ? new Date(message.lastChatTime.seconds * 1000).toLocaleString() : 'No date';
          const listing = listings[message.listingId] || {};

          return (
            <li
              key={message.id}
              className="flex items-center border-b py-2 cursor-pointer hover:bg-gray-700 transition duration-300"
              onClick={() => navigate('/my-messages')}
            >
              <div className="w-12 h-12 flex-shrink-0 mr-4">
                {message.isSupportChat ? (
                  <FaLifeRing className="w-full h-full text-yellow-500" /> // Support icon
                ) : listing.image ? (
                  <img src={listing.image} alt="Listing" className="w-full h-full object-cover rounded-lg" />
                ) : (
                  <FaRegCommentDots className="w-full h-full text-gray-400" />
                )}
              </div>
              <div className="flex-grow">
                <div className={message.isSupportChat ? 'text-yellow-500' : 'text-gray-300'}>
                  {message.isSupportChat ? 'Support Chat' : users[otherUserId] || 'No Profile'}
                </div>
                <div className="text-gray-400 text-sm">
                  Last message: {latestMessage}
                </div>
                <div className="text-gray-500 text-xs mt-1">
                  Last chat: {lastChatTime}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MyMessagesDropDown;

