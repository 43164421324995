import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../../firebase/firebase'; // Adjust import path as needed
import { collection, getDocs, query, where } from 'firebase/firestore';
import ListingCard from '../alllistings/ListingCard';

const ListingsBySubcategory = () => {
  const { subcategoryName } = useParams();
  const [listings, setListings] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const subcategory = subcategoryName.replace(/-/g, ' ');
        console.log("Fetching listings for subcategory:", subcategory);

        const q = query(
          collection(db, 'listings'),
          where('subcategory', '==', subcategory)
        );

        const querySnapshot = await getDocs(q);
        console.log("Query Snapshot:", querySnapshot);

        const listingsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log("Listings Data:", listingsData);
        
        setListings(listingsData);
      } catch (error) {
        console.error('Error fetching listings:', error);
        setErrorMessage('Failed to fetch listings');
      }
    };

    fetchListings();
  }, [subcategoryName]);

  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold mb-4">Listings in {subcategoryName.replace(/-/g, ' ')}</h1>
      {errorMessage && <div className="text-red-600 font-bold mb-4">{errorMessage}</div>}
      {listings.length === 0 ? (
        <p>No listings available</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {listings.map(listing => (
            <ListingCard key={listing.id} listing={listing} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ListingsBySubcategory;

