// src/components/profile/update/RemoveProfile.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext';
import { db } from '../../../firebase/firebase';
import { doc, deleteDoc } from 'firebase/firestore';

const RemoveProfile = () => {
  const { currentUser, logout } = useAuth(); // Ensure logout function is available
  const navigate = useNavigate();

  const handleRemoveProfile = async () => {
    const confirmRemove = window.confirm("Are you sure you want to delete your profile? This action cannot be undone.");
    if (!confirmRemove) return;

    try {
      const userRef = doc(db, 'users', currentUser.uid);
      await deleteDoc(userRef);
      await logout(); // Log out the user
      navigate('/login'); // Redirect to login
    } catch (error) {
      console.error("Error removing profile:", error);
      alert('Failed to remove profile');
    }
  };

  return (
    <button
      onClick={handleRemoveProfile}
      className="pay-forlisting-button x-4 py-2 text-white rounded-lg "
    >
      Remove Profile
    </button>
  );
};

export default RemoveProfile;

