import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase/firebase';
import { useAuth } from '../../../../contexts/authContext';
import { useWallet } from '@solana/wallet-adapter-react';
import PaymentComponent from '../PaymentComponent';

const PaymentForTrending = ({ listingId, onPaymentSuccess, onClose }) => {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const { connect, publicKey, wallet } = useWallet();
  const [freeTrending, setFreeTrending] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false); // Modal state

  const trendingAmountSOL = 1; // Define the amount in SOL for trending

  useEffect(() => {
    const fetchUserData = async () => {
      if (!currentUser) return;

      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFreeTrending(userData.freeTrending || 0);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handlePaymentSuccess = async () => {
    try {
      const listingRef = doc(db, 'listings', listingId);
      const trendingDate = new Date();
      await updateDoc(listingRef, {
        trending: true,
        trendingDate,
      });

      setTimeout(async () => {
        await updateDoc(listingRef, {
          trending: false,
        });
      }, 24 * 60 * 60 * 1000); // 24 hours in milliseconds

      onPaymentSuccess();
      alert('Payment successful and listing is now trending!');
    } catch (error) {
      console.error('Update failed', error);
      alert('Update failed. Please try again.');
    }
  };

  const handlePaymentFailure = (error) => {
    console.error('Payment failed', error);
    alert('Payment failed. Please try again.');
  };

  const handlePayment = async () => {
    if (loading) return;

    setLoading(true);

    try {
      if (freeTrending > 0) {
        const userRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userRef, {
          freeTrending: freeTrending - 1,
        });

        await handlePaymentSuccess();
      } else {
        if (!wallet || !publicKey) {
          alert('Please connect your wallet');
          setLoading(false);
          return;
        }

        await connect();
        // Handle the payment logic with wallet here...
        // You could integrate this with the PaymentComponent if necessary.
      }
    } catch (error) {
      console.error('Payment setup failed', error);
      setLoading(false);
    }
  };

  const openConfirmationModal = () => {
    setShowConfirmationModal(true); // Open the modal
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false); // Close the modal
  };

  const confirmTrending = async () => {
    setShowConfirmationModal(false); // Close the modal on confirmation
    await handlePayment(); // Proceed with the payment
  };

  return (
    <div className="max-w-md mx-auto p-4 text-white">
      <h2 className="text-xl font-semibold text-center mb-4">Get Trending</h2>
      <p className="text-center mb-4">
        Make your listing more visible and attract more buyers by getting it trending! You can use a free boost or pay 1 SOL to trend your listing for 24 hours.
      </p>

      {freeTrending > 0 ? (
        <button
          type="button"
          onClick={openConfirmationModal} // Show confirmation modal before proceeding
          disabled={loading}
          className={`pay-forlisting-button w-full px-4 py-2 text-white font-medium rounded-lg transition duration-300`}
        >
          {loading ? 'Processing...' : 'Use Free Trending Boost'}
        </button>
      ) : (
        <button
          type="button"
          onClick={openConfirmationModal} // Show confirmation modal before proceeding
          disabled={loading}
          className={`pay-forlisting-button w-full px-4 py-2 text-white font-medium rounded-lg transition duration-300`}
        >
          {loading ? 'Processing...' : 'Pay 1 SOL to Trend'}
        </button>
      )}

      {freeTrending > 0 && (
        <p className="text-center text-green-500 mt-2">
          You have {freeTrending} free trending boosts left.
        </p>
      )}

      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-sm w-full">
            <h3 className="text-lg font-semibold text-center mb-4">Confirm Trending</h3>
            <p className="text-center mb-4">
              Are you sure you want to trend this listing? This will make your listing more visible for the next 24 hours.
            </p>
            <div className="flex justify-between">
              <button
                onClick={closeConfirmationModal}
                className="bg-gray-600 text-white py-2 px-4 rounded-lg hover:bg-gray-500"
              >
                Deny
              </button>
              <button
                onClick={confirmTrending} // Confirm and proceed with the payment
                className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-500"
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentForTrending;

