import React, { useState, useEffect } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { Box, Typography } from '@mui/material';
import { getEarningsByListing } from './utils/analyticsFunctions';

const EarningsByListingChart = ({ freelancerID, timeFrame, subTimeFrame, customMonth, customRange, selectedQuarter }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const earningsByListing = await getEarningsByListing(freelancerID, timeFrame, subTimeFrame, customMonth, customRange, selectedQuarter);

      const formattedData = Object.keys(earningsByListing).map((listing, index) => ({
        label: listing,
        value: earningsByListing[listing] || 0,
        rank: index + 1,
      }));

      setChartData(formattedData);
    };

    fetchData();
  }, [freelancerID, timeFrame, subTimeFrame, customMonth, customRange, selectedQuarter]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', height: 400 }}>
      {chartData.length > 0 ? (
        <PieChart
          series={[
            {
              data: chartData.map((d) => ({ label: d.label, id: d.label, value: d.value })),
              valueFormatter: (v, { dataIndex }) => {
                const { rank } = chartData[dataIndex];
                return `${v.label} has earned ${v.value} SOL and is ranked #${rank}`;
              },
            },
          ]}
          width={400} // Ensure the chart has a consistent size
          height={400} // Ensure the chart has a consistent size
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              padding: -150,
              itemMarkWidth: 20,
              itemMarkHeight: 2,
              markGap: 5,
              itemGap: 10,
              labelStyle: {
                fontSize: 14,
                fill: '#ffffff',
              },
              sx: {
                position: 'absolute', // Position the legend absolutely relative to the chart container
                right: 0, // Align the legend to the right
                top: '50%', // Center the legend vertically
                transform: 'translateY(-50%)', // Center the legend vertically
              },
            },
          }}
        />
      ) : (
        <Typography variant="body1">No data available for the selected period.</Typography>
      )}
    </Box>
  );
};

export default EarningsByListingChart;

