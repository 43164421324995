import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { db } from '../../../firebase/firebase';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { useAuth } from '../../../contexts/authContext';
import { FaStar, FaUserCircle } from 'react-icons/fa';

const ReviewDetail = () => {
  const { id } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [review, setReview] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editedComment, setEditedComment] = useState('');
  const [editedPrivateSuggestion, setEditedPrivateSuggestion] = useState('');
  const [editedRating, setEditedRating] = useState(0);

  const extractIdFromUrl = () => {
    const url = window.location.href;
    const parts = url.split('/');
    return parts[parts.length - 1];
  };

  useEffect(() => {
    const reviewId = extractIdFromUrl();

    if (!reviewId) {
      setErrorMessage('Review ID is missing from the URL');
      return;
    }

    const fetchReview = async () => {
      try {
        const reviewDocRef = doc(db, 'reviews', reviewId);
        const reviewDoc = await getDoc(reviewDocRef);

        if (!reviewDoc.exists()) {
          setErrorMessage('Review not found');
          return;
        }

        const reviewData = reviewDoc.data();

        const reviewerDocRef = doc(db, 'users', reviewData.uid);
        const reviewerDoc = await getDoc(reviewerDocRef);
        const reviewerData = reviewerDoc.exists() ? reviewerDoc.data() : null;

        const listingDocRef = doc(db, 'listings', reviewData.listingId);
        const listingDoc = await getDoc(listingDocRef);
        const listingData = listingDoc.exists() ? listingDoc.data() : null;

        setReview({ ...reviewData, id: reviewDoc.id, reviewer: reviewerData, listing: listingData });
        setEditedComment(reviewData.comment);
        setEditedPrivateSuggestion(reviewData.privateSuggestion);
        setEditedRating(reviewData.rating);
      } catch (error) {
        console.error('Error fetching review:', error);
        setErrorMessage('Failed to fetch review.');
      }
    };

    fetchReview();
  }, []);

  const handleEdit = async () => {
    if (!review) return;

    try {
      const reviewDocRef = doc(db, 'reviews', review.id);
      await updateDoc(reviewDocRef, {
        comment: editedComment,
        privateSuggestion: editedPrivateSuggestion,
        rating: editedRating
      });
      setReview({ ...review, comment: editedComment, privateSuggestion: editedPrivateSuggestion, rating: editedRating });
      setEditMode(false);
    } catch (error) {
      console.error('Error updating review:', error);
      setErrorMessage('Failed to update review.');
    }
  };

const handleDelete = async () => {
  if (!review) return;

  const confirmed = window.confirm('Are you sure you want to delete this review? This action cannot be reverted.');

  if (!confirmed) return;

  try {
    const reviewDocRef = doc(db, 'reviews', review.id);
    await deleteDoc(reviewDocRef);
    navigate('/'); // Redirect after deletion
  } catch (error) {
    console.error('Error deleting review:', error);
    setErrorMessage('Failed to delete review.');
  }
};

  if (errorMessage) {
    return <div className="text-red-600">{errorMessage}</div>;
  }

  if (!review) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-2xl mx-auto p-4 shadow-md border rounded-lg bg-gray-800">
      <h2 className="text-2xl font-semibold mb-4 text-white">Review Detail</h2>
      <div className="flex items-center mb-4">
        {review.reviewer?.avatarURL ? (
          <img src={review.reviewer.avatarURL} alt="Avatar" className="w-12 h-12 rounded-full mr-2" />
        ) : (
          <FaUserCircle className="w-12 h-12 text-gray-400 mr-2" />
        )}
        <div>
          <Link to={`/profile/${review.uid}`} className="font-bold text-blue-400 hover:underline">
            {review.reviewer?.username || 'Unknown User'}
          </Link>
          <div className="flex items-center text-yellow-400">
            {[...Array(5)].map((star, i) => (
              <FaStar key={i} className={i < review.rating ? 'text-yellow-400' : 'text-gray-500'} />
            ))}
          </div>
        </div>
      </div>
      {editMode ? (
        <>
          <div className="mb-4">
            <label className="block text-gray-300 mb-2">Rating</label>
            <select
              value={editedRating}
              onChange={(e) => setEditedRating(Number(e.target.value))}
              className="w-full px-3 py-2 border rounded-lg bg-gray-700 text-white"
            >
              <option value={0}>Select a rating</option>
              {[1, 2, 3, 4, 5].map((num) => (
                <option key={num} value={num}>
                  {num} Star{num > 1 && 's'}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-300 mb-2">Comment</label>
            <textarea
              value={editedComment}
              onChange={(e) => setEditedComment(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg bg-gray-700 text-white"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-300 mb-2">Private Suggestion</label>
            <textarea
              value={editedPrivateSuggestion}
              onChange={(e) => setEditedPrivateSuggestion(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg bg-gray-700 text-white"
            />
          </div>
          <button
            onClick={handleEdit}
            className="hustle-button hover:bg-blue-700 transition duration-300"
          >
            Save
          </button>
          <button
            onClick={() => setEditMode(false)}
            className="hustle-button hover:bg-gray-700 transition duration-300 mt-2"
          >
            Cancel
          </button>
        </>
      ) : (
        <>
          <p className="text-gray-300 mb-4">{review.comment}</p>
          {review.listing && (
            <p className="text-gray-400">
              For listing: <Link to={`/listing/${review.listingId}`} className="text-blue-400 hover:underline">{review.listing.name}</Link>
            </p>
          )}
          {(review.uid === currentUser.uid || review.ownerId === currentUser.uid) && review.privateSuggestion && (
            <div className="mt-4 p-4 bg-gray-700 rounded-lg">
              <h3 className="text-xl font-semibold mb-2 text-white">Private Suggestion</h3>
              <p className="text-gray-300">{review.privateSuggestion}</p>
            </div>
          )}
          {review.uid === currentUser.uid && (
            <>
	      <br/>
              <button
                onClick={() => setEditMode(true)}
                className="hustle-button hover:bg-green-700 transition duration-300 mt-4"
              >
                Edit Review
              </button>
		<br/>
              <button
                onClick={handleDelete}
                className="pay-forlisting-button hover:bg-red-700 transition duration-300 mt-2"
              >
                Delete Review
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ReviewDetail;

