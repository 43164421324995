import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import { collection, doc, getDoc, query, where, getDocs } from 'firebase/firestore';
import Milestone from './Milestone';

const Project = ({ projectId }) => {
  const [project, setProject] = useState(null);
  const [milestones, setMilestones] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const projectDocRef = doc(db, 'projects', projectId);
        console.log('Fetching project with projectID:', projectId);
        console.log('Project document reference:', projectDocRef);
        
        const projectDoc = await getDoc(projectDocRef);
        if (projectDoc.exists()) {
          const projectData = projectDoc.data();
          setProject(projectData);
          console.log('Project data:', projectData);
        } else {
          console.log('No such document in projects collection!');
        }

        const milestonesQuery = query(collection(db, 'milestones'), where('project_id', '==', projectId));
        console.log('Milestones query:', milestonesQuery);
        const milestonesSnapshot = await getDocs(milestonesQuery);
        const milestonesData = milestonesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setMilestones(milestonesData);
        console.log('Milestones data:', milestonesData);
        
        setLoading(false);
      } catch (error) {
        console.error('Error fetching project details:', error);
        setLoading(false);
      }
    };

    fetchProject();
  }, [projectId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!project) {
    console.log('Project state:', project);
    return <div>Project not found.</div>;
  }

  return (
    <div>
      <h1>Project ID: {project.projectID}</h1>
      <p>Client ID: {project.clientID}</p>
      <p>Freelancer ID: {project.freelancerID}</p>
      <p>Listing ID: {project.listingID}</p>
      <p>Status: {project.status}</p>
      <p>Created At: {project.createdAt.toDate().toLocaleString()}</p>

      <h2>Milestones</h2>
      {milestones.length > 0 ? (
        milestones.map(milestone => (
          <Milestone key={milestone.id} milestone={milestone} />
        ))
      ) : (
        <p>No milestones found.</p>
      )}
    </div>
  );
};

export default Project;

