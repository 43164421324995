import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../../firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';
import MessageForm from './MessageForm';
import { useAuth } from '../../../contexts/authContext';
import ClientPayment from '../payment/clientPayment';
import PaymentForTrending from '../payment/PaymentForTrending';
import MediaLightbox from './MediaLightbox';
import RemoveListing from './RemoveListing';
import { FaChartLine, FaEnvelope, FaTrash } from 'react-icons/fa';
import './ListingDetail.css';
import { trackClick } from './clickTracker';

const ListingDetail = () => {
  const { id } = useParams();
  const { currentUser } = useAuth();
  const [listing, setListing] = useState(null);
  const [ownerUsername, setOwnerUsername] = useState('');
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedMedia, setSelectedMedia] = useState({ url: '', type: 'image' });
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [activePackage, setActivePackage] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [showTrendingModal, setShowTrendingModal] = useState(false);
  const [showMessageForm, setShowMessageForm] = useState(false);
  const [selectedTab, setSelectedTab] = useState('info');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchListing = async () => {
      if (!id) {
        setErrorMessage('Invalid listing ID');
        setLoading(false);
        return;
      }

      try {
        const docRef = doc(db, 'listings', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const listingData = docSnap.data();
          setListing({ id: docSnap.id, ...listingData });

          // Ensure that selectedPackage is set properly when the listing loads
          if (listingData.standardPackage) {
            setSelectedPackage({
              ...listingData.standardPackage,
              type: 'standard',
            });
            setActivePackage('standard');
          } else {
            setSelectedPackage({
              price: listingData.price,
              deliveryTime: listingData.daysToDelivery,
              type: 'default',
            });
            setActivePackage('default');
          }

          // Fetch owner data and handle other logic...

        } else {
          setErrorMessage('No such listing!');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching listing:', error);
        setErrorMessage('Failed to fetch listing');
        setLoading(false);
      }
    };

    fetchListing();
  }, [id, currentUser.uid]);

  const displayPrice = selectedPackage?.price || listing?.price;
  const displayDeliveryTime = selectedPackage?.deliveryTime || listing?.daysToDelivery;

  const handleMediaClick = (media) => {
    setSelectedMedia(media);
    setLightboxOpen(true);
  };

  const handleModalClose = () => {
    setSelectedMedia({ url: '', type: 'image' });
    setLightboxOpen(false);
  };

  const handlePaymentSuccess = () => {
    setListing((prevState) => ({ ...prevState, trending: true }));
  };

  const handlePackageClick = (packageType) => {
    setActivePackage(packageType);
  };

  const handleSelectPackage = (packageType) => {
    if (packageType === 'standard') {
      setSelectedPackage({
        ...listing.standardPackage,
        type: 'standard',
      });
    } else if (packageType === 'deluxe') {
      setSelectedPackage({
        ...listing.deluxePackage,
        type: 'deluxe',
      });
    } else if (packageType === 'premium') {
      setSelectedPackage({
        ...listing.premiumPackage,
        type: 'premium',
      });
    } else {
      setSelectedPackage({
        price: listing.price,
        deliveryTime: listing.daysToDelivery,
        type: 'default',
      });
    }
  };

  const handleOpenRemoveModal = () => {
    setShowRemoveModal(true);
  };

  const handleCloseRemoveModal = () => {
    setShowRemoveModal(false);
  };

  const handleRemoveSuccess = () => {
    handleCloseRemoveModal();
    navigate('/');
  };

  const handleOpenTrendingModal = () => {
    setShowTrendingModal(true);
  };

  const handleCloseTrendingModal = () => {
    setShowTrendingModal(false);
  };

  const startChat = () => {
    setShowMessageForm(true);
  };

  const closeMessageForm = (e) => {
    if (e.target === e.currentTarget) {
      setShowMessageForm(false);
    }
  };

  // Function to determine the device type
  const getDeviceType = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    if (/android/i.test(userAgent)) {
      return 'mobile';
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'mobile';
    }
    if (/Windows Phone/i.test(userAgent)) {
      return 'mobile';
    }
    if (/Macintosh|Windows|Linux/.test(userAgent)) {
      return 'desktop';
    }
  
    return 'unknown';
  };
  
  // Referrer logic
  const referrer = document.referrer ? new URL(document.referrer).hostname : 'direct';

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900 text-white">
        <div className="spinner"></div>
      </div>
    );
  }

  if (errorMessage) {
    return <div className="text-red-500 text-center">{errorMessage}</div>;
  }

  const { standardPackage, deluxePackage, premiumPackage, faqs } = listing || {};

  return (
      <div className="listing-detail-container flex flex-col lg:flex-row lg:p-8 border border-gray-700 rounded-lg shadow-lg bg-gradient-to-r from-gray-800 via-gray-900 to-gray-800 text-white">
        <div className="listing-content w-full lg:w-4/5 border border-gray-700 rounded-lg bg-gray-700">
          <div className="relative w-full h-80 sm:h-80 md:h-[100vh]">
            <img
              src={listing.images[0] || 'https://via.placeholder.com/640x480?text=Image+Not+Available'}
              alt="Promo"
              className="w-full h-full object-cover rounded-lg"
            />
            <div className="absolute top-0 left-0 p-4 text-white bg-black bg-opacity-50 rounded-lg">
              <h2 className="text-3xl font-bold">{listing.name}</h2>
              <p className="text-sm text-gray-400">{listing.category}</p>
              <a
                href={`/profile/${listing.uid}`}
                className="text-blue-400 hover:underline"
              >
                {ownerUsername}
              </a>
              <div className="text-sm text-gray-300 delivery-time">
                {displayDeliveryTime} days to deliver
              </div>
              <div className="text-sm text-gray-300">
                Price: {displayPrice} SOL
              </div>
              {!isBlocked && currentUser && listing.uid && listing.uid !== currentUser.uid && (
                <ClientPayment
                  onPaymentSuccess={handlePaymentSuccess}
                  currentUser={currentUser}
                  listing={listing}
                  selectedPackage={selectedPackage}
                />
              )}
            </div>
            <div className="absolute top-0 right-0 p-4 flex space-x-2 text-white">
              {currentUser && listing.uid !== currentUser.uid && (
                <div className="tooltip">
                  <FaEnvelope
                    className="cursor-pointer hover:text-gray-300 text-2xl"
                    onClick={startChat}
                  />
                  <span className="tooltiptext">Message Seller</span>
                </div>
              )}
              {currentUser && listing.uid === currentUser.uid && (
                <>
                  <div className="tooltip">
                    <FaChartLine
                      className="cursor-pointer hover:text-gray-300"
                      onClick={handleOpenTrendingModal}
                    />
                    <span className="tooltiptext">Feature Listing</span>
                  </div>
                  <div className="tooltip">
                    <FaTrash
                      className="cursor-pointer hover:text-gray-300"
                      onClick={handleOpenRemoveModal}
                    />
                    <span className="tooltiptext">Delete Listing</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="tab-container mt-4">
          <button
            onClick={() => setSelectedTab('info')}
            className={`py-2 px-4 ${selectedTab === 'info' ? 'active' : ''}`}
          >
            Info
          </button>
          <button
            onClick={() => setSelectedTab('packages')}
            className={`py-2 px-4 ${selectedTab === 'packages' ? 'active' : ''}`}
          >
            Options 
          </button>
          <button
            onClick={() => setSelectedTab('media')}
            className={`py-2 px-4 ${selectedTab === 'media' ? 'active' : ''}`}
          >
            Additional Media
          </button>
        </div>

        <div className="tab-content">
          {selectedTab === 'info' && (
            <>
              <h2 className="text-xl font-bold">Description</h2>
              <p>{listing.description || 'No description available'}</p>
              <h2 className="text-xl font-bold mt-4">Software Used</h2>
              <div className="software-used mt-2">
                {listing.softwareUsed.map((software, index) => (
                  <span key={index} className="tag bg-gray-600 text-white p-2 rounded-lg mr-2">
                    {software}
                  </span>
                ))}
              </div>

              {/* FAQ Section */}
              {faqs && faqs.length > 0 && (
                <div className="faqs mt-6">
                  <h2 className="text-xl font-bold mb-4">Frequently Asked Questions</h2>
                  {faqs.map((faq, index) => (
                    <div key={index} className="faq-item mb-4">
                      <h3 className="font-semibold text-lg mb-2">Q: {faq.question}</h3>
                      <p className="text-gray-400">A: {faq.answer}</p>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          {selectedTab === 'packages' && (
            <div className="packages-container flex flex-col gap-4">
              {listing.price && (
                <div
                  className={`package-item p-4 border rounded-lg cursor-pointer text-center ${activePackage === 'default' ? 'selected' : 'bg-gray-700'}`}
                  onClick={() => handlePackageClick('default')}
                >
                  <p className="font-semibold">
                    Default Package <span className="text-sm">{selectedPackage?.type === 'default' ? 'Selected' : ''}</span>
                  </p>
                  {activePackage === 'default' && (
                    <>
                      <p className="font-semibold">Price: {displayPrice} SOL</p>
                      <p>Delivery Time: {displayDeliveryTime} days</p>
                    </>
                  )}
                  {activePackage === 'default' && (
                    <button
                      className={`select-package-button mt-3 py-1 rounded-lg ${selectedPackage?.type === 'default' ? 'bg-gray-600 text-white' : 'bg-gray-600 text-gray-300'}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectPackage('default');
                      }}
                    >
                      {selectedPackage?.type === 'default' ? 'Selected' : 'Select'}
                    </button>
                  )}
                </div>
              )}
              {standardPackage && standardPackage.title && standardPackage.price && (
                <div
                  className={`package-item p-4 border rounded-lg cursor-pointer text-center ${activePackage === 'standard' ? 'selected' : 'bg-gray-700'}`}
                  onClick={() => handlePackageClick('standard')}
                >
                  <p className="font-semibold">
                    {standardPackage.title || 'Standard Package'} <span className="text-sm">{selectedPackage?.type === 'standard' ? 'Selected' : ''}</span>
                  </p>
                  {activePackage === 'standard' && (
                    <>
                      <p>{standardPackage.description}</p>
                      <p className="font-semibold">Price: {standardPackage.price} SOL</p>
                      <p>Delivery Time: {standardPackage.deliveryTime} days</p>
                    </>
                  )}
                  {activePackage === 'standard' && (
                    <button
                      className={`select-package-button mt-3 py-1 rounded-lg ${selectedPackage?.type === 'standard' ? 'bg-gray-600 text-white' : 'bg-gray-600 text-gray-300'}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectPackage('standard');
                      }}
                    >
                      {selectedPackage?.type === 'standard' ? 'Selected' : 'Select'}
                    </button>
                  )}
                </div>
              )}
              {deluxePackage && deluxePackage.title && deluxePackage.price && (
                <div
                  className={`package-item p-4 border rounded-lg cursor-pointer text-center ${activePackage === 'deluxe' ? 'selected' : 'bg-gray-700'}`}
                  onClick={() => handlePackageClick('deluxe')}
                >
                  <p className="font-semibold">
                    {deluxePackage.title || 'Deluxe Package'} <span className="text-sm">{selectedPackage?.type === 'deluxe' ? 'Selected' : ''}</span>
                  </p>
                  {activePackage === 'deluxe' && (
                    <>
                      <p>{deluxePackage.description}</p>
                      <p className="font-semibold">Price: {deluxePackage.price} SOL</p>
                      <p>Delivery Time: {deluxePackage.deliveryTime} days</p>
                    </>
                  )}
                  {activePackage === 'deluxe' && (
                    <button
                      className={`select-package-button mt-3 py-1 rounded-lg ${selectedPackage?.type === 'deluxe' ? 'bg-gray-600 text-white' : 'bg-gray-600 text-gray-300'}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectPackage('deluxe');
                      }}
                    >
                      {selectedPackage?.type === 'deluxe' ? 'Selected' : 'Select'}
                    </button>
                  )}
                </div>
              )}
              {premiumPackage && premiumPackage.title && premiumPackage.price && (
                <div
                  className={`package-item p-4 border rounded-lg cursor-pointer text-center ${activePackage === 'premium' ? 'selected' : 'bg-gray-700'}`}
                  onClick={() => handlePackageClick('premium')}
                >
                  <p className="font-semibold">
                    {premiumPackage.title || 'Premium Package'} <span className="text-sm">{selectedPackage?.type === 'premium' ? 'Selected' : ''}</span>
                  </p>
                  {activePackage === 'premium' && (
                    <>
                      <p>{premiumPackage.description}</p>
                      <p className="font-semibold">Price: {premiumPackage.price} SOL</p>
                      <p>Delivery Time: {premiumPackage.deliveryTime} days</p>
                    </>
                  )}
                  {activePackage === 'premium' && (
                    <button
                      className={`select-package-button mt-3 py-1 rounded-lg ${selectedPackage?.type === 'premium' ? 'bg-gray-600 text-white' : 'bg-gray-600 text-gray-300'}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectPackage('premium');
                      }}
                    >
                      {selectedPackage?.type === 'premium' ? 'Selected' : 'Select'}
                    </button>
                  )}
                </div>
              )}
            </div>
          )}

          {selectedTab === 'media' && (
            <div className="media-gallery mb-6">
              <h3 className="text-xl font-semibold mb-3">Additional Media</h3>
              <div className="flex overflow-x-auto space-x-4">
                {listing.media.map((media, index) => (
                  <div key={index} className="media-container">
                    {media.type === 'image' ? (
                      <img
                        src={media.url || 'https://via.placeholder.com/100x100?text=Media+Not+Available'}
                        alt={`Media ${index + 1}`}
                        className="media-image h-32 w-auto rounded-lg cursor-pointer"
                        onClick={() => handleMediaClick(media)}
                      />
                    ) : (
                      <div
                        className="media-video h-32 w-auto bg-gray-600 rounded-lg cursor-pointer flex items-center justify-center"
                        onClick={() => handleMediaClick(media)}
                      >
                        <span className="text-gray-300">Video</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {lightboxOpen && selectedMedia.url && (
          <MediaLightbox
            mediaUrl={selectedMedia.url}
            mediaType={selectedMedia.type}
            onClose={handleModalClose}
          />
        )}

        {showRemoveModal && (
          <RemoveListing
            listingId={listing.id}
            onClose={handleCloseRemoveModal}
            onSuccess={handleRemoveSuccess}
          />
        )}

        {showTrendingModal && (
          <div className="modal-backdrop" onClick={handleCloseTrendingModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <PaymentForTrending
                listingId={listing.id}
                currentUser={currentUser}
                onPaymentSuccess={handlePaymentSuccess}
                onClose={handleCloseTrendingModal}
              />
            </div>
          </div>
        )}

        {showMessageForm && (
          <div className="modal-backdrop" onClick={closeMessageForm}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <MessageForm
                listingOwnerId={listing.uid}
                listingId={id}
                onClose={closeMessageForm}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  export default ListingDetail;
