import React from 'react';

const BackgroundWrapper = ({ children }) => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-800 via-gray-900 to-black text-white">
      {children}
    </div>
  );
};

export default BackgroundWrapper;
