import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const InfoCard = ({ title, value, percentChange }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="h4" color="primary">{value}</Typography>
        {percentChange !== undefined && (
          <Typography
            variant="body2"
            color={percentChange >= 0 ? 'green' : 'red'}
            sx={{ mt: 1 }}
          >
            {percentChange >= 0 ? `+${percentChange}%` : `${percentChange}%`} from last month
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default InfoCard;

