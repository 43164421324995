import React, { useEffect } from 'react';
import './MediaLightbox.css';

const MediaLightbox = ({ mediaUrl, mediaType, onClose }) => {
  useEffect(() => {
    console.log(`MediaLightbox opened with mediaUrl: ${mediaUrl}, mediaType: ${mediaType}`);
  }, [mediaUrl, mediaType]);

  const handleMediaError = (e) => {
    console.error(`Error loading ${mediaType}:`, e.nativeEvent);
    alert(`An error occurred while loading the ${mediaType}.`);
  };

  const handleLightboxClick = () => {
    onClose(); // Close lightbox on overlay click
  };

  const handleContentClick = (e) => {
    e.stopPropagation(); // Prevent closing lightbox on inner content click
  };

  return (
    <div
      className="media-lightbox fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
      onClick={handleLightboxClick} // Close lightbox on overlay click
    >
      <div
        className="relative bg-transparent p-4 rounded-lg"
        onClick={handleContentClick} // Prevent closing lightbox on inner content click
      >
        {mediaType === 'image' ? (
          <img
            src={mediaUrl}
            alt="Media"
            className="lightbox-media cursor-pointer"
            onError={handleMediaError}
          />
        ) : (
          <video
            controls
            className="lightbox-media cursor-pointer"
            autoPlay
            onError={handleMediaError}
          >
            <source src={mediaUrl} type="video/mp4" />
            <source src={mediaUrl} type="video/webm" />
            <source src={mediaUrl} type="video/ogg" />
            Your browser does not support the video tag. Click <a href={mediaUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">here</a> to view the video.
          </video>
        )}
        <button
          className="absolute top-2 right-2 text-white bg-black p-2 rounded"
          onClick={(e) => {
            e.stopPropagation(); // Prevent button click from closing lightbox
            onClose();
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default MediaLightbox;

