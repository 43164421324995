// src/components/orders/freelancerOrderManagement/OrderCard.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './OrderCard.css'; // Ensure this path is correct

const OrderCard = ({ order }) => {
  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleString();
  };

  return (
    <div className="order-card">
      <div className="order-card-content">
        <div>
          <h3>Listing Name: {order.listingName}</h3>
          <p>Order ID: {order.projectID}</p>
          <p>Purchased: {formatTimestamp(order.createdAt)}</p>
        </div>
        <div className="status">
          <p>Status: {order.status}</p>
          <Link to={`/order-tracking/${order.projectID}`}>
            <button>View Details</button>
          </Link>
        </div>
      </div>
      {order.listingImageUrl && (
        <a href={`/listing/${order.listingID}`} target="_blank" rel="noopener noreferrer">
          <img
            src={order.listingImageUrl}
            alt="Listing Thumbnail"
            className="order-card-thumbnail"
          />
        </a>
      )}
    </div>
  );
};

export default OrderCard;

