import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { db, storage } from '../../firebase/firebase'; // Ensure this path is correct
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc, arrayUnion, setDoc, query, where, getDocs, collection, getDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/authContext';

const ClientUploadDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectID, freelancerQuestions } = location.state || { projectID: null, freelancerQuestions: [] };
  const [answers, setAnswers] = useState(freelancerQuestions.map(() => ''));
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { currentUser } = useAuth();

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const handleFilesSelect = (e) => {
    setFiles(e.target.files);
  };

  const handleAdditionalDetailsChange = (e) => {
    setAdditionalDetails(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    if (!projectID) {
      setErrorMessage('Project ID is missing');
      setUploading(false);
      return;
    }

    // Fetch the correct project document ID
    const projectsQuery = query(collection(db, 'projects'), where('projectID', '==', projectID));
    const projectsSnapshot = await getDocs(projectsQuery);

    if (projectsSnapshot.empty) {
      setErrorMessage('No project found with the provided project ID');
      setUploading(false);
      return;
    }

    const projectDoc = projectsSnapshot.docs[0];
    const projectRef = doc(db, 'projects', projectDoc.id);

    const uploadTasks = [];
    const fileUrls = [];

    for (const file of files) {
      const fileRef = ref(storage, `projects/${projectID}/${file.name}-${Date.now()}`);
      const uploadTask = uploadBytesResumable(fileRef, file);

      uploadTasks.push(
        uploadTask.then(async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          fileUrls.push(downloadURL);
        })
      );

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error('Error uploading file:', error);
          setErrorMessage('Failed to upload file');
        }
      );
    }

    await Promise.all(uploadTasks);

    try {
      console.log('Updating project document with:', {
        clientAnswers: answers,
        additionalDetails,
        clientFiles: fileUrls,
        timestamp: new Date(),
      });

      await updateDoc(projectRef, {
        clientAnswers: answers,
        additionalDetails,
        clientFiles: arrayUnion(...fileUrls),
        timestamp: new Date(),
      });

      console.log('Updated project document:', {
        clientAnswers: answers,
        additionalDetails,
        clientFiles: fileUrls,
        timestamp: new Date(),
      });

      console.log('Project document updated successfully');
    } catch (error) {
      console.error('Error updating project document:', error);
    }

    alert('Details submitted successfully');
    setAnswers(freelancerQuestions.map(() => ''));
    setAdditionalDetails('');
    setFiles([]);
    setUploading(false);

    navigate('/client-upload-confirmation', { state: { projectID } });
  };

  return (
    <div className="max-w-md mx-auto p-4 shadow-md border rounded-lg bg-gray-900 text-gray-200">
      <h2 className="text-xl font-semibold text-center mb-4">Upload Project Details</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        {freelancerQuestions.map((question, index) => (
          <div key={index} className="space-y-2">
            <label className="text-sm text-gray-400 font-semibold">{question}</label>
            <textarea
              value={answers[index]}
              onChange={(e) => handleInputChange(e, index)}
              required
              className="w-full mt-1 px-4 py-3 text-gray-300 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="3"
            />
          </div>
        ))}
        <div className="mb-4">
          <label className="text-sm text-gray-400 font-semibold">Additional Details</label>
          <textarea
            className="w-full mt-1 px-4 py-3 text-gray-300 bg-gray-700 border border-gray-600 rounded-lg"
            rows="4"
            value={additionalDetails}
            onChange={handleAdditionalDetailsChange}
          ></textarea>
        </div>
        <div>
          <label className="text-sm text-gray-400 font-semibold">Upload Files</label>
          <input
            type="file"
            multiple
            onChange={handleFilesSelect}
            className="w-full mt-1 px-4 py-2 text-gray-200 bg-gray-800 border border-gray-700 rounded-lg"
          />
        </div>
        {uploadProgress > 0 && (
          <div className="w-full bg-gray-800 rounded-full h-2.5 mb-4">
            <div
              className="bg-blue-500 h-2.5 rounded-full"
              style={{ width: `${uploadProgress}%` }}
            ></div>
          </div>
        )}
        {errorMessage && <div className="text-red-500 text-center mt-4">{errorMessage}</div>}
        <div className="flex justify-center mt-6">
          <button
            type="submit"
            disabled={uploading}
            className={`hustle-button w-full px-4 py-2 text-white font-medium rounded-lg ${uploading ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700'} transition duration-300`}
          >
            {uploading ? 'Uploading...' : 'Submit'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientUploadDetails;

