import React from 'react';
import './FreelanceModal.css';

const FreelanceModal = ({ isOpen, onRequestClose, contentLabel, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="dark-overlay">
      <div className="dark-modal">
        <button className="close-button" onClick={onRequestClose}>×</button>
        <h2>{contentLabel}</h2>
        <div className="dark-modal-content">{children}</div>
      </div>
    </div>
  );
};

export default FreelanceModal;

