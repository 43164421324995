import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../firebase/firebase'; // Adjust import path as necessary
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';

const UserProfile = ({ userId, onClose }) => {
  const [userData, setUserData] = useState(null);
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [lightbox, setLightbox] = useState({ isOpen: false, src: '' });

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        // Fetch user profile
        const userDocRef = doc(db, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          setUserData(userDocSnap.data());
        } else {
          setErrorMessage('User profile not found');
          setLoading(false);
          return;
        }

        // Fetch portfolio items
        const portfolioCollectionRef = collection(db, 'users', userId, 'portfolio');
        const portfolioSnapshot = await getDocs(portfolioCollectionRef);
        
        if (!portfolioSnapshot.empty) {
          const portfolioData = portfolioSnapshot.docs.map(doc => doc.data());
          setPortfolioItems(portfolioData);
        } else {
          console.log(`No portfolio items found for user ${userId}`);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching user profile or portfolio items:', error);
        setErrorMessage('Failed to fetch user profile or portfolio items');
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [userId]);

  const openLightbox = (src) => {
    setLightbox({ isOpen: true, src });
  };

  const closeLightbox = useCallback(() => {
    setLightbox({ isOpen: false, src: '' });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* Lightbox Modal */}
      {lightbox.isOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
          }}
          onClick={closeLightbox}
        >
          <div
            style={{
              position: 'relative',
              backgroundColor: 'white',
              borderRadius: '8px',
              padding: '20px',
              maxWidth: '80%',
              maxHeight: '80vh',
              overflow: 'auto',
            }}
            onClick={(e) => e.stopPropagation()} // Prevent clicks on the content from closing the lightbox
          >
            <button
              onClick={closeLightbox}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                backgroundColor: 'red', // Red background color
                color: 'white',         // White text color
                border: 'none',
                borderRadius: '50%',
                padding: '0.5rem',
                cursor: 'pointer',
                fontSize: '1.25rem',
                zIndex: 1100, // Ensure button is on top
              }}
              aria-label="Close"
            >
              &times;
            </button>
            <img
              src={lightbox.src}
              alt="Enlarged"
              style={{
                maxWidth: '100%',
                maxHeight: '80vh',
                borderRadius: '8px',
              }}
            />
          </div>
        </div>
      )}

      {/* User Profile Modal */}
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 50,
        }}
      >
        <div
          style={{
            position: 'relative',
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '20px',
            maxWidth: '80vw',
            maxHeight: '80vh',
            overflow: 'auto',
          }}
        >
          <button
            onClick={onClose}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              backgroundColor: 'red', // Red background color
              color: 'white',         // White text color
              border: 'none',
              borderRadius: '50%',
              padding: '0.5rem',
              cursor: 'pointer',
              fontSize: '1.25rem',
              zIndex: 1100, // Ensure button is on top
            }}
            aria-label="Close"
          >
            &times;
          </button>
          <h2 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '1rem' }}>User Profile</h2>
          {errorMessage && <div style={{ color: 'red', fontWeight: 'bold', marginBottom: '1rem' }}>{errorMessage}</div>}
          {userData && (
            <div>
              <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Age</h3>
                <p>{userData.age || 'N/A'}</p>
              </div>
              <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Bio</h3>
                <p>{userData.bio || 'N/A'}</p>
              </div>
              <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Skills</h3>
                <ul>
                  {userData.skills && userData.skills.length > 0 ? (
                    userData.skills.map((skill, index) => (
                      <li key={index}>{skill}</li>
                    ))
                  ) : (
                    <li>N/A</li>
                  )}
                </ul>
              </div>
              <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Wallet Address</h3>
                <p>{userData.walletAddress || 'N/A'}</p>
              </div>
              <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Portfolio Items</h3>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '1rem' }}>
                  {portfolioItems.length > 0 ? (
                    portfolioItems.map((item, index) => (
                      <div key={index} style={{ position: 'relative' }}>
                        <div style={{ marginBottom: '0.5rem' }}>
                          {item.urls && item.urls.length > 0 ? (
                            item.urls.map((url, idx) => (
                              <img
                                key={idx}
                                src={url}
                                alt={`Portfolio Image ${index + 1}-${idx + 1}`}
                                style={{
                                  width: '100%',
                                  height: '8rem',
                                  objectFit: 'cover',
                                  borderRadius: '8px',
                                  cursor: 'pointer',
                                  marginBottom: '0.5rem',
                                }}
                                onClick={() => openLightbox(url)}
                              />
                            ))
                          ) : (
                            <p>No images available</p>
                          )}
                        </div>
                        {item.description && (
                          <p style={{ fontSize: '0.875rem' }}>{item.description}</p>
                        )}
                        {item.customerName && (
                          <p style={{ fontSize: '0.875rem', color: 'gray' }}>Customer: {item.customerName}</p>
                        )}
                      </div>
                    ))
                  ) : (
                    <p>No portfolio items available</p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;

