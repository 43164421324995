import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebase'; // Adjust the import path as necessary
import { useAuth } from '../../contexts/authContext'; // Import useAuth hook
import './update/EditModal.css'; // Import custom styles if needed

const skillOptions = [
  'Cryptocurrency Trading', 'Blockchain Development', 'Smart Contracts', 'DeFi',
  'NFTs', 'Crypto Mining', 'Tokenomics', 'Wallet Security', 'Crypto Regulations',
  'Crypto Market Analysis'
].map(skill => ({ label: skill, value: skill }));

const languageOptions = [
  'English', 'Spanish', 'Chinese', 'German', 'French', 'Japanese', 'Russian'
].map(lang => ({ label: lang, value: lang }));

const EditBioModal = ({ userData, onClose, handleProfileUpdate }) => {
  const { currentUser } = useAuth(); // Get current user
  const [bio, setBio] = useState(userData.bio || '');
  const [location, setLocation] = useState(userData.location?.country || '');
  const [languages, setLanguages] = useState(userData.languages || []);
  const [skills, setSkills] = useState(userData.skills || []);
  const [telegramHandle, setTelegramHandle] = useState(userData.telegramHandle || '');
  const [twitterHandle, setTwitterHandle] = useState(userData.twitterHandle || '');
  const [initialReputation, setInitialReputation] = useState(0);

  // Fetch current reputation score from Firebase when the component mounts
  useEffect(() => {
    const fetchReputation = async () => {
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          setInitialReputation(userDoc.data().reputation || 0);
        }
      } catch (error) {
        console.error('Error fetching reputation:', error);
      }
    };

    fetchReputation();
  }, [currentUser]);

  const calculateReputationChange = () => {
    let repChange = 0;

    // Check changes for each field and adjust reputation accordingly
    if (bio !== userData.bio) {
      repChange += bio ? 5 : -5;
    }
    if (location !== userData.location?.country) {
      repChange += location ? 5 : -5;
    }
    if (languages.join(',') !== (userData.languages || []).join(',')) {
      repChange += languages.length > 0 ? 5 : -5;
    }
    if (skills.join(',') !== (userData.skills || []).join(',')) {
      repChange += skills.length > 0 ? 5 : -5;
    }
    if (telegramHandle !== userData.telegramHandle) {
      repChange += telegramHandle ? 5 : -5;
    }
    if (twitterHandle !== userData.twitterHandle) {
      repChange += twitterHandle ? 5 : -5;
    }

    return repChange;
  };

  const saveChanges = async () => {
    try {
      // Prepare updated data
      const updatedData = {
        bio,
        location: { country: location },
        languages,
        skills,
        telegramHandle,
        twitterHandle,
      };

      // Calculate reputation change
      const reputationChange = calculateReputationChange();
      const newReputation = initialReputation + reputationChange;

      // Update the profile data in the Firestore
      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, { ...updatedData, reputation: newReputation });

      // Call the parent function to update the state
      handleProfileUpdate(updatedData);

      // Close the modal
      onClose();
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 px-4 sm:px-0">
      <div className="bg-gray-900 p-4 rounded-lg shadow-lg w-full max-w-xl mx-auto text-white">
        <h2 className="text-2xl mb-4 text-center font-bold">Edit Bio</h2>

        <div className="mb-2">
          <label className="block mb-1 font-semibold">Bio</label>
          <textarea
            className="textarea-field w-full px-3 py-2 rounded-lg bg-gray-800 border border-gray-700 focus:outline-none focus:border-blue-500"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
          />
        </div>

        <div className="mb-2">
          <label className="block mb-1 font-semibold">Location</label>
          <input
            type="text"
            className="input-field w-full px-3 py-2 rounded-lg bg-gray-800 border border-gray-700 focus:outline-none focus:border-blue-500"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>

        <div className="mb-2">
          <label className="block mb-1 font-semibold">Languages</label>
          <CreatableSelect
            isMulti
            options={languageOptions}
            value={languages.map(lang => ({ label: lang, value: lang }))}
            onChange={selectedLanguages => setLanguages(selectedLanguages.map(lang => lang.value))}
            placeholder="Select or add languages"
            classNamePrefix="select"
            styles={{
              control: (base) => ({
                ...base,
                backgroundColor: 'transparent',
                borderColor: 'rgba(255, 255, 255, 0.08)',
                color: 'white',
                borderRadius: '1rem',
                padding: '0.4rem',
              }),
              input: (base) => ({
                ...base,
                color: 'white',
              }),
              menu: (base) => ({
                ...base,
                backgroundColor: '#1f2937',
              }),
              option: (base, { isFocused }) => ({
                ...base,
                backgroundColor: isFocused ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
                color: 'white',
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                color: 'white',
              }),
              multiValueLabel: (base) => ({
                ...base,
                color: 'white',
              }),
              placeholder: (base) => ({
                ...base,
                color: '#9CA3AF',
              }),
            }}
          />
        </div>

        <div className="mb-2">
          <label className="block mb-1 font-semibold">Skills</label>
          <CreatableSelect
            isMulti
            options={skillOptions}
            value={skills.map(skill => ({ label: skill, value: skill }))}
            onChange={selectedSkills => setSkills(selectedSkills.map(skill => skill.value))}
            placeholder="Select or add skills"
            classNamePrefix="select"
            styles={{
              control: (base) => ({
                ...base,
                backgroundColor: 'transparent',
                borderColor: 'rgba(255, 255, 255, 0.08)',
                color: 'white',
                borderRadius: '1rem',
                padding: '0.4rem',
              }),
              input: (base) => ({
                ...base,
                color: 'white',
              }),
              menu: (base) => ({
                ...base,
                backgroundColor: '#1f2937',
              }),
              option: (base, { isFocused }) => ({
                ...base,
                backgroundColor: isFocused ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
                color: 'white',
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                color: 'white',
              }),
              multiValueLabel: (base) => ({
                ...base,
                color: 'white',
              }),
              placeholder: (base) => ({
                ...base,
                color: '#9CA3AF',
              }),
            }}
          />
        </div>

        <div className="mb-2">
          <label className="block mb-1 font-semibold">Telegram Handle</label>
          <input
            type="text"
            className="input-field w-full px-3 py-2 rounded-lg bg-gray-800 border border-gray-700 focus:outline-none focus:border-blue-500"
            value={telegramHandle}
            onChange={(e) => setTelegramHandle(e.target.value)}
          />
        </div>

        <div className="mb-2">
          <label className="block mb-1 font-semibold">Twitter Handle</label>
          <input
            type="text"
            className="input-field w-full px-3 py-2 rounded-lg bg-gray-800 border border-gray-700 focus:outline-none focus:border-blue-500"
            value={twitterHandle}
            onChange={(e) => setTwitterHandle(e.target.value)}
          />
        </div>

        <div className="mt-6 flex justify-between">
          <button
            onClick={onClose}
            className="hustlesmall-button bg-gray-700 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded-lg transition-all"
          >
            Cancel
          </button>
          <button
            onClick={saveChanges}
            className="hustlesmall-button bg-blue-600 hover:bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg transition-all"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditBioModal;

