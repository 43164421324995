import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";
import { db } from "../../../firebase/firebase"; // Adjust the import path based on your setup

// Function to get total money made within a specific time frame
export const getTotalMoneyMade = async (freelancerID, timeFrame, subTimeFrame, customRange, selectedQuarter) => {
  let startDate, endDate;

  if (subTimeFrame === 'customMonth' && customRange) {
    startDate = customRange.start instanceof Date ? customRange.start : customRange.start.toDate();
    endDate = customRange.end instanceof Date 
      ? new Date(customRange.end.getFullYear(), customRange.end.getMonth() + 1, 0, 23, 59, 59, 999)
      : customRange.end.endOf('month').toDate();
  } else {
    const now = new Date();
    switch (timeFrame) {
      case 'monthly':
        if (subTimeFrame === 'previousMonth') {
          const previousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          startDate = new Date(previousMonth.getFullYear(), previousMonth.getMonth(), 1, 0, 0, 0, 0);
          endDate = new Date(previousMonth.getFullYear(), previousMonth.getMonth() + 1, 0, 23, 59, 59, 999);
        } else if (subTimeFrame === 'currentMonth') {
          startDate = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0);
          endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
        }
        break; 
      case 'quarterly':
        console.log('Selected Quarter:', selectedQuarter);
        if (selectedQuarter) {
          const quarterStartMonth = (selectedQuarter - 1) * 3;
          startDate = new Date(now.getFullYear(), quarterStartMonth, 1, 0, 0, 0, 0);
          endDate = new Date(now.getFullYear(), quarterStartMonth + 3, 0, 23, 59, 59, 999);
        } else {
          const quarterStartMonth = Math.floor(now.getMonth() / 3) * 3;
          startDate = new Date(now.getFullYear(), quarterStartMonth, 1, 0, 0, 0, 0);
          endDate = new Date(now.getFullYear(), quarterStartMonth + 3, 0, 23, 59, 59, 999);
        }
        break;
      case 'yearly':
        startDate = new Date(now.getFullYear(), 0, 1, 0, 0, 0, 0);
        endDate = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999);
        break;
      default:
        startDate = new Date(0);
        endDate = new Date();
    }
  }

  console.log(`Fetching projects from ${startDate.toISOString()} to ${endDate.toISOString()}`);

  const projectsQuery = query(
    collection(db, "projects"),
    where("freelancerID", "==", freelancerID),
    where("completedAt", ">=", Timestamp.fromDate(startDate)),
    where("completedAt", "<=", Timestamp.fromDate(endDate))
  );

  const projectsSnapshot = await getDocs(projectsQuery);
  let totalEarnings = 0;
  console.log("Number of projects fetched:", projectsSnapshot.size);

  projectsSnapshot.forEach((doc) => {
    const projectData = doc.data();
    const amountEarned = projectData.amountEarned || 0;
    console.log(`Project: ${doc.id}, Earned: ${amountEarned}, CompletedAt: ${projectData.completedAt.toDate().toISOString()}`);
    totalEarnings += amountEarned;
  });

  console.log('Total Earnings for the period:', totalEarnings);
  return totalEarnings;
};

// Function to get total projects by status within a specific time frame
export const getTotalProjects = async (freelancerID, status, timeFrame, subTimeFrame, customRange, selectedQuarter) => {
  let startDate, endDate;

  const now = new Date();
  if (timeFrame === 'quarterly' && selectedQuarter) {
    const quarterStartMonth = (selectedQuarter - 1) * 3;
    startDate = new Date(now.getFullYear(), quarterStartMonth, 1);
    endDate = new Date(now.getFullYear(), quarterStartMonth + 3, 0, 23, 59, 59, 999);
  } else if (subTimeFrame === 'customMonth' && customRange) {
    startDate = customRange.start instanceof Date ? customRange.start : new Date(customRange.start);
    endDate = customRange.end instanceof Date ? customRange.end : new Date(customRange.end);
  } else {
    switch (timeFrame) {
      case 'monthly':
        if (subTimeFrame === 'previousMonth') {
          const previousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          startDate = new Date(previousMonth.getFullYear(), previousMonth.getMonth(), 1);
          endDate = new Date(previousMonth.getFullYear(), previousMonth.getMonth() + 1, 0, 23, 59, 59, 999);
        } else {
          startDate = new Date(now.getFullYear(), now.getMonth(), 1);
          endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
        }
        break;
      case 'yearly':
        startDate = new Date(now.getFullYear(), 0, 1);
        endDate = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999);
        break;
      default:
        startDate = new Date(0);
        endDate = new Date();
    }
  }

  console.log(`Fetching projects with status ${status} from ${startDate.toISOString()} to ${endDate.toISOString()}`);

  let projectsQuery;

  if (status === 'open') {
    projectsQuery = query(
      collection(db, "projects"),
      where("freelancerID", "==", freelancerID),
      where("createdAt", ">=", Timestamp.fromDate(startDate)),
      where("createdAt", "<=", Timestamp.fromDate(endDate)),
      where("status", "==", "active")  // Filter for active projects
    );
  } else if (status === 'completed') {
    projectsQuery = query(
      collection(db, "projects"),
      where("freelancerID", "==", freelancerID),
      where("completedAt", ">=", Timestamp.fromDate(startDate)),
      where("completedAt", "<=", Timestamp.fromDate(endDate))  // Filter for completed projects within the range
    );
  } else {
    projectsQuery = query(
      collection(db, "projects"),
      where("freelancerID", "==", freelancerID),
      where("status", "==", status),
      where("createdAt", ">=", Timestamp.fromDate(startDate)),
      where("createdAt", "<=", Timestamp.fromDate(endDate))
    );
  }

  console.log('Query for projects:', projectsQuery);

  const projectsSnapshot = await getDocs(projectsQuery);

  projectsSnapshot.forEach((doc) => {
    const data = doc.data();
    const completedAt = data.completedAt ? data.completedAt.toDate().toISOString() : 'Field not present';
  });

  console.log(`Fetched ${projectsSnapshot.size} projects with status ${status}`);
  return projectsSnapshot.size;
};

// Function to get earnings data for charting purposes
export const getEarningsData = async (freelancerID, timeFrame, subTimeFrame, customRange, selectedQuarter) => {
  console.log("getEarningsData called with:");
  console.log("freelancerID:", freelancerID);
  console.log("timeFrame:", timeFrame);
  console.log("subTimeFrame:", subTimeFrame);
  console.log("customRange:", customRange);
  console.log("selectedQuarter:", selectedQuarter);

  const now = new Date();
  let startDate, endDate;

  // Handle the quarterly timeframe with selectedQuarter
  if (timeFrame === 'quarterly' && selectedQuarter) {
    const quarterStartMonth = (selectedQuarter - 1) * 3;
    startDate = new Date(now.getFullYear(), quarterStartMonth, 1, 0, 0, 0, 0);
    endDate = new Date(now.getFullYear(), quarterStartMonth + 3, 0, 23, 59, 59, 999);
  } else if (!customRange || !customRange.start || !customRange.end) {
    console.error("Error: customRange is undefined or missing start/end date");
    return {
      dates: [],
      earnings: [],
    };
  } else {
    // Convert customRange start and end to Date objects if they aren't already
    const start = customRange.start instanceof Date ? customRange.start : new Date(customRange.start);
    const end = customRange.end instanceof Date ? customRange.end : new Date(customRange.end);

    switch (timeFrame) {
      case 'monthly':
        switch (subTimeFrame) {
          case 'currentMonth':
            startDate = new Date(start.getFullYear(), start.getMonth(), 1, 0, 0, 0, 0);
            endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59, 999);
            break;
          case 'previousMonth':
            startDate = new Date(start.getFullYear(), start.getMonth() - 1, 1, 0, 0, 0, 0);
            endDate = new Date(start.getFullYear(), start.getMonth(), 0, 23, 59, 59, 999);
            break;
          case 'customMonth':
            startDate = start;
            endDate = end;
            break;
          default:
            startDate = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0);
            endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
        }
        break;
      case 'yearly':
        startDate = new Date(now.getFullYear(), 0, 1, 0, 0, 0, 0);
        endDate = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999);
        break;
      default:
        startDate = new Date(0);
        endDate = new Date();
    }
  }

  console.log(`Querying projects from ${startDate.toISOString()} to ${endDate.toISOString()}`);

  const projectsQuery = query(
    collection(db, "projects"),
    where("freelancerID", "==", freelancerID),
    where("completedAt", ">=", Timestamp.fromDate(startDate)),
    where("completedAt", "<=", Timestamp.fromDate(endDate))
  );

  const projectsSnapshot = await getDocs(projectsQuery);

  let earnings = {};

  projectsSnapshot.forEach((doc) => {
    const data = doc.data();
    const completedDate = data.completedAt.toDate();

    let key;
    if (timeFrame === 'monthly') {
      key = completedDate.getDate().toString();
    } else if (timeFrame === 'quarterly' || timeFrame === 'yearly') {
      key = `${completedDate.getFullYear()}-${String(completedDate.getMonth() + 1).padStart(2, '0')}`;
    } else if (timeFrame === 'custom') {
      key = `${completedDate.getFullYear()}-${String(completedDate.getMonth() + 1).padStart(2, '0')}-${String(completedDate.getDate()).padStart(2, '0')}`;
    }

    if (!earnings[key]) {
      earnings[key] = 0;
    }
    earnings[key] += data.amountEarned || 0;
  });

  const dates = Object.keys(earnings);
  const earningsValues = dates.map(date => earnings[date]);

  console.log('Formatted Data: ', { dates, earnings: earningsValues });

  return {
    dates,
    earnings: earningsValues
  };
};

// Function to get earnings by listing for the donut chart
export const getEarningsByListing = async (freelancerID, timeFrame, subTimeFrame, customMonth, customRange, selectedQuarter) => {
  try {
    let startDate, endDate;
    const now = new Date();

    if (subTimeFrame === 'customMonth' && customRange) {
      startDate = customRange.start.toDate();
      endDate = customRange.end.endOf('month').toDate();
    } else {
      switch (timeFrame) {
        case 'monthly':
          if (subTimeFrame === 'previousMonth') {
            const previousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
            startDate = new Date(previousMonth.getFullYear(), previousMonth.getMonth(), 1, 0, 0, 0, 0);
            endDate = new Date(previousMonth.getFullYear(), previousMonth.getMonth() + 1, 0, 23, 59, 59, 999);
          } else {
            startDate = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0);
            endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
          }
          break;
        case 'quarterly':
          if (selectedQuarter) {
            const quarterStartMonth = (selectedQuarter - 1) * 3;
            startDate = new Date(now.getFullYear(), quarterStartMonth, 1, 0, 0, 0, 0);
            endDate = new Date(now.getFullYear(), quarterStartMonth + 3, 0, 23, 59, 59, 999);
          } else {
            const quarterStartMonth = Math.floor(now.getMonth() / 3) * 3;
            startDate = new Date(now.getFullYear(), quarterStartMonth, 1, 0, 0, 0, 0);
            endDate = new Date(now.getFullYear(), quarterStartMonth + 3, 0, 23, 59, 59, 999);
          }
          break;
        case 'yearly':
          startDate = new Date(now.getFullYear(), 0, 1, 0, 0, 0, 0);
          endDate = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999);
          break;
        default:
          startDate = new Date(0);
          endDate = new Date();
      }
    }

    console.log(`Fetching earnings by listing from ${startDate.toISOString()} to ${endDate.toISOString()}`);

    const projectsQuery = query(
      collection(db, 'projects'),
      where('freelancerID', '==', freelancerID),
      where('completedAt', '>=', Timestamp.fromDate(startDate)),
      where('completedAt', '<=', Timestamp.fromDate(endDate)),
      where('status', '==', 'completed') // Only completed projects
    );

    const projectsSnapshot = await getDocs(projectsQuery);
    const earningsByListing = {};

    projectsSnapshot.forEach((doc) => {
      const project = doc.data();
      const listingName = project.listingName || 'Unnamed Listing';
      const amountEarned = project.amountEarned || 0;

      if (earningsByListing[listingName]) {
        earningsByListing[listingName] += amountEarned;
      } else {
        earningsByListing[listingName] = amountEarned;
      }
    });

    return earningsByListing;
  } catch (error) {
    console.error('Error fetching earnings by listing:', error);
    return {};
  }
};


// Function to get click-through rate data within a specific time frame
export const getClickThroughRateData = async (freelancerID, timeFrame, subTimeFrame, customRange, selectedQuarter) => {
  let startDate, endDate;

  const convertToDate = (date) => {
    if (date && typeof date.toDate === 'function') {
      return date.toDate();
    }
    return date instanceof Date ? date : new Date(date);
  };

  // Determine the start and end dates based on the time frame
  if (timeFrame === 'quarterly' && selectedQuarter) {
    const now = new Date();
    const quarterStartMonth = (selectedQuarter - 1) * 3;
    startDate = new Date(now.getFullYear(), quarterStartMonth, 1, 0, 0, 0, 0);
    endDate = new Date(now.getFullYear(), quarterStartMonth + 3, 0, 23, 59, 59, 999);
  } else if (!customRange || !customRange.start || !customRange.end) {
    console.error("Error: customRange is undefined or missing start/end date");
    return {
      listings: [],
      clicks: [],
      purchases: [],
    };
  } else {
    const now = new Date();
    const start = convertToDate(customRange.start);
    const end = convertToDate(customRange.end);

    switch (timeFrame) {
      case 'monthly':
        switch (subTimeFrame) {
          case 'currentMonth':
            startDate = new Date(start.getFullYear(), start.getMonth(), 1, 0, 0, 0, 0);
            endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59, 999);
            break;
          case 'previousMonth':
            startDate = new Date(start.getFullYear(), start.getMonth() - 1, 1, 0, 0, 0, 0);
            endDate = new Date(start.getFullYear(), start.getMonth(), 0, 23, 59, 59, 999);
            break;
          case 'customMonth':
            startDate = start;
            endDate = end;
            break;
          default:
            startDate = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0);
            endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
        }
        break;
      case 'yearly':
        startDate = new Date(now.getFullYear(), 0, 1, 0, 0, 0, 0);
        endDate = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999);
        break;
      default:
        startDate = new Date(0);
        endDate = new Date();
    }
  }

  console.log(`Fetching click-through data from ${startDate.toISOString()} to ${endDate.toISOString()}`);

  const clicksQuery = query(
    collection(db, 'clicks'),
    where('freelancerID', '==', freelancerID),
    where('timestamp', ">=", Timestamp.fromDate(startDate)),
    where('timestamp', "<=", Timestamp.fromDate(endDate))
  );
  const clicksSnapshot = await getDocs(clicksQuery);

  const purchasesQuery = query(
    collection(db, 'projects'),
    where('freelancerID', '==', freelancerID),
    where('completedAt', ">=", Timestamp.fromDate(startDate)),
    where('completedAt', "<=", Timestamp.fromDate(endDate)),
    where('status', '==', 'completed')
  );
  const purchasesSnapshot = await getDocs(purchasesQuery);

  let clicksByListing = {};
  let purchasesByListing = {};

  clicksSnapshot.forEach((doc) => {
    const data = doc.data();
    const listingID = data.listingID;
    clicksByListing[listingID] = (clicksByListing[listingID] || 0) + 1;
  });

  purchasesSnapshot.forEach((doc) => {
    const data = doc.data();
    const listingID = data.listingID;
    purchasesByListing[listingID] = (purchasesByListing[listingID] || 0) + 1;
  });

  const listings = Object.keys(clicksByListing);
  const clicks = listings.map(listingID => clicksByListing[listingID] || 0);
  const purchases = listings.map(listingID => purchasesByListing[listingID] || 0);

  return {
    listings,
    clicks,
    purchases,
  };
};

