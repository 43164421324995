import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

const ResetPasswordModal = ({ isOpen, onClose }) => {
    const [email, setEmail] = useState('');
    const [resetMessage, setResetMessage] = useState('');
    const [isResettingPassword, setIsResettingPassword] = useState(false);

    const auth = getAuth();

    const handleResetPassword = async () => {
        if (!email) {
            setResetMessage('Please provide a valid email.');
            return;
        }

        setIsResettingPassword(true);
        setResetMessage('');

        try {
            await sendPasswordResetEmail(auth, email);
            setResetMessage('Password reset email sent! Check your inbox.');
        } catch (error) {
            setResetMessage('Failed to send password reset email: ' + error.message);
        } finally {
            setIsResettingPassword(false);
        }
    };

    return (
        isOpen ? (
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="relative bg-gray-800 text-gray-300 rounded-lg shadow-lg max-w-sm w-full p-6">
                    <button 
                        onClick={onClose} 
                        className="absolute top-2 right-2 text-gray-400 hover:text-gray-300 text-xl"
                    >
                        &times;
                    </button>
                    <h2 className="text-lg font-bold mb-4">Reset Your Password</h2>
                    <p className="mb-4">Enter your email address below. We will send you a password reset link.</p>
                    
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-600 rounded-md bg-gray-900 text-gray-300 mb-4"
                    />
                    
                    <button
                        onClick={handleResetPassword}
                        disabled={isResettingPassword}
                        className={`w-full py-2 px-4 text-white font-medium rounded-md ${isResettingPassword ? 'bg-gray-600 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}`}
                    >
                        {isResettingPassword ? 'Sending Email...' : 'Send Password Reset Email'}
                    </button>
                    
                    {resetMessage && (
                        <div className={`mt-4 ${resetMessage.startsWith('Failed') ? 'text-red-400' : 'text-green-400'}`}>
                            {resetMessage}
                        </div>
                    )}
                </div>
            </div>
        ) : null
    );
};

export default ResetPasswordModal;

