import React from 'react';
import PaymentsReadyForDistribution from '../PaymentsReadyForDistribution';
import { Container, Grid } from '@mui/material';

const PaymentDistributionManagement = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 10, mb: 10 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PaymentsReadyForDistribution detailedView={true} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PaymentDistributionManagement;

