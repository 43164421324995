import React, { useState } from 'react';
import { useAuth } from '../../../contexts/authContext';
import { FaRegCopy, FaLink, FaPercentage, FaUserFriends } from 'react-icons/fa';
import './ReferralLink.css';

const ReferralLink = () => {
  const { currentUser } = useAuth();
  const [referralLink, setReferralLink] = useState('');

  const generateReferralLink = () => {
    if (currentUser?.uid) {
      const reversedUid = currentUser.uid.split('').reverse().join('');
      const transformedUid = `ab${reversedUid}yz`;
      const link = `${window.location.origin}/login?ref=${transformedUid}`;
      setReferralLink(link);
    }
  };

  const copyToClipboard = () => {
    if (referralLink) {
      navigator.clipboard.writeText(referralLink)
        .then(() => {
          alert('Link copied to clipboard!');
        })
        .catch((err) => {
          console.error('Could not copy text: ', err);
        });
    }
  };

  return (
    <div className="referral-container">
      <h2 className="text-3xl font-bold text-center mb-8">Referral Program</h2>
      
      <div className="referral-story grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
        <div className="story-card bg-gray-800 p-6 rounded-lg text-center">
          <FaUserFriends className="text-4xl text-green-400 mb-4 mx-auto" />
          <h3 className="text-xl font-semibold mb-2">Invite Your Friends</h3>
          <p className="text-gray-300">Share your unique referral link with friends and invite them to join our platform.</p>
        </div>

        <div className="story-card bg-gray-800 p-6 rounded-lg text-center">
          <FaLink className="text-4xl text-blue-400 mb-4 mx-auto" />
          <h3 className="text-xl font-semibold mb-2">They Use Your Link</h3>
          <p className="text-gray-300">When they sign up using your referral link, they become linked to your account.</p>
        </div>

        <div className="story-card bg-gray-800 p-6 rounded-lg text-center">
          <FaPercentage className="text-4xl text-yellow-400 mb-4 mx-auto" />
          <h3 className="text-xl font-semibold mb-2">Earn Rewards</h3>
          <p className="text-gray-300">You earn 0.5% of every buy or sell your referrals make on our platform, automatically credited to your account.</p>
        </div>
      </div>

      <div className="generate-link-section">
        <h2 className="text-2xl font-bold mb-4">Your Referral Link</h2>
        <button onClick={generateReferralLink} className="generate-link-btn bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
          Generate Referral Link
        </button>
        {referralLink && (
          <div className="referral-link mt-4 bg-gray-700 p-4 rounded-lg flex items-center justify-between">
            <input type="text" value={referralLink} readOnly className="bg-transparent text-white flex-grow outline-none" />
            <button
              onClick={copyToClipboard}
              className="copy-link-btn bg-green-500 text-white py-2 px-4 ml-4 rounded hover:bg-green-600 flex items-center"
            >
              <FaRegCopy className="mr-2" /> Copy Link
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferralLink;

