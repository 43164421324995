import React, { useState } from 'react';

const DeleteMessage = ({ messageId, onDelete, messageText }) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDeleteClick = () => {
    if (confirmDelete) {
      onDelete(messageId);
      setConfirmDelete(false);
    } else {
      setConfirmDelete(true);
    }
  };

  if (messageText === "User has deleted this message") {
    return null; // Don't render the trash can if the message is deleted
  }

  return (
    <div className="delete-message-container">
      {confirmDelete ? (
        <span className="confirm-delete-text">
          Are you sure? <button onClick={handleDeleteClick}>Yes</button>
        </span>
      ) : (
        <button onClick={handleDeleteClick} className="trash-can-icon">🗑️</button>
      )}
    </div>
  );
};

export default DeleteMessage;

