import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../../firebase/firebase';
import { collection, query, where, getDocs, updateDoc } from 'firebase/firestore';

const VerifyEmail = () => {
  const { verifyId } = useParams();
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const q = query(collection(db, 'verifications'), where('hash', '==', verifyId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          querySnapshot.forEach(async (docSnapshot) => {
            const docRef = docSnapshot.ref;
            await updateDoc(docRef, { verified: true });
            setMessage('Email successfully verified!');
          });

          // Optionally update user's emailVerified status here
          setTimeout(() => navigate('/'), 3000); // Redirect after 3 seconds
        } else {
          setMessage('Invalid or expired verification link.');
        }
      } catch (error) {
        console.error('Error verifying email:', error);
        setMessage('An error occurred while verifying the email.');
      }
    };

    verifyEmail();
  }, [verifyId, navigate]);

  return (
    <div className="p-4 mx-auto bg-gray-900 text-white">
      <h2 className="text-2xl font-semibold mb-4">Email Verification</h2>
      <p>{message}</p>
    </div>
  );
};

export default VerifyEmail;

