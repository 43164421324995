import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/authContext';
import CategorySelection from '../CategorySelection';
import DynamicForm from '../DynamicForm';
import Payment from '../payment/PaymentforList';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';

const CreateListing = () => {
  const { currentUser } = useAuth();
  
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [userPaid, setUserPaid] = useState(false);

  useEffect(() => {
    const checkUserPaymentStatus = async () => {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUserPaid(userDoc.data().paidFor || false);
        }
      }
    };

    checkUserPaymentStatus();
  }, [currentUser]);

  const handleCategorySelect = (selectedCategory, selectedSubcategory) => {
    setCategory(selectedCategory);
    setSubcategory(selectedSubcategory);
  };

  const handlePaymentSuccess = () => {
    setPaymentSuccessful(true);
  };

  const handlePaymentFailure = (error) => {
    console.error('Payment failed:', error);
    // Additional logic to handle payment failure, e.g., displaying an error message
  };

  return (
    <div className="w-full h-full p-6 bg-gray-900 text-gray-200">
      <div className="max-w-screen-xl mx-auto">
        {userPaid ? (
          !category ? (
            <CategorySelection onCategorySelect={handleCategorySelect} />
          ) : (
            <DynamicForm
              category={category}
              subcategory={subcategory}
              onCancel={() => {
                setCategory('');
                setSubcategory('');
              }}
            />
          )
        ) : (
          !paymentSuccessful ? (
            <Payment
              onPaymentSuccess={handlePaymentSuccess}
              onPaymentFailure={handlePaymentFailure} // Passing the failure handler
              currentUser={currentUser}
            />
          ) : (
            <CategorySelection onCategorySelect={handleCategorySelect} />
          )
        )}
      </div>
    </div>
  );
};

export default CreateListing;

