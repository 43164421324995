import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { getTotalMoneyMade } from './utils/analyticsFunctions';

const EarningsOverview = ({ freelancerID, timeFrame, subTimeFrame, customRange, selectedQuarter }) => {
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [previousEarnings, setPreviousEarnings] = useState(0);
  const [percentageChange, setPercentageChange] = useState(0);
  const [dateDisplay, setDateDisplay] = useState('');
  const [changeIndicator, setChangeIndicator] = useState(null);

  useEffect(() => {
    const fetchEarnings = async () => {
      console.log("Fetching total earnings with customRange:", customRange);
      const earnings = await getTotalMoneyMade(freelancerID, timeFrame, subTimeFrame, customRange, selectedQuarter);
      setTotalEarnings(earnings);

      // Set up previous date range
      let previousRange = null;
      if (timeFrame === 'monthly') {
        const now = new Date();
        let previousMonthStart, previousMonthEnd;

        if (subTimeFrame === 'currentMonth') {
          previousMonthStart = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          previousMonthEnd = new Date(now.getFullYear(), now.getMonth(), 0);
        } else if (subTimeFrame === 'previousMonth') {
          previousMonthStart = new Date(now.getFullYear(), now.getMonth() - 2, 1);
          previousMonthEnd = new Date(now.getFullYear(), now.getMonth() - 1, 0);
        } else if (subTimeFrame === 'customMonth') {
          const customMonth = dayjs(customRange.start);
          previousMonthStart = customMonth.subtract(1, 'month').startOf('month').toDate();
          previousMonthEnd = customMonth.subtract(1, 'month').endOf('month').toDate();
        }

        previousRange = {
          start: previousMonthStart,
          end: previousMonthEnd,
        };
      } else if (timeFrame === 'quarterly' && selectedQuarter) {
        const quarterStartMonth = (selectedQuarter - 1) * 3;
        previousRange = {
          start: dayjs().month(quarterStartMonth - 3).startOf('month').toDate(),
          end: dayjs().month(quarterStartMonth - 1).endOf('month').toDate(),
        };
      } else if (timeFrame === 'yearly') {
        previousRange = {
          start: dayjs().subtract(1, 'year').startOf('year').toDate(),
          end: dayjs().subtract(1, 'year').endOf('year').toDate(),
        };
      }

      // Fetch previous earnings
      if (previousRange) {
        const prevEarnings = await getTotalMoneyMade(freelancerID, timeFrame, 'customMonth', previousRange, selectedQuarter);
        setPreviousEarnings(prevEarnings);

        if (prevEarnings === 0) {
          if (earnings > 0) {
            setChangeIndicator('▲');
            setPercentageChange(100); // 100% increase from 0 to a positive number
          } else {
            setChangeIndicator(null);
            setPercentageChange(0); // No change if both are 0
          }
        } else {
          const percentage = ((earnings - prevEarnings) / prevEarnings) * 100;
          setPercentageChange(percentage.toFixed(2));

          if (percentage > 0) {
            setChangeIndicator('▲');
          } else if (percentage < 0) {
            setChangeIndicator('▼');
          } else {
            setChangeIndicator(null);
          }
        }
      }
    };

    const formatDateDisplay = () => {
      if (timeFrame === 'monthly') {
        if (subTimeFrame === 'currentMonth') {
          setDateDisplay(dayjs().format('MMMM YYYY')); // Current month name and year
        } else if (subTimeFrame === 'customMonth') {
          setDateDisplay(customRange.start ? dayjs(customRange.start).format('MMMM YYYY') : 'Custom Month');
        } else if (subTimeFrame === 'previousMonth') {
          setDateDisplay(dayjs().subtract(1, 'month').format('MMMM YYYY')); // Previous month name and year
        }
      } else if (timeFrame === 'quarterly') {
        if (selectedQuarter && customRange.start && customRange.end) {
          const startMonth = dayjs(customRange.start).format('MMM');
          const endMonth = dayjs(customRange.end).format('MMM YYYY');
          setDateDisplay(`Q${selectedQuarter} (${startMonth} - ${endMonth})`);
        }
      } else if (timeFrame === 'yearly') {
        setDateDisplay(dayjs().format('YYYY')); // Current year
      } else if (timeFrame === 'custom') {
        if (customRange.start && customRange.end) {
          const startDate = dayjs(customRange.start).format('MMM D, YYYY');
          const endDate = dayjs(customRange.end).format('MMM D, YYYY');
          setDateDisplay(`${startDate} - ${endDate}`);
        }
      }
    };

    fetchEarnings();
    formatDateDisplay(); // Call the function to set the date display text
  }, [freelancerID, timeFrame, subTimeFrame, customRange, selectedQuarter]);

  return (
    <div className="earnings-overview">
      <p>{totalEarnings.toFixed(2)} SOL earned {dateDisplay}</p> {/* Display formatted date */}
      <p style={{ color: percentageChange > 0 ? 'green' : percentageChange < 0 ? 'red' : 'gray' }}>
        {changeIndicator} {percentageChange}%
      </p>
    </div>
  );
};

export default EarningsOverview;

