import React from 'react';

const ListingExplanationModal = ({ isOpen, onRequestClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gradient-to-b from-gray-700 via-gray-800 to-gray-900 p-6 rounded-lg max-w-lg w-full mx-4">
        <h2 className="text-2xl font-semibold text-yellow-400 mb-4">Listing Your Service on Hustlez</h2>
        <p className="mb-4 text-gray-300">
          By listing your service on Hustlez, you agree to the following terms:
        </p>
        <ul className="mb-4 text-gray-300 space-y-2 list-disc list-inside">
          <li>
            <span className="font-semibold">Earnings:</span> You will earn 
            <span className="text-yellow-400"> 90% </span> 
            of the total payment for each completed order as a normal user. If you're a premium user, you'll earn 
            <span className="text-yellow-400"> 92.5% </span> 
            of the total payment.
          </li>
          <li>
            <span className="font-semibold">Payment Lock:</span> The funds will be securely locked until the project is completed and both parties are satisfied. 
            Hustlez ensures a fair resolution in case of disputes.
          </li>
          <li>
            <span className="font-semibold">Refund Policy:</span> If the order cannot be fulfilled within the agreed timeframe, or if a dispute remains unresolved, 
            the buyer will receive a full refund (excluding transaction fees).
          </li>
        </ul>
        <p className="mb-4 text-gray-300">
          Make sure you fulfill your service commitments to avoid any issues. Your reputation on Hustlez depends on timely and quality service delivery.
        </p>
        <div className="flex justify-end">
          <button
            onClick={onRequestClose}
            className="px-4 py-2 hustlesmall-button text-white rounded-lg mr-2 hover:bg-gray-700 transition duration-300"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 hustlesmall-button text-white rounded-lg hover:bg-blue-700 transition duration-300"
          >
            Confirm and List Service
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListingExplanationModal;

