import React, { useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../../firebase/firebase';
import { doc, updateDoc, arrayUnion, collection, addDoc, query, where, getDocs, getDoc, Timestamp } from 'firebase/firestore';
import { useNotification } from '../../../contexts/notificationContext';
import { useAuth } from '../../../contexts/authContext';
import FreelanceModal from './FreelanceModal';
import {
  Box,
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  LinearProgress,
} from '@mui/material';

const FreelancerUpload = ({ projectID, onUpload }) => {
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadType, setUploadType] = useState('');
  const [showPrompt, setShowPrompt] = useState(false);
  const [showFinalPrompt, setShowFinalPrompt] = useState(false);
  const { addNotification } = useNotification();
  const { currentUser } = useAuth();
  const [freelancerUsername, setFreelancerUsername] = useState('');
  const [clientUsername, setClientUsername] = useState('');
  const [currentStep, setCurrentStep] = useState(2); // Default to 2 because the initial milestone is step 1

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        setFreelancerUsername(userDoc.data().username || 'Unknown User');
      } else {
        setFreelancerUsername('Unknown User');
      }
    };

    const fetchClientUsername = async () => {
      const projectsQuery = query(collection(db, 'projects'), where('projectID', '==', projectID));
      const projectsSnapshot = await getDocs(projectsQuery);
      if (!projectsSnapshot.empty) {
        const projectDoc = projectsSnapshot.docs[0];
        const projectData = projectDoc.data();
        const clientRef = doc(db, 'users', projectData.clientID);
        const clientDoc = await getDoc(clientRef);
        if (clientDoc.exists()) {
          setClientUsername(clientDoc.data().username || 'Unknown User');
        } else {
          setClientUsername('Unknown User');
        }
      } else {
        console.error('Project document does not exist');
      }
    };

    const fetchUploadedFiles = async () => {
      const milestonesQuery = query(collection(db, 'milestones'), where('project_id', '==', projectID));
      const milestonesSnapshot = await getDocs(milestonesQuery);
      if (!milestonesSnapshot.empty) {
        const milestoneDoc = milestonesSnapshot.docs[0];
        const milestoneData = milestoneDoc.data();

        // Calculate the next step number based on existing updates
        if (milestoneData.updates && milestoneData.updates.length > 0) {
          setCurrentStep(Math.max(...milestoneData.updates.map(update => update.step || 1)) + 1);
        } else {
          setCurrentStep(2); // Ensure it's set to 2 if no updates exist, after the initial milestone
        }

        setUploadedFiles(milestoneData.updates || []);
      } else {
        console.error('Milestone document does not exist');
      }
    };

    fetchUserDetails();
    fetchClientUsername();
    fetchUploadedFiles();
  }, [projectID, currentUser.uid]);

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleRemoveFile = (fileName) => {
    setFiles(files.filter((file) => file.name !== fileName));
  };

  const handleUploadTypeChange = (e) => {
    setUploadType(e.target.value);
  };

  const handleUpload = async () => {
    if (files.length === 0) return;
    if (!uploadType) {
      alert('Please select the version type.');
      return;
    }

    if (uploadType === 'Draft' && uploadedFiles.length > 0) {
      setShowPrompt(true);
      return;
    }

    await uploadFiles(uploadType);
  };

  const uploadFiles = async (type) => {
    const timestamp = Timestamp.now();

    // Fetch milestone document for the project
    const milestonesQuery = query(collection(db, 'milestones'), where('project_id', '==', projectID));
    const milestonesSnapshot = await getDocs(milestonesQuery);
    let milestoneRef;
    if (!milestonesSnapshot.empty) {
      milestoneRef = doc(db, 'milestones', milestonesSnapshot.docs[0].id);
    } else {
      milestoneRef = await addDoc(collection(db, 'milestones'), {
        project_id: projectID,
        step: 1,
        updates: [],
      });
    }

    const projectsQuery = query(collection(db, 'projects'), where('projectID', '==', projectID));
    const projectsSnapshot = await getDocs(projectsQuery);
    if (!projectsSnapshot.empty) {
      const projectDoc = projectsSnapshot.docs[0];
      const projectRef = doc(db, 'projects', projectDoc.id);
      await updateDoc(projectRef, {
        status: 'awaiting_client_review',
      });
    } else {
      console.error('Project document does not exist');
      addNotification({ message: 'Project document does not exist', type: 'error' });
      return;
    }

    await Promise.all(
      files.map(async (file) => {
        const storageRef = ref(storage, `projects/${projectID}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progressPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress((prevProgress) => ({ ...prevProgress, [file.name]: progressPercent }));
          },
          (error) => {
            console.error('Upload failed:', error);
            addNotification({ message: 'Failed to upload work', type: 'error' });
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

              await updateDoc(milestoneRef, {
                updates: arrayUnion({
                  name: file.name,
                  url: downloadURL,
                  type: type || 'unknown',
                  createdAt: timestamp,
                  version: type,
                  title: `Work Uploaded - ${type}`,
                  status: 'submitted',
                  step: currentStep, // Dynamically assign step
                }),
              });

              setUploadedFiles((prevFiles) => [
                ...prevFiles,
                { name: file.name, url: downloadURL, type: type, createdAt: timestamp },
              ]);

              setCurrentStep((prevStep) => prevStep + 1); // Increment step after successful upload

              console.log('File uploaded successfully:', file.name);
            } catch (error) {
              console.error('Error updating milestone document:', error);
              addNotification({ message: 'Failed to upload work', type: 'error' });
            }
          }
        );
      })
    );

    addNotification({ message: 'Work uploaded successfully', type: 'success' });
    setShowFinalPrompt(false);
    setShowPrompt(false);
    if (onUpload) onUpload();
  };

  const handleContinueDraft = () => {
    setShowPrompt(false);
    setShowFinalPrompt(true);
  };

  const handleUpdateToRevision = async () => {
    setUploadType('Revision');
    setShowPrompt(false);
    await uploadFiles('Revision');
  };

  const handleCancelUpload = () => {
    setShowPrompt(false);
    setShowFinalPrompt(false);
  };

  const handleConfirmFinalPrompt = async () => {
    setShowFinalPrompt(false);
    await uploadFiles(uploadType);
  };

  return (
    <Box
      sx={{
        marginTop: '20px',
        padding: '1rem',
        backgroundColor: '#1f2937',
        borderRadius: '10px',
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: '10px', color: '#e5e7eb' }}>
        Upload Work
      </Typography>
      <Button
        variant="contained"
        component="label"
        sx={{
          backgroundColor: '#2d3748',
          color: '#fff',
          marginBottom: '10px',
          textTransform: 'none',
          width: '100%',
          padding: '10px',
        }}
      >
        Choose Files
        <input type="file" hidden multiple onChange={handleFileChange} />
        {files.length > 0 && (
          <Typography variant="caption" sx={{ marginLeft: '10px' }}>
            {files.length} {files.length > 1 ? 'files' : 'file'}
          </Typography>
        )}
      </Button>
      <Typography variant="body1" sx={{ marginTop: '10px', color: '#e5e7eb' }}>
        Version
      </Typography>
      <Select
        value={uploadType}
        onChange={handleUploadTypeChange}
        fullWidth
        displayEmpty
        sx={{
          marginBottom: '20px',
          backgroundColor: '#2d3748',
          color: '#fff',
          borderRadius: '5px',
        }}
      >
        <MenuItem value="" disabled>
          Select version type
        </MenuItem>
        <MenuItem value="Draft">Draft</MenuItem>
        <MenuItem value="Revision">Revision</MenuItem>
        <MenuItem value="Final Version">Final Version</MenuItem>
      </Select>
      {files.length > 0 && (
        <Box sx={{ marginTop: '10px' }}>
          {files.map((file) => (
            <Box key={file.name} sx={{ marginBottom: '10px' }}>
              <Typography variant="body2" sx={{ color: '#fff' }}>
                {file.name}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={progress[file.name] || 0}
                sx={{
                  height: '10px',
                  borderRadius: '5px',
                  backgroundColor: '#444',
                  marginBottom: '10px',
                }}
              />
              <Button
                onClick={() => handleRemoveFile(file.name)}
                sx={{
                  backgroundColor: '#f44336',
                  color: '#fff',
                  textTransform: 'none',
                  padding: '5px 10px',
                  fontSize: '0.875rem',
                }}
              >
                Remove
              </Button>
            </Box>
          ))}
        </Box>
      )}
      <Button
        onClick={handleUpload}
        variant="contained"
        fullWidth
        sx={{
          backgroundColor: '#4caf50',
          color: '#fff',
          textTransform: 'none',
          padding: '12px',
          fontSize: '1rem',
        }}
      >
        Upload
      </Button>

      <FreelanceModal
        isOpen={showPrompt}
        onRequestClose={handleCancelUpload}
        contentLabel="Draft Upload Confirmation"
      >
        <Typography variant="body1" sx={{ marginBottom: '10px', color: '#ddd' }}>
          You have selected draft, are you sure this is correct? If you have provided revisions based on client request, please select "Revision" as version type.
        </Typography>
        <Button
          onClick={handleContinueDraft}
          sx={{ marginRight: '10px', backgroundColor: '#4caf50', color: '#fff', textTransform: 'none' }}
        >
          Continue uploading as draft
        </Button>
        <Button
          onClick={handleUpdateToRevision}
          sx={{ marginRight: '10px', backgroundColor: '#f44336', color: '#fff', textTransform: 'none' }}
        >
          Update to Revision
        </Button>
        <Button onClick={handleCancelUpload} sx={{ backgroundColor: '#444', color: '#fff', textTransform: 'none' }}>
          Cancel
        </Button>
      </FreelanceModal>

      <FreelanceModal
        isOpen={showFinalPrompt}
        onRequestClose={handleCancelUpload}
        contentLabel="Final Confirmation"
      >
        <Typography variant="body1" sx={{ marginBottom: '10px', color: '#ddd' }}>
          Are you sure you want to upload another draft?
        </Typography>
        <Button
          onClick={handleConfirmFinalPrompt}
          sx={{ marginRight: '10px', backgroundColor: '#4caf50', color: '#fff', textTransform: 'none' }}
        >
          Yes
        </Button>
        <Button onClick={handleCancelUpload} sx={{ backgroundColor: '#f44336', color: '#fff', textTransform: 'none' }}>
          No
        </Button>
      </FreelanceModal>

      <Box className="uploaded-files" sx={{ marginTop: '20px' }}>
        <Typography variant="h6" sx={{ marginBottom: '10px', color: '#fff' }}>
          Uploaded Files
        </Typography>
        <TableContainer component={Paper} sx={{ backgroundColor: '#2d3748', borderRadius: '8px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#4a5568', color: '#e5e7eb' }}>Uploaded Files</TableCell>
                <TableCell sx={{ backgroundColor: '#4a5568', color: '#e5e7eb' }}>Version</TableCell>
                <TableCell sx={{ backgroundColor: '#4a5568', color: '#e5e7eb' }}>Timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uploadedFiles.map((file, index) => (
                <TableRow key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#2d3748' }, '&:nth-of-type(even)': { backgroundColor: '#2a2a2a' } }}>
                  <TableCell sx={{ color: '#4caf50' }}>
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#4caf50', textDecoration: 'none' }}
                    >
                      {file.name}
                    </a>
                  </TableCell>
                  <TableCell sx={{ color: '#fff' }}>{file.type}</TableCell>
                  <TableCell sx={{ color: '#fff' }}>
                    {file.createdAt
                      ? file.createdAt instanceof Timestamp
                        ? new Date(file.createdAt.seconds * 1000).toLocaleString()
                        : file.createdAt.toLocaleString()
                      : 'N/A'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default FreelancerUpload;

