import React from 'react';
import './contactus.css'; // Create a CSS file for specific styles if needed

const ContactUs = () => {
  return (
    <div className="contact-us">
      <h1>Contact Us</h1>
      <p>Feel free to reach out to us at contact@hustlez.com or through our social media channels.</p>
    </div>
  );
};

export default ContactUs;

