import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Link } from '@mui/material';
import dayjs from 'dayjs';

const DisputeTakeover = () => {
  const [takeoverDisputes, setTakeoverDisputes] = useState([]);

  useEffect(() => {
    const fetchTakeoverDisputes = async () => {
      try {
        const now = dayjs();
        const cutoffDate = now.subtract(48, 'hour').toDate();

        const disputesQuery = query(
          collection(db, 'disputes'),
          where('disputeStatus', '!=', 'closed'),
          orderBy('disputeOpenedAt', 'desc')
        );

        const disputesSnapshot = await getDocs(disputesQuery);
        const disputesList = disputesSnapshot.docs
          .filter(doc => {
            const data = doc.data();
            const lastResponse = data.responses?.[data.responses.length - 1];
            const lastResponseTime = lastResponse ? lastResponse.respondedAt.toDate() : null;

            const disputeOpenedAt = data.disputeOpenedAt ? data.disputeOpenedAt.toDate() : null;

            // Include disputes with no response after 48 hours or responses older than 48 hours
            return (!lastResponseTime && disputeOpenedAt <= cutoffDate) || (lastResponseTime && lastResponseTime <= cutoffDate);
          })
          .map(doc => {
            const data = doc.data();
            const lastResponse = data.responses?.[data.responses.length - 1];
            const lastResponseAt = lastResponse ? lastResponse.respondedAt.toDate().toLocaleDateString() : 'No Response';

            return {
              disputeNumber: data.disputeNumber || 'Unknown ID',
              disputeOpenedAt: data.disputeOpenedAt ? data.disputeOpenedAt.toDate().toLocaleDateString() : 'N/A',
              lastResponseAt,
              status: data.disputeStatus || 'Unknown'
            };
          });

        setTakeoverDisputes(disputesList);
      } catch (error) {
        console.error("Error fetching disputes for takeover:", error);
      }
    };

    fetchTakeoverDisputes();
  }, []);

  return (
    <Paper elevation={3} sx={{ padding: 2, width: '100%', maxWidth: '1000px', margin: '0 auto' }}>
      <Box mb={2}>
        <Typography variant="h6">
          Dispute Takeover
        </Typography>
      </Box>
      <TableContainer sx={{ maxHeight: '300px', overflowY: 'auto' }}>
        <Table aria-label="dispute takeover table">
          <TableHead>
            <TableRow>
              <TableCell>Dispute ID</TableCell>
              <TableCell align="center">Opened On</TableCell>
              <TableCell align="center">Last Response</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {takeoverDisputes.map((dispute, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <Link href={`/dispute/${dispute.disputeNumber}`} underline="hover">
                    {dispute.disputeNumber}
                  </Link>
                </TableCell>
                <TableCell align="center">{dispute.disputeOpenedAt}</TableCell>
                <TableCell align="center">{dispute.lastResponseAt}</TableCell>
                <TableCell align="center">{dispute.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default DisputeTakeover;

