import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../../../../firebase/firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { FaFire } from 'react-icons/fa';
import { useAuth } from '../../../../contexts/authContext';
import './ListingCard.css'; // Import the custom CSS file

const ListingCard = ({ listing, openModal, modalImage, isModalOpen, closeModal, openUserProfile }) => {
  const [userName, setUserName] = useState(listing.uid);
  const [averageRating, setAverageRating] = useState(null);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'users', listing.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserName(userData.username || listing.uid);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const fetchAverageRating = async () => {
      try {
        const reviewsQuery = query(
          collection(db, 'reviews'),
          where('listingId', '==', listing.id)
        );
        const reviewsSnapshot = await getDocs(reviewsQuery);
        const reviews = reviewsSnapshot.docs.map((doc) => doc.data());
        if (reviews.length > 0) {
          const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
          const avgRating = totalRating / reviews.length;
          setAverageRating(avgRating);
        } else {
          setAverageRating(null);
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchUserName();
    fetchAverageRating();
  }, [listing.uid, listing.id]);

  const isTrending = listing.trending && listing.trendingDate && (new Date() - listing.trendingDate.toDate() <= 24 * 60 * 60 * 1000);

  const handleProtectedLinkClick = (e, path) => {
    if (!currentUser) {
      e.preventDefault(); // Prevent default link behavior
      navigate('/login'); // Redirect to login page
    } else {
      navigate(path);
    }
  };

  const handleGoToListingClick = (e, path) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
    handleProtectedLinkClick(e, path);
  };

  const displayPrice = listing.standardPackage ? listing.standardPackage.price : listing.price;

  return (
    <div className="listing-card-container">
      <div className="listing-card-image">
        {listing.images && listing.images.length > 0 && (
          <img
            src={listing.images[0]}
            alt={listing.name}
            className="listing-card-img"
            loading="lazy"
            onClick={(e) => handleGoToListingClick(e, `/listing/${listing.id}`)} // Navigate to listing on image click
          />
        )}
        {isTrending && (
          <div className="listing-card-trending-icon">
            <FaFire className="text-yellow-500 animate-pulse" title="Trending" size={24} />
          </div>
        )}
        <div className="listing-card-info">
          <Link
            to={`/listing/${listing.id}`}
            className="listing-card-title"
            onClick={(e) => handleProtectedLinkClick(e, `/listing/${listing.id}`)}
          >
            {listing.name}
          </Link>
          <Link
            to={`/profile/${listing.uid}`}
            className="listing-card-username"
            onClick={(e) => handleProtectedLinkClick(e, `/profile/${listing.uid}`)}
          >
            {userName}
          </Link>
          <p className="listing-card-description">
            {listing.description.length > 60 ? `${listing.description.slice(0, 60)}...` : listing.description}
          </p>
          <p className="listing-card-price">Price: {displayPrice} SOL</p>
          {averageRating !== null && averageRating > 0 && (
            <p className="listing-card-rating">Rating: {averageRating.toFixed(1)} / 5</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListingCard;

