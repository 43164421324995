import React, { useState } from 'react';

const FAQ = ({ formState, handleFaqChange, addFaq }) => {
  const [faqList, setFaqList] = useState(formState.faqs);
  const [isFaqVisible, setIsFaqVisible] = useState(faqList.length > 0);

  const handleAddFaqClick = () => {
    const newFaqs = [...faqList, { question: '', answer: '' }];
    setFaqList(newFaqs);
    addFaq(); // Ensure this adds a new FAQ in the parent component
    setIsFaqVisible(true);
  };

  const handleFaqChangeLocal = (e, index, field) => {
    const updatedFaqs = [...faqList];
    updatedFaqs[index][field] = e.target.value;
    setFaqList(updatedFaqs);
    handleFaqChange(e, index, field); // Update parent state
  };

  const handleRemoveFaq = (index) => {
    const updatedFaqs = faqList.filter((_, i) => i !== index);
    setFaqList(updatedFaqs);
    handleFaqChange({ target: { value: updatedFaqs } }, 0, 'faqs'); // Trigger change event to update parent state
    if (updatedFaqs.length === 0) {
      setIsFaqVisible(false); // Hide FAQ section if no FAQs left
    }
  };

  return (
    <div className="space-y-4">
      {isFaqVisible && faqList.map((faq, index) => (
        <div key={index} className="mb-4 space-y-4 flex items-start">
          <div className="flex-1">
            <input
              type="text"
              value={faq.question}
              onChange={(e) => handleFaqChangeLocal(e, index, 'question')}
              placeholder="Question"
              className="input-field px-4 py-3 text-gray-300 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <textarea
              value={faq.answer}
              onChange={(e) => handleFaqChangeLocal(e, index, 'answer')}
              placeholder="Answer"
              className="input-field px-4 py-3 text-gray-300 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="3"
            />
          </div>
          <button
            type="button"
            onClick={() => handleRemoveFaq(index)}
            className="ml-4 px-3 py-1 bg-red-500 text-white font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            X
          </button>
        </div>
      ))}
      <button
        type="button"
        onClick={handleAddFaqClick}
        className="hustlesmall-button px-4 py-2 text-white font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
      >
        Add FAQ
      </button>
    </div>
  );
};

export default FAQ;

