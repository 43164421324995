import React from 'react';
import './careers.css'; // If you want to add specific styles

const Careers = () => {
  return (
    <div className="careers-container">
      <h1>Careers</h1>
      <p>Join our team at Hustlez...</p>
      {/* Add more content here */}
    </div>
  );
};

export default Careers;

