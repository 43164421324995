// src/components/adminDashboard/AdminSearchBar.jsx
import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AdminSearchBar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = () => {
    // Navigate based on the search query, assuming you have routes set up for each entity
    if (searchQuery.startsWith('ord-')) {
      navigate(`/order-tracking/${searchQuery}`);
    } else if (searchQuery.startsWith('dis-')) {
      navigate(`/dispute/${searchQuery}`);
    } else {
      navigate(`/user/${searchQuery}`);
    }
  };

  return (
    <Box 
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#1c2330', // Darker background
        padding: '8px',             // Some padding around the input and button
        borderRadius: '8px',        // Rounded corners
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // Light shadow
      }}
    >
      <TextField
        variant="outlined"
        placeholder="Search by Order ID, Dispute ID, or Wallet Address"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        fullWidth
        sx={{
          input: {
            color: '#ffffff' // White text
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#3a466e', // Border color
            },
            '&:hover fieldset': {
              borderColor: '#5671f5', // Border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: '#5671f5', // Border color when focused
            },
          },
        }}
      />
      <Button
        variant="contained"
        onClick={handleSearch}
        sx={{
          ml: 2, // Margin-left to add space between input and button
          backgroundColor: '#5671f5', // Button background color
          color: '#fff',              // Button text color
          '&:hover': {
            backgroundColor: '#455cdb', // Darker blue on hover
          },
        }}
      >
        SEARCH
      </Button>
    </Box>
  );
};

export default AdminSearchBar;

