import React from 'react';

const Requirements = ({ formState, handleInputChange, handleAddRequirement, handleRequirementChange, handleRemoveRequirement }) => (
  <div className="space-y-4">
    <label className="text-sm text-gray-400 font-semibold">Requirements from Buyer</label>
    {formState.requirements.map((requirement, index) => (
      <div key={index} className="flex space-x-2">
        <textarea
          name={`requirement-${index}`}
          value={requirement}
          onChange={(e) => handleRequirementChange(e, index)}
          placeholder="Specify what you need from the buyer to get started, e.g., logo, color scheme, content, etc."
          required
          className="w-full mt-1 px-4 py-3 text-gray-300 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          style={{
            background: 'linear-gradient(135deg, #2d3748, #4a5568)',
          }}
          rows="3"
        />
        <button type="button" onClick={() => handleRemoveRequirement(index)} className="text-red-500">
          Remove
        </button>
      </div>
    ))}
    <button type="button" onClick={handleAddRequirement} className="text-blue-500">
      Add Requirement
    </button>
  </div>
);

export default Requirements;

