import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';

const Milestone = ({ milestone, currentUser }) => {
  const [updates, setUpdates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newUpdate, setNewUpdate] = useState('');

  useEffect(() => {
    const fetchUpdates = async () => {
      const updatesQuery = query(collection(db, 'updates'), where('milestone_id', '==', milestone.id));
      const updatesSnapshot = await getDocs(updatesQuery);
      const updatesData = updatesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUpdates(updatesData);
      setLoading(false);
    };

    fetchUpdates();
  }, [milestone.id]);

  const handleAddUpdate = async () => {
    if (!newUpdate.trim()) return;

    try {
      const updateData = {
        milestone_id: milestone.id,
        content: newUpdate,
        createdAt: new Date()
      };
      await addDoc(collection(db, 'updates'), updateData);
      setUpdates([...updates, updateData]);
      setNewUpdate('');
    } catch (error) {
      console.error('Error adding update:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h3>{milestone.title}</h3>
      <p>{milestone.description}</p>
      <p>Status: {milestone.status}</p>
      <p>Due Date: {milestone.due_date.toDate().toLocaleString()}</p>

      <h4>Updates</h4>
      {updates.length > 0 ? (
        updates.map((update) => (
          <div key={update.id}>
            <p>{update.content}</p>
            <p>Updated on: {update.createdAt.toDate().toLocaleString()}</p>
          </div>
        ))
      ) : (
        <p>No updates available</p>
      )}

      {currentUser && (
        <div>
          <textarea
            value={newUpdate}
            onChange={(e) => setNewUpdate(e.target.value)}
            placeholder="Add a new update"
          />
          <button onClick={handleAddUpdate}>Add Update</button>
        </div>
      )}
    </div>
  );
};

export default Milestone;

