import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase/firebase'; // Adjust the import path as necessary
import { doc, getDoc } from 'firebase/firestore';

const PortfolioGallery = () => {
  const { userId } = useParams(); // Extract userId from URL parameters
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedImage, setSelectedImage] = useState(null); // State for the selected image

  useEffect(() => {
    const fetchPortfolioItems = async () => {
      try {
        if (!userId) {
          throw new Error('User ID is undefined');
        }
        const userDocRef = doc(db, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const portfolio = userData.portfolio || [];
          setPortfolioItems(portfolio);
        } else {
          throw new Error('User not found');
        }
      } catch (err) {
        setError(`Failed to fetch portfolio items: ${err.message}`);
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPortfolioItems();
  }, [userId]);

  const openModal = (url) => {
    setSelectedImage(url);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  if (loading) return <div className="text-gray-400">Loading portfolio...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="mt-4">
      {portfolioItems.length === 0 ? (
        <p className="text-gray-500">No portfolio items available</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {portfolioItems.map((item, index) => (
            <div key={index} className="bg-gray-800 p-4 border border-gray-700 rounded-lg shadow-lg">
              <h3 className="text-lg font-semibold mb-2 text-gray-100">Customer: {item.customerName || 'N/A'}</h3>
              <p className="text-gray-300 mb-2">Description: {item.description || 'N/A'}</p>
              <div className="flex flex-wrap gap-2">
                {item.urls && item.urls.map((url, idx) => (
                  <img
                    key={idx}
                    src={url}
                    alt={`Portfolio item ${idx + 1}`}
                    className="w-24 h-24 object-cover cursor-pointer rounded-lg hover:opacity-80 transition-opacity duration-300"
                    onClick={() => openModal(url)}
                  />
                ))}
              </div>
              <p className="text-gray-500 mt-2 text-sm">
                Posted on: {item.createdAt?.seconds ? new Date(item.createdAt.seconds * 1000).toLocaleDateString() : 'Unknown'}
              </p>
            </div>
          ))}
        </div>
      )}

      {/* Modal for enlarged image */}
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div
            className="relative bg-gray-900 p-4 rounded-lg"
            onClick={e => e.stopPropagation()}
            style={{ maxWidth: '90%', maxHeight: '90%' }}
          >
            <button
              className="absolute top-2 right-2 bg-gray-600 text-white p-2 rounded-full"
              onClick={closeModal}
            >
              &times;
            </button>
            <img
              src={selectedImage}
              alt="Enlarged preview"
              className="max-w-full max-h-screen object-contain"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PortfolioGallery;

