import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/authContext';
import { db } from '../../firebase/firebase';
import { doc, updateDoc } from 'firebase/firestore';

const ExperienceSection = ({ userData: initialUserData, onExperienceUpdate }) => {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState(initialUserData);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedExperience, setEditedExperience] = useState(null);
  const [newExperience, setNewExperience] = useState({
    companyName: '',
    jobTitle: '',
    duration: '',
    description: '',
  });
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    setUserData(initialUserData);
  }, [initialUserData]);

  const handleEdit = (index) => {
    if (currentUser?.uid !== userData.uid) return;
    setEditingIndex(index);
    setEditedExperience({ ...userData.professionalExperience[index] });
    setIsAdding(false);
  };

  const handleSave = async () => {
    if (currentUser?.uid !== userData.uid) return;
    let updatedExperience = userData.professionalExperience ? [...userData.professionalExperience] : [];

    try {
      const userRef = doc(db, 'users', currentUser.uid);

      if (isAdding) {
        updatedExperience.push(newExperience);
        await updateDoc(userRef, { professionalExperience: updatedExperience });
        onExperienceUpdate(updatedExperience);
        setNewExperience({ companyName: '', jobTitle: '', duration: '', description: '' });
        setIsAdding(false);
      } else if (editingIndex !== null) {
        updatedExperience[editingIndex] = editedExperience;
        await updateDoc(userRef, { professionalExperience: updatedExperience });
        onExperienceUpdate(updatedExperience);
        setEditingIndex(null);
        setEditedExperience(null);
      }
    } catch (error) {
      console.error('Error updating experience:', error);
    }
  };

  const handleDelete = async (index) => {
    if (currentUser?.uid !== userData.uid) return;
    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const updatedExperience = userData.professionalExperience.filter((_, i) => i !== index);
      await updateDoc(userRef, { professionalExperience: updatedExperience });
      onExperienceUpdate(updatedExperience);
    } catch (error) {
      console.error('Error deleting experience:', error);
    }
  };

  const handleChange = (field, value) => {
    if (isAdding) {
      setNewExperience((prev) => ({ ...prev, [field]: value }));
    } else {
      setEditedExperience((prev) => ({ ...prev, [field]: value }));
    }
  };

  if (currentUser?.uid !== userData?.uid) return null;

  return (
    <div className="mt-4">
      {userData.professionalExperience?.length > 0 && (
        <div className="flex items-center">
          <h2 className="text-xl font-bold mt-4">Professional Experience</h2>
        </div>
      )}

      {isAdding && (
        <div>
          <input
            type="text"
            value={newExperience.companyName}
            onChange={(e) => handleChange('companyName', e.target.value)}
            className="input-field"
            placeholder="Company Name"
          />
          <input
            type="text"
            value={newExperience.jobTitle}
            onChange={(e) => handleChange('jobTitle', e.target.value)}
            className="input-field"
            placeholder="Job Title"
          />
          <input
            type="text"
            value={newExperience.duration}
            onChange={(e) => handleChange('duration', e.target.value)}
            className="input-field"
            placeholder="Duration"
          />
          <textarea
            value={newExperience.description}
            onChange={(e) => handleChange('description', e.target.value)}
            className="textarea-field"
            placeholder="Description"
          />
          <div className="flex justify-between mt-2">
            <button onClick={handleSave} className="hustlesmall-button">Save</button>
            <button onClick={() => setIsAdding(false)} className="hustlesmall-button">Cancel</button>
          </div>
        </div>
      )}

      {userData.professionalExperience?.map((experience, index) => (
        <div key={index} className="mb-4 border-t border-gray-600 pt-2">
          {editingIndex === index ? (
            <div>
              <input
                type="text"
                value={editedExperience.companyName}
                onChange={(e) => handleChange('companyName', e.target.value)}
                className="input-field"
                placeholder="Company Name"
              />
              <input
                type="text"
                value={editedExperience.jobTitle}
                onChange={(e) => handleChange('jobTitle', e.target.value)}
                className="input-field"
                placeholder="Job Title"
              />
              <input
                type="text"
                value={editedExperience.duration}
                onChange={(e) => handleChange('duration', e.target.value)}
                className="input-field"
                placeholder="Duration"
              />
              <textarea
                value={editedExperience.description}
                onChange={(e) => handleChange('description', e.target.value)}
                className="textarea-field"
                placeholder="Description"
              />
              <div className="flex justify-between mt-2">
                <button onClick={handleSave} className="hustlesmall-button">Save</button>
                <button onClick={() => setEditingIndex(null)} className="hustlesmall-button">Cancel</button>
              </div>
            </div>
          ) : (
            <div>
              <h3 className="font-semibold">{experience.jobTitle} at {experience.companyName}</h3>
              <p className="text-gray-400">{experience.duration}</p>
              <p className="text-gray-400">{experience.description}</p>
              <div className="flex justify-between space-x-4 mt-2">
                <button onClick={() => handleEdit(index)} className="hustlesmall-button">Edit</button>
                <button onClick={() => handleDelete(index)} className="hustlesmall-button text-red-600">Delete</button>
              </div>
            </div>
          )}
        </div>
      ))}

      {!isAdding && (
        <div className="flex justify-center">
          <button onClick={() => setIsAdding(true)} className="hustlesmall-button mt-4">Add Experience</button>
        </div>
      )}
    </div>
  );
};

export default ExperienceSection;

