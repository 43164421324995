import React from 'react';
import { Link } from 'react-router-dom';
import { FaTwitter, FaDiscord } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-b from-gray-800 via-gray-900 to-black text-white py-8">
      <div className="max-w-screen-xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-4">
        {/* Get to Know Us Section */}
        <div className="footer-section">
          <h4 className="text-lg font-bold mb-4 text-yellow-400">Get to Know Us</h4>
          <ul>
            <li className="mb-2"><Link to="/about-us" className="hover:text-yellow-400 transition duration-300 ease-in-out">About Us</Link></li>
            <li className="mb-2"><Link to="/referal" className="hover:text-yellow-400 transition duration-300 ease-in-out">Referals</Link></li>
          </ul>
        </div>

        {/* Support Section */}
        <div className="footer-section">
          <h4 className="text-lg font-bold mb-4 text-yellow-400">Support</h4>
          <ul>
            <li className="mb-2"><Link to="/faq" className="hover:text-yellow-400 transition duration-300 ease-in-out">FAQ</Link></li>
            <li className="mb-2"><Link to="/terms-of-service" className="hover:text-yellow-400 transition duration-300 ease-in-out">Terms of Service</Link></li>
            <li className="mb-2"><Link to="/privacy-policy" className="hover:text-yellow-400 transition duration-300 ease-in-out">Privacy Policy</Link></li>
          </ul>
        </div>

        {/* Follow Us Section */}
        <div className="footer-section">
          <h4 className="text-lg font-bold mb-4 text-yellow-400">Follow Us</h4>
          <ul className="flex space-x-6">
            <li>
              <a href="https://twitter.com/hustlezio" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2 hover:text-yellow-400 transition duration-300 ease-in-out">
                <FaTwitter size={20} /> <span>Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://discord.gg/nWNKA6DVaN" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2 hover:text-yellow-400 transition duration-300 ease-in-out">
                <FaDiscord size={20} /> <span>Discord</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

