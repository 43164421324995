import React, { useState } from 'react';
import { Connection, PublicKey, VersionedTransaction, TransactionMessage, ComputeBudgetProgram, SystemProgram } from '@solana/web3.js';
import { db } from '../../../firebase/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { useAuth } from '../../../contexts/authContext';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import LoadingModal from './LoadingModal';
import { v4 as uuidv4 } from 'uuid';
import './Payment.css';

const HELIUS_RPC_URL = `https://mainnet.helius-rpc.com/?api-key=${process.env.REACT_APP_HELIUS_KEY}`;
const connection = new Connection(HELIUS_RPC_URL);
const COMPANY_WALLET_ADDRESS = '368kh8dsySikUfGtbuKMBfwnX2GuhC6iDS5zFbPRnrD3';

const PaymentComponent = ({ amountSOL, paymentDescription, onPaymentSuccess, onPaymentFailure, clientPaymentData }) => {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const { wallet, publicKey, signTransaction, connect } = useWallet();

  const handlePayment = async () => {
    if (loading) return;

    setLoading(true);

    try {
      if (!currentUser || !currentUser.uid) {
        alert('User information is missing. Please log in again.');
        setLoading(false);
        return;
      }

      if (!wallet || !publicKey) {
        throw new WalletNotConnectedError();
      }

      await connect();

      const lamports = amountSOL * 1000000000;

      // Check balance before proceeding
      const balance = await connection.getBalance(publicKey);
      if (balance < lamports) {
        throw new Error('Insufficient funds in the wallet');
      }

      const recentBlockhash = await getRecentBlockhash();
      if (!recentBlockhash) {
        throw new Error('Unable to fetch recent blockhash');
      }

      const instructions = [
        ComputeBudgetProgram.setComputeUnitLimit({ units: 1_400_000 }),
        ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 1 }),
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new PublicKey(COMPANY_WALLET_ADDRESS),
          lamports,
        }),
      ];

      const v0Message = new TransactionMessage({
        instructions: instructions,
        payerKey: publicKey,
        recentBlockhash: recentBlockhash,
      }).compileToV0Message();

      const versionedTransaction = new VersionedTransaction(v0Message);
      const signedTransaction = await signTransaction(versionedTransaction);
      const serializedTransaction = signedTransaction.serialize();

      let signature;
      try {
        signature = await connection.sendRawTransaction(serializedTransaction, {
          skipPreflight: true,
          preflightCommitment: 'finalized'
        });
      } catch (error) {
        console.error('Transaction error:', error);
        if (error.message.includes('Blockhash not found')) {
          console.log('Retrying with a fresh blockhash...');
          const freshBlockhash = await getRecentBlockhash();
          if (freshBlockhash) {
            const newMessage = new TransactionMessage({
              instructions: instructions,
              payerKey: publicKey,
              recentBlockhash: freshBlockhash,
            }).compileToV0Message();
            const newTransaction = new VersionedTransaction(newMessage);
            const newSignedTransaction = await signTransaction(newTransaction);
            const newSerializedTransaction = newSignedTransaction.serialize();
            signature = await connection.sendRawTransaction(newSerializedTransaction, {
              skipPreflight: true,
              preflightCommitment: 'finalized'
            });
          }
        } else {
          throw error;
        }
      }

      onPaymentSuccess(publicKey, signature);

      connection.confirmTransaction(signature, 'finalized')
        .then(() => console.log('Transaction confirmed'))
        .catch((error) => onPaymentFailure(error));

      const paymentData = {
        paymentID: uuidv4(),
        uid: currentUser.uid,
        paymentType: paymentDescription,
        walletAddress: publicKey.toString(),
        timestamp: new Date(),
        amount: lamports,
        signature,
      };

      if (clientPaymentData) {
        paymentData.listingID = clientPaymentData.listingID;
        paymentData.totalPaid = amountSOL;
        paymentData.selectedPackage = clientPaymentData.selectedPackage;
        paymentData.projectID = clientPaymentData.projectID;
      }

      await addDoc(collection(db, 'payments'), paymentData);
      console.log('Payment data saved to Firestore');
    } catch (error) {
      console.error("Payment Error:", error);
      onPaymentFailure(error);
    } finally {
      setLoading(false);
    }
  };

  const getRecentBlockhash = async () => {
    try {
      const { blockhash } = await connection.getLatestBlockhash();
      console.log('Fetched blockhash:', blockhash);
      if (!blockhash) {
        throw new Error('Failed to retrieve recent blockhash.');
      }
      return blockhash;
    } catch (error) {
      console.error('Error fetching recent blockhash:', error);
      alert('Failed to get recent blockhash. Please try again later.');
      return null;
    }
  };

  return (
    <div className="max-w-md mx-auto text-white">
      <button
        type="button"
        onClick={handlePayment}
        disabled={loading}
        className={`paylist-button w-full px-4 py-2 text-white font-medium rounded-lg ${loading ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'} transition duration-300`}
      >
        {loading ? 'Processing...' : `Pay ${amountSOL} SOL`}
      </button>
      {loading && <LoadingModal isVisible={loading} message="Processing payment..." />}
    </div>
  );
};

export default PaymentComponent;

