import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs, updateDoc, arrayUnion } from 'firebase/firestore';
import { db, storage } from '../../firebase/firebase';
import { useAuth } from '../../contexts/authContext';
import { Link } from 'react-router-dom';
import { FaPaperclip } from 'react-icons/fa';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import './DisputePage.css';

const DisputePage = () => {
  const { currentUser } = useAuth();
  const { disputeNumber } = useParams();
  const [dispute, setDispute] = useState(null);
  const [responseText, setResponseText] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const [freelancerUsername, setFreelancerUsername] = useState('');
  const [clientUsername, setClientUsername] = useState('');

  useEffect(() => {
    const fetchDispute = async () => {
      try {
        const disputeQuery = query(
          collection(db, 'disputes'),
          where('disputeNumber', '==', disputeNumber)
        );
        const disputeSnapshot = await getDocs(disputeQuery);

        if (!disputeSnapshot.empty) {
          const disputeDoc = disputeSnapshot.docs[0];
          const disputeData = disputeDoc.data();
          setDispute({ ...disputeData, id: disputeDoc.id });

          const freelancerRef = doc(db, 'users', disputeData.freelancerID);
          const clientRef = doc(db, 'users', disputeData.clientID);
          const freelancerDoc = await getDoc(freelancerRef);
          const clientDoc = await getDoc(clientRef);

          if (freelancerDoc.exists()) {
            setFreelancerUsername(freelancerDoc.data().username || 'Freelancer');
          }

          if (clientDoc.exists()) {
            setClientUsername(clientDoc.data().username || 'Client');
          }
        } else {
          console.error('Dispute not found with this dispute number');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching dispute:', error);
        setLoading(false);
      }
    };

    fetchDispute();
  }, [disputeNumber]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
  };

  const handleRemoveFile = (fileName) => {
    setSelectedFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
  };

  const handleSubmitResponse = async () => {
    if (!responseText.trim() && selectedFiles.length === 0) return;

    try {
      const disputeRef = doc(db, 'disputes', dispute.id);
      const uploadedFiles = await Promise.all(selectedFiles.map(file => uploadFile(file)));

      const newResponse = {
        responder: currentUser.uid,
        responseText: responseText,
        respondedAt: new Date(),
        files: uploadedFiles
      };

      await updateDoc(disputeRef, {
        responses: arrayUnion(newResponse)
      });

      const updatedDisputeDoc = await getDoc(disputeRef);
      if (updatedDisputeDoc.exists()) {
        setDispute(updatedDisputeDoc.data());
      }

      alert('Response submitted successfully');
      setResponseText('');
      setSelectedFiles([]);
    } catch (error) {
      console.error('Error submitting response:', error);
    }
  };

  const handleCloseDispute = async () => {
    try {
      const disputeRef = doc(db, 'disputes', dispute.id);
      const closeResponse = {
        responder: currentUser.uid,
        responseText: `Dispute closed by ${clientUsername}`,
        respondedAt: new Date(),
        files: []
      };

      await updateDoc(disputeRef, {
        disputeStatus: 'closed',
        closedAt: new Date(),
        responses: arrayUnion(closeResponse)
      });

      const updatedDisputeDoc = await getDoc(disputeRef);
      if (updatedDisputeDoc.exists()) {
        setDispute(updatedDisputeDoc.data());
      }

      alert('Dispute closed successfully');
    } catch (error) {
      console.error('Error closing dispute:', error);
    }
  };

  const uploadFile = (file) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `disputes/${disputeNumber}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed',
        (snapshot) => {
          const progressPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(prevProgress => ({ ...prevProgress, [file.name]: progressPercent }));
        },
        (error) => {
          console.error('Upload failed:', error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve({ fileName: file.name, fileUrl: downloadURL });
          } catch (error) {
            console.error('Error getting download URL:', error);
            reject(error);
          }
        }
      );
    });
  };

  if (loading) return <div>Loading...</div>;

  if (!dispute) return <div>Dispute not found.</div>;

  return (
    <div className="dispute-page">
      <div className="dispute-details">
        <h2>Dispute Details</h2>
        <p><strong>Dispute Number:</strong> {dispute.disputeNumber}</p> {/* Display Dispute Number */}
        <p><strong>Project ID:</strong> {dispute.projectID}</p>
        <p><strong>Reason:</strong> {dispute.disputeReason}</p>
        <p><strong>Status:</strong> {dispute.disputeStatus}</p>
        <p><strong>Date Opened:</strong> {new Date(dispute.disputeOpenedAt.seconds * 1000).toLocaleString()}</p>
      </div>

      <div className="response-log">
        <h3>Response Log:</h3>
        {dispute.responses && dispute.responses.length > 0 ? (
          dispute.responses.map((response, index) => (
            <div key={index} className="response-entry">
              <p><strong>Responder:</strong> {response.responder === dispute.freelancerID ? freelancerUsername : clientUsername}</p>
              <p><strong>Response:</strong> {response.responseText}</p>
              <p><strong>Date:</strong> {new Date(response.respondedAt.seconds * 1000).toLocaleString()}</p>
              {response.files && response.files.length > 0 && response.files.map((file, idx) => (
                <p key={idx}>
                  <strong>Attachment:</strong> <a href={file.fileUrl} target="_blank" rel="noopener noreferrer">{file.fileName}</a>
                </p>
              ))}
            </div>
          ))
        ) : (
          <p>No responses yet.</p>
        )}
      </div>

      {dispute.disputeStatus !== 'closed' && (
        <>
          <div className="response-input-container">
            <textarea
              value={responseText}
              onChange={(e) => setResponseText(e.target.value)}
              placeholder="Enter your response..."
              className="response-textarea"
            />
            <div className="file-upload-container">
              <label htmlFor="file-upload" className="file-upload-label">
                <FaPaperclip className="file-upload-icon" />
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                className="file-upload-input"
                multiple
              />
              {selectedFiles.length > 0 && (
                <div className="selected-files">
                  {selectedFiles.map((file, index) => (
                    <div key={index} className="selected-file">
                      {file.name}
                      <button onClick={() => handleRemoveFile(file.name)} className="remove-file-button">x</button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <button onClick={handleSubmitResponse} className="hustlesmall-button">
            Submit Response
          </button>

          {currentUser.uid === dispute.clientID && (
            <button onClick={handleCloseDispute} className="hustlesmall-button close-dispute-button">
              Close Dispute
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default DisputePage;

