import React, { useState, useEffect } from 'react';
import { doc, updateDoc, arrayUnion, collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import { useNotification } from '../../../contexts/notificationContext';
import { useAuth } from '../../../contexts/authContext';

const ApproveOrRequestChanges = ({ projectID, onApprove, onRequestChanges, clientUsername }) => {
  const [action, setAction] = useState('');
  const [changeRequestReason, setChangeRequestReason] = useState('');
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotification();
  const { currentUser } = useAuth();
  const [isClient, setIsClient] = useState(false);
  const [projectStatus, setProjectStatus] = useState('');
  const [milestoneType, setMilestoneType] = useState('');
  const [milestoneDocId, setMilestoneDocId] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [nextStep, setNextStep] = useState(1); // This will be dynamically set

  useEffect(() => {
    const checkClient = async () => {
      if (projectID) {
        const projectsQuery = query(collection(db, 'projects'), where('projectID', '==', projectID));
        const projectsSnapshot = await getDocs(projectsQuery);
        if (!projectsSnapshot.empty) {
          const projectDoc = projectsSnapshot.docs[0];
          const projectData = projectDoc.data();
          setProjectStatus(projectData.status);
          if (projectData.clientID === currentUser.uid) {
            setIsClient(true);
          }
        } else {
          console.error('Project document does not exist');
        }
      } else {
        console.error('Project ID is not defined');
      }
    };

    checkClient();
  }, [projectID, currentUser.uid]);

  const fetchMilestoneTypeAndStep = async () => {
    if (projectID) {
      const milestonesQuery = query(collection(db, 'milestones'), where('project_id', '==', projectID));
      const milestonesSnapshot = await getDocs(milestonesQuery);
      if (!milestonesSnapshot.empty) {
        const milestoneDoc = milestonesSnapshot.docs[0]; // Assuming only one milestone document exists per project
        const milestoneData = milestoneDoc.data();
        
        // Identify the latest milestone and set the milestoneType accordingly
        if (milestoneData.updates && milestoneData.updates.length > 0) {
          const lastUpdate = milestoneData.updates[milestoneData.updates.length - 1];
          setMilestoneType(lastUpdate.version || 'Draft');
        } else {
          setMilestoneType(milestoneData.version || 'Draft'); // Set to Draft if nothing else is present
        }

        setMilestoneDocId(milestoneDoc.id); // Save the document ID for updates

        // Determine the current step based on existing updates
        const maxStep = Math.max(...milestoneData.updates.map(update => update.step || 1), 1);
        setNextStep(maxStep + 1); // Set the next step dynamically
      } else {
        console.error('Milestone document does not exist');
      }
    }
  };

  useEffect(() => {
    fetchMilestoneTypeAndStep();
  }, [projectID]);

  const handleActionChange = (e) => {
    setAction(e.target.value);
  };

  const handleReasonChange = (e) => {
    setChangeRequestReason(e.target.value);
  };

  const handleSubmit = async () => {
    if (submitDisabled) return;

    setLoading(true);
    setSubmitDisabled(true); // Disable submit button after first click
    const timestamp = Timestamp.now();

    try {
      const projectsQuery = query(collection(db, 'projects'), where('projectID', '==', projectID));
      const projectsSnapshot = await getDocs(projectsQuery);
      if (!projectsSnapshot.empty) {
        const projectDoc = projectsSnapshot.docs[0];
        const projectRef = doc(db, 'projects', projectDoc.id);

        if (!milestoneType) {
          await fetchMilestoneTypeAndStep();
        }

        let updateContent = {
          title: '',
          description: '',
          createdAt: timestamp,
          userStatus: '',
          version: milestoneType,
          step: nextStep, // Assign the dynamically determined step number
        };

        if (action === 'approve') {
          if (milestoneType === 'Final Version') {
            await updateDoc(projectRef, {
              status: 'completed',
              completedAt: timestamp,
            });

            updateContent = {
              ...updateContent,
              title: 'Project Completed',
              description: `${clientUsername} has approved the final version of the work. Project is now complete.`,
              userStatus: 'completed',
              version: 'Final Version',
            };
          } else {
            await updateDoc(projectRef, {
              status: 'in_progress',
            });

            updateContent = {
              ...updateContent,
              title: `Client Approved ${milestoneType}`,
              description: `${clientUsername} has approved the ${milestoneType.toLowerCase()}.`,
              userStatus: 'in-progress',
              version: milestoneType,
            };
          }

          await updateDoc(doc(db, 'milestones', milestoneDocId), {
            updates: arrayUnion(updateContent),
          });

          addNotification({
            message: updateContent.description,
            uid: 'system',
          });

          onApprove();
        } else if (action === 'requestChanges') {
          await updateDoc(projectRef, {
            status: 'revisions_needed',
            changeRequests: arrayUnion(changeRequestReason),
          });

          updateContent = {
            ...updateContent,
            title: `Client Requested Changes to ${milestoneType}`,
            description: `${clientUsername} requested changes: ${changeRequestReason}`,
            userStatus: 'revisions required',
            version: milestoneType,
          };

          await updateDoc(doc(db, 'milestones', milestoneDocId), {
            updates: arrayUnion(updateContent),
          });

          addNotification({
            message: updateContent.description,
            uid: 'system',
          });

          onRequestChanges(changeRequestReason);
        }
        alert(`Project ${action === 'approve' ? 'approved' : 'change request submitted'}`);
      } else {
        console.error('Project document does not exist');
        alert('Failed to find project document');
      }
    } catch (error) {
      console.error('Error updating project document:', error);
      alert('Failed to update project status');
    }
    setLoading(false);
    setSubmitDisabled(false); // Re-enable submit button after the process completes
  };

  useEffect(() => {
    console.log('isClient:', isClient);
    console.log('projectStatus:', projectStatus);
    console.log('milestoneType:', milestoneType);
    console.log('milestoneDocId:', milestoneDocId);
    console.log('nextStep:', nextStep); // Log the next step for debugging
  }, [isClient, projectStatus, milestoneType, milestoneDocId, nextStep]);

  if (!isClient || projectStatus !== 'awaiting_client_review') {
    console.log('Component not rendering because:', !isClient ? 'User is not client' : 'Project status is not awaiting_client_review');
    return null;
  }

  return (
    <div className="max-w-md mx-auto p-4 shadow-md border rounded-lg bg-gray-900 text-gray-200">
      <h2 className="text-xl font-semibold text-center mb-4">Approve or Request Changes</h2>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-400 mb-1">Action</label>
        <select value={action} onChange={handleActionChange} className="w-full px-3 py-2 bg-gray-800 text-gray-200 border border-gray-700 rounded-lg">
          <option value="">Select Action</option>
          <option value="approve">Approve</option>
          <option value="requestChanges">Request Changes</option>
        </select>
      </div>
      {action === 'requestChanges' && (
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-400 mb-1">Reason for Change Request</label>
          <textarea
            value={changeRequestReason}
            onChange={handleReasonChange}
            className="w-full px-3 py-2 bg-gray-800 text-gray-200 border border-gray-700 rounded-lg"
            rows="4"
          />
        </div>
      )}
      <button
        onClick={handleSubmit}
        disabled={loading || !action || submitDisabled}
        className={`w-full px-4 py-2 text-white font-medium rounded-lg ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700'} transition duration-300`}
      >
        {loading ? 'Processing...' : 'Submit'}
      </button>
    </div>
  );
};

export default ApproveOrRequestChanges;

