import React, { useState } from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import EarningsOverview from './EarningsOverview';
import ProjectStats from './ProjectStats';
import EarningsChart from './EarningsChart';
import EarningsByListingChart from './EarningsByListingChart';
import { useAuth } from '../../contexts/authContext';
import ClickThroughRateChart from './ClickThroughRateChart';
import StatCard from './dashboardStyles/StatCard';
import { ThemeProvider } from '@mui/material/styles';
import theme from './dashboardStyles/theme';

const MonthPickerDialog = ({ open, onClose, onSelect }) => {
  const handleMonthClick = (monthIndex) => {
    onSelect(monthIndex);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select a Month</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map(
            (month, index) => (
              <Grid item xs={3} key={index}>
                <Button
                  variant="contained"
                  onClick={() => handleMonthClick(index)}
                  fullWidth
                >
                  {month}
                </Button>
              </Grid>
            )
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

const FreelancerDashboard = () => {
  const [timeFrame, setTimeFrame] = useState('monthly');
  const [subTimeFrame, setSubTimeFrame] = useState('currentMonth');
  const [customMonth, setCustomMonth] = useState(dayjs());
  const [customRange, setCustomRange] = useState({ start: dayjs().startOf('month'), end: dayjs().endOf('month') });
  const [monthPickerOpen, setMonthPickerOpen] = useState(false);
  const [selectedQuarter, setSelectedQuarter] = useState(1);
  const { currentUser } = useAuth();
  const freelancerID = currentUser?.uid;

  const handleTimeFrameChange = (event) => {
    setTimeFrame(event.target.value);
    setSubTimeFrame('currentMonth');
  };

  const handleSubTimeFrameChange = (event) => {
    setSubTimeFrame(event.target.value);
  };

  const handleMonthPickerOpen = () => {
    setMonthPickerOpen(true);
  };

  const handleMonthSelect = (monthIndex) => {
    const updatedMonth = customMonth.month(monthIndex);
    setCustomMonth(updatedMonth);
    setCustomRange({
      start: updatedMonth.startOf('month'),
      end: updatedMonth.endOf('month'),
    });
  };
  
  const handleYearClick = () => {
    const newYear = prompt('Enter a year:', customMonth.year());
    if (newYear) {
      const updatedMonth = customMonth.year(parseInt(newYear, 10));
      setCustomMonth(updatedMonth);
      setCustomRange({
        start: updatedMonth.startOf('month'),
        end: updatedMonth.endOf('month'),
      });
    }
  };

  const handleQuarterChange = (event) => {
    setSelectedQuarter(event.target.value);
    const currentYear = customMonth.year();
    const startMonth = (event.target.value - 1) * 3;
    const start = dayjs().year(currentYear).month(startMonth).startOf('month');
    const end = dayjs().year(currentYear).month(startMonth + 2).endOf('month');
    setCustomRange({ start, end });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: theme.palette.background.default }}>
        <Box sx={{ flexGrow: 1, p: 3 }}>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel 
                  id="time-frame-select-label"
                  sx={{
                    color: '#ffffff',
                    '&.Mui-focused': { color: '#ffffff' },
                  }}>
                  Time Frame
                </InputLabel>
                <Select
                  labelId="time-frame-select-label"
                  value={timeFrame}
                  label="Time Frame"
                  onChange={handleTimeFrameChange}
                  sx={{
                    backgroundColor: '#1c1c28',
                    color: '#ffffff',
                    borderRadius: '8px',
                    '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#575757' },
                    '& .MuiSelect-icon': { color: '#9c9c9c' },
                  }}
                >
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="quarterly">Quarterly</MenuItem>
                  <MenuItem value="yearly">Yearly</MenuItem>
                  <MenuItem value="custom">Custom Range</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              {timeFrame === 'monthly' && (
                <FormControl fullWidth variant="outlined">
                  <InputLabel 
                    id="sub-time-frame-select-label"
                    sx={{
                      color: '#ffffff',
                      '&.Mui-focused': { color: '#ffffff' },
                    }}>
                    Month Filter
                  </InputLabel>
                  <Select
                    labelId="sub-time-frame-select-label"
                    value={subTimeFrame}
                    label="Month Filter"
                    onChange={handleSubTimeFrameChange}
                    sx={{
                      backgroundColor: '#1c1c28',
                      color: '#ffffff',
                      borderRadius: '8px',
                      '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
                      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#575757' },
                      '& .MuiSelect-icon': { color: '#9c9c9c' },
                    }}
                  >
                    <MenuItem value="currentMonth">Current Month</MenuItem>
                    <MenuItem value="previousMonth">Previous Month</MenuItem>
                    <MenuItem value="customMonth">Custom Month</MenuItem>
                  </Select>
                </FormControl>
              )}

              {timeFrame === 'quarterly' && (
                <FormControl fullWidth variant="outlined">
                  <InputLabel 
                    id="quarter-select-label"
                    sx={{
                      color: '#ffffff',
                      '&.Mui-focused': { color: '#ffffff' },
                    }}>
                    Select Quarter
                  </InputLabel>
                  <Select
                    labelId="quarter-select-label"
                    value={selectedQuarter}
                    label="Select Quarter"
                    onChange={handleQuarterChange}
                    sx={{
                      backgroundColor: '#1c1c28',
                      color: '#ffffff',
                      borderRadius: '8px',
                      '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
                      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#575757' },
                      '& .MuiSelect-icon': { color: '#9c9c9c' },
                    }}
                  >
                    <MenuItem value={1}>Q1 (Jan - Mar)</MenuItem>
                    <MenuItem value={2}>Q2 (Apr - Jun)</MenuItem>
                    <MenuItem value={3}>Q3 (Jul - Sep)</MenuItem>
                    <MenuItem value={4}>Q4 (Oct - Dec)</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>

          {subTimeFrame === 'customMonth' && (
            <Box sx={{ mb: 3 }}>
              <Button
                variant="outlined"
                onClick={handleMonthPickerOpen}
                sx={{
                  backgroundColor: '#1c1c28',
                  color: '#ffffff',
                  borderRadius: '8px',
                  border: '1px solid #2f2f3a',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                  '&:hover': {
                    backgroundColor: '#292b38',
                    borderColor: '#575757',
                  },
                }}
              >
                {customMonth.format('MMMM YYYY')}
              </Button>
              <Button
                onClick={handleYearClick}
                sx={{
                  ml: 2,
                  backgroundColor: '#6d44a1',
                  color: '#ffffff',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                  '&:hover': {
                    backgroundColor: '#7e4fb2',
                  },
                }}
              >
                Change Year
              </Button>
              <MonthPickerDialog
                open={monthPickerOpen}
                onClose={() => setMonthPickerOpen(false)}
                onSelect={handleMonthSelect}
              />
            </Box>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <StatCard>
                <Typography variant="h6">Total Earnings</Typography>
                <EarningsOverview
                  freelancerID={freelancerID}
                  timeFrame={timeFrame}
                  subTimeFrame={subTimeFrame}
                  customMonth={customMonth}
                  customRange={customRange}
                  selectedQuarter={selectedQuarter}
                />
              </StatCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <StatCard>
                <Typography variant="h6">Open Projects</Typography>
                <ProjectStats
                  freelancerID={freelancerID}
                  timeFrame={timeFrame}
                  subTimeFrame={subTimeFrame}
                  customMonth={customMonth}
                  customRange={customRange}
                  selectedQuarter={selectedQuarter}
                  status="open"
                />
              </StatCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <StatCard>
                <Typography variant="h6">Completed Projects</Typography>
                <ProjectStats
                  freelancerID={freelancerID}
                  timeFrame={timeFrame}
                  subTimeFrame={subTimeFrame}
                  customMonth={customMonth}
                  customRange={customRange}
                  selectedQuarter={selectedQuarter}
                  status="completed"
                />
              </StatCard>
            </Grid>

            <Grid item xs={12} md={12}>
              <StatCard>
                <Typography variant="h6">Earnings Over Time</Typography>
                <EarningsChart
                  freelancerID={freelancerID}
                  timeFrame={timeFrame}
                  subTimeFrame={subTimeFrame}
                  customMonth={customMonth}
                  customRange={customRange}
                  selectedQuarter={selectedQuarter}
                />
              </StatCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <StatCard>
                <Typography variant="h6">Earnings by Listing</Typography>
                <EarningsByListingChart
                  freelancerID={freelancerID}
                  timeFrame={timeFrame}
                  subTimeFrame={subTimeFrame}
                  customMonth={customMonth}
                  customRange={customRange}
                />
              </StatCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <StatCard>
                <Typography variant="h6">Views to Purchase Ratio</Typography>
                <ClickThroughRateChart
                  freelancerID={freelancerID}
                  timeFrame={timeFrame}
                  subTimeFrame={subTimeFrame}
                  customMonth={customMonth}
                  customRange={customRange}
                />
              </StatCard>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default FreelancerDashboard;

