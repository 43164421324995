import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase/firebase';
import { collection, doc, query, where, getDocs, updateDoc, addDoc, serverTimestamp } from 'firebase/firestore';
import { useAuth } from '../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import './MessageForm.css';

const MessageForm = ({ listingId, listingOwnerId, onClose }) => {
  const { currentUser: authUser } = useAuth();
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim()) {
      setErrorMessage('Message cannot be empty');
      return;
    }

    try {
      const senderId = authUser.uid;
      const recipientId = listingOwnerId;

      // Query for an existing message with the same listingId and participants
      const messagesQuery = query(
        collection(db, 'messages'),
        where('listingId', '==', listingId),
        where('participants', 'array-contains', senderId)
      );

      const querySnapshot = await getDocs(messagesQuery);

      if (!querySnapshot.empty) {
        // Update the existing message document
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, {
          messages: [
            ...querySnapshot.docs[0].data().messages,
            {
              text: message,
              senderId,
              timestamp: new Date(),
              read: false
            }
          ],
          lastChatTime: serverTimestamp()
        });
      } else {
        // No existing message found, create a new document
        await addDoc(collection(db, 'messages'), {
          listingId,
          participants: [senderId, recipientId],
          messages: [
            {
              text: message,
              senderId,
              timestamp: new Date(),
              read: false
            }
          ],
          lastChatTime: serverTimestamp()
        });
      }

      setMessage('');
      setErrorMessage('');
      setMessageSent(true);
    } catch (error) {
      console.error('Error sending message:', error);
      setErrorMessage('Failed to send message');
    }
  };

  const goToMessages = () => {
    navigate('/my-messages');
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="h2 text-white font-bold">Message</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="input-field w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
          />
        </div>
        {errorMessage && (
          <span className='text-red-600 font-bold'>{errorMessage}</span>
        )}
        <button
          type="submit"
          className="sendmessage-button w-full"
        >
          Send Message
        </button>
      </form>

      {messageSent && (
        <div className="mt-4">
          <p className="text-green-600 font-bold">Message sent successfully!</p>
          <button
            onClick={goToMessages}
            className="sendmessage-button w-full"
          >
            Go to My Messages
          </button>
        </div>
      )}

      <button onClick={onClose} className="sendmessage-button w-full mt-4">
        Close
      </button>
    </div>
  );
};

export default MessageForm;

