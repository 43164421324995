import React, { useState, useEffect } from 'react';
import { doc, getDoc, query, where, getDocs, collection } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import { Box, Typography, Avatar, Divider } from '@mui/material';
import { Star, StarBorder } from '@mui/icons-material';

const ReviewDisplay = ({ projectID, clientID, freelancerID, listingName, currentUserID }) => {
  const [reviewData, setReviewData] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchReviewData = async () => {
      try {
        console.log('Fetching review data...');
        const reviewsQuery = query(
          collection(db, 'reviews'),
          where('projectID', '==', projectID),
          where('uid', '==', clientID)
        );
        const reviewSnapshot = await getDocs(reviewsQuery);
        if (!reviewSnapshot.empty) {
          const reviewDoc = reviewSnapshot.docs[0].data();
          console.log('Review data found:', reviewDoc);
          setReviewData(reviewDoc);
        } else {
          console.error('No review found for the given projectID and clientID');
        }
      } catch (error) {
        console.error('Error fetching review data:', error);
      }
    };

    const fetchClientData = async () => {
      try {
        console.log('Fetching client data...');
        const clientRef = doc(db, 'users', clientID);
        const clientDoc = await getDoc(clientRef);
        if (clientDoc.exists()) {
          console.log('Client data found:', clientDoc.data());
          setClientData(clientDoc.data());
        } else {
          console.error('No client data found');
        }
      } catch (error) {
        console.error('Error fetching client data:', error);
      }
    };

    const fetchData = async () => {
      await fetchReviewData();
      await fetchClientData();
      setIsLoading(false);
    };

    fetchData();
  }, [projectID, clientID]);

  if (isLoading) {
    return <Typography>Loading review...</Typography>;
  }

  if (!reviewData || !clientData) {
    console.log('Review or client data is missing');
    return <Typography>No review data available.</Typography>;
  }

  const isClient = currentUserID === clientID;
  const reviewTitle = isClient 
    ? `Your Review of ${listingName}`
    : `${clientData.username}'s Review of Your Service`;

  // Generate star rating
  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        i <= reviewData.rating ? (
          <Star key={i} sx={{ color: '#FFD700' }} /> // Gold star for filled
        ) : (
          <StarBorder key={i} sx={{ color: '#FFD700' }} /> // Border star for empty
        )
      );
    }
    return stars;
  };

  console.log('Rendering ReviewDisplay component with data:', { reviewData, clientData });

  return (
    <Box sx={{ p: 2, backgroundColor: '#1a202c', borderRadius: '8px' }}>
      <Typography variant="h6" sx={{ color: '#E0E0E0', marginBottom: '8px' }}>
        {reviewTitle}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        <Avatar src={clientData.avatarURL} alt={clientData.username} sx={{ marginRight: '8px' }} />
        <Box>
          <Typography variant="body1" sx={{ color: '#E0E0E0' }}>
            {clientData.username}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {renderStars()}
          </Box>
        </Box>
      </Box>
      <Divider sx={{ backgroundColor: '#4A90E2', marginBottom: '8px' }} />
      <Typography variant="body2" sx={{ color: '#E0E0E0' }}>
        {reviewData.comment}
      </Typography>
    </Box>
  );
};

export default ReviewDisplay;

