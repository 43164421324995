import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4A148C', // Dark purple shade for primary elements
    },
    secondary: {
      main: '#9c27b0', // Lighter purple for secondary elements
    },
    background: {
      default: '#1c1c28', // Dark background color for the entire dashboard
      paper: '#2a2d3d',  // Darker element background for cards, sidebars, etc.
    },
    text: {
      primary: '#ffffff', // White text for headings and primary text
      secondary: '#B0BEC5', // Light grey text for secondary content
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600,
      color: '#ffffff', // White text for main headings
    },
    h6: {
      fontSize: '1.125rem',
      fontWeight: 500,
      color: '#ffffff', // White text for secondary headings
    },
    body1: {
      fontSize: '1rem',
      color: '#B0BEC5', // Light grey text for body content
    },
    button: {
      textTransform: 'none',
      fontSize: '1rem',
      color: '#ffffff', // White text for buttons
    },
  },
  shape: {
    borderRadius: 12, // Larger border radius for a softer, more modern look
  },
  shadows: [
    'none',
    '0px 4px 8px rgba(0, 0, 0, 0.4)',  // Soft shadow for elevated elements
    '0px 5px 10px rgba(0, 0, 0, 0.6)', // Medium shadow for more prominent elevation
    '0px 6px 12px rgba(0, 0, 0, 0.8)',  // Stronger shadow for deeply elevated elements
    '0px 8px 16px rgba(0, 0, 0, 1)',  // Deepest shadow for the most prominent elements
    '0px 8px 16px rgba(0, 0, 0, 1.2)',
    '0px 8px 16px rgba(0, 0, 0, 1.4)',
    '0px 8px 16px rgba(0, 0, 0, 1.6)',
    '0px 8px 16px rgba(0, 0, 0, 1.8)',
  ],
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#2a2d3d', // Background color for Paper components
          color: '#ffffff', // Ensure text color is white on dark background
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#1c1c28', // Sidebar background color
          color: '#ffffff', // Sidebar text color
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#2a2d3d', // Top bar background color
          color: '#ffffff', // Top bar text color
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#ffffff', // Button text color
          backgroundColor: '#4A148C', // Button background color (primary)
          '&:hover': {
            backgroundColor: '#6a1b9a', // Darken on hover
          },
        },
      },
    },
  },
});

export default theme;

