import React, { useEffect } from 'react';
import { doc, updateDoc, increment, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';

const MedalUpdateOnListing = ({ uid, isSuccess }) => {
  useEffect(() => {
    const updateListingMedals = async () => {
      if (isSuccess) {
        const userMedalsRef = doc(db, 'medals', uid);

        try {
          // Increment the listing count in the medals collection
          await updateDoc(userMedalsRef, {
            listings: increment(1),
            lastListing: serverTimestamp(),
          });

          console.log('Medals updated successfully.');
        } catch (error) {
          console.error('Error updating medals:', error);
        }
      }
    };

    updateListingMedals();
  }, [isSuccess, uid]);

  return null; // This component doesn't render anything
};

export default MedalUpdateOnListing;

