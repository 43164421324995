import React, { useEffect } from 'react';

const NotificationPopup = ({ message, onClose, read }) => {
  useEffect(() => {
    if (read) return; // Do not show the notification if it's already read

    const timer = setTimeout(() => {
      onClose();
    }, 3000); // Automatically close the popup after 3 seconds

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [onClose, read]);

  if (read) return null; // Do not render the notification if it's read

  return (
    <div className="fixed bottom-4 right-4 bg-gradient-to-br from-gray-800 to-gray-600 text-white p-3 rounded-lg shadow-lg z-50 transition-opacity duration-500">
      <p>{message}</p>
    </div>
  );
};

export default NotificationPopup;

