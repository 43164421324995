import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCrown, FaTimes } from 'react-icons/fa';
import { useAuth } from '../../contexts/authContext';
import { useWallet } from '@solana/wallet-adapter-react'; // Import the useWallet hook
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebase';

const SidebarModal = ({ isOpen, onClose, handleLogout }) => {
  const { currentUser } = useAuth();
  const { disconnect } = useWallet(); // Destructure disconnect function
  const modalRef = useRef(null);
  const [avatarURL, setAvatarURL] = useState(null);

  const dashboardimgSrc = process.env.PUBLIC_URL + '/dashboard.webp';
  const medalimgSrc = process.env.PUBLIC_URL + '/medalwall.webp';

  useEffect(() => {
    const fetchAvatarURL = async () => {
      if (currentUser && currentUser.uid) {
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setAvatarURL(userData.avatarURL || '/profileplaceholder.webp');
          } else {
            console.log('No such document!');
            setAvatarURL('/profileplaceholder.webp');
          }
        } catch (error) {
          console.error('Error fetching avatar URL:', error);
          setAvatarURL('/profileplaceholder.webp');
        }
      }
    };

    fetchAvatarURL();
  }, [currentUser]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  const handleLogoutClick = async () => {
    try {
      await disconnect(); // Disconnect the wallet
      handleLogout(); // Call the logout function from props
      onClose(); // Close the sidebar modal
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div
      className={`fixed inset-0 z-50 bg-black bg-opacity-60 flex justify-end ${isOpen ? 'block' : 'hidden'} transition-opacity duration-300 ease-in-out`}
      onClick={handleClickOutside}
    >
      <div
        ref={modalRef}
        className={`w-full md:w-1/4 h-full bg-gradient-to-b from-gray-800 via-gray-900 to-black shadow-2xl flex flex-col transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}
      >
        {/* Combined Header and Menu Title Section */}
        <div className="flex justify-between items-center p-4 shadow-lg">
          <h2 className="text-lg font-bold text-white">Menu</h2>
          <button
            onClick={onClose}
            className="text-white text-2xl focus:outline-none hover:text-yellow-400 transition-colors duration-200"
          >
            <FaTimes />
          </button>
        </div>

        {/* Profile Section */}
        <div className="p-6 flex-grow overflow-y-auto text-white">
          {currentUser && (
            <div className="block mb-8 rounded-lg transition duration-300 ease-in-out text-center">
              <div className="flex justify-around mb-4">
                <div className="text-center">
                  <Link to={`/profile/${currentUser.uid}`} onClick={onClose}>
                    <img
                      src={avatarURL}
                      alt="Profile"
                      className="w-14 h-14 rounded-full shadow-lg"
                    />
                    <p className="text-sm text-white mt-2">Profile</p>
                  </Link>
                </div>
                <div className="text-center">
                  <Link to="/dashboard" onClick={onClose}>
                    <img
                      src={dashboardimgSrc}
                      alt="Dashboard"
                      className="w-14 h-14 rounded-full shadow-lg"
                    />
                    <p className="text-sm text-white mt-2">Dashboard</p>
                  </Link>
                </div>
                <div className="text-center">
                  <Link to="/medals" onClick={onClose}>
                    <img
                      src={medalimgSrc}
                      alt="Medals"
                      className="w-14 h-14 rounded-full shadow-lg"
                    />
                    <p className="text-sm text-white mt-2">Medals</p>
                  </Link>
                </div>
              </div>

              <div className="mt-4 text-white">
                <div className="flex flex-col items-center">
                  <span className="text-xl font-bold">
                    {currentUser.displayName}
                  </span>
                  <span className="text-sm text-gray-400">
                    {currentUser.email}
                  </span>
                </div>
              </div>
            </div>
          )}

          {/* Navigation Links */}
          <nav className="mt-4">
            <Link
              to="/"
              className="block text-lg py-3 px-4 hover:bg-gray-800 rounded-lg transition duration-300 ease-in-out hover:text-yellow-400"
              onClick={onClose}
            >
              Home
            </Link>
            <Link
              to="/all-listings"
              className="block text-lg py-3 px-4 hover:bg-gray-800 rounded-lg transition duration-300 ease-in-out hover:text-yellow-400"
              onClick={onClose}
            >
              All Listings
            </Link>
            {currentUser && (
              <>
                <Link
                  to="/my-messages"
                  className="block text-lg py-3 px-4 hover:bg-gray-800 rounded-lg transition duration-300 ease-in-out hover:text-yellow-400"
                  onClick={onClose}
                >
                  My Messages
                </Link>
                <Link
                  to="/create-listing"
                  className="block text-lg py-3 px-4 hover:bg-gray-800 rounded-lg transition duration-300 ease-in-out hover:text-yellow-400"
                  onClick={onClose}
                >
                  Create Listing
                </Link>
                <Link
                  to="/orders"
                  className="block text-lg py-3 px-4 hover:bg-gray-800 rounded-lg transition duration-300 ease-in-out hover:text-yellow-400"
                  onClick={onClose}
                >
                  Orders
                </Link>
                <button
                  onClick={handleLogoutClick} // Updated to use handleLogoutClick
                  className="block w-full text-left text-lg py-3 px-4 hover:bg-gray-800 rounded-lg transition duration-300 ease-in-out hover:text-yellow-400"
                >
                  Logout
                </button>
                {!currentUser.isPremium && (
                  <Link
                    to="/Premium"
                    className="block text-yellow-400 py-3 px-4 hover:bg-yellow-600 rounded-lg flex items-center transition duration-300 ease-in-out"
                    onClick={onClose}
                  >
                    <FaCrown className="mr-2" /> Get Premium
                  </Link>
                )}
              </>
            )}
            {!currentUser && (
              <Link
                to="/login"
                className="block text-lg py-3 px-4 hover:bg-gray-800 rounded-lg transition duration-300 ease-in-out hover:text-yellow-400"
                onClick={onClose}
              >
                Login
              </Link>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SidebarModal;

