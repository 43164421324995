import React from 'react';
import './termsofservice.css';

const TermsOfService = () => {
  return (
    <div className="terms-root">
      <div className="terms-container">
        <div className="terms-header">
          <h1>Terms of Service for Hustlez</h1>
        </div>
        <div className="terms-section">
          <h2>Introduction</h2>
          <p>
            Welcome to Hustlez! By using our decentralized freelance platform, you agree to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Hustlez's relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our platform.
          </p>
        </div>
        <div className="terms-section">
          <h2>General Terms</h2>
          <ul>
            <li>
              <strong>Acceptance of Terms</strong>: By accessing and using Hustlez, you accept and agree to be bound by the terms and provisions of this agreement. In addition, when using Hustlez's services, you shall be subject to any posted guidelines or rules applicable to such services.
            </li>
            <li>
              <strong>Modification of Terms</strong>: Hustlez reserves the right to change, modify, or remove the contents of these Terms of Service at any time and for any reason at our sole discretion without notice. It is your responsibility to review these Terms of Service periodically to stay informed of updates.
            </li>
            <li>
              <strong>User Accounts</strong>: To access certain features of the platform, you must create an account. You are solely responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
            </li>
          </ul>
        </div>
        <div className="terms-section">
          <h2>User Responsibilities</h2>
          <ul>
            <li>
              <strong>Prohibited Uses</strong>: You may use our platform only for lawful and appropriate purposes. You may not use our platform:
              <ul>
                <li>In any way that breaches any applicable decentralized protocols or community guidelines.</li>
                <li>In any way that is fraudulent or has any fraudulent purpose or effect.</li>
                <li>To transmit, or procure the sending of, any unsolicited or unauthorized advertising or promotional material or any other form of similar solicitation (spam).</li>
                <li>To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware, or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</li>
              </ul>
            </li>
            <li>
              <strong>User Content</strong>: You are responsible for any content that you post on Hustlez. You must ensure that your content does not violate any community guidelines and does not infringe the rights of any third party.
            </li>
          </ul>
        </div>
        <div className="terms-section">
          <h2>Services and Transactions</h2>
          <ul>
            <li>
              <strong>Platform Services</strong>: Hustlez provides a decentralized marketplace for freelancers and clients to connect and engage in work-related transactions. Hustlez itself is not a party to the agreements entered into between freelancers and clients.
            </li>
            <li>
              <strong>No Warranty</strong>: Hustlez does not warrant the quality, accuracy, or reliability of the work provided by freelancers on the platform. Any issues arising from the work performed should be resolved directly between the client and the freelancer.
            </li>
            <li>
              <strong>Dispute Resolution</strong>: Although Hustlez is not responsible for the work provided on the platform, we will mediate disputes between clients and freelancers to facilitate a mutually acceptable resolution. Our decision in such disputes is final and binding.
            </li>
          </ul>
        </div>
        <div className="terms-section">
          <h2>Limitation of Liability</h2>
          <ul>
            <li>
              <strong>Limitation of Liability</strong>: To the fullest extent permitted by the decentralized nature of the platform, Hustlez shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
              <ul>
                <li>Your use of the platform or your inability to use the platform.</li>
                <li>Any conduct or content of any third party on the platform, including any defamatory, offensive, or illegal conduct of other users or third parties.</li>
                <li>Any content obtained from the platform.</li>
                <li>Unauthorized access, use, or alteration of your transmissions or content.</li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="terms-section">
          <h2>Miscellaneous</h2>
          <ul>
            <li>
              <strong>Termination</strong>: Hustlez reserves the right to terminate or suspend your account and bar access to the platform immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms. This includes the removal of accounts and listings that are considered offensive or harmful.
            </li>
            <li>
              <strong>Governing Principles</strong>: These Terms shall be governed and construed in accordance with the decentralized principles of the Hustlez community, without regard to traditional legal jurisdictions.
            </li>
            <li>
              <strong>Entire Agreement</strong>: These Terms constitute the entire agreement between us regarding our service, and supersede and replace any prior agreements we might have had between us regarding the service.
            </li>
          </ul>
        </div>
        <p>By continuing to use Hustlez, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service. If you have any questions or concerns, please contact us in our Discord channel.</p>
        <p>Thank you for using Hustlez!</p>
      </div>
    </div>
  );
};

export default TermsOfService;

