import { styled } from '@mui/system';
import { Paper } from '@mui/material';

const StatCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: 20, // Increased rounded corners
  background: 'linear-gradient(135deg, #1e2a38 30%, #243a52 100%)', // Gradient background for depth
  color: '#ffffff',
  textAlign: 'center',
  boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.2)', // Shadow for depth
  display: 'flex', // Ensures icon and text are aligned
  flexDirection: 'column',
  justifyContent: 'space-between', // Space between icon and text
  height: '100%', // Ensure the card fills the container
  transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transition on hover
  '&:hover': {
    transform: 'translateY(-5px)', // Slight lift on hover
    boxShadow: '0px 15px 40px rgba(0, 0, 0, 0.3)', // Increase shadow on hover
  },
  '& .MuiTypography-h6': {
    fontSize: '1.5rem', // Adjust title font size
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  '& .MuiTypography-body1': {
    fontSize: '1.125rem', // Adjust content font size
    opacity: 0.9,
  },
}));

export default StatCard;

