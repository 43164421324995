import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import ListingCard from '../listings/alllistings/ListingCard';
import CategoryGallery from './CategoryGallery';
import ResponsiveBanner from './ResponsiveBanner';
import { FaSpinner } from 'react-icons/fa'; 
import HowItWorks from './HowItWorks';
import { useAuth } from '../../contexts/authContext'; 
import UpdateMedalsOnLogin from '../hustlermedals/Update/UpdateMedalsOnLogin'; 

const LandingPage = () => {
  const { currentUser } = useAuth(); 
  const [listings, setListings] = useState([]);
  const [userDataMap, setUserDataMap] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchListingsAndUsers = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'listings'));
        const listingsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const userPromises = listingsData.map(async (listing) => {
          const userDocRef = doc(db, 'users', listing.uid);
          const userDocSnap = await getDoc(userDocRef);
          return {
            userId: listing.uid,
            userData: userDocSnap.exists() ? userDocSnap.data() : { username: listing.uid }
          };
        });

        const userDataResults = await Promise.all(userPromises);
        const userDataMap = userDataResults.reduce((acc, { userId, userData }) => {
          acc[userId] = userData;
          return acc;
        }, {});

        setListings(listingsData);
        setUserDataMap(userDataMap);
      } catch (error) {
        setErrorMessage('Failed to fetch listings');
      } finally {
        setLoading(false);
      }
    };

    fetchListingsAndUsers();
  }, []);

  const handleSearchChange = (term) => {
    setSearchTerm(term.toLowerCase());
  };

  const filteredListings = listings
    .filter(listing => {
      const matchesName = listing.name?.toLowerCase().includes(searchTerm);
      const matchesCategory = listing.category?.toLowerCase().includes(searchTerm);
      const matchesSubcategory = listing.subcategory?.toLowerCase().includes(searchTerm);
      const matchesUsername = userDataMap[listing.uid]?.username?.toLowerCase().includes(searchTerm);
      return matchesName || matchesCategory || matchesSubcategory || matchesUsername;
    })
    .sort((a, b) => {
      if (a.trending && !b.trending) return -1;
      if (!a.trending && b.trending) return 1;
      return b.createdAt - a.createdAt;
    }) 
    .slice(0, 9); 

  return (
    <div className="bg-gradient-to-b from-gray-800 via-gray-900 to-black text-white min-h-screen">
      {currentUser && <UpdateMedalsOnLogin uid={currentUser.uid} />}
      <ResponsiveBanner onSearch={handleSearchChange} />
      <div className="p-4">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <FaSpinner className="animate-spin text-white text-4xl" />
          </div>
        ) : (
          <>
            {errorMessage && <div className="text-red-500 font-bold mb-4">{errorMessage}</div>}
            {searchTerm && filteredListings.length > 0 && (
              <div className="mb-4">
                <h2 className="text-2xl font-semibold mb-4">Search Results</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {filteredListings.map(listing => (
                    <ListingCard
                      key={listing.id}
                      listing={listing}
                      userData={userDataMap[listing.uid] || {}}
                      openModal={() => {}}
                      modalImage={null}
                      isModalOpen={false}
                      closeModal={() => {}}
                      openUserProfile={() => {}}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        )}
        {currentUser ? (
          <>
            <CategoryGallery />
            <div className="my-4 h-0.5 bg-transparent rounded-full shadow-[0_0_10px_rgba(255,255,255,0.8)]"></div>
            <HowItWorks />
            <div className="my-4 h-0.5 bg-transparent rounded-full"></div> 
          </>
        ) : (
          <>
            <HowItWorks />
            <div className="my-4 h-0.5 bg-transparent rounded-full shadow-[0_0_10px_rgba(255,255,255,0.8)]"></div>
            <CategoryGallery />
            <div className="my-4 h-0.5 bg-transparent rounded-full"></div>
          </>
        )}
      </div>
    </div>
  );
};

export default LandingPage;

