import React from 'react';
import { db } from '../../../../firebase/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import PaymentComponent from '../PaymentComponent';

const PremiumPayment = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleSubscriptionSuccess = async () => {
    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.exists() ? userDoc.data() : {};

      // Update user document
      await updateDoc(userRef, {
        isPremium: true,
        freeTrending: (userData.freeTrending || 0) + 10,
        reputation: (userData.reputation || 0) + 30, // Add 30 to user's reputation
      });

      console.log('Subscription successful! Enjoy your premium benefits.');
      alert('Subscription successful! Enjoy your premium benefits.');
      
      // Navigate to the user's profile
      navigate(`/profile/${currentUser.uid}`);
    } catch (error) {
      console.error('Subscription or update failed', error);
      alert('Subscription failed. Please try again.');
    }
  };

  const handleSubscriptionFailure = (error) => {
    console.error('Subscription failed', error);
    alert('Subscription failed. Please try again.');
  };

  return (
    <div className="max-w-md mx-auto p-6 text-white">
      <h2 className="text-2xl font-semibold text-center mb-4">Subscribe to Premium</h2>
      <div className="mb-6">
        <p className="text-lg mb-2">Gain access to exclusive premium features:</p>
        <ul className="list-disc list-inside mb-4 pl-4">
          <li className="mb-2">Enjoy a 50% discount on all fees.</li>
          <li className="mb-2">Receive 10 complimentary trending boosts, valued at 1 SOL each.</li>
          <li className="mb-2">Increase your free trending boosts with each additional subscription.</li>
          <li className="mb-2">Earn an additional 30 reputation points.</li>
        </ul>
        <p className="text-lg font-bold">Price: 5 SOL</p>
      </div>
      <PaymentComponent
        amountSOL={5}
        paymentDescription="Premium Subscription"
        onPaymentSuccess={handleSubscriptionSuccess}
        onPaymentFailure={handleSubscriptionFailure}
      />
    </div>
  );
};

export default PremiumPayment;

