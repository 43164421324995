import React, { useState } from 'react';
import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import { useAuth } from '../../../contexts/authContext';

const EmailVerificationModal = ({ email, onClose, onVerificationSuccess }) => {
  const { currentUser } = useAuth();
  const [checking, setChecking] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCheckVerification = async () => {
    setChecking(true);
    setErrorMessage('');

    try {
      if (!currentUser || !currentUser.uid) throw new Error('User not authenticated');

      const q = query(
        collection(db, 'verifications'),
        where('uid', '==', currentUser.uid),
        where('verified', '==', true)
      );

      const querySnapshot = await getDocs(q);
      console.log('Verification querySnapshot:', querySnapshot);

      if (!querySnapshot.empty) {
        const userRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userRef, {
          email,
          emailVerified: true
        });

        onVerificationSuccess();
      } else {
        setErrorMessage('Email verification failed. Please try again.');
      }
    } catch (error) {
      console.error('Error checking email verification:', error);
      setErrorMessage('An error occurred. Please try again.');
    } finally {
      setChecking(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50">
      <div className="bg-gray-900 p-6 rounded-lg shadow-lg max-w-md mx-auto text-white">
        <h2 className="text-2xl font-bold mb-4">Verify Your Email</h2>
        <div className="mb-4">
          <p>Keep this window open during the verification process. Once you have verified your email, click the button below to check the verification status.</p>
        </div>
        {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>}
        <div className="flex space-x-4">
          <button
            onClick={handleCheckVerification}
            className="hustle-button w-full"
            disabled={checking}
          >
            {checking ? 'Checking...' : 'Check Verification'}
          </button>
          <button
            onClick={onClose}
            className="pay-forlisting-button w-full"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationModal;

