import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase/firebase'; // Adjust import path as needed
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { categories } from '../createlisting/categories'; // Adjust import path as necessary
import ListingCard from './ListingCard';
import UserProfile from '../../profile/UserProfile'; // Adjust import path as necessary
import { useAuth } from '../../../contexts/authContext'; // Import the useAuth hook

const AllListings = () => {
  const [listings, setListings] = useState([]);
  const [userDataMap, setUserDataMap] = useState({}); // Map of userId to userData
  const [errorMessage, setErrorMessage] = useState('');
  const [modalImage, setModalImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [viewingProfile, setViewingProfile] = useState(null);
  const [showMyListings, setShowMyListings] = useState(false);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const { currentUser } = useAuth(); // Get current user data

  useEffect(() => {
    const fetchListingsAndUsers = async () => {
      try {
        // Fetch listings
        const querySnapshot = await getDocs(collection(db, 'listings'));
        const listingsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Fetch user data for all users in listings
        const userPromises = listingsData.map(async (listing) => {
          const userDocRef = doc(db, 'users', listing.uid);
          const userDocSnap = await getDoc(userDocRef);
          return {
            userId: listing.uid,
            userData: userDocSnap.exists() ? userDocSnap.data() : { username: listing.uid }
          };
        });

        const userDataResults = await Promise.all(userPromises);
        const userDataMap = userDataResults.reduce((acc, { userId, userData }) => {
          acc[userId] = userData;
          return acc;
        }, {});

        setListings(listingsData);
        setUserDataMap(userDataMap);
      } catch (error) {
        console.error('Error fetching listings or user data:', error);
        setErrorMessage('Failed to fetch listings');
      }
    };

    fetchListingsAndUsers();
  }, []);

  const openModal = (imageUrl) => {
    setModalImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  const handleSubcategoryChange = (e) => {
    setSelectedSubcategory(e.target.value);
    setCurrentPage(1); // Reset to the first page when filter changes
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page when search term changes
  };

  const openUserProfile = (userId) => {
    setViewingProfile(userId);
  };

  const closeUserProfile = () => {
    setViewingProfile(null);
  };

  const toggleMyListings = () => {
    setShowMyListings(!showMyListings);
    setCurrentPage(1); // Reset to the first page when toggling
  };

  const filteredListings = listings.filter(listing => {
    const matchesSubcategory = selectedSubcategory ? listing.subcategory === selectedSubcategory : true;
    const matchesSearch = listing.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesUser = !showMyListings || (currentUser && listing.uid === currentUser.uid);
    return matchesSubcategory && matchesSearch && matchesUser;
  });

  // Pagination logic
  const indexOfLastListing = currentPage * itemsPerPage;
  const indexOfFirstListing = indexOfLastListing - itemsPerPage;
  const currentListings = filteredListings.slice(indexOfFirstListing, indexOfLastListing);

  // Calculate total number of pages
  const totalPages = Math.ceil(filteredListings.length / itemsPerPage);

  return (
    <div>
      {errorMessage && <div className="text-red-600 font-bold mb-4">{errorMessage}</div>}
      <div className="mb-4 flex flex-col md:flex-row gap-4">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search by name"
          className="w-full md:w-1/2 px-3 py-2 text-gray-500 bg-transparent border rounded-lg"
        />
        <div className="w-full md:w-1/2">
          <label className="text-sm text-gray-600 font-bold">Filter by Subcategory</label>
          <select
            value={selectedSubcategory}
            onChange={handleSubcategoryChange}
            className="w-full mt-1 px-3 py-2 text-gray-500 bg-transparent border rounded-lg"
          >
            <option value="">All Subcategories</option>
            {categories.flatMap(cat => cat.subcategories).map((subcat, index) => (
              <option key={index} value={subcat}>{subcat}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={toggleMyListings}
          className={`px-4 py-2 font-medium rounded-lg ${showMyListings ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700'} transition duration-300`}
        >
          {showMyListings ? 'Show All Listings' : 'Show My Listings'}
        </button>
      </div>
      {viewingProfile && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg relative">
            <button className="absolute top-2 right-2 text-xl font-bold" onClick={closeUserProfile}>×</button>
            <UserProfile userId={viewingProfile} />
          </div>
        </div>
      )}
      {currentListings.length === 0 ? (
        <p>No listings available</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {currentListings.map(listing => (
            <ListingCard
              key={listing.id}
              listing={listing}
              userData={userDataMap[listing.uid] || {}}
              openModal={openModal}
              modalImage={modalImage}
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              openUserProfile={openUserProfile}
            />
          ))}
        </div>
      )}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          className="px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-600 disabled:opacity-50"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="text-gray-600">{`Page ${currentPage} of ${totalPages}`}</span>
        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          className="px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-600 disabled:opacity-50"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AllListings;

