import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase/firebase'; // Ensure this path is correct
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from '../../../contexts/authContext';
import OrderCard from '../freelancerOrderManagement/OrderCard'; // Reuse the same OrderCard component
import './ClientOrderManagement.css'; // Create and style this CSS file

const ClientOrderManagement = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('active');
  const [sortOrder, setSortOrder] = useState('newest'); // Add sorting state
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchOrders = async () => {
      if (!currentUser) return;
      console.log('Fetching orders for client:', currentUser.uid);
      const ordersQuery = query(
        collection(db, 'projects'),
        where('clientID', '==', currentUser.uid)
      );
      const ordersSnapshot = await getDocs(ordersQuery);
      const ordersData = ordersSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(order => order.projectID.startsWith('ord-'));
      console.log('Fetched orders:', ordersData);
      setOrders(ordersData);
      setLoading(false);
    };

    fetchOrders();
  }, [currentUser]);

  // Sorting Logic
  const sortOrders = (orders, sortOrder) => {
    return orders.sort((a, b) => {
      if (sortOrder === 'newest') {
        return b.createdAt.seconds - a.createdAt.seconds;
      } else if (sortOrder === 'oldest') {
        return a.createdAt.seconds - b.createdAt.seconds;
      }
      return 0;
    });
  };

  const statusMapping = {
    'active': 'active',
    'awaiting_client_review': 'in-progress',
    'revisions_needed': 'in-progress',
    'in-progress': 'in-progress',
    'completed': 'completed'
  };

  const filteredOrders = sortOrders(
    orders.filter(order => {
      const userFriendlyStatus = statusMapping[order.status];
      switch (currentTab) {
        case 'active':
          return userFriendlyStatus === 'active' || userFriendlyStatus === 'in-progress';
        case 'completed':
          return userFriendlyStatus === 'completed';
        default:
          return false;
      }
    }),
    sortOrder
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>My Orders</h1>
      <div className="tabs">
        <button
          className={currentTab === 'active' ? 'active' : ''}
          onClick={() => setCurrentTab('active')}
        >
          Active Orders
        </button>
        <button
          className={currentTab === 'completed' ? 'active' : ''}
          onClick={() => setCurrentTab('completed')}
        >
          Completed Orders
        </button>
      </div>

      {/* Sorting Dropdown */}
      <div className="sorting">
        <label htmlFor="sortOrder">Sort by:</label>
        <select
          id="sortOrder"
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
        >
          <option value="newest">Newest</option>
          <option value="oldest">Oldest</option>
        </select>
      </div>

      <div className="order-cards-container">
        {filteredOrders.length > 0 ? (
          filteredOrders.map(order => (
            <OrderCard key={order.id} order={order} />
          ))
        ) : (
          <p>No orders found.</p>
        )}
      </div>
    </div>
  );
};

export default ClientOrderManagement;

