import React, { useState, useRef, useEffect } from 'react';

const DragAndDropUpload = ({ onFilesSelect }) => {
  const [dragging, setDragging] = useState(false);
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    // Notify parent component whenever files state updates
    if (files.length) {
      onFilesSelect(files);
    }
  }, [files, onFilesSelect]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    if (droppedFiles.length) {
      setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    }
  };

  const handleChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length) {
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="w-full max-w-screen-lg mx-auto">
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
        className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition duration-300 ease-in-out ${
          dragging ? 'border-yellow-400 bg-gray-800' : 'border-gray-600 bg-gradient-to-b from-gray-800 via-gray-900 to-black'
        }`}
      >
        <input
          type="file"
          multiple
          ref={fileInputRef}
          onChange={handleChange}
          className="hidden"
        />
        <p className="text-white text-lg">Drag & drop files here or click to select additional media</p>
      </div>

      <div className="mt-6 text-center">
      </div>

      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {files.map((file, index) => (
          <div key={index} className="relative bg-gray-800 rounded-lg shadow-lg p-2">
            {file.type.startsWith('image/') ? (
              <img
                src={URL.createObjectURL(file)}
                alt={`Preview ${index}`}
                className="w-full h-40 object-cover rounded-lg"
              />
            ) : (
              <video
                src={URL.createObjectURL(file)}
                controls
                className="w-full h-40 object-cover rounded-lg"
              />
            )}
            <button
              type="button"
              className="absolute top-1 right-1 bg-red-600 text-white p-1 rounded-full hover:bg-red-700 transition duration-300"
              onClick={() => setFiles(files.filter((_, i) => i !== index))}
            >
              &times;
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DragAndDropUpload;

