import React from 'react';

const About = () => {
  return (
    <section className="bg-gradient-to-b from-gray-800 via-gray-900 to-black text-white py-12 px-4">
      <div className="max-w-screen-xl mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8 text-white border-b-2 border-gray-700 pb-2">About Us</h2>

        <div className="mb-8">
          <h3 className="text-2xl font-semibold mb-4 text-white">What is Hustlez?</h3>
          <p className="text-lg text-gray-300 leading-relaxed">
            Hustlez is a platform where freelancers and clients can connect to share and buy goods and services, all within the exciting world of decentralized finance (DeFi). Whether you're looking to offer your skills or find someone to help with your project, Hustlez makes it easy to do business in a way that's secure, transparent, and fun.
          </p>
        </div>

        <div className="mb-8">
          <h3 className="text-2xl font-semibold mb-4 text-white">Why Hustlez?</h3>
          <p className="text-lg text-gray-300 leading-relaxed">
            We created Hustlez because there just aren't many options out there for people who want to mix freelancing with the world of DeFi. We saw a gap and thought, "Why not build something that brings these two worlds together?" So, that's what we did. Hustlez is here to make life easier for freelancers and clients who want to explore new opportunities in a fresh, innovative space.
          </p>
        </div>

        <div className="mb-8">
          <h3 className="text-2xl font-semibold mb-4 text-white">Who We Are</h3>
          <p className="text-lg text-gray-300 leading-relaxed">
            Hustlez is the brainchild of a small but mighty team. Meet Prim, a Dutch tech enthusiast with over 10 years of experience in DevOps and web hosting. Alongside him is TechguyTy, our U.S.-based cybersecurity expert who also brings over a decade of experience to the table. Together, we’re passionate about building a platform that not only serves the community but also makes the journey of freelancing in the DeFi space enjoyable and rewarding.
          </p>
        </div>

      </div>
    </section>
  );
};

export default About;

