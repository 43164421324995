// src/contexts/notificationContext/index.jsx

import React, { createContext, useContext, useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import { collection, query, where, onSnapshot, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../authContext';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    let unsubscribe = () => {};

    if (currentUser) {
      const q = query(collection(db, 'notifications'), where('uid', '==', currentUser.uid));
      unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedNotifications = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setNotifications(fetchedNotifications);
      });
    }

    return () => unsubscribe();
  }, [currentUser]);

  const addNotification = async (message, type, projectID) => {
    try {
      const notification = {
        uid: currentUser.uid,
        message,
        type,
        projectID,
        timestamp: new Date(),
      };
      const docRef = await addDoc(collection(db, 'notifications'), notification);
      setNotifications((prevNotifications) => [...prevNotifications, { id: docRef.id, ...notification }]);
    } catch (error) {
      console.error("Error adding notification: ", error);
    }
  };

  const removeNotification = async (id) => {
    try {
      await deleteDoc(doc(db, 'notifications', id));
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.id !== id)
      );
    } catch (error) {
      console.error("Error removing notification: ", error);
    }
  };

  const markAsRead = async (id) => {
    try {
      const notificationRef = doc(db, 'notifications', id);
      await updateDoc(notificationRef, { read: true });
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === id ? { ...notification, read: true } : notification
        )
      );
    } catch (error) {
      console.error("Error marking notification as read: ", error);
    }
  };

  return (
    <NotificationContext.Provider value={{ notifications, setNotifications, addNotification, removeNotification, markAsRead }}>
      {children}
    </NotificationContext.Provider>
  );
};

