import React, { useState, useEffect } from 'react';
import { db } from '../../../../firebase/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../../../contexts/authContext';
import PaymentComponent from '../PaymentComponent';
import ExplanationModal from '../ListingExplanationModal'; // Import the ExplanationModal component

const Payment = ({ onPaymentSuccess, onPaymentFailure }) => {
  const [isPremium, setIsPremium] = useState(false);
  const { currentUser } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(true); // State to manage the modal visibility

  useEffect(() => {
    const checkPremiumStatus = async () => {
      if (currentUser) {
        try {
          const userRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            setIsPremium(userDoc.data().isPremium || false);
          }
        } catch (error) {
          console.error('Error checking premium status:', error);
        }
      }
    };

    checkPremiumStatus();
  }, [currentUser]);

  const handlePaymentSuccess = async () => {
    if (currentUser) {
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userRef, { paidFor: true });
        onPaymentSuccess(); // Trigger the parent callback
      } catch (error) {
        console.error('Error updating paidFor status:', error);
        onPaymentFailure(); // Trigger the parent callback on failure
      }
    } else {
      console.error('No current user');
      onPaymentFailure(); // Trigger the parent callback on failure
    }
  };

  const amountSOL = isPremium ? 0.0005 : 0.001;

  return (
    <div className="max-w-md mx-auto mb-48 p-6 shadow-lg rounded-lg text-white">
      <h2 className="text-xl font-bold text-center mb-6 text-white">Complete Payment</h2>
      
      {/* Explanation Modal */}
      <ExplanationModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)} // Close modal
        onConfirm={() => setIsModalOpen(false)} // Proceed to payment
      />
      
      {!isModalOpen && (
        <PaymentComponent
          amountSOL={amountSOL}
          paymentDescription="Listing"
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentFailure={onPaymentFailure}
        />
      )}
    </div>
  );
};

export default Payment;

