import React, { useState, useEffect } from 'react';
import UserProfileContent from './UserProfileContent';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs, updateDoc, arrayUnion, arrayRemove, addDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { useAuth } from '../../contexts/authContext';

const UserProfilePage = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { currentUser: authUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const [averageRating, setAverageRating] = useState(null);
  const [listings, setListings] = useState([]);
  const [receivedReviews, setReceivedReviews] = useState([]);
  const [selectedTab, setSelectedTab] = useState('portfolio');
  const [isBlocked, setIsBlocked] = useState(false);
  const [blockError, setBlockError] = useState('');
  const [showBlockConfirmation, setShowBlockConfirmation] = useState(false);
  const [authUserData, setAuthUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userRef = doc(db, 'users', userId);
        const userSnapshot = await getDoc(userRef);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setUserData(userData);

          const listingsQuery = query(collection(db, 'listings'), where('uid', '==', userId));
          const listingsSnapshot = await getDocs(listingsQuery);
          const userListings = listingsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setListings(userListings);

          const reviewsQuery = query(collection(db, 'reviews'), where('ownerId', '==', userId));
          const reviewsSnapshot = await getDocs(reviewsQuery);
          const reviews = await Promise.all(
            reviewsSnapshot.docs.map(async (reviewDoc) => {
              const reviewData = reviewDoc.data();
              const reviewerDoc = await getDoc(doc(db, 'users', reviewData.uid));
              const reviewerData = reviewerDoc.exists() ? reviewerDoc.data() : null;
              const listingDoc = await getDoc(doc(db, 'listings', reviewData.listingId));
              const listingData = listingDoc.exists() ? listingDoc.data() : null;
              return { ...reviewData, id: reviewDoc.id, reviewer: reviewerData, listing: listingData };
            })
          );
          setReceivedReviews(reviews);

          const totalRating = reviews.reduce((acc, review) => acc + review.rating, 0);
          const average = reviews.length > 0 ? (totalRating / reviews.length).toFixed(1) : null;
          setAverageRating(average);

          const currentUserRef = doc(db, 'users', authUser.uid);
          const currentUserSnap = await getDoc(currentUserRef);
          if (currentUserSnap.exists()) {
            const currentUserData = currentUserSnap.data();
            setAuthUserData(currentUserData);
            setIsBlocked(currentUserData.isBlocked?.includes(userId) || false);
          }
        } else {
          console.error('User not found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();

    const userDocRef = doc(db, 'users', userId);
    
    // Set up a real-time listener
    const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setUserData(docSnapshot.data());
      } else {
        console.error('User not found');
      }
    });

    // Cleanup listener on unmount
    return () => unsubscribe();
  }, [userId, authUser.uid]);

  const handleBlockUser = async () => {
    try {
      const userRef = doc(db, 'users', authUser.uid);
      await updateDoc(userRef, {
        isBlocked: arrayUnion(userId),
      });
      setIsBlocked(true);
      setShowBlockConfirmation(false);
    } catch (error) {
      console.error('Error blocking user:', error);
      setBlockError('Failed to block user. Please try again.');
    }
  };

  const handleUnblockUser = async () => {
    try {
      const userRef = doc(db, 'users', authUser.uid);
      await updateDoc(userRef, {
        isBlocked: arrayRemove(userId),
      });
      setIsBlocked(false);
    } catch (error) {
      console.error('Error unblocking user:', error);
      setBlockError('Failed to unblock user. Please try again.');
    }
  };

  const confirmBlockUser = () => {
    setShowBlockConfirmation(true);
  };

  const cancelBlock = () => {
    setShowBlockConfirmation(false);
  };

  const handleCreateSupportChat = async () => {
    try {
      const existingChatQuery = query(
        collection(db, 'messages'),
        where('participants', 'array-contains', authUser.uid)
      );
      const existingChatSnapshot = await getDocs(existingChatQuery);

      let existingChatId = null;
      existingChatSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.participants.includes(userId)) {
          existingChatId = doc.id;
        }
      });

      if (existingChatId) {
        navigate(`/my-messages`);
      } else {
        await addDoc(collection(db, 'messages'), {
          participants: [authUser.uid, userId],
          isSupportChat: true,
          messages: [],
          lastChatTime: new Date()
        });
        navigate(`/my-messages`);
      }
    } catch (error) {
      console.error('Error creating support chat:', error);
    }
  };

  const handleProfileUpdate = async (updatedData) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, updatedData);
      
      // Update the local state
      setUserData((prevData) => ({
        ...prevData,
        ...updatedData,
      }));
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  if (!userData) return <div>Loading...</div>;

  return (
    <UserProfileContent
      userData={userData}
      averageRating={averageRating}
      listings={listings}
      receivedReviews={receivedReviews}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      isBlocked={isBlocked}
      handleBlockUser={handleBlockUser}
      handleUnblockUser={handleUnblockUser}
      confirmBlockUser={confirmBlockUser}
      cancelBlock={cancelBlock}
      handleCreateSupportChat={handleCreateSupportChat}
      blockError={blockError}
      authUser={authUser}
      navigate={navigate}
      formatTelegramHandle={(handle) => handle.startsWith('@') ? handle.slice(1) : handle}
      showBlockConfirmation={showBlockConfirmation}
      handleProfileUpdate={handleProfileUpdate}
    />
  );
};

export default UserProfilePage;

