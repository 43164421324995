// src/components/ReviewSuccessModal.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const ReviewSuccessModal = ({ reviewId, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md mx-auto text-white">
        <h2 className="text-2xl font-bold mb-4">Review Submitted Successfully!</h2>
        <div className="mb-4">
          <p>Your review has been submitted successfully. What would you like to do next?</p>
        </div>
        <div className="flex space-x-4">
          <Link
            to={`/review/${reviewId}`}
            className="pay-forlisting-button w-full text-center"
          >
            View Review
          </Link>
          <Link
            to="/"
            className="hustle-button w-full text-center"
          >
            Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ReviewSuccessModal;

