import React, { useEffect, useState } from 'react';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import { Box, Typography, Card, CardContent, List, ListItem, ListItemText, Divider, Link } from '@mui/material';

const ClientResponses = ({ additionalDetails, clientAnswers, freelancerQuestions, projectID, clientFiles }) => {
  const [fileURLs, setFileURLs] = useState([]);

  useEffect(() => {
    const fetchClientFiles = async () => {
      const storage = getStorage();
      const listRef = ref(storage, `projects/${projectID}/clientUploads`);
      try {
        const res = await listAll(listRef);
        const urls = await Promise.all(res.items.map(item => getDownloadURL(item)));
        setFileURLs(urls);
      } catch (error) {
        console.error('Error fetching client files:', error);
      }
    };

    if (clientFiles && clientFiles.length > 0) {
      setFileURLs(clientFiles);
    } else {
      fetchClientFiles();
    }
  }, [projectID, clientFiles]);

  const getFileName = (url) => {
    const decodedUrl = decodeURIComponent(url);
    const parts = decodedUrl.split('/');
    return parts[parts.length - 1].split('?')[0];
  };

  return (
    <Card sx={{ background: '#1a202c', boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: '8px', padding: '1rem' }}>
      <CardContent>
        <Typography variant="h6" sx={{ color: '#fbbf24', marginBottom: 2 }}>
          Client Responses
        </Typography>
        {freelancerQuestions.length > 0 ? (
          freelancerQuestions.map((question, index) => (
            <Box key={index} sx={{ marginBottom: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'white' }}>
                Question {index + 1}:
              </Typography>
              <Typography variant="body1" sx={{ color: '#f7fafc', marginBottom: 1 }}>
                {question}
              </Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'white' }}>
                Answer:
              </Typography>
              <Typography variant="body1" sx={{ color: '#f7fafc' }}>
                {clientAnswers[index] || 'No answer provided'}
              </Typography>
              {index < freelancerQuestions.length - 1 && (
                <Divider sx={{ marginTop: 2, marginBottom: 2, backgroundColor: '#4a5568' }} />
              )}
            </Box>
          ))
        ) : (
          <Typography variant="body1" sx={{ color: '#f7fafc' }}>
            No questions found.
          </Typography>
        )}
        
        {/* Display additional details if available */}
        {additionalDetails && (
          <Box sx={{ marginTop: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'white' }}>
              Additional Details:
            </Typography>
            <Typography variant="body1" sx={{ color: '#f7fafc' }}>
              {additionalDetails}
            </Typography>
          </Box>
        )}
       

         {clientFiles && clientFiles.length > 0 && (
           <Box sx={{ marginTop: 3 }}>
             <Typography variant="h6" sx={{ color: '#fbbf24', marginBottom: 1 }}>
               Uploaded Files:
             </Typography>
             <List>
               {clientFiles.map((url, index) => (
                 <ListItem key={index} disablePadding>
                   <ListItemText
                     primary={
                       <Link href={url} target="_blank" rel="noopener noreferrer" sx={{ color: '#4a90e2' }}>
                         {getFileName(url)}
                       </Link>
                     }
                   />
                 </ListItem>
               ))}
             </List>
           </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ClientResponses;

