import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import { addDoc, collection, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from '../../contexts/authContext';
import { useNotification } from '../../contexts/notificationContext';
import { v4 as uuidv4 } from 'uuid';
import DisputeProcessModal from './DisputeModal/DisputeProcessModal';

const DisputeForm = ({ projectID, freelancerID, onDisputeSubmitted }) => {
  const [disputeReason, setDisputeReason] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disputeID, setDisputeID] = useState(null);
  const { currentUser } = useAuth();
  const { addNotification } = useNotification();

  useEffect(() => {
    if (!projectID || !freelancerID) {
      console.error('DisputeForm: Missing projectID or freelancerID.');
      setErrorMessage('Cannot submit dispute: Missing required project information.');
    }
  }, [projectID, freelancerID]);

  // Handle opening the modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!disputeReason.trim()) {
      setErrorMessage('Please provide a reason for the dispute.');
      return;
    }

    if (!projectID || !freelancerID) {
      setErrorMessage('Cannot submit dispute: Missing project or freelancer information.');
      return;
    }

    try {
      console.log('Starting dispute submission process...');

      const projectsQuery = query(collection(db, 'projects'), where('projectID', '==', projectID));
      const projectsSnapshot = await getDocs(projectsQuery);

      if (!projectsSnapshot.empty) {
        const projectDoc = projectsSnapshot.docs[0];
        const projectData = projectDoc.data();

        const completionDate = projectData.completedAt ? projectData.completedAt.toDate() : null;
        const currentTime = new Date();

        if (projectData.status === 'completed' && (currentTime - completionDate >= 72 * 60 * 60 * 1000)) {
          setErrorMessage('The dispute window has closed.');
          return;
        }

        const disputeNumber = `dis-${uuidv4()}`;

        const disputeDocRef = await addDoc(collection(db, 'disputes'), {
          disputeNumber,
          projectID,
          clientID: currentUser.uid,
          freelancerID,
          disputeReason,
          disputeStatus: 'open',
          disputeOpenedAt: new Date(),
        });

        await updateDoc(projectDoc.ref, {
          disputeStatus: 'opened',
          disputeOpenedAt: new Date(),
          disputeReason,
        });

        setDisputeID(disputeDocRef.id);
        handleOpenModal(); // Open the modal upon successful submission

        setDisputeReason('');
        addNotification({
          uid: freelancerID,
          message: `A dispute has been opened on your project. Dispute Number: ${disputeNumber}`,
          url: `/dispute/${disputeDocRef.id}`,
          read: false,
          timestamp: new Date(),
        });

        if (onDisputeSubmitted) {
          onDisputeSubmitted();
        }
      } else {
        setErrorMessage('Project not found. Unable to submit dispute.');
      }
    } catch (error) {
      console.error('Error opening dispute:', error);
      setErrorMessage('Failed to open dispute. Please try again later.');
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 shadow-md border rounded-lg bg-gray-800">
      <h2 className="text-xl font-semibold text-center mb-4 text-white">Open a Dispute</h2>
      {errorMessage && <div className="text-red-600 mb-4">{errorMessage}</div>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-300 mb-2">Reason for Dispute</label>
          <textarea
            value={disputeReason}
            onChange={(e) => setDisputeReason(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg bg-gray-700 text-white"
            placeholder="Describe your issue..."
          />
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300"
          disabled={!projectID || !freelancerID}
        >
          Submit Dispute
        </button>
      </form>

      <DisputeProcessModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        disputeID={disputeID}
      />
    </div>
  );
};

export default DisputeForm;

