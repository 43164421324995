import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  Typography,
  Box,
  Button,
  Checkbox,
} from '@mui/material';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { Parser } from 'json2csv';

const PaymentsReadyForDistribution = ({ detailedView = false }) => {
  const [readyPayments, setReadyPayments] = useState([]);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    const fetchReadyForDistributionPayments = async () => {
      try {
        const now = dayjs();
        const cutoffDate = now.subtract(72, 'hour').toDate();

        console.log("Cutoff date for 72 hours:", cutoffDate);

        const readyQuery = query(
          collection(db, 'projects'),
          where('status', '==', 'completed'),
          where('completedAt', '<=', cutoffDate),
          orderBy('completedAt', 'desc')
        );

        const readySnapshot = await getDocs(readyQuery);

        if (readySnapshot.empty) {
          console.log("No documents found that are ready for distribution.");
        } else {
          console.log(`Found ${readySnapshot.size} documents ready for distribution.`);
        }

        const paymentsList = readySnapshot.docs
          .filter(doc => {
            const data = doc.data();
            const hasCompletedAt = data.completedAt && data.completedAt.toDate();
            const isPast72Hours = hasCompletedAt && data.completedAt.toDate() <= cutoffDate;
            const isNotDisputed = !data.disputeStatus || data.disputeStatus === '';
            
            return hasCompletedAt && isPast72Hours && isNotDisputed;
          })
          .map(doc => {
            const data = doc.data();
            return {
              orderID: data.projectID || doc.id,
              amountPaid: data.amountEarned || 'N/A',
              dateCompleted: data.completedAt ? data.completedAt.toDate().toLocaleDateString() : 'N/A',
              freelancerID: detailedView ? data.freelancerID || 'N/A' : undefined,
              referrer: detailedView ? data.referrer || 'N/A' : undefined,
              isPremiumUser: detailedView ? data.isPremiumUser || false : undefined,
            };
          });

        setReadyPayments(paymentsList);
      } catch (error) {
        console.error("Error fetching payments ready for distribution:", error);
      }
    };

    fetchReadyForDistributionPayments();
  }, [detailedView]);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedPayments([]);
    } else {
      setSelectedPayments(readyPayments.map(payment => payment.orderID));
    }
    setSelectAll(!selectAll);
  };

  const handleSelectPayment = (orderID) => {
    const selected = selectedPayments.includes(orderID)
      ? selectedPayments.filter(id => id !== orderID)
      : [...selectedPayments, orderID];
    setSelectedPayments(selected);
  };

  const handleReleaseFunds = (orderID) => {
    console.log(`Release funds for Order ID: ${orderID}`);
    // Placeholder for release funds logic
  };

  const handleBatchRelease = () => {
    console.log("Batch release for selected payments:", selectedPayments);
    // Placeholder for batch release logic
  };

  const exportToCSV = () => {
    try {
      const json2csvParser = new Parser();
      const csv = json2csvParser.parse(readyPayments);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, 'payments_ready_for_distribution.csv');
    } catch (error) {
      console.error("Error exporting to CSV:", error);
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, width: detailedView ? '100%' : '100%', maxWidth: detailedView ? '1400px' : '1000px', margin: '0 auto' }}>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">
          Payments Ready for Distribution
        </Typography>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBatchRelease}
            disabled={selectedPayments.length === 0}
            sx={{ marginRight: 2 }}
          >
            Batch Release
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={exportToCSV}
          >
            Export to CSV
          </Button>
        </Box>
      </Box>
      <TableContainer sx={{ maxHeight: 535, overflowY: 'auto' }}>
        <Table aria-label="payments ready for distribution table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Order ID</TableCell>
              <TableCell align="center">Amount Paid (SOL)</TableCell>
              <TableCell align="center">Completed On</TableCell>
              {detailedView && (
                <>
                  <TableCell align="center">Freelancer ID</TableCell>
                  <TableCell align="center">Referrer</TableCell>
                  <TableCell align="center">Premium User</TableCell>
                </>
              )}
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {readyPayments.map((payment, index) => (
              <TableRow key={index}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedPayments.includes(payment.orderID)}
                    onChange={() => handleSelectPayment(payment.orderID)}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Link href={`/order-tracking/${payment.orderID}`} underline="hover">
                    {payment.orderID}
                  </Link>
                </TableCell>
                <TableCell align="center">{payment.amountPaid}</TableCell>
                <TableCell align="center">{payment.dateCompleted}</TableCell>
                {detailedView && (
                  <>
                    <TableCell align="center">{payment.freelancerID}</TableCell>
                    <TableCell align="center">{payment.referrer}</TableCell>
                    <TableCell align="center">{payment.isPremiumUser ? 'Yes' : 'No'}</TableCell>
                  </>
                )}
                <TableCell align="center">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleReleaseFunds(payment.orderID)}
                  >
                    Release Funds
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PaymentsReadyForDistribution;

