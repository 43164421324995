import React from 'react';

const Description = ({ formState, handleInputChange }) => (
  <div className="space-y-4">
    <div>
      <label className="text-sm text-gray-300 font-semibold">Detailed Description</label>
      <textarea
        name="description"
        value={formState.description}
        onChange={handleInputChange}
        placeholder="Describe your web design service, including what is included, the process, and any other relevant details."
        maxLength="1000"
        required
        className="input-field mt-1 px-4 py-3 text-gray-200 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        rows="5"
      />
    </div>
  </div>
);

export default Description;

