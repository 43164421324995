import React from 'react';
import { FaMedal, FaComments } from 'react-icons/fa'; // Import specific icons
import './Medal.css';

const Medal = ({ icon, name, description, achieved, progress, goal, achievedOn }) => {
  const IconComponent = icon === 'FaMedal' ? FaMedal : FaComments; // Conditionally render the correct icon

  return (
    <div className={`medal ${achieved ? 'achieved' : ''}`}>
      <div className="medal-icon">
        <IconComponent />
      </div>
      <div className="medal-details">
        <h3>{name}</h3>
        <p>{achieved ? `Achieved on ${achievedOn}` : description}</p>
        <div className="progress-bar">
          <div
            className="progress"
            style={{ width: `${(progress / goal) * 100}%` }}
          ></div>
        </div>
        <div className="progress-text">
          {progress}/{goal}
        </div>
      </div>
    </div>
  );
};

export default Medal;

