import { useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { IconContext } from "react-icons";
import { MdHome, MdAssessment, MdPayment, MdGavel } from "react-icons/md";

function Sidenav({ routes }) {
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  useEffect(() => {
    function handleMiniSidenav() {
      // Logic to handle mini sidenav or responsive behavior (optional)
    }

    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();

    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [location]);

  const renderRoutes = routes.map(({ name, IconComponent, route }, key) => {
    return (
      <NavLink key={key} to={route} style={{ textDecoration: 'none', color: 'inherit' }}>
        <div style={{ padding: '10px 20px', display: 'flex', alignItems: 'center' }}>
          <IconComponent />
          <span style={{ marginLeft: '10px' }}>{name}</span>
        </div>
      </NavLink>
    );
  });

  return (
    <IconContext.Provider value={{ size: "20px", color: "#fff" }}>
      <div style={{ width: "250px", background: "#1f2937", height: "100vh", padding: "10px" }}>
        <Divider style={{ backgroundColor: "#ffffff33" }} />
        <List>
          {renderRoutes}
        </List>
      </div>
    </IconContext.Provider>
  );
}

Sidenav.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;

